// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useEffect, useModal, useState, useTextInput} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {Currency, Percent} from '@supermove/utils';

// App
import BillItemTypeCategory from '@shared/modules/Billing/enums/BillItemTypeCategory';
import BillForm from '@shared/modules/Billing/forms/BillForm';
import BillItemForm from '@shared/modules/Billing/forms/BillItemForm';
import DeleteConditionalBillItemModal from 'modules/Project/Billing/components/DeleteConditionalBillItemModal';
import EditBillLineItemCells from 'modules/Project/Billing/components/EditBillLineItemCells';

const Row = Styled.View`
  flex-direction: row;
`;

const Container = Styled.View`
  flex: 1;
`;

const KindLabelContainer = Styled.View`
  padding-horizontal: 8px;
  padding-top: ${(props) => (props.index > 0 ? 7 : 6)}px;
`;

const DescriptionButton = Styled.Touchable`
  position: absolute;
  top: 7px;
  right: 8px;
`;

const NameColumn = ({form, field, billItemForm, index, handleShowDescription}) => {
  const nameField = `${field}.${index}.name`;
  const disabled = !!_.get(form.values, `${field}.${index}.nameFormulaId`);
  const hasError = _.get(form.errors, nameField);
  const handleChangeText = (text) => {
    form.setFieldValue(nameField, text);
  };
  return (
    <EditBillLineItemCells.LeftDataCell
      isLarge
      hasBorder
      hasError={hasError}
      isFirstItem={index === 0}
      disabled={disabled}
      style={{width: null, flex: 1}}
    >
      <KindLabelContainer index={index}>
        <EditBillLineItemCells.CellCaption>
          {BillItemTypeCategory.getDisplayCategory(billItemForm.category, billItemForm)}
        </EditBillLineItemCells.CellCaption>
      </KindLabelContainer>
      <EditBillLineItemCells.NameInput
        value={billItemForm.name}
        handleChangeText={handleChangeText}
        disabled={disabled}
      />
      <DescriptionButton activeOpacity={0.8} onPress={handleShowDescription}>
        <EditBillLineItemCells.CellButtonText>+ Description</EditBillLineItemCells.CellButtonText>
      </DescriptionButton>
    </EditBillLineItemCells.LeftDataCell>
  );
};

const QuantityColumn = ({index}) => {
  return (
    <EditBillLineItemCells.DataCell
      pad
      hasBorder
      isFirstItem={index === 0}
      color={colors.gray.border}
    />
  );
};

const AmountPriceInput = ({form, field, billItemForm, index, disableEditPrices}) => {
  const [showTbd, setShowTbd] = useState(billItemForm.amount === '');
  const [value, setValue] = useState(billItemForm.amount);
  const [isFocused, setIsFocused] = useState(false);
  const amountField = `${field}.${index}.amount`;
  const disabled = disableEditPrices || !!billItemForm.amountFormulaId;
  const hasError = _.get(form.errors, amountField);
  const handleChangeText = (text) => {
    setValue(text);
    form.setFieldValue(
      amountField,
      billItemForm.isEnabledTbdBillItems && text === ''
        ? ''
        : Currency.toForm(Currency.convertToCents(text)),
    );
  };
  useEffect(() => {
    setShowTbd(value === '');
  }, [value]);
  return (
    <EditBillLineItemCells.RightDataCell disabled={disabled} hasBorder isFirstItem={index === 0}>
      {!isFocused && (
        <EditBillLineItemCells.CellText
          vars={{hasPad: true}}
          color={showTbd ? colors.gray.tertiary : colors.black}
        >
          {showTbd ? 'TBD' : billItemForm.amount}
        </EditBillLineItemCells.CellText>
      )}
      <EditBillLineItemCells.CellInput
        vars={{isFocused, hasError}}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setValue(billItemForm.amount);
          setIsFocused(false);
        }}
        onChangeText={handleChangeText}
        disabled={disabled}
        value={value}
      />
    </EditBillLineItemCells.RightDataCell>
  );
};

const PercentPriceInput = ({form, field, billItemForm, index}) => {
  const [showTbd, setShowTbd] = useState(billItemForm.amount === '');
  const [value, setValue] = useState(billItemForm.percentage);
  const [isFocused, setIsFocused] = useState(false);
  const percentField = `${field}.${index}.percentage`;
  const disabled = !!billItemForm.amountFormulaId;
  const hasError = _.get(form.errors, percentField);
  const handleChangeText = (text) => {
    setValue(text);
    form.setFieldValue(
      percentField,
      billItemForm.isEnabledTbdBillItems && text === ''
        ? ''
        : Percent.toForm(Percent.toFloat(text)),
    );
  };
  useEffect(() => {
    setShowTbd(value === '');
  }, [value]);
  return (
    <EditBillLineItemCells.RightDataCell hasBorder isFirstItem={index === 0} disabled={disabled}>
      {!isFocused && (
        <EditBillLineItemCells.CellText
          vars={{hasPad: true}}
          color={showTbd ? colors.gray.tertiary : colors.black}
        >
          {showTbd ? 'TBD' : billItemForm.percentage}
        </EditBillLineItemCells.CellText>
      )}
      <EditBillLineItemCells.CellInput
        vars={{isFocused, hasError}}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setValue(billItemForm.percentage);
          setIsFocused(false);
        }}
        onChangeText={handleChangeText}
        value={value}
        disabled={disabled}
      />
    </EditBillLineItemCells.RightDataCell>
  );
};

const PriceColumn = ({form, field, billItemForm, index, disableEditPrices}) => {
  if (BillItemForm.getIsAmount(billItemForm)) {
    return (
      <AmountPriceInput
        form={form}
        field={field}
        billItemForm={billItemForm}
        index={index}
        disableEditPrices={disableEditPrices}
      />
    );
  }
  if (BillItemForm.getIsPercentage(billItemForm)) {
    return (
      <PercentPriceInput form={form} field={field} billItemForm={billItemForm} index={index} />
    );
  }
  return <EditBillLineItemCells.RightDataCell hasBorder isFirstItem={index === 0} />;
};

const DeleteItemColumn = ({form, billItemForm, conditionalBillRuleForm}) => {
  const deleteConditionalBillItemModal = useModal({name: 'Delete Conditional Bill Item Modal'});
  const handleDelete = () =>
    BillForm.handleRemoveBillItemForm({
      form,
      billFormField: 'billForm',
      billItemFormsField: 'billItemFormsPostSubtotal',
      billItemForm,
    });
  return (
    <React.Fragment>
      <EditBillLineItemCells.IconCell>
        <Space height={3} />
        <EditBillLineItemCells.IconCircle
          activeOpacity={0.8}
          color={colors.gray.tertiary}
          onPress={
            conditionalBillRuleForm ? deleteConditionalBillItemModal.handleOpen : handleDelete
          }
        >
          <Icon source={Icon.Trash} size={12} color={colors.gray.tertiary} />
        </EditBillLineItemCells.IconCircle>
      </EditBillLineItemCells.IconCell>
      <DeleteConditionalBillItemModal
        key={deleteConditionalBillItemModal.key}
        isOpen={deleteConditionalBillItemModal.isOpen}
        handleClose={deleteConditionalBillItemModal.handleClose}
        handleConfirm={handleDelete}
        billRuleName={billItemForm.name}
        billItemName={conditionalBillRuleForm?.name || ''}
      />
    </React.Fragment>
  );
};

const EditBillBillItemsPostSubtotalListItem = ({
  form,
  field,
  billItemForm,
  index,
  disableEditPrices,
}) => {
  const descriptionInput = useTextInput();
  const [isShowingDescription, setIsShowingDescription] = useState(!!billItemForm.description);
  const [inputHeight, setInputHeight] = useState();
  const [isUpdatingDescription, setIsUpdatingDescription] = useState(false);
  const handleUpdateForm = (text) => {
    form.setFieldValue(`${field}.${index}.description`, text);
  };
  const handleOnFocus = () => {
    setIsUpdatingDescription(true);
  };
  const handleOnBlur = () => {
    setIsUpdatingDescription(false);
  };

  return (
    <Container>
      <Row style={{width: '100%'}}>
        <NameColumn
          form={form}
          field={field}
          billItemForm={billItemForm}
          index={index}
          handleShowDescription={() => {
            setIsShowingDescription(true);
            descriptionInput.handleFocus();
          }}
        />
        <QuantityColumn index={index} />
        <PriceColumn
          form={form}
          field={field}
          billItemForm={billItemForm}
          index={index}
          disableEditPrices={disableEditPrices}
        />
        <Space width={2} />
        <DeleteItemColumn
          form={form}
          billItemForm={billItemForm}
          conditionalBillRuleForm={_.find(
            form.values.billForm.billRuleForms,
            (billRuleForm) =>
              _.toString(billItemForm.billItemId) === _.toString(billRuleForm.billItemId),
          )}
        />
      </Row>
      {isShowingDescription && (
        <Row style={{width: '100%'}}>
          <EditBillLineItemCells.FullWidthCell
            style={{width: null, flex: 1, marginLeft: 22, marginRight: 22}}
          >
            <EditBillLineItemCells.DescriptionInputLabel>
              Description
            </EditBillLineItemCells.DescriptionInputLabel>
            <EditBillLineItemCells.DescriptionInput
              ref={descriptionInput.ref}
              value={billItemForm.description}
              onChangeText={handleUpdateForm}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
              onContentSizeChange={(event) => setInputHeight(event.nativeEvent.contentSize.height)}
              height={inputHeight}
              vars={{isUpdating: isUpdatingDescription}}
              multiline
            />
          </EditBillLineItemCells.FullWidthCell>
        </Row>
      )}
    </Container>
  );
};

export default EditBillBillItemsPostSubtotalListItem;
