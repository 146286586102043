// Libraries
import React from 'react';

// Supermove
import {Icon, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover, useModal, usePopover, useResponsive} from '@supermove/hooks';
import {Bill, Job} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import InvoiceStatus from '@shared/modules/Billing/enums/InvoiceStatus';
import UpdateValuesForm from '@shared/modules/Billing/forms/UpdateValuesForm';
import useUpdateValuesMutation from '@shared/modules/Billing/hooks/useUpdateValuesMutation';
import BillingProjectDeleteBillModal from 'modules/Project/Billing/components/BillingProjectDeleteBillModal';
import BillingProjectEditBillModal from 'modules/Project/Billing/components/BillingProjectEditBillModal';
import EditBillBillItemsModal from 'modules/Project/Billing/components/EditBillBillItemsModal';
import EditBillBillRulesModal from 'modules/Project/Billing/components/EditBillBillRulesModal';
import EditBillingValuesModal from 'modules/Project/Billing/components/EditBillingValuesModal';
import UpdateProjectValuesModal from 'modules/Project/Billing/components/UpdateProjectValuesModal';
import ConfirmInvoiceEditModal from 'modules/Storage/components/ConfirmInvoiceEditModal';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const Column = Styled.View`
  flex: 1;
  justify-content: center;
`;

const CaptionText = Styled.H8`
  color: ${(props) => props.color};
  ${fontWeight(500)};
`;

const BillHeaderRow = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const BillTitleText = Styled.H7`
  color: ${colors.gray.primary};
  ${fontWeight(700)};
`;

const BadgeContainer = Styled.View`
  padding-horizontal: 8px;
  background-color: ${(props) => (props.isHighlighted ? colors.blue.accent : colors.gray.border)};
  border-radius: 4px;
  justify-content: center;
  height: 20px;
`;

const BadgeText = Styled.H8`
  font-size: 10px;
  color: ${colors.blue.accentDark};
  ${fontWeight(700)};
`;

const BillActionsPopoverContainer = Styled.View`
  width: 180px;
  border-radius: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  background-color: ${colors.white};
`;

const BillActionsPopoverItem = Styled.Touchable`
  padding: 12px;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) => (props.isHovered ? colors.hover : 'transparent')};
`;

const BillActionsPopoverItemText = Styled.H7`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const BillActionsPopoverAction = ({
  bill,
  showConfirmEditModal,
  children,
  handlePress,
  billActionsPopover,
}) => {
  const confirmInvoiceEditModal = useModal({name: 'Confirm Invoice Edit Modal'});
  const {ref, isHovered} = useHover();

  return (
    <BillActionsPopoverItem
      ref={ref}
      isHovered={isHovered}
      activeOpacity={0.8}
      onPress={() => {
        if (showConfirmEditModal) {
          confirmInvoiceEditModal.handleOpen();
        } else {
          handlePress();
        }
        billActionsPopover.handleClose();
      }}
    >
      <BillActionsPopoverItemText>{children}</BillActionsPopoverItemText>
      {bill.invoice && (
        <ConfirmInvoiceEditModal
          key={confirmInvoiceEditModal.key}
          isOpen={confirmInvoiceEditModal.isOpen}
          invoice={bill.invoice}
          handleAction={handlePress}
          handleClose={confirmInvoiceEditModal.handleClose}
        />
      )}
    </BillActionsPopoverItem>
  );
};

const BillActionsPopover = ({
  bill,
  billActionsPopover,
  billItemsModal,
  billRulesModal,
  updateProjectValuesModal,
  editBillModal,
  deleteBillModal,
  showConfirmEditModal,
}) => {
  return (
    <Popover
      placement={Popover.Positions.BottomEnd}
      isOpen={billActionsPopover.isOpen}
      handleOpen={billActionsPopover.handleOpen}
      handleClose={billActionsPopover.handleClose}
      reference={billActionsPopover.ref}
      offset={[0, 4]}
    >
      <BillActionsPopoverContainer>
        <Space height={10} />
        <BillActionsPopoverAction
          bill={bill}
          showConfirmEditModal={showConfirmEditModal}
          handlePress={billItemsModal.handleOpen}
          billActionsPopover={billActionsPopover}
        >
          Edit billing items
        </BillActionsPopoverAction>
        <Space height={2} />
        <BillActionsPopoverAction
          bill={bill}
          showConfirmEditModal={showConfirmEditModal}
          handlePress={billRulesModal.handleOpen}
          billActionsPopover={billActionsPopover}
        >
          Edit billing rules
        </BillActionsPopoverAction>
        <Space height={2} />
        <BillActionsPopoverAction
          bill={bill}
          showConfirmEditModal={showConfirmEditModal}
          handlePress={updateProjectValuesModal.handleOpen}
          billActionsPopover={billActionsPopover}
        >
          Edit billing values
        </BillActionsPopoverAction>
        <Space height={2} />
        <BillActionsPopoverAction
          bill={bill}
          showConfirmEditModal={showConfirmEditModal}
          handlePress={editBillModal.handleOpen}
          billActionsPopover={billActionsPopover}
        >
          Edit title & description
        </BillActionsPopoverAction>
        {Bill.getIsDeletable(bill) && (
          <React.Fragment>
            <Space height={2} />
            <BillActionsPopoverAction
              bill={bill}
              handlePress={deleteBillModal.handleOpen}
              billActionsPopover={billActionsPopover}
            >
              Delete bill
            </BillActionsPopoverAction>
          </React.Fragment>
        )}
        <Space height={10} />
      </BillActionsPopoverContainer>
    </Popover>
  );
};

const HeaderActions = ({bill, refetch}) => {
  const billActionsPopover = usePopover();
  const billItemsModal = useModal({name: 'Bill Items Modal'});
  const billRulesModal = useModal({name: 'Bill Rules Modal'});
  const updateProjectValuesModal = useModal({name: 'Update Project Values Modal'});
  const editBillingValuesModal = useModal({name: 'Edit Billing Values Modal'});
  const editBillModal = useModal({name: 'Edit Bill Modal'});
  const deleteBillModal = useModal({name: 'Delete Bill Modal'});
  const updateValuesForm = UpdateValuesForm.edit(bill.project);
  const {form, handleSubmit} = useUpdateValuesMutation({
    updateValuesForm,
    onSuccess: () => {
      editBillingValuesModal.handleClose();
      refetch();
    },
    onError: (error) => {
      console.log(error);
    },
  });
  return (
    <Container>
      <Popover.Content innerRef={billActionsPopover.ref}>
        <SecondaryButton
          onPress={billActionsPopover.handleToggle}
          isSelected={billActionsPopover.isOpen}
          iconLeft={Icon.Pen}
          isDisabled={bill.invoice && bill.invoice.status === InvoiceStatus.FINALIZED}
          text={'Edit'}
          iconRight={billActionsPopover.isOpen ? Icon.ChevronUp : Icon.ChevronDown}
          isSmall
        />
      </Popover.Content>
      <EditBillBillItemsModal
        key={billItemsModal.key}
        isOpen={billItemsModal.isOpen}
        billUuid={bill.uuid}
        handleClose={billItemsModal.handleClose}
        refetch={refetch}
      />
      <EditBillBillRulesModal
        key={billRulesModal.key}
        isOpen={billRulesModal.isOpen}
        billUuid={bill.uuid}
        refetch={refetch}
        handleClose={billRulesModal.handleClose}
      />
      <UpdateProjectValuesModal
        key={updateProjectValuesModal.key}
        isOpen={updateProjectValuesModal.isOpen}
        projectUuid={bill.project.uuid}
        refetch={refetch}
        handleClose={updateProjectValuesModal.handleClose}
      />
      <EditBillingValuesModal
        key={editBillingValuesModal.key}
        isOpen={editBillingValuesModal.isOpen}
        handleClose={editBillingValuesModal.handleClose}
        projectUuid={bill.project.uuid}
        updateValuesForm={form}
        handleSubmit={handleSubmit}
      />
      <BillingProjectEditBillModal
        key={editBillModal.key}
        isOpen={editBillModal.isOpen}
        billUuid={bill.uuid}
        refetch={refetch}
        handleClose={editBillModal.handleClose}
      />
      <BillingProjectDeleteBillModal
        key={deleteBillModal.key}
        isOpen={deleteBillModal.isOpen}
        billUuid={bill.uuid}
        refetch={refetch}
        handleClose={deleteBillModal.handleClose}
      />
      <BillActionsPopover
        billActionsPopover={billActionsPopover}
        billItemsModal={billItemsModal}
        billRulesModal={billRulesModal}
        updateProjectValuesModal={editBillingValuesModal}
        showConfirmEditModal={bill.invoice && bill.invoice.status === InvoiceStatus.PAID}
        editBillModal={editBillModal}
        deleteBillModal={deleteBillModal}
        bill={bill}
      />
    </Container>
  );
};

const BillsListItemHeaderV1 = ({
  bill,
  refetch,
  isEditable,
  isHighlighted,
  isProjectAccountingBlock,
}) => {
  const responsive = useResponsive();
  return (
    <BillHeaderRow>
      <Column>
        {!!bill.job && (
          <React.Fragment>
            <Row>
              {!!bill.job.date && (
                <React.Fragment>
                  <BadgeContainer isHighlighted={isHighlighted}>
                    <BadgeText>
                      {Job.getDisplayDate(bill.job, Datetime.DISPLAY_MONTH_DAY)}
                    </BadgeText>
                  </BadgeContainer>
                  <Space width={4} />
                </React.Fragment>
              )}
              <BadgeContainer isHighlighted={isHighlighted}>
                <BadgeText numberOfLines={1}>{Bill.getJobName(bill)}</BadgeText>
              </BadgeContainer>
            </Row>
            <Space height={8} />
          </React.Fragment>
        )}
        <BillTitleText numberOfLines={2}>{bill.title}</BillTitleText>
        {!!bill.description && (
          <React.Fragment>
            <Space height={4} />
            <CaptionText color={isHighlighted ? colors.gray.secondary : colors.gray.secondary}>
              {bill.description}
            </CaptionText>
          </React.Fragment>
        )}
      </Column>
      {isEditable &&
        !(
          responsive.mobile && bill.organization.features.isEnabledProjectBillingEditMobileView
        ) && (
          <HeaderActions
            bill={bill}
            refetch={refetch}
            isProjectAccountingBlock={isProjectAccountingBlock}
          />
        )}
    </BillHeaderRow>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

BillsListItemHeaderV1.fragment = gql`
  ${Bill.getJobName.fragment}
  ${Bill.getIsDeletable.fragment}
  ${Job.getDisplayDate.fragment}
  ${UpdateValuesForm.edit.fragment}
  ${ConfirmInvoiceEditModal.fragment}

  fragment BillsListItemHeaderV1 on Bill {
    id
    description
    uuid
    invoice {
      id
      status
      ...ConfirmInvoiceEditModal
    }
    job {
      id
      date
      ...Job_getDisplayDate
    }
    project {
      id
      uuid
      ...UpdateValuesForm_edit
    }
    organization {
      id
      features {
        isEnabledProjectBillingEditMobileView: isEnabled(
          feature: "PROJECT_BILLING_EDIT_MOBILE_VIEW"
        )
      }
    }
    ...Bill_getIsDeletable
    ...Bill_getJobName
  }
`;

export default BillsListItemHeaderV1;
