// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useDrawer,
  useModal,
  useNavigationDOM,
  usePopover,
  useResponsive,
  useToast,
} from '@supermove/hooks';
import {Invoice} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import TextTooltip from '@shared/design/components/TextTooltip';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import InvoiceStatus from '@shared/modules/Billing/enums/InvoiceStatus';
import BillForm from '@shared/modules/Billing/forms/BillForm';
import InvoiceForm from '@shared/modules/Billing/forms/InvoiceForm';
import SendInvoiceToCodatForm from '@shared/modules/Billing/forms/SendInvoiceToCodatForm';
import UpdateValuesForm from '@shared/modules/Billing/forms/UpdateValuesForm';
import useCreateBillAndBillItemsMutation from '@shared/modules/Billing/hooks/useCreateBillAndBillItemsMutation';
import useSendInvoiceMutation from '@shared/modules/Billing/hooks/useSendInvoiceMutation';
import useSendInvoiceToCodatMutation from '@shared/modules/Billing/hooks/useSendInvoiceToCodatMutation';
import useUpdateValuesMutation from '@shared/modules/Billing/hooks/useUpdateValuesMutation';
import InvoiceChargeCreditCardDrawer from 'modules/Accounting/components/InvoiceChargeCreditCardDrawer';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import useAppContext from 'modules/App/context/useAppContext';
import SelectBillTemplateDrawer from 'modules/Bill/components/SelectBillTemplateDrawer';
import ClientCreditCardsDrawer from 'modules/Client/components/ClientCreditCardsDrawer';
import ChargeCreditCardForBillModal from 'modules/Job/V2/Bill/components/ChargeCreditCardForBillModal';
import UpdateSalesTaxModal from 'modules/Project/Billing/SalesTax/components/UpdateSalesTaxModal';
import BillingProjectAddBillDrawer from 'modules/Project/Billing/components/BillingProjectAddBillDrawer';
import BillingProjectRecordPaymentModalV2 from 'modules/Project/Billing/components/BillingProjectRecordPaymentModalV2';
import EditBillingValuesModal from 'modules/Project/Billing/components/EditBillingValuesModal';
import InvalidInvoiceForExportModal from 'modules/Project/Billing/components/InvalidInvoiceForExportModal';
import FinalizeInvoiceModal from 'modules/Storage/components/FinalizeInvoiceModal';
import UnfinalizeInvoiceModal from 'modules/Storage/components/UnfinalizeInvoiceModal';
import UpdateInvoiceDrawer from 'modules/Storage/components/UpdateInvoiceDrawer';

const Row = Styled.View`
  flex-direction: row;
`;

const IconButton = Styled.ButtonV2`
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const handleAddBill = ({billTypeId, jobId, project, form, handleSubmit}) => {
  if (billTypeId) {
    const billType = _.find(
      project.projectType.billingLibrary.billTypes,
      ({id}) => id === billTypeId,
    );
    form.setFieldValue(`billForm`, BillForm.newFromBillType(project, {billType}));
    form.setFieldValue(`billForm.title`, `${billType.name}`);
  } else {
    form.setFieldValue(`billForm`, BillForm.new(project));
    form.setFieldValue(`billForm.title`, `New bill`);
  }
  form.setFieldValue(`billForm.jobId`, jobId);

  setTimeout(handleSubmit, 0);
};

const LoadingComponent = () => {
  return (
    <Row>
      <SkeletonLoader width={162} height={SkeletonLoader.HEIGHT.ButtonSmall} />
      <Space width={12} />
      <SkeletonLoader width={116} height={SkeletonLoader.HEIGHT.ButtonSmall} />
    </Row>
  );
};

const InvoiceActions = ({
  invoice,
  refetch,
  visibleSections,
  addBillDrawer,
  selectBillTemplateDrawer,
  editBillingValuesModal,
  buttonColor,
  responsive,
  ActionMenuButtonComponent,
}) => {
  const {viewer} = useAppContext();
  const {isAuthorizedAccountingActions} = viewer || {};
  const {navigator} = useNavigationDOM();
  const invoiceActionsPopover = usePopover({name: 'Invoice Actions Popover'});
  const recordPaymentModal = useModal({name: 'Record Payment Modal'});
  const finalizeInvoiceModal = useModal({name: 'Finalize Invoice Modal', enableTracking: true});
  const unfinalizeInvoiceModal = useModal({name: 'Unfinalize Invoice Modal', enableTracking: true});
  const chargeCreditCardModal = useModal({name: 'Charge Credit Card Modal', enableTracking: true});
  const {project} = invoice;

  const invalidInvoiceForExportModal = useModal({
    name: 'Invalid Invoice For Export Modal',
    enableTracking: true,
  });

  const chargeCreditCardDrawer = useDrawer({
    name: 'Charge Credit Card Drawer',
    enableTracking: true,
  });
  const manageCreditCardsDrawer = useDrawer({
    name: 'Manage Credit Cards Drawer',
    enableTracking: true,
  });
  const updateInvoiceDrawer = useDrawer({name: 'Update Invoice Drawer', enableTracking: true});
  const sendInvoiceToCodatToast = useToast({
    ToastComponent: SuccessToast,
    message: `Invoice ${invoice.identifier} has been queued for export. It will be exported in the next batch.`,
  });
  const updateSalesTaxModal = useModal({name: 'Update Sales Tax Modal', enableTracking: true});

  const {handleSubmit: submitInvoiceToCodat} = useSendInvoiceToCodatMutation({
    sendInvoiceToCodatForm: SendInvoiceToCodatForm.edit(invoice),
    onSuccess: () => {
      refetch();
      sendInvoiceToCodatToast.handleToast();
    },
    onError: (errors) => {
      refetch();
      console.log(errors);
    },
  });

  const handleSendInvoiceToCodatSubmit = () => {
    if (!invoice.date || !invoice.hasAtLeastOneInvoiceItem) {
      invalidInvoiceForExportModal.handleOpen();
      return;
    }
    submitInvoiceToCodat();
  };

  const successResendInvoiceToast = useToast({
    ToastComponent: SuccessToast,
    message: `Invoice ${invoice.identifier} sent!`,
  });

  const {handleSubmit: handleResendInvoiceSubmit} = useSendInvoiceMutation({
    invoiceForm: InvoiceForm.idOnly({invoiceId: invoice.id}),
    onSuccess: () => {
      successResendInvoiceToast.handleToast();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  const billForm = BillForm.new(project);
  const createBillAndBillItemsMutation = useCreateBillAndBillItemsMutation({
    billForm,
    onSuccess: () => {
      refetch();
      selectBillTemplateDrawer.handleClose();
    },
    onError: (error) => {
      console.log({error});
    },
  });

  const updateValuesForm = UpdateValuesForm.edit(project);
  const updateValuesMutation = useUpdateValuesMutation({
    updateValuesForm,
    onSuccess: () => {
      editBillingValuesModal.handleClose();
      refetch();
    },
    onError: (error) => {
      console.log({error});
    },
  });

  return (
    <React.Fragment>
      <ActionMenuPopover
        popover={invoiceActionsPopover}
        placement={ActionMenuPopover.Position.BottomEnd}
        width={248}
        actions={Invoice.getInvoiceActions(
          invoice,
          {
            navigator,
            handleSendInvoiceToCodatSubmit,
            recordPaymentModal,
            chargeCreditCardModal,
            chargeCreditCardDrawer,
            manageCreditCardsDrawer,
            finalizeInvoiceModal,
            unfinalizeInvoiceModal,
            updateInvoiceDrawer,
            handleResendInvoiceSubmit,
            updateSalesTaxModal,
            isAuthorizedAccountingActions,
            ...(responsive.desktop
              ? {}
              : {
                  addBillDrawer,
                  selectBillTemplateDrawer,
                  editBillingValuesModal,
                }),
          },
          visibleSections,
        ).map((section) => ({
          ...section,
          actions: section.actions.map((action) => ({
            ...action,
            text: action.label,
          })),
        }))}
      >
        {ActionMenuButtonComponent ? (
          <ActionMenuButtonComponent onPress={invoiceActionsPopover.handleToggle} />
        ) : (
          <IconButton style={{height: 32, width: 32}} onPress={invoiceActionsPopover.handleToggle}>
            <Icon
              source={Icon.EllipsisV}
              size={responsive.desktop ? 14 : 16}
              color={buttonColor || colors.gray.secondary}
            />
          </IconButton>
        )}
      </ActionMenuPopover>
      <ChargeCreditCardForBillModal
        key={chargeCreditCardModal.key}
        isOpen={chargeCreditCardModal.isOpen}
        billUuid={project.currentPrimaryBill.uuid}
        handleClose={() => {
          chargeCreditCardModal.handleClose();
          refetch();
        }}
      />
      <InvoiceChargeCreditCardDrawer
        key={chargeCreditCardDrawer.key}
        isOpen={chargeCreditCardDrawer.isOpen}
        invoiceUuid={invoice.uuid}
        handleClose={chargeCreditCardDrawer.handleClose}
        refetch={refetch}
      />
      <ClientCreditCardsDrawer
        key={manageCreditCardsDrawer.key}
        isOpen={manageCreditCardsDrawer.isOpen}
        clientId={project.billingClientId}
        projectId={project.id}
        handleClose={manageCreditCardsDrawer.handleClose}
      />
      <BillingProjectRecordPaymentModalV2
        key={recordPaymentModal.key}
        isOpen={recordPaymentModal.isOpen}
        handleClose={recordPaymentModal.handleClose}
        refetch={refetch}
        bill={project.currentPrimaryBill}
      />
      <FinalizeInvoiceModal
        key={finalizeInvoiceModal.key}
        invoiceId={invoice.id}
        isOpen={finalizeInvoiceModal.isOpen}
        handleClose={finalizeInvoiceModal.handleClose}
        refetch={refetch}
      />
      <UnfinalizeInvoiceModal
        key={unfinalizeInvoiceModal.key}
        invoiceId={invoice.id}
        isOpen={unfinalizeInvoiceModal.isOpen}
        handleClose={unfinalizeInvoiceModal.handleClose}
        refetch={refetch}
      />
      <UpdateInvoiceDrawer
        key={updateInvoiceDrawer.key}
        invoiceUuid={invoice.uuid}
        isOpen={updateInvoiceDrawer.isOpen}
        handleClose={updateInvoiceDrawer.handleClose}
        refetch={refetch}
      />
      <UpdateSalesTaxModal
        key={updateSalesTaxModal.key}
        isOpen={updateSalesTaxModal.isOpen}
        handleClose={updateSalesTaxModal.handleClose}
        projectUuid={project.uuid}
        parentRefetch={refetch}
      />
      <BillingProjectAddBillDrawer
        key={addBillDrawer.key}
        isOpen={addBillDrawer.isOpen}
        handleClose={addBillDrawer.handleClose}
        project={project}
        refetch={refetch}
      />
      <SelectBillTemplateDrawer
        key={selectBillTemplateDrawer.key}
        isOpen={selectBillTemplateDrawer.isOpen}
        handleClose={selectBillTemplateDrawer.handleClose}
        project={project}
        handleAddBill={(billTypeId, jobId) =>
          handleAddBill({
            billTypeId,
            jobId,
            project,
            form: createBillAndBillItemsMutation.form,
            handleSubmit: createBillAndBillItemsMutation.handleSubmit,
          })
        }
        isSubmitting={createBillAndBillItemsMutation.submitting}
      />
      <EditBillingValuesModal
        key={editBillingValuesModal.key}
        isOpen={editBillingValuesModal.isOpen}
        handleClose={editBillingValuesModal.handleClose}
        updateValuesForm={updateValuesMutation.form}
        handleSubmit={updateValuesMutation.handleSubmit}
        projectUuid={project.uuid}
      />
      <InvalidInvoiceForExportModal
        key={invalidInvoiceForExportModal.key}
        isOpen={invalidInvoiceForExportModal.isOpen}
        onClose={invalidInvoiceForExportModal.handleClose}
      />
    </React.Fragment>
  );
};

const InvoiceBillingActionButtons = ({
  invoice,
  refetch,
  isVisibleInvoiceActions,
  invoiceActionSections,
  ActionMenuButtonComponent,
}) => {
  const responsive = useResponsive();
  const editBillingValuesModal = useModal({name: 'Edit Billing Values Modal'});
  const addBillDrawer = useDrawer({name: 'Add Bill Drawer'});
  const selectBillTemplateDrawer = useDrawer({name: 'Select Bill Template Modal'});
  const isInvoiceFinalized = invoice.status === InvoiceStatus.FINALIZED;
  return responsive.desktop ? (
    <React.Fragment>
      <TextTooltip
        text={
          isInvoiceFinalized
            ? 'Cannot edit billing values after the invoice has been finalized.'
            : null
        }
      >
        <Row>
          <SecondaryButton
            text={'Edit Billing Values'}
            iconLeft={Icon.Pen}
            onPress={editBillingValuesModal.handleOpen}
            isSmall
            isDisabled={isInvoiceFinalized}
          />
        </Row>
      </TextTooltip>
      <Space width={12} />
      <DropdownButton
        isSmall
        actions={[
          {text: 'Add new bill', onPress: () => addBillDrawer.handleOpen()},
          {text: 'Select bill template', onPress: () => selectBillTemplateDrawer.handleOpen()},
        ]}
        text={'Add Bill'}
        iconLeft={Icon.Plus}
        isDisabled={isInvoiceFinalized}
        menuWidth={150}
        menuPosition={DropdownButton.MENU_POSITION.RIGHT}
      />
      {isVisibleInvoiceActions && (
        <React.Fragment>
          <Space width={12} />
          <InvoiceActions
            invoice={invoice}
            refetch={refetch}
            visibleSections={invoiceActionSections}
            addBillDrawer={addBillDrawer}
            selectBillTemplateDrawer={selectBillTemplateDrawer}
            editBillingValuesModal={editBillingValuesModal}
            responsive={responsive}
            ActionMenuButtonComponent={ActionMenuButtonComponent}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  ) : (
    <InvoiceActions
      invoice={invoice}
      refetch={refetch}
      visibleSections={invoiceActionSections}
      addBillDrawer={addBillDrawer}
      selectBillTemplateDrawer={selectBillTemplateDrawer}
      editBillingValuesModal={editBillingValuesModal}
      buttonColor={colors.blue.interactive}
      responsive={responsive}
      ActionMenuButtonComponent={ActionMenuButtonComponent}
    />
  );
};

InvoiceBillingActionButtons.SkeletonLoader = LoadingComponent;

// --------------------------------------------------
// Data
// --------------------------------------------------
InvoiceBillingActionButtons.fragment = gql`
  ${BillForm.new.fragment}
  ${BillForm.newFromBillType.fragment}
  ${BillingProjectAddBillDrawer.fragment}
  ${BillingProjectRecordPaymentModalV2.fragment}
  ${Invoice.getInvoiceActions.fragment}
  ${SendInvoiceToCodatForm.edit.fragment}
  ${SelectBillTemplateDrawer.fragment}
  ${UpdateValuesForm.edit.fragment}

  fragment InvoiceBillingActionButtons on Invoice {
    id
    status
    uuid
    identifier
    hasAtLeastOneInvoiceItem
    project {
      id
      billingClientId
      uuid
      projectType {
        id
        billingLibrary {
          id
          billTypes {
            id
          }
        }
      }
      currentPrimaryBill {
        id
        uuid
        ...BillingProjectRecordPaymentModalV2
      }
      ...BillForm_new
      ...BillForm_newFromBillType
      ...BillingProjectAddBillDrawer
      ...SelectBillTemplateDrawer
      ...UpdateValuesForm_edit
    }
    ...Invoice_getInvoiceActions
    ...SendInvoiceToCodatForm_edit
  }
`;

export default InvoiceBillingActionButtons;
