// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const Container = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const Button = Styled.ButtonV2`
  flex-direction: row;
  cursor: pointer;
`;

const CircleOuter = Styled.View`
  height: 16px;
  width: 16px;
  background-color: ${colors.white};
  border-radius: 8px;
  border-width: 2px;
  border-color: ${({color}: any) => color};
  justify-content: center;
  align-items: center;
  padding: 1px;
  margin-top: 1px;
`;

const CircleInner = Styled.View`
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: ${({color}: any) => color};
`;

const LabelText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${({color}: any) => color};
`;

const HintText = Styled.Text`
  ${Typography.Responsive.Micro}
  color: ${colors.gray.secondary};
`;

const getCircleColor = ({isDisabled, hasErrors, isOn, color}: any) => {
  if (isDisabled) {
    return colors.gray.disabled;
  }
  if (hasErrors) {
    return colors.red.warning;
  }
  if (isOn) {
    return color;
  }
  return colors.gray.tertiary;
};

const getLabelColor = ({isDisabled, hasErrors}: any) => {
  if (isDisabled) {
    return colors.gray.secondary;
  }
  if (hasErrors) {
    return colors.red.warning;
  }
  return colors.gray.primary;
};

const ButtonCircle = ({isOn, color, isDisabled, hasErrors}: any) => {
  const circleColor = getCircleColor({isDisabled, hasErrors, isOn, color});
  return (
    <CircleOuter color={circleColor} activeOpacity={0.8}>
      <CircleInner color={isOn ? circleColor : colors.white} />
    </CircleOuter>
  );
};

const RadioButton = ({
  isOn,
  color = colors.blue.interactive,
  onPress,
  label = '',
  isDisabled = false,
  hasErrors = false,
  hint = '',
}: {
  isOn: boolean;
  color?: string;
  onPress?: (isOn: boolean) => void;
  label?: string;
  isDisabled?: boolean;
  hasErrors?: boolean;
  hint?: string;
}) => {
  const responsive = useResponsive();
  return (
    // Wrapping the radio button in a row keeps the width of the
    // pressable area contained to the content of the radio button
    // rather than extending the full width of the parent component.
    <Row>
      <Button
        onPress={() => !isDisabled && onPress && onPress(!isOn)}
        disabled={!onPress || isDisabled}
        style={{maxWidth: '100%'}}
      >
        <ButtonCircle isOn={isOn} color={color} isDisabled={isDisabled} hasErrors={hasErrors} />
        {!!label && (
          <React.Fragment>
            <Space width={8} />
            <Container style={{flex: 1}}>
              <LabelText responsive={responsive} color={getLabelColor({isDisabled, hasErrors})}>
                {label}
              </LabelText>
              {!!hint && (
                <React.Fragment>
                  <Space height={4} />
                  <HintText responsive={responsive}>{hint}</HintText>
                </React.Fragment>
              )}
            </Container>
          </React.Fragment>
        )}
      </Button>
    </Row>
  );
};

export default RadioButton;
