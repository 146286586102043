// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';
import DashboardForm from '@shared/modules/Reports/forms/DashboardForm';
import useCreateReportDashboardMutation from '@shared/modules/Reports/hooks/useCreateReportDashboardMutation';
import ReportDashboardModalFields from 'modules/Organization/Settings/Reports/components/ReportDashboardModalFields';

const Text = Styled.Text`
  ${Typography.Body}
`;

const CreateReportDashboardModal = ({
  isOpen,
  handleClose,
  handleCreateSuccess,
  organizationId,
  name,
  exploDashboardEmbedId,
  exploVariables,
  refetch,
}) => {
  const reportDashboardForm = DashboardForm.new({
    organizationId,
    name,
    exploDashboardEmbedId,
    exploVariables,
  });

  const {form, submitting, handleSubmit} = useCreateReportDashboardMutation({
    reportDashboardForm,
    onSuccess: ({dashboard}) => {
      handleCreateSuccess(dashboard);
      handleClose();
      refetch();
    },
    onError: () => {},
  });

  return (
    <React.Fragment>
      <SmallModal isOpen={isOpen} style={{overflow: 'visible'}}>
        <SmallModal.HeaderText>{'Create New Report'}</SmallModal.HeaderText>
        <Space height={16} />
        <Text>Create a new report which will be visible on the 'Reports' page.</Text>
        <Space height={16} />
        <ReportDashboardModalFields form={form} field={'reportDashboardForm'} />
        <SmallModal.Footer>
          <SmallModal.Button onPress={handleClose}>Cancel</SmallModal.Button>
          <SmallModal.Button
            color={colors.blue.interactive}
            onPress={handleSubmit}
            isSubmitting={submitting}
          >
            Create
          </SmallModal.Button>
        </SmallModal.Footer>
      </SmallModal>
    </React.Fragment>
  );
};

export default CreateReportDashboardModal;
