// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import DashboardCategory from '@shared/modules/Reports/enums/DashboardCategory';
import DashboardKind from '@shared/modules/Reports/enums/DashboardKind';

export type DashboardType = {
  id: string;
  uuid: string;
  name: string;
  organizationId: string;
  kind: typeof DashboardKind;
  category: typeof DashboardCategory;
  exploDashboardEmbedId: string;
  exploVariables: string;
  index: number;
  description: string | null;
  dashboardCategoryId: string;
  dashboardTags: {
    id: string;
    tag: {
      id: string;
      name: string;
    };
  }[];
  helpArticleUrl: string | null;
};

const _new = ({
  organizationId,
  name,
  exploDashboardEmbedId,
  exploVariables,
}: {
  organizationId: string;
  name: string;
  exploDashboardEmbedId: string;
  exploVariables: string;
}) => ({
  dashboardId: null,
  organizationId,
  kind: DashboardKind.EXPLO,
  category: DashboardCategory.GENERAL,
  dashboardCategoryId: null,
  name: name || '',
  exploDashboardEmbedId: exploDashboardEmbedId || '',
  exploVariables: exploVariables || null,
  index: null,
  uuid: null,
  description: null,
  helpArticleUrl: null,
  tagIds: [],
});

const toForm = ({
  id,
  organizationId,
  kind,
  category,
  name,
  exploDashboardEmbedId,
  exploVariables,
  index,
  uuid,
}: ReturnType<typeof _new> & {id?: string}) => ({
  organizationId,
  kind,
  category,
  name,
  exploDashboardEmbedId,
  exploVariables,
  id,
  index,
  uuid,
});

const toMutation = ({
  organizationId,
  kind,
  category,
  name,
  exploDashboardEmbedId,
  exploVariables,
  id,
  index,
  dashboardCategoryId,
  tagIds,
  description,
  helpArticleUrl,
}: ReturnType<typeof _new> & {id?: string}) => ({
  organizationId,
  kind,
  category,
  name,
  exploDashboardEmbedId,
  exploVariables: exploVariables && exploVariables.trim() ? exploVariables : null,
  dashboardId: id,
  index,
  dashboardCategoryId,
  tagIds,
  description,
  helpArticleUrl,
});

const edit = withFragment(
  (dashboard: DashboardType) => ({
    id: dashboard.id,
    uuid: dashboard.uuid,
    name: dashboard.name,
    organizationId: dashboard.organizationId,
    kind: dashboard.kind,
    category: dashboard.category,
    exploDashboardEmbedId: dashboard.exploDashboardEmbedId,
    exploVariables: dashboard.exploVariables,
    index: dashboard.index,
    dashboardCategoryId: dashboard.dashboardCategoryId,
    tagIds: dashboard.dashboardTags?.map((dt) => dt.tag.id),
    description: dashboard.description,
    helpArticleUrl: dashboard.helpArticleUrl,
  }),
  gql`
    fragment DashboardForm_edit on Dashboard {
      id
      organizationId
      kind
      category
      name
      exploDashboardEmbedId
      exploVariables
      index
      uuid
      dashboardCategoryId
      dashboardTags {
        id
        tag {
          id
          name
        }
      }
      helpArticleUrl
    }
  `,
);

const DashboardForm = {
  new: _new,
  toForm,
  toMutation,
  edit,
};

export default DashboardForm;
