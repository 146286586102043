// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useModal,
  useState,
  useToast,
  useQuery,
  useResponsive,
  useDrawer,
  useNavigationDOM,
} from '@supermove/hooks';
import {Invoice} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Datetime, List} from '@supermove/utils';

// App
import CollapsibleContent from '@shared/design/components/CollapsibleContent';
import FieldValue from '@shared/design/components/Field/FieldValue';
import TextTooltip from '@shared/design/components/TextTooltip';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import InvoicePaymentTerm from '@shared/modules/Billing/enums/InvoicePaymentTerm';
import InvoiceStatus from '@shared/modules/Billing/enums/InvoiceStatus';
import BillForm from '@shared/modules/Billing/forms/BillForm';
import InvoiceForm from '@shared/modules/Billing/forms/InvoiceForm';
import SendInvoiceToCodatForm from '@shared/modules/Billing/forms/SendInvoiceToCodatForm';
import UpdateValuesForm from '@shared/modules/Billing/forms/UpdateValuesForm';
import useCreateBillAndBillItemsMutation from '@shared/modules/Billing/hooks/useCreateBillAndBillItemsMutation';
import useSendInvoiceMutation from '@shared/modules/Billing/hooks/useSendInvoiceMutation';
import useSendInvoiceToCodatMutation from '@shared/modules/Billing/hooks/useSendInvoiceToCodatMutation';
import useUpdateValuesMutation from '@shared/modules/Billing/hooks/useUpdateValuesMutation';
import InvoiceChargeCreditCardDrawer from 'modules/Accounting/components/InvoiceChargeCreditCardDrawer';
import Switch from 'modules/App/components/Switch';
import useAppContext from 'modules/App/context/useAppContext';
import SelectBillTemplateModal from 'modules/Bill/components/SelectBillTemplateModal';
import ClientCreditCardsDrawer from 'modules/Client/components/ClientCreditCardsDrawer';
import ChargeCreditCardForBillModal from 'modules/Job/V2/Bill/components/ChargeCreditCardForBillModal';
import BillingProjectAddBillModal from 'modules/Project/Billing/components/BillingProjectAddBillModal';
import BillingProjectBillsListV1 from 'modules/Project/Billing/components/BillingProjectBillsListV1';
import BillingProjectPaymentsList from 'modules/Project/Billing/components/BillingProjectPaymentsList';
import BillingProjectRecordPaymentModal from 'modules/Project/Billing/components/BillingProjectRecordPaymentModal';
import EditBillingValuesModal from 'modules/Project/Billing/components/EditBillingValuesModal';
import UpdateProjectValuesModal from 'modules/Project/Billing/components/UpdateProjectValuesModal';
import FinalizeInvoiceModal from 'modules/Storage/components/FinalizeInvoiceModal';
import InvoiceExportStatusWithDate from 'modules/Storage/components/InvoiceExportStatusWithDate';
import InvoiceStatusBadge from 'modules/Storage/components/InvoiceStatusBadge';
import UnfinalizeInvoiceModal from 'modules/Storage/components/UnfinalizeInvoiceModal';
import UpdateInvoiceDrawer from 'modules/Storage/components/UpdateInvoiceDrawer';

const View = Styled.View``;

const Container = Styled.View`
  z-index: ${(props) => 100 - props.sectionIndex};
`;

const PaddingContainer = Styled.View`
  padding: 16px;
`;

const BorderedContainer = Styled.View`
  border-radius: 4px;
  border-color: ${colors.gray.border};
  border-width: 1px;
`;

const InvoiceDetails = Styled.View`
  padding-horizontal: 12px;
  flex-direction: row;
`;

const FieldValueContainer = Styled.View`
  flex: 1;
`;

const SwitchContainer = Styled.View`
  align-self: flex-start;
  margin-right: 4px;
`;

const WarningCallout = Styled.View`
  flex-direction: row;
  padding-vertical: 8px;
  padding-horizontal: 24px;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  border-color: ${colors.red.accent};
  background-color: ${colors.red.accent};
`;

const WarningText = Styled.Text`
  ${Typography.Body}
  color: ${colors.red.warning};
`;

const LinkText = Styled.Text`
  ${Typography.Link};
`;

const ReorderSwitch = ({isReordering, setIsReordering}) => {
  return (
    <SwitchContainer>
      <Switch isOn={isReordering} onChange={setIsReordering} label={'Reorder Bills'} />
    </SwitchContainer>
  );
};

const getDisplayDate = (date) =>
  date ? Datetime.convertToDisplayDate(date, Datetime.DISPLAY_SHORT_DATE) : null;

const getProjectBillsListKey = ({project}) => {
  // The primary responsibility of this key is to know when to refresh the underlying
  // ReorderBillsForm in BillingProjectBillsListV1. After the initial render, an action
  // such as 'Mark this job as completed' would get an updated list of bills, in which
  // case we would need ReorderBillsForm to be refreshed to show the updated bills.

  // This key is unique from the top level component key because this key is based on
  // the data coming in from the query that the top level component key is refetching.
  // While the top level component key triggers the block refetch, it's the data from
  // the refetch that would update this key.
  return project.activeJobsAggregateBill.bills.map((bill) => bill.id).join('_');
};

const JobProjectInvoiceBlockContent = ({sectionIndex, job, refetch}) => {
  const responsive = useResponsive();
  const [isReordering, setIsReordering] = useState(false);
  const {date, dueDate, paymentTerm, hasEmailSendError} = job.project.mainMoveInvoice;

  return (
    <Container sectionIndex={sectionIndex}>
      {hasEmailSendError && (
        <React.Fragment>
          <WarningCallout>
            <WarningText color={colors.red.warning}>Invoice failed to send.</WarningText>
            <Space style={{flex: 1}} />
            <a href={Invoice.EMAIL_ERROR_HELP_LINK} target={'_blank'}>
              <LinkText>View help article</LinkText>
            </a>
          </WarningCallout>
          <Space height={16} />
        </React.Fragment>
      )}
      <BorderedContainer>
        <Space height={12} />
        <InvoiceDetails>
          <FieldValueContainer>
            <FieldValue
              label={'Invoice Date'}
              value={getDisplayDate(date)}
              empty={'-'}
              numberOfLines={2}
              size={FieldValue.SIZE.MEDIUM}
            />
          </FieldValueContainer>
          <FieldValueContainer>
            <FieldValue
              label={'Due Date'}
              value={getDisplayDate(dueDate)}
              empty={'-'}
              numberOfLines={2}
              size={FieldValue.SIZE.MEDIUM}
            />
          </FieldValueContainer>
          {!responsive.mobile && (
            <React.Fragment>
              <FieldValueContainer>
                <FieldValue
                  label={'Payment Terms'}
                  value={InvoicePaymentTerm.getDisplayName(paymentTerm)}
                  empty={'-'}
                  numberOfLines={2}
                  size={FieldValue.SIZE.MEDIUM}
                />
              </FieldValueContainer>
              <FieldValueContainer>
                <FieldValue
                  label={'Export Status'}
                  numberOfLines={2}
                  size={FieldValue.SIZE.MEDIUM}
                />
                <InvoiceExportStatusWithDate invoice={job.project.mainMoveInvoice} showDate />
              </FieldValueContainer>
            </React.Fragment>
          )}
        </InvoiceDetails>
        <Space height={12} />
      </BorderedContainer>
      <Space height={16} />
      <ReorderSwitch isReordering={isReordering} setIsReordering={setIsReordering} />
      <Space height={16} />
      <BillingProjectBillsListV1
        key={getProjectBillsListKey({project: job.project})}
        project={job.project}
        aggregateBill={job.project.activeJobsAggregateBill}
        refetch={refetch}
        isEditable
        isReordering={isReordering}
        highlightedJobId={job.id}
      />
      <Space height={32} />
      <BorderedContainer>
        <Space height={12} />
        <BillingProjectPaymentsList
          aggregateBill={job.project.activeJobsAggregateBill}
          refetch={refetch}
          isEditable
        />
        <Space height={12} />
      </BorderedContainer>
      <Space height={16} />
    </Container>
  );
};

const JobProjectInvoiceBlockSection = ({sectionIndex, job, refetch}) => {
  const {viewer} = useAppContext();
  const {isAuthorizedAccountingActions} = viewer || {};
  const {navigator} = useNavigationDOM();
  const responsive = useResponsive();
  const addBillModal = useModal({name: 'Add Bill Modal'});
  const selectBillTemplateModal = useModal({
    name: 'Select Bill Template Modal',
    enableTracking: true,
  });
  const updateProjectValuesModal = useModal({name: 'Update Project Values Modal'});
  const editBillingValuesModal = useModal({name: 'Edit Billing Values Modal'});
  const recordPaymentModal = useModal({name: 'Record Payment Modal'});
  const finalizeInvoiceModal = useModal({name: 'Finalize Invoice Modal', enableTracking: true});
  const unfinalizeInvoiceModal = useModal({name: 'Unfinalize Invoice Modal', enableTracking: true});
  const chargeCreditCardModal = useModal({name: 'Charge Credit Card Modal', enableTracking: true});
  const chargeCreditCardDrawer = useDrawer({
    name: 'Charge Credit Card Drawer',
    enableTracking: true,
  });
  const manageCreditCardsDrawer = useDrawer({
    name: 'Manage Credit Cards Drawer',
    enableTracking: true,
  });
  const updateInvoiceDrawer = useDrawer({name: 'Update Invoice Drawer', enableTracking: true});
  const isInvoiceFinalized = job.project.mainMoveInvoice.status === InvoiceStatus.FINALIZED;

  const billForm = BillForm.new(job.project);
  const {form, handleSubmit, submitting} = useCreateBillAndBillItemsMutation({
    billForm,
    onSuccess: () => {
      refetch();
      selectBillTemplateModal.handleClose();
    },
    onError: (error) => {
      console.log({error});
    },
  });

  const updateValuesForm = UpdateValuesForm.edit(job.project);
  const updateValuesMutation = useUpdateValuesMutation({
    updateValuesForm,
    onSuccess: () => {
      editBillingValuesModal.handleClose();
      refetch();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const sendInvoiceToCodatToast = useToast({
    ToastComponent: SuccessToast,
    message: `Invoice ${job.project.mainMoveInvoice.identifier} has been queued for export. It will be exported in the next batch.`,
    isClosable: true,
  });

  const {handleSubmit: handleSendInvoiceToCodatSubmit} = useSendInvoiceToCodatMutation({
    sendInvoiceToCodatForm: SendInvoiceToCodatForm.edit(job.project.mainMoveInvoice),
    onSuccess: () => {
      refetch();
      sendInvoiceToCodatToast.handleToast();
    },
    onError: (errors) => {
      refetch();
      console.log(errors);
    },
  });

  const successResendInvoiceToast = useToast({
    ToastComponent: SuccessToast,
    message: `Invoice ${job.project.mainMoveInvoice.identifier} sent!`,
    isClosable: true,
  });

  const {handleSubmit: handleResendInvoiceSubmit} = useSendInvoiceMutation({
    invoiceForm: InvoiceForm.idOnly({invoiceId: job.project.mainMoveInvoice.id}),
    onSuccess: () => {
      successResendInvoiceToast.handleToast();
    },
    onError: (errors) => {
      console.log(errors);
    },
  });

  const handleAddBill = (billTypeId = null, jobId = null) => {
    if (billTypeId) {
      const billType = _.find(
        job.project.projectType.billingLibrary.billTypes,
        ({id}) => id === billTypeId,
      );
      form.setFieldValue(`billForm`, BillForm.newFromBillType(job.project, {billType}));
      form.setFieldValue(`billForm.title`, `${billType.name}`);
    } else {
      form.setFieldValue(`billForm`, BillForm.new(job.project));
      form.setFieldValue(`billForm.title`, `New bill`);
    }
    form.setFieldValue(`billForm.jobId`, jobId);

    setTimeout(handleSubmit, 0);
  };

  const handleOpenBillingValuesEditor = () => {
    editBillingValuesModal.handleOpen();
  };

  return (
    <React.Fragment>
      <CollapsibleContent
        title={`Invoice ${job.project.mainMoveInvoice.identifier}`}
        TitleIconComponent={
          <React.Fragment>
            {job.project.mainMoveInvoice.hasEmailSendError && (
              <React.Fragment>
                <TextTooltip text={'Invoice failed to send'}>
                  <View>
                    <Icon source={Icon.ExclamationTriangle} size={16} color={colors.red.warning} />
                  </View>
                </TextTooltip>
                <Space width={12} />
              </React.Fragment>
            )}
            <InvoiceStatusBadge invoice={job.project.mainMoveInvoice} />
          </React.Fragment>
        }
        primaryActionText={!responsive.mobile ? 'Add Bill' : 'Actions'}
        primaryDropdownActions={[
          {text: 'Add new bill', onPress: () => addBillModal.handleOpen()},
          {text: 'Select bill template', onPress: () => selectBillTemplateModal.handleOpen()},
          ...List.insertIf(responsive.mobile, {
            text: 'Edit Billing Values',
            onPress: handleOpenBillingValuesEditor,
          }),
        ]}
        isPrimaryActionDropdown
        primaryActionLeftIcon={Icon.Plus}
        isDisabledPrimaryAction={isInvoiceFinalized}
        isDisabledSecondaryAction={isInvoiceFinalized}
        primaryActionTooltipText={
          isInvoiceFinalized ? `Bills can't be added to a finalized invoice` : null
        }
        handleSecondaryAction={handleOpenBillingValuesEditor}
        secondaryActionText={!responsive.mobile ? 'Edit Billing Values' : null}
        secondaryActionLeftIcon={Icon.Pen}
        menuActions={Invoice.getInvoiceActions(
          job.project.mainMoveInvoice,
          {
            handleSendInvoiceToCodatSubmit,
            recordPaymentModal,
            chargeCreditCardModal,
            chargeCreditCardDrawer,
            manageCreditCardsDrawer,
            finalizeInvoiceModal,
            unfinalizeInvoiceModal,
            updateInvoiceDrawer,
            handleResendInvoiceSubmit,
            navigator,
            isAuthorizedAccountingActions,
          },
          ['INVOICE', 'PAYMENTS', 'ADMIN'],
        )}
      >
        <PaddingContainer>
          <JobProjectInvoiceBlockContent sectionIndex={sectionIndex} job={job} refetch={refetch} />
        </PaddingContainer>
      </CollapsibleContent>
      {/* Modals and Popovers */}
      <BillingProjectAddBillModal
        key={addBillModal.key}
        isOpen={addBillModal.isOpen}
        handleClose={addBillModal.handleClose}
        project={job.project}
        refetch={refetch}
      />
      <SelectBillTemplateModal
        key={selectBillTemplateModal.key}
        isOpen={selectBillTemplateModal.isOpen}
        handleClose={selectBillTemplateModal.handleClose}
        project={job.project}
        handleAddBill={handleAddBill}
        isSubmitting={submitting}
      />
      <UpdateProjectValuesModal
        key={updateProjectValuesModal.key}
        isOpen={updateProjectValuesModal.isOpen}
        handleClose={updateProjectValuesModal.handleClose}
        projectUuid={job.project.uuid}
        refetch={refetch}
      />
      <EditBillingValuesModal
        key={editBillingValuesModal.key}
        isOpen={editBillingValuesModal.isOpen}
        handleClose={editBillingValuesModal.handleClose}
        updateValuesForm={updateValuesMutation.form}
        handleSubmit={updateValuesMutation.handleSubmit}
        projectUuid={job.project.uuid}
      />
      <ChargeCreditCardForBillModal
        key={chargeCreditCardModal.key}
        isOpen={chargeCreditCardModal.isOpen}
        jobId={job.id}
        billUuid={job.project.currentPrimaryBill.uuid}
        handleClose={() => {
          chargeCreditCardModal.handleClose();
          refetch();
        }}
      />
      <InvoiceChargeCreditCardDrawer
        key={chargeCreditCardDrawer.key}
        isOpen={chargeCreditCardDrawer.isOpen}
        handleClose={chargeCreditCardDrawer.handleClose}
        invoiceUuid={job.project.mainMoveInvoice.uuid}
        refetch={refetch}
      />
      <ClientCreditCardsDrawer
        key={manageCreditCardsDrawer.key}
        isOpen={manageCreditCardsDrawer.isOpen}
        clientId={job.project.billingClientId}
        projectId={job.project.id}
        handleClose={manageCreditCardsDrawer.handleClose}
      />
      <BillingProjectRecordPaymentModal
        key={recordPaymentModal.key}
        isOpen={recordPaymentModal.isOpen}
        handleClose={recordPaymentModal.handleClose}
        refetch={refetch}
        bill={job.project.currentPrimaryBill}
        jobUuid={job.uuid}
      />
      <FinalizeInvoiceModal
        key={finalizeInvoiceModal.key}
        invoiceId={job.project.mainMoveInvoice.id}
        isOpen={finalizeInvoiceModal.isOpen}
        handleClose={finalizeInvoiceModal.handleClose}
        refetch={refetch}
      />
      <UnfinalizeInvoiceModal
        key={unfinalizeInvoiceModal.key}
        invoiceId={job.project.mainMoveInvoice.id}
        isOpen={unfinalizeInvoiceModal.isOpen}
        handleClose={unfinalizeInvoiceModal.handleClose}
        refetch={refetch}
      />
      <UpdateInvoiceDrawer
        key={updateInvoiceDrawer.key}
        invoiceUuid={job.project.mainMoveInvoice.uuid}
        isOpen={updateInvoiceDrawer.isOpen}
        handleClose={updateInvoiceDrawer.handleClose}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const JobProjectInvoiceBlockQuery = ({sectionIndex, job, parentRefetch}) => {
  const {data, loading, refetch} = useQuery(JobProjectInvoiceBlock.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      jobUuid: job.uuid,
    },
  });

  return (
    <Loading loading={loading}>
      {() => {
        return (
          <JobProjectInvoiceBlockSection
            sectionIndex={sectionIndex}
            job={data.job}
            refetch={() => {
              refetch();
              parentRefetch();
            }}
          />
        );
      }}
    </Loading>
  );
};

const JobProjectInvoiceBlock = ({sectionIndex, job, refetch}) => {
  return (
    <JobProjectInvoiceBlockQuery
      key={`${job.project.billsHash}_${job.project.totalRevenue}`}
      sectionIndex={sectionIndex}
      job={job}
      parentRefetch={refetch}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobProjectInvoiceBlock.listener = gql`
  fragment JobProjectInvoiceBlock_listener on Project {
    id
    billsHash
    totalRevenue
  }
`;

JobProjectInvoiceBlock.fragment = gql`
  ${JobProjectInvoiceBlock.listener}
  fragment JobProjectInvoiceBlock on Job {
    id
    uuid
    project {
      id
      ...JobProjectInvoiceBlock_listener
    }
  }
`;

JobProjectInvoiceBlock.query = gql`
  ${JobProjectInvoiceBlock.listener}
  ${Invoice.getInvoiceActions.fragment}
  ${SelectBillTemplateModal.fragment}
  ${BillForm.new.fragment}
  ${BillForm.newFromBillType.fragment}
  ${BillingProjectBillsListV1.fragment}
  ${BillingProjectAddBillModal.fragment}
  ${BillingProjectRecordPaymentModal.fragment}
  ${BillingProjectPaymentsList.fragment}
  ${InvoiceStatusBadge.fragment}
  ${InvoiceExportStatusWithDate.fragment}

  query JobProjectInvoiceBlock($jobUuid: String!) {
    ${gql.query}
    job(uuid: $jobUuid) {
      id
      uuid
      project {
        id
        uuid
        status
        billingClientId
        mainMoveInvoice {
          id
          uuid
          identifier
          status
          date
          dueDate
          paymentTerm
          hasEmailSendError
          ...Invoice_getInvoiceActions
          ...InvoiceStatusBadge
          ...InvoiceExportStatusWithDate
        }
        activeJobsAggregateBill {
          bills {
            id
          }
          ...BillingProjectBillsListV1_AggregateBill
          ...BillingProjectPaymentsList_AggregateBill
        }
        currentPrimaryBill {
          id
          uuid
          ...BillingProjectRecordPaymentModal
        }
        ...BillingProjectBillsListV1
        ...BillingProjectAddBillModal
        ...SelectBillTemplateModal
        ...BillForm_new
        ...BillForm_newFromBillType
        ...JobProjectInvoiceBlock_listener
      }
    }
  }
`;

export default JobProjectInvoiceBlock;
