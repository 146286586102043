// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Space, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {useToast} from '@supermove/hooks';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import DashboardLinkedToGlobalDashboardForm from '@shared/modules/Reports/forms/DashboardLinkedToGlobalDashboardForm';
import useUpdateDashboardLinkedToGlobalDashboardMutation from '@shared/modules/Reports/hooks/useUpdateDashboardLinkedToGlobalDashboardMutation';

const Container = Styled.View`
`;

export type DashboardDataModel = {
  id: string;
  uuid: string;
  organizationId: string;
  name: string;
  description: string;
  dashboardCategoryId: string;
  exploVariables: string;
};

type DashboardCategoryDataModel = {
  id: string;
  name: string;
};

type EditDashboardModalQueryResponse = {
  dashboardCategories: DashboardCategoryDataModel[];
};

const toDashboardCategoryOptions = (dashboardCategories: DashboardCategoryDataModel[]) =>
  dashboardCategories
    .map((category) => ({
      label: category.name,
      value: _.toNumber(category.id),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

interface DashboardsPageEditDashboardModalProps {
  dashboard: DashboardDataModel;
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
}

const DashboardsPageEditDashboardModal = ({
  dashboard,
  isOpen,
  handleClose,
  refetch,
}: DashboardsPageEditDashboardModalProps) => {
  const updateGlobalDashboardSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Dashboard updated!',
  });

  const {data, loading} = useQuery<EditDashboardModalQueryResponse>(
    DashboardsPageEditDashboardModal.query,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const dashboardCategories = data?.dashboardCategories ?? [];

  const dashboardLinkedToGlobalDashboardForm = DashboardLinkedToGlobalDashboardForm.edit(dashboard);
  const {form, submitting, handleSubmit} = useUpdateDashboardLinkedToGlobalDashboardMutation({
    dashboardLinkedToGlobalDashboardForm,
    onSuccess: () => {
      updateGlobalDashboardSuccessToast.handleToast();
      refetch();
      handleClose();
    },
    onError: (errors: any) => console.log({errors}),
  });

  const field = 'dashboardLinkedToGlobalDashboardForm';

  return (
    <SmallModal
      isOpen={isOpen}
      title={'Edit Report Details'}
      handleAction={handleSubmit}
      handleClose={handleClose}
      isLoading={submitting}
    >
      <Container>
        <FieldInput
          {...form}
          index={0}
          name={`${field}.name`}
          label={'Report Name'}
          isResponsive
          isRequired
          input={{
            placeholder: 'Enter report name',
          }}
        />
        <Space height={16} />
        <FieldInput
          {...form}
          index={1}
          name={`${field}.dashboardCategoryId`}
          label={'Category'}
          isResponsive
          isRequired
          component={DropdownInput}
          input={{
            options: toDashboardCategoryOptions(dashboardCategories),
            setFieldValue: form.setFieldValue,
            placeholder: `Type to search`,
            style: {flex: 1},
            noOptionsMessage: '',
            isPortaled: true,
          }}
        />
        <Space height={16} />
        <FieldInput
          {...form}
          index={4}
          name={`${field}.description`}
          label={'Description'}
          isResponsive
          input={{
            placeholder: 'Enter description',
            multiline: true,
            style: {height: 76, paddingTop: 8},
          }}
        />
        <Space height={16} />
      </Container>
    </SmallModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DashboardsPageEditDashboardModal.query = gql`
  query EditDashboardModal {
    ${gql.query}
    dashboardCategories {
      id
      name
    }
  }
`;

DashboardsPageEditDashboardModal.fragment = gql`
  fragment DashboardsPageEditDashboardModalFragment on Dashboard {
    id
    uuid
    organizationId
    name
    description
    dashboardCategoryId
    exploVariables
  }
`;

export default DashboardsPageEditDashboardModal;
