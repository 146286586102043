// Supermove
import {gql} from '@supermove/graphql';
import {DashboardTagModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

export type DashboardTagType = {
  id: string;
  name: string;
  isDeleted: boolean;
};

export interface DashboardTagFormType {
  dashboardTagId: number | null;
  name: string;
  isDeleted: boolean;
}

const _new = ({name}: Pick<DashboardTagFormType, 'name'>) => ({
  dashboardTagId: null,
  name: name || '',
  isDeleted: false,
});

const edit = withFragment(
  (dashboardTag: DashboardTagModel) => {
    return {
      dashboardTagId: dashboardTag.id,
      name: dashboardTag.name,
      isDeleted: dashboardTag.isDeleted,
    };
  },
  gql`
    fragment DashboardTagForm_edit on Tag {
      id
      name
      isDeleted
    }
  `,
);

const toForm = (dashboardTagForm: DashboardTagFormType): DashboardTagFormType => ({
  dashboardTagId: dashboardTagForm.dashboardTagId,
  name: dashboardTagForm.name,
  isDeleted: dashboardTagForm.isDeleted,
});

const toMutation = (dashboardTagForm: DashboardTagFormType): DashboardTagFormType => ({
  dashboardTagId: dashboardTagForm.dashboardTagId,
  name: dashboardTagForm.name,
  isDeleted: dashboardTagForm.isDeleted,
});

const DashboardTagForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default DashboardTagForm;
