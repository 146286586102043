// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useNavigationDOM, useHover} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Currency, Datetime} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Checkbox from '@shared/design/components/Checkbox';
import Table from '@shared/design/components/TableV2Deprecated';
import ProjectTypeCategory from '@shared/modules/Project/enums/ProjectTypeCategory';
import ClaimActionsButton from 'modules/Project/Claim/components/ClaimActionsButton';
import EditProjectClaimDrawer from 'modules/Project/Claim/components/EditProjectClaimDrawer';
import ProjectClientCommunicationButtons from 'modules/Project/components/ProjectClientCommunicationButtons';

const Container = Styled.View`
`;

const Cell = Styled.View`
  flex: 1;
`;

const HeaderText = Styled.Text`
  ${Typography.Label}
`;

const PrimaryText = Styled.Text`
  ${Typography.Body}
`;

const SecondaryText = Styled.Text`
  ${Typography.Body};
  color: ${colors.gray.secondary}
`;

const ProjectTypeColorIndicator = Styled.Text`
  color: ${({color}) => color};
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ClaimTypeColor = Styled.View`
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: ${({color}) => color};
`;

const handleCheckAllClaims = ({setSelectedClaims, isSelectedAllClaims, allClaimIds}) => {
  if (isSelectedAllClaims) {
    return setSelectedClaims([]);
  }
  return setSelectedClaims(allClaimIds);
};

const handleCheckClaim = ({selectedClaims, setSelectedClaims, isSelected, claimId}) => {
  if (isSelected) {
    const claimIdsToKeep = _.difference(selectedClaims, [claimId]);
    return setSelectedClaims(claimIdsToKeep);
  }
  return setSelectedClaims([...selectedClaims, claimId]);
};

const ClaimIdentifierButton = ({claim, project, refetch}) => {
  const viewClaimDrawer = useDrawer({name: 'Edit Claim Drawer'});
  return (
    <Table.PreventPropagationContainer>
      <TertiaryButton onPress={viewClaimDrawer.handleOpen}>
        <Table.CellLink>{claim.identifier}</Table.CellLink>
      </TertiaryButton>
      <EditProjectClaimDrawer
        key={viewClaimDrawer.key}
        claim={claim}
        projectUuid={project.uuid}
        isOpen={viewClaimDrawer.isOpen}
        handleClose={viewClaimDrawer.handleClose}
        onSuccess={() => {
          viewClaimDrawer.handleClose();
          refetch();
        }}
        isViewMode
      />
    </Table.PreventPropagationContainer>
  );
};

const ClaimProjectButton = ({claim}) => {
  const {project} = claim;
  const {navigator} = useNavigationDOM();
  const {ref, isHovered} = useHover();
  const projectUrl =
    project.projectType.category === ProjectTypeCategory.STORAGE
      ? `/storage/projects/${project.uuid}`
      : `/projects/${project.uuid}`;
  const openProject = () => navigator.pushNewTab(projectUrl);

  return (
    <Cell>
      <Row>
        <TertiaryButton onPress={openProject}>
          {isHovered && (
            <Icon
              source={Icon.ExternalLinkAlt}
              size={11}
              color={colors.blue.interactive}
              style={{position: 'absolute', left: -16}}
            />
          )}
          <Table.CellLink ref={ref}>{`Project ${project.identifier}`}</Table.CellLink>
        </TertiaryButton>
      </Row>
      <SecondaryText numberOfLines={1}>{_.get(project, 'client.name')}</SecondaryText>
    </Cell>
  );
};

const BulkEditCheckbox = ({isChecked, onPress, style}) => {
  return <Checkbox isChecked={isChecked} handleToggle={onPress} style={style} />;
};

const SelectAllCheckbox = ({claims, selectedClaims, setSelectedClaims}) => {
  const allClaimIds = claims.map((claim) => claim.id);
  const isSelectedAllClaims =
    allClaimIds.length === selectedClaims.length && allClaimIds.length !== 0;
  return (
    <BulkEditCheckbox
      isChecked={isSelectedAllClaims}
      onPress={() =>
        handleCheckAllClaims({
          setSelectedClaims,
          isSelectedAllClaims,
          allClaimIds,
        })
      }
      style={{paddingRight: 8}}
    />
  );
};

const SelectClaimCheckbox = ({claim, selectedClaims, setSelectedClaims}) => {
  const isSelected = _.includes(selectedClaims, claim.id);
  return (
    <BulkEditCheckbox
      isChecked={isSelected}
      onPress={() =>
        handleCheckClaim({
          selectedClaims,
          setSelectedClaims,
          isSelected,
          claimId: claim.id,
        })
      }
      style={{paddingVertical: 8, paddingRight: 8}}
    />
  );
};

const getColumnDefinitions = ({
  claims,
  selectedClaims,
  setSelectedClaims,
  refetch,
  organization,
  isEnabledClaimsProjectOrganizationMigration,
}) => {
  return [
    // Checkbox
    {
      width: 48,
      headerContent: () => {
        return (
          <SelectAllCheckbox
            claims={claims}
            selectedClaims={selectedClaims}
            setSelectedClaims={setSelectedClaims}
          />
        );
      },
      cellContent: ({item: claim}) => {
        return (
          <SelectClaimCheckbox
            claim={claim}
            selectedClaims={selectedClaims}
            setSelectedClaims={setSelectedClaims}
          />
        );
      },
    },
    // Project Details
    {
      flex: 3,
      headerContent: () => {
        return <Table.HeaderText numberOfLines={1}>Project</Table.HeaderText>;
      },
      cellContent: ({item: claim}) => {
        return <ClaimProjectButton claim={claim} />;
      },
    },
    // Claim ID
    {
      flex: 1,
      headerContent: () => {
        return <Table.HeaderText numberOfLines={1}>Claim ID</Table.HeaderText>;
      },
      cellContent: ({item: claim}) => {
        return <ClaimIdentifierButton claim={claim} project={claim.project} refetch={refetch} />;
      },
    },
    // Claim Type
    {
      flex: 2,
      headerContent: () => {
        return <Table.HeaderText numberOfLines={1}>Type</Table.HeaderText>;
      },
      cellContent: ({item: claim}) => {
        const {color, name} = claim.claimType;
        return (
          <Row style={{flex: 1}}>
            <ClaimTypeColor color={color} />
            <Space width={8} />
            <Table.CellText numberOfLines={1}>{name}</Table.CellText>
          </Row>
        );
      },
    },
    // Claim Amount
    {
      flex: 1,
      headerContent: () => {
        return <Table.HeaderText numberOfLines={1}>Amount</Table.HeaderText>;
      },
      cellContent: ({item: claim}) => {
        return (
          <Table.CellText>
            {_.isNil(claim.amount) ? 'TBD' : Currency.display(claim.amount)}
          </Table.CellText>
        );
      },
    },
    // Project Type
    {
      flex: 2,
      isHidden: !isEnabledClaimsProjectOrganizationMigration,
      headerContent: () => {
        return (
          <Cell>
            <Table.HeaderText numberOfLines={1}>Project Type</Table.HeaderText>
            {organization.isPrimary && organization.hasMultipleOrganizations && (
              <SecondaryText numberOfLines={1}>Branch</SecondaryText>
            )}
          </Cell>
        );
      },
      cellContent: ({item: claim}) => {
        const {project} = claim;
        const {projectType} = project;
        return (
          <Cell>
            <PrimaryText numberOfLines={1}>
              <ProjectTypeColorIndicator color={projectType.color}>•</ProjectTypeColorIndicator>
              <Space width={7} />
              {projectType.name}
            </PrimaryText>
            {organization.isPrimary && organization.hasMultipleOrganizations && (
              <SecondaryText numberOfLines={1}>
                <Space width={16} />
                {project.owningOrganization.name}
              </SecondaryText>
            )}
          </Cell>
        );
      },
    },
    // Claim Notes
    {
      flex: 4,
      headerContent: () => {
        return <Table.HeaderText numberOfLines={1}>Notes</Table.HeaderText>;
      },
      cellContent: ({item: claim}) => {
        return <Table.CellText numberOfLines={2}>{claim.notes}</Table.CellText>;
      },
    },
    // Last Action
    {
      flex: 4,
      headerContent: () => {
        return <Table.HeaderText numberOfLines={1}>Last Action</Table.HeaderText>;
      },
      cellContent: ({item: claim}) => {
        return (
          <Container style={{flex: 1}}>
            <Table.CellText numberOfLines={1}>
              {claim.isEdited ? 'Claim updated' : 'Claim created'}
            </Table.CellText>
            <Space height={2} />
            <Table.CellText style={{color: colors.gray.secondary}} numberOfLines={1}>
              {Datetime.convertToDisplayDatetime(claim.updatedAt, 'MM/DD/YY, h:mm A')}
              {' by '}
              {claim.updatedBy.fullName}
            </Table.CellText>
          </Container>
        );
      },
    },
    // Contact
    {
      width: 88,
      headerContent: () => {
        return <HeaderText>Contact</HeaderText>;
      },
      cellContent: ({item: claim}) => {
        return (
          <Table.PreventPropagationContainer>
            <ProjectClientCommunicationButtons
              project={claim.project}
              refetch={refetch}
              iconSize={16}
              spaceWidth={16}
            />
          </Table.PreventPropagationContainer>
        );
      },
    },
    // Actions
    {
      width: 80,
      headerContent: () => {
        return null;
      },
      cellContent: ({item: claim}) => {
        return (
          <Table.PreventPropagationContainer style={{flexDirection: 'row'}}>
            <ClaimActionsButton claim={claim} refetch={refetch} />
          </Table.PreventPropagationContainer>
        );
      },
    },
  ];
};

const RowHookComponent = ({isOpen, handleClose, item, refetch}) => {
  return (
    <EditProjectClaimDrawer
      key={isOpen}
      claim={item}
      projectUuid={item.project.uuid}
      isOpen={isOpen}
      handleClose={handleClose}
      onSuccess={() => {
        handleClose();
        refetch();
      }}
      isViewMode
    />
  );
};

const ClaimsList = ({
  claims,
  loading,
  refetch,
  hasError,
  selectedClaims,
  setSelectedClaims,
  organization,
}) => {
  const {isEnabledClaimsProjectOrganizationMigration} = organization.features;

  return (
    <React.Fragment>
      <Table.FixedHeaderScroll
        columnDefinitions={getColumnDefinitions({
          claims,
          selectedClaims,
          setSelectedClaims,
          refetch,
          organization,
          isEnabledClaimsProjectOrganizationMigration,
        })}
        items={claims}
        loading={loading}
        emptyStateText={'No claims to display'}
        isClickable
        scrollViewStyle={{paddingBottom: 24}}
        containerStyle={{overflow: 'visible'}}
        hasError={hasError}
        rowHook={{
          hook: useDrawer,
          hookArgument: {name: 'Edit Claim Drawer'},
          Component: RowHookComponent,
          componentProps: {refetch},
        }}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ClaimsList.fragment = gql`
  ${ClaimActionsButton.fragment}
  ${EditProjectClaimDrawer.fragment}
  ${ProjectClientCommunicationButtons.fragment}
  fragment ClaimsList on Claim {
    id
    isEdited
    identifier
    amount
    notes
    updatedAt
    claimType {
      id
      name
      color
    }
    updatedBy {
      id
      fullName
    }
    project {
      id
      uuid
      identifier
      owningOrganization {
        id
        name
      }
      projectType {
        id
        name
        category
      }
      client {
        id
        name
      }
      ...ProjectClientCommunicationButtons
    }
    ...ClaimActionsButton
    ...EditProjectClaimDrawer
  }

  fragment ClaimsList_viewer on User {
    id
    viewingOrganization {
      id
      slug
      isPrimary
      hasMultipleOrganizations
      features {
        isEnabledClaimsProjectOrganizationMigration: isEnabled(
          feature: "CLAIMS_PROJECT_ORGANIZATION_MIGRATION"
        )
      }
    }
  }
`;

export default ClaimsList;
