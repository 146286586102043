// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {
  useCallback,
  useEffect,
  useModal,
  useNavigationDOM,
  useResponsive,
  useState,
} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import UserRole from '@shared/modules/User/enums/UserRole';
import useAppContext from 'modules/App/context/useAppContext';
import RootDashboardPage from 'modules/Report/Root/RootDashboardPage';
import DashboardsPageDashboardsList from 'modules/Report/components/DashboardsPageDashboardsList';
import DashboardsPageDashboardsListModal from 'modules/Report/components/DashboardsPageDashboardsListModal';
import DashboardsPageHeader from 'modules/Report/components/DashboardsPageHeader';
import DashboardsPageViewPanel from 'modules/Report/components/DashboardsPageViewPanel';

const PageContainer = Styled.View`
  flex: 1;
`;

const ListContainer = Styled.View`
  flex-direction: column;
  align-items: center;
  border-right-width: 1;
  border-color: ${colors.gray.border};
  width: 248px;
  height: 100%;
`;

const PreviewPanelContainer = Styled.View`
  flex: 1;
  min-height: 100%;
`;

const Column = Styled.View`
  flex-direction: column;
  align-items: center;
`;

const Row = Styled.View`
  flex: 1;
  align-items: flex-start;
  flex-direction: row;
`;

const convertDashboardCategoriesAndDashboardsToNavigationTree = ({
  dashboards,
  dashboardCategories,
  isStorageEnabled,
  isStaffAdmin,
}: {
  dashboards: DashboardDataType[];
  dashboardCategories: DashboardCategoryDataType[];
  isStorageEnabled: boolean;
  isStaffAdmin: boolean;
}) => {
  const categoryIdToDashboards = _.groupBy(
    dashboards,
    (d) => d.globalDashboard?.dashboardCategoryId || d.dashboardCategoryId,
  );
  const categoryIdToCategory = _.keyBy(dashboardCategories, 'id');

  const categoryIds = dashboardCategories
    .filter((category) => {
      const dashboards = categoryIdToDashboards[_.toNumber(category.id)] || [];
      if (!dashboards.length) {
        return false;
      }
      // Disable storage category if storage is not enabled in settings
      if (category.name === 'Storage' && !isStorageEnabled) {
        return false;
      }
      // Disable accounting category if not a staff admin
      if (category.name === 'Accounting' && !isStaffAdmin) {
        return false;
      }
      return true;
    })
    .sort((a) => a.index)
    .map((category) => _.toNumber(category.id));

  const treeItems = categoryIds.reduce(
    (list, categoryId) => {
      const category = categoryIdToCategory[categoryId];
      const dashboardItems = categoryIdToDashboards[categoryId]
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((dashboard) => ({
          label: dashboard.name,
          value: dashboard.uuid,
          valueKey: 'block',
        }));
      list.push({
        label: category.name,
        value: category.id,
        valueKey: 'section',
        items: dashboardItems,
      });
      return list;
    },
    [] as {
      label: string;
      value: string;
      valueKey: string;
      items: {label: string; value: string; valueKey: string}[];
    }[],
  );

  return treeItems;
};

interface DashboardCategoryDataType {
  id: string;
  name: string;
  index: number;
}

interface DashboardDataType {
  id: string;
  uuid: string;
  name: string;
  dashboardCategoryId: number;
  globalDashboard: {
    id: string;
    dashboardCategoryId: string;
  };
}

type QueryResponse = {
  viewer: {
    id: string;
    viewingOrganization: {
      id: string;
      features: {
        isEnabledOfficeReportsLibrary: boolean;
      };
      settings: {
        id: string;
        isStorageEnabled: boolean;
      };
    };
  };
  organization: {
    id: string;
    dashboards: DashboardDataType[];
  };
  dashboardCategories: DashboardCategoryDataType[];
};

const DashboardsPage = () => {
  const {
    navigator,
    params: {dashboardUuid},
  } = useNavigationDOM();
  const responsive = useResponsive();
  const {viewer, setGlobalNavigationTab} = useAppContext();
  const isStaffAdmin = UserRole.getIsStaffAdmin(viewer?.role);
  const dashboardSelectModal = useModal({name: 'Dashboard Select Modal'});
  const [selectedDashboardUuid, setSelectedDashboardUuid] = useState<string | null>(dashboardUuid);

  setGlobalNavigationTab('reports');

  const {data, loading, refetch} = useQuery<QueryResponse>(DashboardsPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  const navigateToDashboard = useCallback(({uuid, replace}: {uuid: string; replace?: boolean}) => {
    if (replace) {
      // Using window.history to replace state (react-router-dom v5 triggers rerender of the whole page)
      window.history.replaceState({}, '', `/reports/${uuid}/view`);
    } else {
      // Using window.history to push state (react-router-dom v5 triggers rerender of the whole page)
      window.history.pushState({}, '', `/reports/${uuid}/view`);
    }
    setSelectedDashboardUuid(uuid);
  }, []);

  const navigationItems = data
    ? convertDashboardCategoriesAndDashboardsToNavigationTree({
        dashboards: data.organization.dashboards,
        dashboardCategories: data.dashboardCategories,
        isStorageEnabled: !!data.viewer.viewingOrganization.settings.isStorageEnabled,
        isStaffAdmin,
      })
    : [];

  useEffect(() => {
    if (!loading && !selectedDashboardUuid && navigationItems.at(0)?.items.at(0)) {
      navigateToDashboard({uuid: navigationItems.at(0)?.items.at(0)?.value || '', replace: true});
    }
  }, [loading, selectedDashboardUuid, navigationItems, navigateToDashboard]);

  if (!viewer?.viewingOrganization.features.isEnabledOfficeGlobalReports) {
    return <RootDashboardPage />;
  }

  return (
    <React.Fragment>
      <PageContainer>
        <DashboardsPageHeader
          isEnabledOfficeReportsLibrary={
            !!data?.viewer.viewingOrganization.features.isEnabledOfficeReportsLibrary
          }
          handleAddReport={() => {
            navigator.push(`/reports/library?dashboardUuid=${selectedDashboardUuid}`);
          }}
          handleShowReportsListOnMobile={dashboardSelectModal.handleOpen}
        />
        <Row>
          {responsive.desktop && (
            <ListContainer>
              <Space height={12} />
              <Loading loading={loading || !data}>
                {() =>
                  data && (
                    <DashboardsPageDashboardsList
                      navigationItems={navigationItems}
                      selectedDashboardUuid={selectedDashboardUuid}
                      handleSelect={(uuid) => navigateToDashboard({uuid})}
                    />
                  )
                }
              </Loading>
            </ListContainer>
          )}
          <PreviewPanelContainer>
            <Column style={{borderRightWidth: 1, borderColor: colors.gray.border, flex: 1}}>
              {selectedDashboardUuid && (
                <DashboardsPageViewPanel
                  selectedDashboardUuid={selectedDashboardUuid}
                  handleRemove={() => {
                    navigator.replace('/reports');
                  }}
                />
              )}
            </Column>
          </PreviewPanelContainer>
        </Row>
      </PageContainer>
      <DashboardsPageDashboardsListModal
        isOpen={dashboardSelectModal.isOpen}
        navigationItems={navigationItems}
        selectedDashboardUuid={selectedDashboardUuid}
        handleClose={dashboardSelectModal.handleClose}
        handleSelect={(uuid) => navigateToDashboard({uuid})}
      />
    </React.Fragment>
  );
};

DashboardsPage.query = gql`
  query DashboardsPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        features {
          isEnabledOfficeReportsLibrary: isEnabled(feature: "OFFICE_REPORTS_LIBRARY")
        }
        settings {
          id
          isStorageEnabled
        }
      }
    }
    organization {
      id
      dashboards {
        id
        uuid
        name
        dashboardCategoryId
        globalDashboard {
          id
          dashboardCategoryId
        }
      }
    }
    dashboardCategories {
      id
      name
      index
    }
  }
`;

export default DashboardsPage;
