// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Popover, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery, useHover, usePopover, useModal} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Table from '@shared/design/components/TableV2Deprecated';
import useDeleteBillTypeMutation from '@shared/modules/Billing/hooks/useDeleteBillTypeMutation';
import DeleteModal from 'modules/App/components/DeleteModal';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import BillingLibraryPageHeader from 'modules/Organization/Settings/BillingLibraries/components/BillingLibraryPageHeader';

const Container = Styled.View`
`;

const ContentContainer = Styled.View`
  flex: 1;
  padding-top: 12px;
  padding-horizontal: ${({mobile}) => (mobile ? 12 : 24)}px;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const HeaderText = Styled.Text`
  ${Typography.Label2}
`;

const TableHeaderText = Styled.Text`
  ${Typography.Label3}
  color: ${colors.gray.secondary};
`;

const Button = Styled.ButtonV2`
  height: 28px;
  flex-direction: row;
  align-items: center;
  padding-horizontal: 12px;
  border-radius: 4px;
  background-color: ${colors.blue.interactive};
`;

const ButtonText = Styled.Text`
  ${Typography.Label3}
  color: ${colors.white};
`;

const TableContainer = Styled.View`
  flex-direction: row;
`;

const TableCellText = Styled.Text`
  ${Typography.Body3}
`;

const TableCellButton = Styled.ButtonV2`
`;

const MenuOptionButton = Styled.ButtonV2`
  background-color: ${(props) => (props.isHovered ? colors.hover : 'transparent')};
  padding-vertical: 4px;
  padding-horizontal: 12px;
`;

const MenuItemText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.primary};
`;

const DescriptionText = Styled.Text`
  ${Typography.Body4}
  color: ${colors.gray.secondary};
`;

const getUsedOnText = (usedOn) => {
  const MAX_DISPLAY = 5;
  if (usedOn.length > MAX_DISPLAY) {
    const {names, remainingCount} = usedOn.reduce(
      (acc, name, index) => {
        if (index < MAX_DISPLAY) {
          return {
            ...acc,
            names: acc.names ? `${acc.names}, ${name}` : name,
          };
        } else {
          return {
            ...acc,
            remainingCount: acc.remainingCount + 1,
          };
        }
      },
      {
        names: '',
        remainingCount: 0,
      },
    );

    return `${names} and ${remainingCount} more`;
  } else {
    return _.join(usedOn, ', ');
  }
};

const AddButton = ({children, onPress}) => {
  return (
    <Button onPress={onPress}>
      <Icon source={Icon.Plus} size={10} color={colors.white} />
      <Space width={5} />
      <ButtonText>{children}</ButtonText>
    </Button>
  );
};

const DeleteBillTypeModal = ({billType, isOpen, handleClose, refetch}) => {
  const {handleSubmit} = useDeleteBillTypeMutation({
    billTypeId: billType.id,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <DeleteModal
      isOpen={isOpen}
      title={`Are you sure you want to delete ${billType.name}?`}
      subtitle={
        "This will permanently remove this Bill Template from any linked Project Types, Job Types or Cost/Compensation Items. You can't undo this action."
      }
      handleClose={handleClose}
      handleConfirm={handleSubmit}
    />
  );
};

const BillTypeActionsPopover = ({billTypeActionsPopover, deleteBillTypeModal}) => {
  const {isHovered, ref} = useHover();
  const handleOpenDeleteBillTypeModal = () => {
    deleteBillTypeModal.handleOpen();
    billTypeActionsPopover.handleClose();
  };
  return (
    <Popover
      placement={Popover.Positions.RightStart}
      isOpen={billTypeActionsPopover.isOpen}
      handleOpen={billTypeActionsPopover.handleOpen}
      handleClose={billTypeActionsPopover.handleClose}
      reference={billTypeActionsPopover.ref}
      offset={[0, 4]}
    >
      <ResponsivePopover.StaticContainer width={160}>
        <Space height={8} />
        <MenuOptionButton onPress={handleOpenDeleteBillTypeModal} isHovered={isHovered} ref={ref}>
          <MenuItemText>Delete</MenuItemText>
        </MenuOptionButton>
        <Space height={8} />
      </ResponsivePopover.StaticContainer>
    </Popover>
  );
};

const BillTemplateActions = ({billType, billingLibrary, navigator, refetch}) => {
  const billTypeActionsPopover = usePopover();
  const deleteBillTypeModal = useModal({name: 'DELETE_Bill_TYPE_MODAL'});
  return (
    <React.Fragment>
      <Row>
        <TableCellButton
          onPress={() => {
            navigator.push(
              `/settings/billing/billing-libraries/${billingLibrary.uuid}/bill-templates/${billType.uuid}`,
            );
          }}
        >
          <Icon source={Icon.Pen} color={colors.gray.secondary} size={16} />
        </TableCellButton>
        <Space width={25} />
        <Popover.Content innerRef={billTypeActionsPopover.ref}>
          <TableCellButton onPress={billTypeActionsPopover.handleToggle}>
            <Icon source={Icon.EllipsisV} color={colors.gray.secondary} size={16} />
          </TableCellButton>
        </Popover.Content>
      </Row>
      <BillTypeActionsPopover
        key={`BILL_TYPE_ACTIONS_POPOVER-${billTypeActionsPopover.isOpen}`}
        billTypeActionsPopover={billTypeActionsPopover}
        deleteBillTypeModal={deleteBillTypeModal}
      />
      <DeleteBillTypeModal
        key={deleteBillTypeModal.key}
        isOpen={deleteBillTypeModal.isOpen}
        handleClose={deleteBillTypeModal.handleClose}
        billType={billType}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const getColumnDefinitions = ({billingLibrary, navigator, refetch}) => {
  return [
    {
      width: 260,
      headerContent: () => {
        return <TableHeaderText>Template Name</TableHeaderText>;
      },
      cellContent: ({item: billType}) => (
        <TableCellText numberOfLines={1}>{billType.name}</TableCellText>
      ),
    },
    {
      width: 360,
      headerContent: () => {
        return <TableHeaderText>Description</TableHeaderText>;
      },
      cellContent: ({item: billType}) => (
        <TableCellText numberOfLines={2}>{billType.description}</TableCellText>
      ),
    },
    {
      width: 240,
      headerContent: () => {
        return <TableHeaderText>Used On Project Types</TableHeaderText>;
      },
      cellContent: ({item: billType}) => {
        const usedOnProjectTypes = billType.projectTypes.map((projectType) => projectType.name);
        return <TableCellText>{getUsedOnText(usedOnProjectTypes)}</TableCellText>;
      },
    },
    {
      width: 240,
      headerContent: () => {
        return <TableHeaderText>Used On Job Types</TableHeaderText>;
      },
      cellContent: ({item: billType}) => {
        const usedOnJobTypes = billType.jobTypes.map((jobType) => jobType.name);
        return <TableCellText>{getUsedOnText(usedOnJobTypes)}</TableCellText>;
      },
    },
    {
      width: 160,
      headerContent: () => <TableHeaderText>Last Updated</TableHeaderText>,
      cellContent: ({item: billType}) => (
        <Container>
          <TableCellText>{Datetime.convertToDisplayDatetime(billType.updatedAt)}</TableCellText>
          {!!billType.updatedBy && (
            <React.Fragment>
              <Space height={4} />
              <DescriptionText>{billType.updatedBy.fullName}</DescriptionText>
            </React.Fragment>
          )}
        </Container>
      ),
    },
    {
      width: 100,
      headerContent: () => {
        return <TableHeaderText>Actions</TableHeaderText>;
      },
      cellContent: ({item: billType}) => {
        const isDisabledFromEditing = !!billType.identifier && !billType.createdById;
        if (isDisabledFromEditing) {
          return null;
        }

        return (
          <BillTemplateActions
            billType={billType}
            billingLibrary={billingLibrary}
            navigator={navigator}
            refetch={refetch}
          />
        );
      },
    },
  ];
};

const BillingLibraryBillTypesPageContent = ({billingLibrary, refetch}) => {
  const {navigator} = useNavigationDOM();
  return (
    <React.Fragment>
      <Space height={28} />
      <Row>
        <HeaderText>Bill Templates</HeaderText>
        <Space width={40} />
        <AddButton
          onPress={() =>
            navigator.push(
              `/settings/billing/billing-libraries/${billingLibrary.uuid}/bill-templates/new`,
            )
          }
        >
          New Bill Template
        </AddButton>
      </Row>
      <Space height={12} />
      <TableContainer>
        <Table
          columnDefinitions={getColumnDefinitions({billingLibrary, navigator, refetch})}
          items={billingLibrary.billTypes}
          emptyStateText={'No templates to display'}
          isDense
          headerStyle={{backgroundColor: colors.gray.background}}
        />
      </TableContainer>
      <Space height={50} />
    </React.Fragment>
  );
};

const BillingLibraryBillTypesPage = () => {
  const {params} = useNavigationDOM();

  const {loading, data, refetch} = useQuery(BillingLibraryBillTypesPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      billingLibraryUuid: params.billingLibraryUuid,
    },
  });

  if (loading) {
    return (
      <SidebarPageV2 selected={'settings'}>
        <PageLoadingIndicator />
      </SidebarPageV2>
    );
  }

  return (
    <SidebarPageV2 selected={'settings'}>
      <Container style={{flex: 1}}>
        <BillingLibraryPageHeader billingLibrary={data.billingLibrary} />
        <ContentContainer>
          <ScrollView horizontal contentContainerStyle={{flexGrow: 1}}>
            <ScrollView>
              <BillingLibraryBillTypesPageContent
                billingLibrary={data.billingLibrary}
                refetch={refetch}
              />
            </ScrollView>
          </ScrollView>
        </ContentContainer>
      </Container>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingLibraryBillTypesPage.query = gql`
  ${BillingLibraryPageHeader.fragment}

  query BillingLibraryBillTypesPage(
    $billingLibraryUuid: String!,
  ) {
    ${gql.query}
    billingLibrary(billingLibraryUuid: $billingLibraryUuid) {
      id
      name
      uuid
      billTypes {
        id
        name
        description
        uuid
        identifier
        updatedAt
        updatedBy {
          id
          fullName
        }
        createdById
        projectTypes {
          id
          name
        }
        jobTypes {
          id
          name
        }
      }
      ...BillingLibraryPageHeader
    }
  }
`;

export default BillingLibraryBillTypesPage;
