// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery, useModal} from '@supermove/hooks';
import {Typography} from '@supermove/styles';
import {Datetime, Percent} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import Table from '@shared/design/components/TableV2Deprecated';
import ImportOrganizationProjectsModal from 'modules/Organization/Settings/Jobs/components/ImportOrganizationProjectsModal';
import SettingsPage from 'modules/Organization/Settings/components/SettingsPage';

const ProjectImportsTableContainer = Styled.View`
  max-width: 1300px;
`;

const SectionSubtitle = Styled.Text`
  ${Typography.PageHeading}
`;

const getProjectImportColumnDefinitions = () => [
  {
    flex: 1,
    headerContent: () => <Table.HeaderText>Filename</Table.HeaderText>,
    cellContent: ({item, index}) => {
      return (
        <Table.CellText>{item.files.reduce((acc, file) => acc + file.filename, '')}</Table.CellText>
      );
    },
  },
  {
    flex: 1,

    headerContent: () => <Table.HeaderText>Status</Table.HeaderText>,
    cellContent: ({item, index}) => {
      return <Table.CellText>{item.status}</Table.CellText>;
    },
  },
  {
    flex: 1,
    headerContent: () => <Table.HeaderText># of Projects</Table.HeaderText>,
    cellContent: ({item, index}) => {
      return <Table.CellText>{item.taskCount}</Table.CellText>;
    },
  },
  {
    flex: 1,
    headerContent: () => <Table.HeaderText>Import Progress</Table.HeaderText>,
    cellContent: ({item, index}) => {
      return (
        <Table.CellText>{`${item.completedTaskCount}/${item.taskCount} (${Percent.display(
          _.round(item.progress, 2),
        )})`}</Table.CellText>
      );
    },
  },
  {
    flex: 1,
    headerContent: () => <Table.HeaderText>Uploaded at</Table.HeaderText>,
    cellContent: ({item, index}) => {
      return <Table.CellText>{Datetime.convertToDisplayDatetime(item.createdAt)}</Table.CellText>;
    },
  },
  {
    flex: 1,
    headerContent: () => <Table.HeaderText>Uploaded by</Table.HeaderText>,
    cellContent: ({item, index}) => {
      return <Table.CellText>{item.creator.fullName}</Table.CellText>;
    },
  },
];

const ProjectImportsTable = ({organization}) => {
  return (
    <ProjectImportsTableContainer>
      <Table
        columnDefinitions={getProjectImportColumnDefinitions()}
        emptyStateText='No project imports to display'
        items={organization.projectImportBatches}
        itemKey={'id'}
      />
    </ProjectImportsTableContainer>
  );
};

const OrganizationSettingsJobsPageContent = ({data, refetch}) => {
  const importOrganizationProjectsModal = useModal();
  const textObject = {
    title: 'Import CSV File: Past Moves',
    description:
      'Upload a CSV file of past moves into Supermove. The headers of the CSV file must exactly match the list below. This works best with single-day moves with two locations in the move.',
    textValidationNames: [
      'Company Name: optional',
      'Contact First Name: required',
      'Contact Last Name: required',
      'Contact Email: optional',
      'Contact Phone: optional',
      'Project Type: required, project type name that exists in the system, case sensitive',
      'Job Type: required, job type name that exists in the system, case sensitive',
      'Move Size: required',
      'Salesperson: optional, full name of salesperson that exists in system',
      'Referral Source: optional',
      'Referral Details: optional',
      'Office Notes: optional',
      'Move Date: required, date in YYYY-MM-DD format',
      'Moving From Address: required',
      'Moving From Unit #: optional',
      'Moving From City: optional',
      'Moving From State: optional',
      'Moving From Zip Code: required',
      'Moving From Notes: optional',
      'Moving To Address: required',
      'Moving To Unit #: optional',
      'Moving To City: optional',
      'Moving To State: optional',
      'Moving To Zip Code: required',
      'Moving To Notes: optional',
      'Movers Count: optional',
      'Move Start Time 1: optional',
      'Move Start Time 2: optional',
      'Move Total Price: optional, total cost of moving service',
      'Inventory Estimated Weight: optional, weight in lbs',
      'Inventory Actual Weight: optional, weight in lbs',
    ],
    textHeaders:
      'Company Name,Contact First Name,Contact Last Name,Contact Email,Contact Phone,Project Type,Job Type,Move Size,Salesperson,Referral Source,Referral Details,Office Notes,Move Date,Moving From Address,Moving From Unit #,Moving From City,Moving From State,Moving From Zip Code,Moving From Notes,Moving To Address,Moving To Unit #,Moving To City,Moving To State,Moving To Zip Code,Moving To Notes,Movers Count,Move Start Time 1,Move Start Time 2,Move Total Price,Inventory Estimated Weight,Inventory Actual Weight',
  };

  return (
    <React.Fragment>
      {!data.viewer.viewingOrganization.features.isEnabledImportXlsxProjectSuddath &&
        ['super'].includes(data.viewer.role) && (
          <React.Fragment>
            <Button onPress={importOrganizationProjectsModal.handleOpen} text={'Import CSV File'} />
            <ImportOrganizationProjectsModal
              key={`importOrganizationProjectsModal${importOrganizationProjectsModal.isOpen}`}
              isOpen={importOrganizationProjectsModal.isOpen}
              handleClose={importOrganizationProjectsModal.handleClose}
              refetch={refetch}
              textObject={textObject}
            />
            <Space height={16} />
            <SectionSubtitle>Previous Imports</SectionSubtitle>
            <Space height={16} />
            <ProjectImportsTable organization={data.viewer.viewingOrganization} refetch={refetch} />
          </React.Fragment>
        )}
    </React.Fragment>
  );
};

const OrganizationSettingsJobsPage = () => {
  const {navigator} = useNavigationDOM();
  const {loading, data, refetch} = useQuery(OrganizationSettingsJobsPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <SettingsPage
      title={'Import Past Moves'}
      description={
        'Import information about past moves your company serviced. Press the button below to view what columns are supported.'
      }
      breadcrumbs={[
        {name: 'Settings', onPress: () => navigator.push('/settings')},
        {name: 'Import Jobs'},
      ]}
    >
      <Loading loading={loading}>
        {() => <OrganizationSettingsJobsPageContent data={data} refetch={refetch} />}
      </Loading>
    </SettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsJobsPage.query = gql`
  query OrganizationSettingsJobsPage {
    ${gql.query}
    viewer {
      id
      role
      viewingOrganization {
        id
        features {
          isEnabledImportXlsxProjectSuddath: isEnabled(feature: "IMPORT_XLSX_PROJECT_SUDDATH")
        }
        projectImportBatches {
          id
          createdAt
          creator {
            id
            fullName
          }
          status
          progress
          taskCount
          completedTaskCount
          files {
            id
            filename
          }
        }
      }
    }
  }
`;

export default OrganizationSettingsJobsPage;
