// Libraries
import React from 'react';

// Supermove
import {Icon, PreventPropagation, Space, Styled, IconSource} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import TextTooltip, {TextTooltipTextType} from '@shared/design/components/TextTooltip';

const Cell = Styled.View`
  padding-horizontal: 12px;
`;

const TitleText = Styled.Text`
  ${Typography.Responsive.Label}
`;

const PrimaryText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const PrimaryLink = Styled.Text`
  ${Typography.Responsive.Link}
`;

const SecondaryText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const SecondaryLink = Styled.Text`
  ${Typography.Responsive.MicroLink}
`;

const EmptyMobileText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.tertiary};
  font-style: italic;
`;

const DisplayContentRow = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const DisplayContentTooltipContent = Styled.View`
  flex-direction: row;
  flex-shrink: 1;
`;

const PLACEHOLDER_ROW_HOOK = {
  hook: ({}) => ({}),
  hookArgument: {},
  renderComponent: () => null,
};

const DisplayText = ({
  isSecondary,
  isTitle,
  children,
}: {
  isSecondary?: boolean;
  isTitle?: boolean;
  children: React.ReactNode;
}) => {
  const responsive = useResponsive();
  if (isSecondary) {
    return <SecondaryText responsive={responsive}>{children}</SecondaryText>;
  }
  if (isTitle) {
    return <TitleText responsive={responsive}>{children}</TitleText>;
  }
  return <PrimaryText responsive={responsive}>{children}</PrimaryText>;
};

const DisplayLink = ({
  item,
  isSecondary,
  handlePressCellText,
  children,
}: {
  item: ItemType;
  isSecondary?: boolean;
  isTitle?: boolean;
  handlePressCellText: ItemFunctionReturnVoidType;
  children: React.ReactNode;
}) => {
  const responsive = useResponsive();
  const LinkText = isSecondary ? SecondaryLink : PrimaryLink;

  return (
    <PreventPropagation>
      <TertiaryButton>
        <LinkText
          onPress={() => handlePressCellText(item)}
          style={{cursor: 'pointer'}}
          responsive={responsive}
        >
          {children}
        </LinkText>
      </TertiaryButton>
    </PreventPropagation>
  );
};

const DisplayContentWrapper = ({
  tooltip,
  children,
}: {
  tooltip: TextTooltipTextType;
  children: React.ReactNode;
}) => {
  return (
    <DisplayContentRow>
      {tooltip ? (
        // When there is a tooltip we want to shrink the inner wrapper so that the
        // tooltip hugs the content.
        <TextTooltip text={tooltip}>
          <DisplayContentTooltipContent>{children}</DisplayContentTooltipContent>
        </TextTooltip>
      ) : (
        children
      )}
    </DisplayContentRow>
  );
};

export interface DisplayContentProps extends CellContentType {
  item: ItemType;
  rowIndex: number;
  isSecondary?: boolean;
  isTitle?: boolean;
}
const DisplayContent = ({
  cellIcon,
  cellIconColor = () => colors.gray.secondary,
  cellIconSize = () => 0,
  cellText,
  handlePressCellText,
  cellComponent,
  tooltip,
  item,
  rowIndex,
  isSecondary,
  isTitle,
}: DisplayContentProps) => {
  const responsive = useResponsive();
  const iconSource = cellIcon?.(item);
  const displayText = cellText?.(item, rowIndex);
  const cellComponentContent = cellComponent?.(item, rowIndex);
  const isEmpty = !iconSource && !displayText && !cellComponentContent;

  if (isEmpty) {
    return (
      <React.Fragment>
        {responsive.desktop ? (
          <DisplayText>-</DisplayText>
        ) : (
          <EmptyMobileText responsive={responsive}>None</EmptyMobileText>
        )}
      </React.Fragment>
    );
  }

  return (
    <DisplayContentWrapper tooltip={tooltip}>
      {iconSource && (
        <React.Fragment>
          <Icon
            source={iconSource}
            color={cellIconColor(item)}
            size={cellIconSize(item) || (responsive.desktop ? 14 : 16)}
          />
          {(displayText || cellComponentContent) && <Space width={8} />}
        </React.Fragment>
      )}
      {cellComponentContent && (
        <React.Fragment>
          {cellComponentContent}
          {displayText && <Space width={8} />}
        </React.Fragment>
      )}
      {displayText &&
        (handlePressCellText ? (
          <DisplayLink
            item={item}
            handlePressCellText={handlePressCellText}
            isSecondary={isSecondary}
          >
            {displayText}
          </DisplayLink>
        ) : (
          <DisplayText isSecondary={isSecondary} isTitle={isTitle}>
            {displayText}
          </DisplayText>
        ))}
    </DisplayContentWrapper>
  );
};

// --------------------------------------------------
// Types
// --------------------------------------------------
export type ItemType = Record<string, unknown>;
export type ItemFunctionReturnVoidType = (item: ItemType) => void;
export type ItemFunctionReturnStringType = (item: ItemType) => string;
export type RowHookType = {
  hook: (hookArgument: Record<string, any>) => Record<string, any>;
  hookArgument: Record<string, any>;
  renderComponent?: React.FC<{isOpen: boolean; handleClose: () => void; hookKey?: string}>;
};
export type HookOutputType = {
  isOpen?: boolean;
  handleOpen?: () => void;
  [key: string]: any;
};
export type ActionType = {
  // Required
  text: string;
  onPress: ({
    item,
    handleOpen,
    handleSubmit,
  }: {
    item?: ItemType;
    handleOpen?: () => void;
    handleSubmit?: () => void;
  }) => void;

  // Optional
  isDisabled?: boolean;
  tooltip?: string;

  // Overlays
  actionHook?: RowHookType; // If an actionHook is provided, onPress will receive handleOpen
  hookOutput?: HookOutputType;

  // Adding either of these properties will make it standalone on desktop
  desktopIcon?: IconSource;
  desktopIconSize?: number;
  desktopLabel?: string;
};
type CellStylingType = {
  flex?: number;
  minWidth?: number;
  maxWidth?: number;
  width?: number;
  isHidden?: boolean;
};
type CellHeaderType = {
  headerLabel?: string;
  headerComponent?: React.FC;
};
type CellContentType = CellHeaderType & {
  cellIcon?: (item: ItemType) => IconSource;
  cellIconColor?: (item: ItemType) => string;
  cellIconSize?: (item: ItemType) => number;
  cellText?: (item: ItemType, rowIndex: number) => string;
  handlePressCellText?: () => void;
  cellComponent?: React.FC;
  tooltip?: TextTooltipTextType;
};
export type ColumnDefinitionCellContentType = CellStylingType &
  CellContentType & {
    // Secondary Content
    secondary?: CellContentType;

    // Mobile
    mobileOptions?: {
      isInHeader?: boolean; // Moves content to the card header
      rank?: number; // Customize the order of the content
    };
  };
export type ColumnDefinitionCellActionsType = CellStylingType &
  CellHeaderType & {
    actions?: (item: ItemType) => ActionType[];
  };
export type ColumnDefinitionType =
  | ColumnDefinitionCellContentType
  | ColumnDefinitionCellActionsType;

const getColumnHasActions = (
  column: ColumnDefinitionType,
): column is ColumnDefinitionCellActionsType => {
  return 'actions' in column;
};
const getColumnHasSecondary = (
  column: ColumnDefinitionType,
): column is ColumnDefinitionCellContentType => {
  return 'secondary' in column;
};

const TableBuilder = {
  // Typography
  PrimaryText,
  PrimaryLink,
  SecondaryText,
  SecondaryLink,

  // Constants
  PLACEHOLDER_ROW_HOOK,

  // Components
  Cell,
  DisplayContent,

  // Helpers
  getColumnHasActions,
  getColumnHasSecondary,
};

export default TableBuilder;
