// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {
  Icon,
  DropdownInput,
  ScrollView,
  Styled,
  Space,
  DragAndDropList,
} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDragAndDrop, useNavigationDOM, useQuery} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import TextTooltip from '@shared/design/components/TextTooltip';
import DocumentTemplateCategory from '@shared/modules/Document/enums/DocumentTemplateCategory';
import JobTypeConfigs from '@shared/modules/Job/enums/JobTypeConfigs';
import JobTypeStepForm from '@shared/modules/Job/forms/JobTypeStepForm';
import useUpsertJobTypeStepMutation from '@shared/modules/Job/hooks/useUpsertJobTypeStepMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import Switch from 'modules/App/components/Switch';
import SwitchField from 'modules/App/components/SwitchField';
import ApplyJobTypeConfigPopoverButton from 'modules/Organization/Settings/JobTypes/components/ApplyJobTypeConfigPopoverButton';
import JobTypeSettingsPageHeader from 'modules/Organization/Settings/JobTypes/components/JobTypeSettingsPageHeader';

const View = Styled.View``;

const PageContainer = Styled.View`
  flex: 1;
`;

const ContentContainer = Styled.View`
  flex: 1;
  padding-left: 24px;
  background-color: ${colors.gray.background};
`;

const SearchDocumentInputContainer = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const DocumentRow = Styled.View`
  flex-direction: row;
  align-items: center;
  flex-shrink: 1;
  padding-vertical: 8px;
  padding-horizontal: 16px;
  border-top-width: 1px;
  border-color: ${colors.gray.border};
`;

const JobTypeStepsItemContainer = Styled.View`
  max-width: 840px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 8px;
  zIndex: ${({index}) => 100 - index};
  background-color: ${colors.white};
`;

const DocumentsContainer = Styled.View`
  flex: 1;
`;

const Text = Styled.Text`
  ${Typography.Body3}
  color: ${({color}) => color};
`;

const DocumentHeaderText = Styled.Text`
  ${Typography.Label2}
  color: ${({color}) => color};
`;

const JobTypeHeaderView = Styled.View`
  flex-direction: row;
  flex-shrink: 0;
  flex-basis: 35%;
`;

const JobTypeHeaderText = Styled.Text`
  ${Typography.Subheading}
  color: ${({color}) => color};
`;

const HeaderText = Styled.Text`
  ${Typography.Heading4}
`;

const DocumentStepsTextContainer = Styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding-horizontal: 8px;
  padding-vertical: 4px;
  border-radius: 4px;
  background-color: ${colors.white};
  border-width: 1px;
  border-color: ${colors.gray.border};
`;

const DeleteDocumentButton = Styled.ButtonV2`
  padding-vertical: 4px;
`;

const DocumentSectionItemContainer = Styled.View`
  z-index: -1;
`;

const ButtonRow = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  width: 840px;
`;

const getDocumentListItems = (organizationDocumentTemplates, formDocumentTemplateField) => {
  return organizationDocumentTemplates.reduce(
    (filteredDocumentTemplateOptions, documentTemplateOption) => {
      if (
        !formDocumentTemplateField.includes(documentTemplateOption.identifier) &&
        documentTemplateOption.category !== DocumentTemplateCategory.INVOICE
      ) {
        return [
          ...filteredDocumentTemplateOptions,
          {label: documentTemplateOption.name, value: documentTemplateOption.identifier},
        ];
      }
      return filteredDocumentTemplateOptions;
    },
    [],
  );
};

const removeDocumentStep = (form, documentTemplateField, currentDocumentStepKind) => {
  const jobTypeStepDocumentTemplateKinds = _.get(form.values, documentTemplateField);
  const updatedDocumentTemplateKinds = _.without(
    jobTypeStepDocumentTemplateKinds,
    currentDocumentStepKind,
  );
  form.setFieldValue(documentTemplateField, updatedDocumentTemplateKinds);
  if (_.isEmpty(updatedDocumentTemplateKinds)) {
    form.setFieldValue('jobTypeStepForm.isEnabled', false);
  }
};

const swapDocumentTemplateKinds = ({
  form,
  documentTemplateField,
  fromIndex,
  toIndex,
  handleSubmit,
}) => {
  const jobTypeStepDocumentTemplateKinds = _.get(form.values, documentTemplateField);
  const reorderedDocumentTemplateKinds = List.move({
    list: jobTypeStepDocumentTemplateKinds,
    fromIndex,
    toIndex,
  });
  form.setFieldValue(documentTemplateField, reorderedDocumentTemplateKinds);
  setTimeout(handleSubmit, 0);
};

const JobTypeStepDocumentsSectionItem = ({
  documentTemplateKind,
  documentTemplateField,
  form,
  handleSubmit,
  organization,
}) => {
  return (
    <Row style={{flex: 1}}>
      <DocumentStepsTextContainer>
        <DocumentHeaderText numberOfLines={1} color={colors.blue.interactive}>
          {
            _.find(organization.documentTemplatesWithActiveDocumentTemplateVersion, {
              identifier: documentTemplateKind,
            }).name
          }
        </DocumentHeaderText>
        <Space style={{flex: 1}} />
        <DeleteDocumentButton
          onPress={() => {
            removeDocumentStep(form, documentTemplateField, documentTemplateKind);
            setTimeout(() => handleSubmit(), 0);
          }}
        >
          <Icon source={Icon.Trash} size={12} color={colors.gray.tertiary} />
        </DeleteDocumentButton>
      </DocumentStepsTextContainer>
    </Row>
  );
};

const JobTypeStepDocumentsSection = ({
  jobTypeStep,
  stepIndex,
  form,
  handleSubmit,
  organization,
}) => {
  const {isReordering, handleReorderStart, handleReorderEnd} = useDragAndDrop();
  const documentTemplateField = 'jobTypeStepForm.documentTemplateKinds';
  const formDocumentTemplateField = _.get(form.values, documentTemplateField) || [];

  return (
    <DocumentsContainer>
      <Space height={8} />
      <SearchDocumentInputContainer index={stepIndex}>
        <FieldInput
          {...form}
          component={DropdownInput}
          input={{
            placeholder: 'Add documents from library',
            isSearchable: true,
            name: documentTemplateField,
            options: getDocumentListItems(
              organization.documentTemplatesWithActiveDocumentTemplateVersion,
              formDocumentTemplateField,
            ),
            onChangeValue: (value) => {
              form.setFieldValue(documentTemplateField, [...formDocumentTemplateField, value]);
              setTimeout(() => handleSubmit(), 0);
            },
            setFieldValue: form.setFieldValue,
            style: {
              width: '100%',
              borderRadius: 8,
              borderColor: colors.gray.border,
              paddingLeft: 20,
            },
            components: {
              IndicatorSeparator: () => null,
              DropdownIndicator: () => null,
            },
          }}
          style={{width: '100%'}}
        />
        <Icon
          source={Icon.Search}
          size={12}
          color={colors.gray.tertiary}
          style={{position: 'absolute', top: 11, left: 12, zIndex: 100}}
        />
      </SearchDocumentInputContainer>
      <Space height={8} />
      <DocumentSectionItemContainer>
        <DragAndDropList
          isReordering={isReordering}
          spaceBetweenItems={10}
          onReorder={({fromIndex, toIndex}) => {
            handleReorderStart();
            swapDocumentTemplateKinds({
              form,
              documentTemplateField,
              fromIndex,
              toIndex,
              handleSubmit,
            });
            handleReorderEnd();
          }}
        >
          {formDocumentTemplateField.map((documentTemplateKind, index) => (
            <JobTypeStepDocumentsSectionItem
              key={index}
              form={form}
              documentTemplateOptions={jobTypeStep.documentTemplateOptions}
              documentTemplateField={documentTemplateField}
              documentTemplateKind={documentTemplateKind}
              handleSubmit={handleSubmit}
              organization={organization}
            />
          ))}
        </DragAndDropList>
      </DocumentSectionItemContainer>
    </DocumentsContainer>
  );
};

const FakeJobTypeStepsItem = ({name, description, stepIndex}) => {
  return (
    <JobTypeStepsItemContainer index={stepIndex}>
      <Space height={12} />
      <Row style={{paddingHorizontal: 16}}>
        <JobTypeHeaderView>
          <JobTypeHeaderText style={{width: 32}} color={colors.gray.secondary}>{`${
            stepIndex + 1
          }.`}</JobTypeHeaderText>
          <JobTypeHeaderText numberOfLines={1}>{name}</JobTypeHeaderText>
        </JobTypeHeaderView>
        <Text numberOfLines={2}>{description}</Text>
        <Space style={{flex: 1}} />
        <Space width={16} />
        <TextTooltip text={'This step is on by default for all jobs'}>
          <View>
            <Switch isOn disabled color={colors.blue.interactive} size={Switch.SIZE.MEDIUM} />
          </View>
        </TextTooltip>
      </Row>
      <Space height={8} />
    </JobTypeStepsItemContainer>
  );
};

const JobTypeStepsItem = ({jobTypeStep, stepIndex, organization}) => {
  const jobTypeStepForm = JobTypeStepForm.edit(jobTypeStep);

  const jobTypeStepIsEnabledField = 'jobTypeStepForm.isEnabled';
  const jobTypeStepIdField = 'jobTypeStepForm.jobTypeStepId';
  const jobTypeStepDocumentTemplateKindsField = 'jobTypeStepForm.documentTemplateKinds';

  const {form, handleSubmit} = useUpsertJobTypeStepMutation({
    jobTypeStepForm,
    onSuccess: ({jobTypeStep}) => {
      form.setFieldValue(jobTypeStepIdField, jobTypeStep.id);
      form.setFieldValue(jobTypeStepIsEnabledField, jobTypeStep.isEnabled);
      form.setFieldValue(jobTypeStepDocumentTemplateKindsField, jobTypeStep.documentTemplateKinds);
    },
    onError: () =>
      form.setFieldValue(jobTypeStepIsEnabledField, !form.values.jobTypeStepForm.isEnabled),
  });

  return (
    <JobTypeStepsItemContainer index={stepIndex}>
      <Space height={12} />
      <Row style={{paddingHorizontal: 16}}>
        <JobTypeHeaderView>
          {jobTypeStep.kind !== 'DURING_MOVE' && (
            <JobTypeHeaderText style={{width: 32}} color={colors.gray.secondary}>
              {`${stepIndex + 1}.`}
            </JobTypeHeaderText>
          )}
          <JobTypeHeaderText numberOfLines={1}>{jobTypeStep.name}</JobTypeHeaderText>
        </JobTypeHeaderView>
        <Text numberOfLines={2}>{jobTypeStep.description}</Text>
        <Space style={{flex: 1}} />
        <Space width={16} />
        <SwitchField
          {...form}
          disabled={jobTypeStep.canHaveDocuments}
          index={1}
          name={jobTypeStepIsEnabledField}
          onChangeValue={handleSubmit}
        />
      </Row>
      <Space height={8} />
      {jobTypeStep.canHaveDocuments && (
        <DocumentRow>
          <JobTypeStepDocumentsSection
            form={form}
            handleSubmit={handleSubmit}
            jobTypeStep={jobTypeStep}
            stepIndex={stepIndex}
            organization={organization}
          />
        </DocumentRow>
      )}
    </JobTypeStepsItemContainer>
  );
};

// NOTE(kevin): This transformation takes combines job types steps with organization
// configured job steps
const mergedJobStepsWithOrganizationJobSteps = (
  baseJobSteps,
  jobTypeSteps,
  jobTypeId,
  organizationId,
) => {
  return (
    baseJobSteps
      // We merge the crew default steps with job type step
      .reduce((mergedJobTypeSteps, defaultJobStep) => {
        // Remove Pre Move payments from the FE, we can't do this in the backend
        // as we can't remove a kind from config if there are existing job steps with the kind
        if (defaultJobStep.kind === 'CREW_PRE_MOVE_PAYMENTS') {
          return mergedJobTypeSteps;
        }

        // Find job type step exist for given organization job steps
        const jobTypeStep = _.find(jobTypeSteps, {
          kind: defaultJobStep.kind,
        });

        if (jobTypeStep) {
          return [...mergedJobTypeSteps, jobTypeStep];
        } else {
          return [
            ...mergedJobTypeSteps,
            {
              ...defaultJobStep,
              documentTemplateKinds: [],
              isEnabled: false,
              jobTypeId,
              organizationId,
            },
          ];
        }
      }, [])
  );
};

const PrimarySteps = ({jobTypeSteps, organization}) => {
  // Used to determine step number
  const postMoveBillingIndex = _.findIndex(jobTypeSteps, {
    kind: 'CREW_POST_MOVE_BILLING',
  });

  return jobTypeSteps.map((jobTypeStep, index) => {
    const isAfterInsertedStep = index >= postMoveBillingIndex;
    if (jobTypeStep.kind === 'AFTER_TIMESHEET') {
      return (
        <React.Fragment key={index}>
          {/* Insert a fake job step item (doesn't exist on the backend) to be clear to users that the timesheets step exists here */}
          <FakeJobTypeStepsItem
            name={'Job Timesheet'}
            description={
              'Allow the crew to record time worked on the job. These hours are billed to the customer.'
            }
            stepIndex={index}
          />
          <Space height={8} />
          <JobTypeStepsItem
            jobTypeStep={jobTypeStep}
            stepIndex={index + 1}
            organization={organization}
          />
          <Space height={8} />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment key={index}>
        <JobTypeStepsItem
          jobTypeStep={jobTypeStep}
          stepIndex={isAfterInsertedStep ? index + 1 : index}
          organization={organization}
        />
        <Space height={8} />
      </React.Fragment>
    );
  });
};

const AdditionalSteps = ({index, jobTypeStep, organization}) => {
  return (
    <React.Fragment key={index}>
      <HeaderText>Additional Steps</HeaderText>
      <Space height={24} />
      <JobTypeStepsItem jobTypeStep={jobTypeStep} stepIndex={index} organization={organization} />
      <Space height={8} />
    </React.Fragment>
  );
};

const JobTypeStepsSettingsContent = ({jobTypeUuid}) => {
  const {loading, data, refetch} = useQuery(JobTypeStepsSettingsPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      jobTypeUuid,
    },
  });

  if (loading) {
    return <PageLoadingIndicator />;
  }

  const jobType = data.jobTypeByUuid;
  const {organization, jobTypeSteps, projectType} = jobType;

  const jobTypeStepsTransformed = mergedJobStepsWithOrganizationJobSteps(
    jobType.projectType.defaultCrewJobSteps,
    jobTypeSteps,
    jobType.id,
    organization.id,
  );

  return (
    <PageContainer>
      <JobTypeSettingsPageHeader jobType={jobType} />
      <ContentContainer>
        <ScrollView horizontal contentContainerStyle={{flexGrow: 1}}>
          <ScrollView>
            <Space height={24} />
            <ButtonRow>
              <ApplyJobTypeConfigPopoverButton
                jobType={jobType}
                refetch={refetch}
                defaultJobTypeConfigs={[JobTypeConfigs.CREW_STEPS.value]}
              />
            </ButtonRow>
            <HeaderText>Crew App Steps & Documents</HeaderText>
            <Space height={12} />
            <Text>
              {
                'Configure crew app steps and document settings for this job type. Supermove will automatically apply these settings to your jobs.'
              }
            </Text>
            <Space height={24} />
            <PrimarySteps
              jobTypeSteps={_.filter(jobTypeStepsTransformed, (jobTypeStep) => {
                if (
                  projectType.features.timesheetsV2 &&
                  jobTypeStep.kind === 'CREW_AFTER_MOVE_TIMESHEET'
                ) {
                  return false;
                }
                return jobTypeStep.kind !== 'DURING_MOVE';
              })}
              organization={organization}
            />
            <Space height={40} />
            <AdditionalSteps
              index={jobTypeStepsTransformed.length}
              jobTypeStep={_.find(jobTypeStepsTransformed, {kind: 'DURING_MOVE'})}
              organization={organization}
            />
          </ScrollView>
        </ScrollView>
        <Space height={48} />
      </ContentContainer>
    </PageContainer>
  );
};

const JobTypeStepsSettingsPage = () => {
  const {params} = useNavigationDOM();
  return (
    <SidebarPageV2 selected={'settings'}>
      <JobTypeStepsSettingsContent jobTypeUuid={params.jobTypeUuid} />
    </SidebarPageV2>
  );
};

JobTypeStepsSettingsPage.query = gql`
${ApplyJobTypeConfigPopoverButton.fragment}
${JobTypeSettingsPageHeader.fragment}

query JobTypeStepsSettingsPage(
  $jobTypeUuid: String!,
) {
  ${gql.query}
  jobTypeByUuid(jobTypeUuid: $jobTypeUuid) {
    id
    organization {
      id
      documentTemplatesWithActiveDocumentTemplateVersion {
        id
        name
        identifier
        category
      }
    }
    projectType {
      id
      defaultCrewJobSteps {
        kind
        name
        description
        documentTemplateOptions {
          kind
          name
        }
        canHaveDocuments
      }
      features {
        timesheetsV2
      }
    }
    jobTypeSteps {
      id
      kind
      documentTemplateKinds
      description
      canHaveDocuments
      documentTemplateOptions {
        kind
        name
      }
      name
      isEnabled
      jobTypeId
      organizationId
    }
    ...ApplyJobTypeConfigPopoverButton
    ...JobTypeSettingsPageHeader
  }
}
`;

export default JobTypeStepsSettingsPage;
