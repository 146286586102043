// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {useEffect, useModal, useResponsive, useState} from '@supermove/hooks';
import {Dashboard} from '@supermove/models';
import {Typography} from '@supermove/styles';

// App
import ExploDashboard, {DashboardEvent} from '@shared/modules/Reports/components/ExploDashboard';
import Line from 'modules/App/components/Line';
import DashboardsPageEditDashboardModal, {
  DashboardDataModel as EditDashboardModalDashboardDataModel,
} from 'modules/Report/components/DashboardsPageEditDashboardModal';
import DashboardsPageRemoveDashboardModal from 'modules/Report/components/DashboardsPageRemoveDashboardModal';
import DashboardsPageResetDashboardToDefaultModal from 'modules/Report/components/DashboardsPageResetDashboardToDefaultModal';
import DashboardsPageUpdateDashboardVariablesModal, {
  DashboardDataModel as UpdateDashboardVariablesModalDashboardDataModel,
} from 'modules/Report/components/DashboardsPageUpdateDashboardVariablesModal';
import DashboardsPageViewPanelHeader, {
  DashboardDataType as HeaderDashboardDataType,
} from 'modules/Report/components/DashboardsPageViewPanelHeader';

const Container = Styled.View`
  flex: 1;
  width: 100%;
  flex-direction: column;
`;

const HeaderContainer = Styled.View`
  width: 100%;
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 16px;
  padding-horizontal: 24px;
`;

const ExploContainer = Styled.View`
  flex: 1;
  width: 100%;
  flex-direction: column;
  padding: 24px;
`;

const NoteText = Styled.Text`
  ${Typography.Responsive.Micro}
`;

type QueryResponse = {
  viewer: {
    id: string;
    viewingOrganization: {
      id: number;
      exploUserGroupToken: string;
      features: {
        isEnabledOfficeReportsUpdateAndResetView: boolean;
      };
    };
  };
  dashboard: EditDashboardModalDashboardDataModel &
    HeaderDashboardDataType &
    UpdateDashboardVariablesModalDashboardDataModel & {
      exploDashboardEmbedId: string;
      exploVariables: string;
    };
};

type DashboardsPageViewPanelProps = {
  selectedDashboardUuid: string;
  handleRemove: () => void;
};

const DashboardsPageViewPanel = ({
  selectedDashboardUuid,
  handleRemove,
}: DashboardsPageViewPanelProps) => {
  const responsive = useResponsive();
  const editDashboardModal = useModal({name: 'Dashboards Page Edit Dashboard Modal'});
  const removeDashboardModal = useModal({name: 'Dashboards Page Remove Dashboard Modal'});
  const resetToDefaultDashboardModal = useModal({
    name: 'Dashboards Page Reset To Default Dashboard Modal',
  });
  const updateViewDashboardModal = useModal({name: 'Dashboards Page Update View Dashboard Modal'});

  const {data, loading, refetch} = useQuery<QueryResponse>(DashboardsPageViewPanel.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      uuid: selectedDashboardUuid,
    },
  });

  const [newExploVariables, setNewExploVariables] = useState<Record<string, any>>(
    JSON.parse(data?.dashboard.exploVariables || '{}'),
  );

  useEffect(() => {
    if (data) {
      setNewExploVariables(JSON.parse(data.dashboard.exploVariables));
    }
  }, [data]);

  const handleExploMessage = (event: DashboardEvent) => {
    if (event.event === 'sendVariableUpdatedEvent') {
      const existingVariables = JSON.parse(data?.dashboard.exploVariables || '{}');
      setNewExploVariables((prev) => ({
        ...existingVariables,
        ...prev,
        [event.detail.varName]: event.detail.newValue,
      }));
    }
  };

  return (
    <Loading loading={loading || !data}>
      {() =>
        data && (
          <React.Fragment>
            <Container>
              <HeaderContainer>
                <DashboardsPageViewPanelHeader
                  dashboard={data.dashboard}
                  isEnabledOfficeReportsUpdateAndResetView={
                    data.viewer.viewingOrganization.features
                      .isEnabledOfficeReportsUpdateAndResetView
                  }
                  isNewExploVariables={Dashboard.isVariablesChanged(
                    data.dashboard,
                    newExploVariables,
                  )}
                  handleEdit={editDashboardModal.handleOpen}
                  handleRemove={removeDashboardModal.handleOpen}
                  handleResetToDefault={resetToDefaultDashboardModal.handleOpen}
                  handleUpdateView={updateViewDashboardModal.handleOpen}
                />
              </HeaderContainer>
              <Line />
              <ExploContainer>
                {!responsive.desktop && (
                  <React.Fragment>
                    <NoteText responsive={responsive}>Tap report to scroll and preview.</NoteText>
                    <Space height={16} />
                  </React.Fragment>
                )}
                <ExploDashboard
                  name={data.dashboard.name}
                  viewerId={_.toNumber(data.viewer.id)}
                  exploDashboardEmbedId={data.dashboard.exploDashboardEmbedId}
                  exploUserGroupToken={data.viewer.viewingOrganization.exploUserGroupToken}
                  exploVariables={data.dashboard.exploVariables}
                  style={{
                    flex: 1,
                    height: responsive.desktop ? undefined : 564,
                  }}
                  onMessage={handleExploMessage}
                />
              </ExploContainer>
            </Container>
            <DashboardsPageEditDashboardModal
              dashboard={data.dashboard}
              isOpen={editDashboardModal.isOpen}
              handleClose={editDashboardModal.handleClose}
              refetch={refetch}
            />
            <DashboardsPageRemoveDashboardModal
              dashboard={data.dashboard}
              isOpen={removeDashboardModal.isOpen}
              handleClose={removeDashboardModal.handleClose}
              handleRemove={handleRemove}
            />
            <DashboardsPageResetDashboardToDefaultModal
              dashboardId={data.dashboard.id}
              isOpen={resetToDefaultDashboardModal.isOpen}
              handleClose={resetToDefaultDashboardModal.handleClose}
              refetch={refetch}
            />
            <DashboardsPageUpdateDashboardVariablesModal
              dashboard={data.dashboard}
              newExploVariables={JSON.stringify(newExploVariables)}
              isOpen={updateViewDashboardModal.isOpen}
              handleClose={updateViewDashboardModal.handleClose}
              refetch={refetch}
            />
          </React.Fragment>
        )
      }
    </Loading>
  );
};

DashboardsPageViewPanel.query = gql`
  ${DashboardsPageViewPanelHeader.fragment}
  ${DashboardsPageEditDashboardModal.fragment}
  ${DashboardsPageRemoveDashboardModal.fragment}
  ${DashboardsPageUpdateDashboardVariablesModal.fragment}
  ${Dashboard.isVariablesChanged.fragment}
  query DashboardsPageViewPanel ($uuid: String!) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        exploUserGroupToken
        features {
          isEnabledOfficeReportsUpdateAndResetView: isEnabled(feature: "OFFICE_REPORTS_UPDATE_AND_RESET_VIEW")
        }
      }
    }
    dashboard(dashboardUuid: $uuid) { 
      id
      name
      exploDashboardEmbedId
      exploVariables
      ...DashboardsPageViewPanelHeaderFragment
      ...DashboardsPageEditDashboardModalFragment
      ...DashboardsPageRemoveDashboardModalFragment
      ...DashboardsPageUpdateDashboardVariablesModalFragment
      ...Dashboard_isVariablesChanged
    }
  }
`;

export default DashboardsPageViewPanel;
