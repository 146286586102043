// Libraries
import React from 'react';

// Supermove
import {Icon, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useMemo, useQuery, useState} from '@supermove/hooks';
import {DashboardCategoryModel, TagModel} from '@supermove/models';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import MultiDropdownInputComponent from '@shared/design/components/MultiDropdownInput';

interface GlobalDashboardFilterModalProps {
  isOpen: boolean;
  selectedCategoryIds: string[];
  selectedTagIds: string[];
  handleClose: () => void;
  handleApply: (props: {selectedCategoryIds: string[]; selectedTagIds: string[]}) => void;
}

const GlobalDashboardsLibraryPageGlobalDashboardsFilterModal = ({
  isOpen,
  selectedCategoryIds,
  selectedTagIds,
  handleClose,
  handleApply,
}: GlobalDashboardFilterModalProps) => {
  const [newSelectedCategoryIds, setNewSelectedCategoryIds] = useState(selectedCategoryIds || []);
  const [newSelectedTagIds, setNewSelectedTagIds] = useState(selectedTagIds || []);

  const {data} = useQuery(GlobalDashboardsLibraryPageGlobalDashboardsFilterModal.query, {
    fetchPolicy: 'cache-and-network',
    skip: !isOpen,
  });

  const sortedCategoryOptions = useMemo(() => {
    const categories: DashboardCategoryModel[] = data?.dashboardCategories || [];
    return categories
      .map((category) => ({
        label: category.name,
        value: category.id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [data]);

  const sortedTagOptions = useMemo(() => {
    const tags: TagModel[] = data?.dashboardTags || [];
    return tags
      .map((tag) => ({
        label: tag.name,
        value: tag.id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [data]);

  const filtersCount = (newSelectedCategoryIds.length ? 1 : 0) + (newSelectedTagIds.length ? 1 : 0);

  return (
    <SmallModal
      isOpen={isOpen}
      isMobileSheet
      title={`Filters (${filtersCount})`}
      handleActionText={'Apply'}
      handleAction={() =>
        handleApply({
          selectedCategoryIds: newSelectedCategoryIds,
          selectedTagIds: newSelectedTagIds,
        })
      }
      headerActionText={'Clear Filters'}
      headerActionIcon={Icon.Trash}
      handleHeaderAction={() => {
        setNewSelectedCategoryIds([]);
        setNewSelectedTagIds([]);
      }}
      handleClose={handleClose}
    >
      <FieldInput
        index={0}
        component={MultiDropdownInputComponent}
        label={'Category'}
        input={{
          options: sortedCategoryOptions,
          name: 'Category',
          value: newSelectedCategoryIds,
          setFieldValue: (name: string, value: string[]) => setNewSelectedCategoryIds(value),
          placeholder: 'Select categories',
          style: {flex: 1},
          isResponsive: true,
          isPortaled: true,
        }}
      />
      <Space height={16} />
      <FieldInput
        index={1}
        component={MultiDropdownInputComponent}
        label={'Tag'}
        input={{
          options: sortedTagOptions,
          name: 'Tag',
          value: newSelectedTagIds,
          setFieldValue: (name: string, value: string[]) => setNewSelectedTagIds(value),
          placeholder: 'Select tags',
          style: {flex: 1},
          isResponsive: true,
          isPortaled: true,
        }}
      />
      <Space height={16} />
    </SmallModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
GlobalDashboardsLibraryPageGlobalDashboardsFilterModal.query = gql`
  query GlobalDashboardsLibraryPageGlobalDashboardsFilterModal {
    ${gql.query}
    dashboardCategories {
        id
        name
    }
    dashboardTags {
        id
        name
    }
  }
`;

export default GlobalDashboardsLibraryPageGlobalDashboardsFilterModal;
