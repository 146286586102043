// Supermove
import {gql} from '@supermove/graphql';
import {DashboardCategoryModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

export type DashboardCategoryType = {
  id: string;
  name: string;
  index: number;
  isDeleted: boolean;
};

export interface DashboardCategoryFormType {
  dashboardCategoryId: number | null;
  name: string;
  index: number;
  replaceWithDashboardCategoryId?: number;
  isDeleted: boolean;
}

const _new = ({name}: Pick<DashboardCategoryFormType, 'name'>) => ({
  dashboardCategoryId: null,
  name: name || '',
  index: 0,
  isDeleted: false,
});

const edit = withFragment(
  (dashboardCategory: DashboardCategoryModel) => {
    return {
      dashboardCategoryId: dashboardCategory.id,
      name: dashboardCategory.name,
      index: dashboardCategory.index,
      isDeleted: dashboardCategory.isDeleted,
    };
  },
  gql`
    fragment DashboardCategoryForm_edit on DashboardCategory {
      id
      name
      index
      isDeleted
    }
  `,
);

const toForm = (dashboardCategoryForm: DashboardCategoryFormType): DashboardCategoryFormType => ({
  dashboardCategoryId: dashboardCategoryForm.dashboardCategoryId,
  name: dashboardCategoryForm.name,
  index: dashboardCategoryForm.index,
  replaceWithDashboardCategoryId: dashboardCategoryForm.replaceWithDashboardCategoryId,
  isDeleted: dashboardCategoryForm.isDeleted,
});

const toMutation = (
  dashboardCategoryForm: DashboardCategoryFormType,
): DashboardCategoryFormType => ({
  dashboardCategoryId: dashboardCategoryForm.dashboardCategoryId,
  name: dashboardCategoryForm.name,
  index: dashboardCategoryForm.index,
  replaceWithDashboardCategoryId: dashboardCategoryForm.replaceWithDashboardCategoryId,
  isDeleted: dashboardCategoryForm.isDeleted,
});

const DashboardCategoryForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default DashboardCategoryForm;
