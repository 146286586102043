// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useState} from '@supermove/hooks';

// App
import Button from '@shared/design/components/Button';
import IconButton from '@shared/design/components/IconButton';
import ModalComponent from '@shared/design/components/Modal';
import GlobalDashboardsLibraryPageGlobalDashboardViewPanel from 'modules/Report/Library/components/GlobalDashboardsLibraryPageGlobalDashboardViewPanel';
import GlobalDashboardsLibraryPageGlobalDashboardsList from 'modules/Report/Library/components/GlobalDashboardsLibraryPageGlobalDashboardsList';
import {GlobalDashboardDataType} from 'modules/Report/Library/components/GlobalDashboardsLibraryPageGlobalDashboardsListItem';
import {GlobalDashboardsLibraryPageUrlFilters} from 'modules/Report/Library/components/types';

const ListContainer = Styled.View`
  flex: 1;
  padding: 16px;
`;

type GlobalDashboardsLibraryPageGlobalDashboardsListModalProps = {
  filteredGlobalDashboards: GlobalDashboardDataType[];
  globalDashboardUuidToDashboardUuid: Record<string, string>;
  organizationId: string;
  urlFilters: GlobalDashboardsLibraryPageUrlFilters;
  isOpen: boolean;
  handleClose: () => void;
  handleSelect: (globalDashboard: GlobalDashboardDataType) => void;
  handleAdd: () => void;
};

const GlobalDashboardsLibraryPageGlobalDashboardsListModal = ({
  filteredGlobalDashboards,
  globalDashboardUuidToDashboardUuid,
  organizationId,
  isOpen,
  urlFilters,
  handleSelect,
  handleClose,
  handleAdd,
}: GlobalDashboardsLibraryPageGlobalDashboardsListModalProps) => {
  const [isPreview, setIsPreview] = useState(false);

  const isAdded = !!globalDashboardUuidToDashboardUuid[urlFilters.get('uuid')];

  return (
    <ModalComponent.Responsive
      isOpen={isOpen}
      width={ModalComponent.WIDTH.LARGE}
      onClose={handleClose}
    >
      <ModalComponent.Header style={{justifyContent: 'space-between'}} size={16}>
        {isPreview ? (
          <IconButton
            source={Icon.AngleLeft}
            isSecondary
            onPress={() => setIsPreview(false)}
            size={16}
          />
        ) : (
          <Space width={22} />
        )}
        <ModalComponent.HeaderText>{'Go to Report'}</ModalComponent.HeaderText>
        <IconButton source={Icon.Xmark} isSecondary onPress={handleClose} />
      </ModalComponent.Header>
      <ModalComponent.Body
        style={{
          flex: 1,
          padding: '0',
        }}
      >
        {isPreview ? (
          <GlobalDashboardsLibraryPageGlobalDashboardViewPanel
            selectedGlobalDashboardUuid={urlFilters.get('uuid')}
            isAdded={isAdded}
          />
        ) : (
          <ListContainer>
            <GlobalDashboardsLibraryPageGlobalDashboardsList
              filteredGlobalDashboards={filteredGlobalDashboards}
              globalDashboardUuidToDashboardUuid={globalDashboardUuidToDashboardUuid}
              organizationId={organizationId}
              urlFilters={urlFilters}
              handleSelect={handleSelect}
            />
          </ListContainer>
        )}
      </ModalComponent.Body>
      <ModalComponent.Footer style={{padding: 16}}>
        {isPreview ? (
          <Button
            text={isAdded ? 'Add as Custom' : 'Add'}
            iconLeft={Icon.Check}
            onPress={handleAdd}
            isWidthOfContainer
            style={{flex: 1}}
          />
        ) : (
          <Button
            text={'Next: Preview'}
            onPress={() => setIsPreview(true)}
            isWidthOfContainer
            isDisabled={!urlFilters.get('uuid')}
            style={{flex: 1}}
          />
        )}
      </ModalComponent.Footer>
    </ModalComponent.Responsive>
  );
};

export default GlobalDashboardsLibraryPageGlobalDashboardsListModal;
