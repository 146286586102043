// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DateInput, Icon, Space, Styled, TimeInput} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useResponsive} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import JobForm from '@shared/modules/Job/forms/JobForm';
import ResponsiveTextInput from 'modules/App/components/ResponsiveTextInput';
import JobTypeDropdown from 'modules/Job/components/JobTypeDropdown';
import DateRangeInput from 'modules/Project/V2/components/DateRangeInput';

const IndexedContainer = Styled.View`
  z-index: ${({index = 0}) => 100 - index};
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: flex-start;
`;

const TextInput = Styled.TextInput`
  ${Typography.Body}
`;

const EndDateToggleButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
`;

const getDateFieldLabel = ({kind}) => {
  switch (kind) {
    case Job.KIND.ESTIMATE:
      return 'Date';
    case Job.KIND.COMMERCIAL:
      return 'Move Date';
    default:
      return 'Job Date';
  }
};

const getFieldName = ({field, name}) => {
  return field ? `${field}.${name}` : name;
};

const handleToggleDateRange = ({form, field, isEstimatedRange, startDate}) => {
  const setFieldValue = form.customSetFieldValue || form.setFieldValue;

  // When toggling the date, we reset the current values that we're toggling away
  // from, but the date value and the startDate value get passed to each other.
  if (isEstimatedRange) {
    setFieldValue(`${field}.isEstimatedRange`, false);
    setFieldValue(`${field}.date`, startDate || _.get(form.values, `${field}.startDate`));
    setFieldValue(`${field}.startDate`, '');
    setFieldValue(`${field}.endDate`, '');
  } else {
    setFieldValue(`${field}.isEstimatedRange`, true);
    setFieldValue(`${field}.startDate`, startDate || _.get(form.values, `${field}.date`));
    setFieldValue(`${field}.endDate`, '');
    setFieldValue(`${field}.date`, '');
  }
};

const Spacer = () => {
  return <Space width={16} height={16} />;
};

const ResponsiveContainer = ({index, responsive, children}) => {
  return (
    <IndexedContainer
      index={index}
      style={responsive.desktop && {flexDirection: 'row', alignItems: 'flex-start'}}
    >
      {children}
    </IndexedContainer>
  );
};

const JobIdentifier = ({index, form, field, isDisabled}) => {
  return (
    <FieldInput.Memoized
      {...form}
      setFieldValue={form.customSetFieldValue}
      index={index}
      name={`${field}.identifier`}
      label={'Job Identifier'}
      isRequired
      input={{placeholder: 'Enter job identifier'}}
      style={{flex: 1}}
      isResponsive
      isDisabled={isDisabled}
    />
  );
};

const JobTypeAndName = ({index, field, form, project, responsive, isDisabled}) => {
  return (
    <ResponsiveContainer index={index} responsive={responsive}>
      <JobTypeDropdown
        index={0}
        organization={project.organization}
        projectTypeId={project.projectTypeId}
        form={form}
        field={field}
        // TODO - cleanp JobTypeDropdown to not need this redundant property
        jobFormField={field}
        shouldResetJobDateOnJobTypeChange
        isRequired
        style={{flex: 1}}
        isResponsive
        isDisabled={isDisabled}
      />
      <Spacer />
      <FieldInput.Memoized
        {...form}
        setFieldValue={form.customSetFieldValue}
        index={1}
        name={`${field}.name`}
        label={'Job Name'}
        input={{placeholder: 'Enter job name', disabled: isDisabled}}
        style={{flex: 1}}
        isResponsive
      />
    </ResponsiveContainer>
  );
};

const EndDateToggle = ({form, field, isEstimatedRange, isDisabled, responsive}) => {
  return (
    <EndDateToggleButton
      onPress={() => handleToggleDateRange({form, field, isEstimatedRange})}
      disabled={isDisabled}
    >
      <Icon
        source={isEstimatedRange ? Icon.Trash : Icon.Plus}
        size={responsive.desktop ? 12 : 14}
        color={isDisabled ? colors.gray.tertiary : colors.blue.interactive}
      />
      <Space width={8} />
      <FieldInput.ActionText
        style={{color: isDisabled ? colors.gray.tertiary : colors.blue.interactive}}
        isResponsive
      >
        End Date
      </FieldInput.ActionText>
    </EndDateToggleButton>
  );
};

const ScheduledDateField = ({
  form,
  field,
  label,
  isRequired,
  isDisabled,
  project,
  responsive,
  params,
  hasPastDateWarning,
}) => {
  const {projectType} = project;
  const jobTypeId = _.get(form.values, `${field}.jobTypeId`);
  const jobType = projectType.jobTypes.find((jobType) => jobType.id === _.toString(jobTypeId));

  const {isEnabledCreateProjectDispatchLocks} = project.organization.features;
  const hasDispatchLocks = jobType && !['ESTIMATE', 'REQUEST'].includes(jobType.kind);
  const excludeDates =
    projectType && isEnabledCreateProjectDispatchLocks && hasDispatchLocks
      ? projectType.futureSalesLockedProjectTypeDays.map(
          (projectTypeDay) => projectTypeDay.day.date,
        )
      : [];
  const dateFieldName = getFieldName({field, name: 'date'});
  const date = _.get(form.values, dateFieldName);
  const isDateInPast = Datetime.isPast(date);
  const hasTouchedDate = form.touched[dateFieldName] || form.touched[`${field}.startDate`]; // Counts if range field is touched

  return (
    // FieldInput is not memoized because the DateInput is listening for changes to excludeDates
    // excludeDates is being set when ProjectTypeDropdown is changed
    <FieldInput
      {...form}
      component={DateInput}
      name={`${field}.date`}
      label={label}
      warningMessage={
        hasPastDateWarning && hasTouchedDate && isDateInPast
          ? 'Selected date is in the past.'
          : undefined
      }
      hasWarning={hasPastDateWarning && hasTouchedDate && isDateInPast}
      tooltip={excludeDates.length > 0 && `Disabled dates have been locked by dispatch.`}
      action={
        <EndDateToggle
          form={form}
          field={field}
          isEstimatedRange={false}
          responsive={responsive}
          isDisabled={isDisabled}
        />
      }
      isRequired={isRequired}
      input={{
        placeholder: 'Enter date',
        onChangeDate: () => {
          form.setTouched({...form.touched, [dateFieldName]: true});
        },
        setFieldValue: form.customSetFieldValue,
        style: {width: '100%'},
        excludeDates,
        autoFocus: params.field === 'startDate',
        disabled: isDisabled,
      }}
      style={{flex: 1}}
      isResponsive
    />
  );
};

const EstimatedRangeFields = ({field, form, label, isRequired, responsive, params}) => {
  const startDateFieldName = `${field}.startDate`;
  const endDateFieldName = `${field}.endDate`;
  const startDate = _.get(form.values, startDateFieldName);
  const endDate = _.get(form.values, endDateFieldName);
  const minEndDate = startDate ? startDate.clone().add(1, 'days') : null;

  return (
    <Row style={{flex: 1}}>
      <FieldInput
        {...form}
        component={DateInput}
        name={startDateFieldName}
        label={label}
        isRequired={isRequired}
        input={{
          placeholder: 'Start date',
          setFieldValue: form.customSetFieldValue,
          style: {width: '100%'},
          autoFocus: params.field === 'startDate',
          onChangeDate: (date) => {
            if (date && endDate && !date.isBefore(endDate)) {
              form.setFieldValue(endDateFieldName, null);
            }
          },
        }}
        style={{flex: 1}}
        isResponsive
      />
      <Spacer />
      <FieldInput
        {...form}
        component={DateInput}
        name={`${field}.endDate`}
        isRequired={isRequired}
        action={
          <React.Fragment>
            <Space style={{flex: 1}} />
            <EndDateToggle form={form} field={field} isEstimatedRange responsive={responsive} />
          </React.Fragment>
        }
        input={{
          position: DateInput.Positions.BOTTOM_END,
          placeholder: 'End date',
          minDate: minEndDate,
          setFieldValue: form.customSetFieldValue,
          style: {width: '100%'},
        }}
        style={{flex: 1}}
        isResponsive
      />
    </Row>
  );
};

const EstimatedRangeFieldsV2 = ({field, form, label, isRequired, responsive, params}) => {
  return (
    <DateRangeInput
      field={field}
      form={form}
      startDateField={`${field}.startDate`}
      endDateField={`${field}.endDate`}
      toggleRangeOnSameDate={(startDate) => {
        handleToggleDateRange({form, field, isEstimatedRange: true, startDate});
      }}
      startDateProps={{
        label,
        isRequired,
        isResponsive: true,
      }}
      startDateInputProps={{
        placeholder: 'Start date',
        autoFocus: params.field === 'startDate',
      }}
      endDateProps={{
        isRequired,
        isResponsive: true,
        action: (
          <React.Fragment>
            <Space style={{flex: 1}} />
            <EndDateToggle form={form} field={field} isEstimatedRange responsive={responsive} />
          </React.Fragment>
        ),
      }}
      endDateInputProps={{
        position: DateInput.Positions.BOTTOM_END,
        placeholder: 'End date',
      }}
    />
  );
};

const DateField = ({index, form, field, label, project, responsive, params, isDisabled}) => {
  const isEstimatedRange = _.get(form.values, `${field}.isEstimatedRange`);
  const isRequired = project.organization.features.isEnabledJobStartDateIsRequired;
  const hasPastDateWarning = project.organization.features.isEnabledPastDateWarning;
  const EstimatedRangeFieldsComponent = hasPastDateWarning
    ? EstimatedRangeFieldsV2
    : EstimatedRangeFields;

  return (
    <IndexedContainer index={index}>
      {isEstimatedRange ? (
        <EstimatedRangeFieldsComponent
          form={form}
          field={field}
          label={label}
          isRequired={isRequired}
          isDisabled={isDisabled}
          responsive={responsive}
          params={params}
        />
      ) : (
        <ScheduledDateField
          form={form}
          field={field}
          label={label}
          isRequired={isRequired}
          isDisabled={isDisabled}
          project={project}
          responsive={responsive}
          params={params}
          hasPastDateWarning={hasPastDateWarning}
        />
      )}
    </IndexedContainer>
  );
};

const StartTime = ({index, form, field, organization, params, isDisabled}) => {
  return (
    <IndexedContainer index={index}>
      <FieldInput.LabelText isResponsive>{organization.jobStartTimeField}</FieldInput.LabelText>
      <Space height={4} />
      <Row>
        <FieldInput.Memoized
          {...form}
          component={TimeInput}
          name={`${field}.startTime1`}
          isRequired={organization.features.isEnabledJobStartTime1IsRequired}
          input={{
            component: TextInput,
            placeholder: 'Start',
            setFieldValue: form.customSetFieldValue,
            autoFocus: params.field === 'startTime1',
            disabled: isDisabled,
          }}
          style={{flex: 1}}
          isResponsive
        />
        <Spacer />
        <FieldInput.Memoized
          {...form}
          component={TimeInput}
          name={`${field}.startTime2`}
          input={{
            component: TextInput,
            placeholder: 'End',
            setFieldValue: form.customSetFieldValue,
            disabled: isDisabled,
          }}
          style={{flex: 1}}
          isResponsive
        />
      </Row>
    </IndexedContainer>
  );
};

const EstimatedHours = ({index, form, field, responsive, params, isDisabled}) => {
  const {isMinEstimateHoursInputVisible, isMaxEstimateHoursInputVisible} =
    JobForm.getDispatchFieldVisibility(_.get(form.values, field));
  if (!isMinEstimateHoursInputVisible && !isMaxEstimateHoursInputVisible) {
    return null;
  }
  return (
    <ResponsiveContainer index={index} responsive={responsive}>
      {isMinEstimateHoursInputVisible && (
        <FieldInput.Memoized
          {...form}
          setFieldValue={form.customSetFieldValue}
          index={0}
          name={`${field}.estimateHours1`}
          label={'Estimate Hours Min'}
          input={{
            placeholder: 'Enter estimate hours min',
            keyboardType: 'numeric',
            autoFocus: params.field === 'estimateHours1',
            disabled: isDisabled,
          }}
          style={{flex: 1}}
          isResponsive
        />
      )}
      {isMinEstimateHoursInputVisible && isMaxEstimateHoursInputVisible && <Spacer />}
      {isMaxEstimateHoursInputVisible && (
        <FieldInput.Memoized
          {...form}
          setFieldValue={form.customSetFieldValue}
          index={1}
          name={`${field}.estimateHours2`}
          label={'Estimate Hours Max'}
          input={{
            placeholder: 'Enter estimate hours max',
            keyboardType: 'numeric',
            disabled: isDisabled,
          }}
          style={{flex: 1}}
          isResponsive
        />
      )}
    </ResponsiveContainer>
  );
};

const JobDescription = ({index, form, field, isDisabled}) => {
  return (
    <IndexedContainer index={index}>
      <ResponsiveTextInput.Memoized
        label={'Job Description'}
        form={form}
        field={`${field}.description`}
        minHeight={56}
        input={{
          placeholder: 'Enter description',
          disabled: isDisabled,
        }}
        isResponsive
      />
    </IndexedContainer>
  );
};

const EditJobInformationFields = ({form, field, project, isDisabled}) => {
  const responsive = useResponsive();
  const kind = _.get(form.values, `${field}.kind`);
  const {params} = useNavigationDOM();

  return (
    <React.Fragment>
      {project.organization.features.isEnabledJobIdentifierField && (
        <React.Fragment>
          <JobIdentifier index={0} form={form} field={field} />
          <Spacer />
        </React.Fragment>
      )}
      <JobTypeAndName
        index={1}
        form={form}
        field={field}
        project={project}
        responsive={responsive}
        isDisabled={isDisabled}
      />
      <Spacer />
      <DateField
        index={2}
        form={form}
        field={field}
        project={project}
        label={getDateFieldLabel({kind})}
        responsive={responsive}
        params={params}
        isDisabled={isDisabled}
      />
      <Spacer />
      <StartTime
        index={3}
        form={form}
        field={field}
        organization={project.organization}
        params={params}
        isDisabled={isDisabled}
      />
      {kind !== Job.KIND.ESTIMATE && (
        <React.Fragment>
          <Spacer />
          <EstimatedHours
            index={4}
            form={form}
            field={field}
            responsive={responsive}
            params={params}
            isDisabled={isDisabled}
          />
        </React.Fragment>
      )}
      <Spacer />
      <JobDescription index={4} form={form} field={field} isDisabled={isDisabled} />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditJobInformationFields.fragment = gql`
  ${JobTypeDropdown.fragment}

  fragment EditJobInformationFields on Project {
    id
    projectTypeId
    projectType {
      id
      jobTypes {
        id
        kind
      }
      futureSalesLockedProjectTypeDays {
        id
        day {
          id
          date
        }
      }
    }
    organization {
      id
      jobStartTimeField
      features {
        isEnabledJobIdentifierField: isEnabled(feature: "JOB_IDENTIFIER_FIELD")
        isEnabledJobStartDateIsRequired: isEnabled(feature: "JOB_START_DATE_IS_REQUIRED")
        isEnabledJobStartTime1IsRequired: isEnabled(feature: "JOB_START_TIME_1_IS_REQUIRED")
        isEnabledCreateProjectDispatchLocks: isEnabled(feature: "CREATE_PROJECT_DISPATCH_LOCKS")
        isEnabledPastDateWarning: isEnabled(feature: "PAST_DATE_WARNING")
      }
      ...JobTypeDropdown
    }
  }
`;

export default EditJobInformationFields;
