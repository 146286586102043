// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {BillRuleTypeModel} from '@supermove/models';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import BillRuleTypeForm from '@shared/modules/Billing/forms/BillRuleTypeForm';
import useUpdateBillRuleTypeMutation from '@shared/modules/Billing/hooks/useUpdateBillRuleTypeMutation';
import BillRuleTypeFields from 'modules/Organization/Settings/BillingLibraries/components/BillRuleTypeFields';

const EditBillRuleTypeDrawerContent = ({
  billRuleType,
  userId,
  refetch,
  isOpen,
  handleClose,
}: {
  billRuleType: BillRuleTypeModel;
  userId: string;
  refetch: () => void;
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const billRuleTypeForm = BillRuleTypeForm.edit(billRuleType, {userId});
  const {form, submitting, handleSubmit} = useUpdateBillRuleTypeMutation({
    billRuleTypeForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });
  return (
    <DrawerWithAction
      isOpen={isOpen}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      headerText={'Edit Bill Rule'}
      shouldCloseOnClickOutside={false}
      isUsingLoadingComponent
    >
      <BillRuleTypeFields
        form={form}
        field={'billRuleTypeForm'}
        billingLibrary={billRuleType.billingLibrary}
      />
    </DrawerWithAction>
  );
};

const EditBillRuleTypeDrawer = ({
  billRuleTypeId,
  refetch,
  userId,
  isOpen,
  handleClose,
}: {
  billRuleTypeId: number;
  refetch: () => void;
  userId: string;
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const {data, loading} = useQuery(EditBillRuleTypeDrawer.query, {
    fetchPolicy: 'cache-and-network',
    variables: {billRuleTypeId},
    skip: !isOpen,
  });

  return (
    <Loading
      loading={!data || loading}
      as={() => {
        return (
          <DrawerWithAction.LoadingComponent
            isOpen={isOpen}
            headerText={'Edit Bill Rule'}
            handleClose={handleClose}
          >
            <PageLoadingIndicator />
          </DrawerWithAction.LoadingComponent>
        );
      }}
    >
      {() => (
        <EditBillRuleTypeDrawerContent
          billRuleType={data.billRuleType}
          userId={userId}
          refetch={refetch}
          isOpen={isOpen}
          handleClose={handleClose}
        />
      )}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditBillRuleTypeDrawer.query = gql`
  ${BillRuleTypeFields.fragment}
  ${BillRuleTypeForm.edit.fragment}
  query EditBillRuleTypeDrawer($billRuleTypeId: Int!) {
    ${gql.query}
    billRuleType(id: $billRuleTypeId) {
      id
      billingLibrary {
        id
        ...BillRuleTypeFields
      }
      ...BillRuleTypeForm_edit
    }
  }
`;

export default EditBillRuleTypeDrawer;
