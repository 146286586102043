// Libraries
import React from 'react';

// Supermove
import {Icon, Link, Popover, Space, Styled, SupermoveLogo} from '@supermove/components';
import {useModal, usePopover, useResponsive, useRef, useEffect} from '@supermove/hooks';
import {Environment} from '@supermove/sdk';
import {colors, Typography} from '@supermove/styles';

// App
import Badge from '@shared/design/components/Badge';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import TextTooltip from '@shared/design/components/TextTooltip';
import UserRole from '@shared/modules/User/enums/UserRole';
import GlobalHeaderMultibranchOrganizationSwitcherModal from 'modules/App/Global/GlobalHeaderMultibranchOrganizationSwitcherModal';
import GlobalCurrentUserActions from 'modules/App/Global/components/GlobalCurrentUserActions';
import GlobalSearchModal from 'modules/App/Global/components/GlobalSearchModal';
import useUserNotificationsCount from 'modules/App/Global/hooks/useUserNotificationsCount';
import ManagerSearch from 'modules/App/components/ManagerSearch';
import NotificationPopover from 'modules/App/components/OfficeHeader/NotificationPopover';
import UpdateSuperAdminOrganizationModal from 'modules/App/components/UpdateSuperAdminOrganizationModal';
import useAppContext from 'modules/App/context/useAppContext';

const HeaderContainer = Styled.View`
  flex-direction: row;
  height: 48px;
  background-color: ${colors.black};
  align-items: center;
`;

const LogoContainer = Styled.View`
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 3px;
`;

const IconContainer = Styled.View`
`;

const OrganizationNameText = Styled.Text`
  ${Typography.Label}
  color: ${colors.white};
`;

const AlertDot = Styled.View`
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: ${colors.red.warning};
  position: absolute;
  top: -4px;
  right: -6px;
`;

const HeaderIconButton = ({icon, onPress, hasAlert}) => {
  return (
    <TertiaryButton
      onPress={onPress}
      style={{
        paddingHorizontal: 8,
        paddingVertical: 8,
      }}
    >
      <Icon source={icon} size={16} color={colors.white} />
      {hasAlert && <AlertDot />}
    </TertiaryButton>
  );
};

const ToggleSidebarButton = () => {
  const {toggleIsGlobalNavSidebarExpanded} = useAppContext();

  return <HeaderIconButton icon={Icon.Bars} onPress={toggleIsGlobalNavSidebarExpanded} />;
};

const LogoButton = () => {
  return (
    <Link to={'/'}>
      <LogoContainer>
        <SupermoveLogo.Icon />
      </LogoContainer>
    </Link>
  );
};

const OrganizationName = () => {
  const responsive = useResponsive();
  const {viewer} = useAppContext();
  const multibranchOrganizationSwitcher = useModal({
    name: 'Multibranch Organization Switcher',
    enableTracking: true,
  });
  const isSuperAdminViewingMultiBranch =
    viewer?.role === UserRole.SUPER_ADMIN && viewer?.viewingOrganization.hasMultipleOrganizations;
  return (
    <React.Fragment>
      {(isSuperAdminViewingMultiBranch || viewer?.isPrimaryOrganizationAdmin) &&
      viewer?.viewingOrganization.features.isEnabledMultibranchOrganizationSwitcher ? (
        <TertiaryButton
          text={viewer?.viewingOrganization.name}
          textColor={colors.white}
          iconRight={Icon.ChevronDown}
          onPress={multibranchOrganizationSwitcher.handleOpen}
          isLarge={responsive.mobile}
        />
      ) : (
        <OrganizationNameText>{viewer?.viewingOrganization.name}</OrganizationNameText>
      )}
      <GlobalHeaderMultibranchOrganizationSwitcherModal
        key={multibranchOrganizationSwitcher.key}
        isOpen={multibranchOrganizationSwitcher.isOpen}
        handleClose={multibranchOrganizationSwitcher.handleClose}
      />
    </React.Fragment>
  );
};

const EnvironmentBadge = () => {
  const isVisible = !Environment.isProduction();
  if (!isVisible) {
    return null;
  }
  return (
    <React.Fragment>
      <Space width={16} />
      <Badge
        iconLeft={Icon.Code}
        label={Environment.getEnvironmentName()}
        color={colors.red.warning}
      />
    </React.Fragment>
  );
};

const SearchButton = ({handleOpenSearch}) => {
  const {viewer} = useAppContext();
  const keyPressHandlerRef = useRef(null);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if ((event.metaKey || event.ctrlKey) && (event.key === 'k' || event.key === 'K')) {
        keyPressHandlerRef.current && keyPressHandlerRef.current();
      }
    };

    // since we're looking for a combined keypress (cmd/ctrl + k), we need to listen to the keydown event
    window.addEventListener('keydown', handleKeyPress);
    // clean up the listener when the component is unmounted
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <React.Fragment>
      {handleOpenSearch ? (
        <HeaderIconButton icon={Icon.Search} onPress={handleOpenSearch} />
      ) : (
        <ManagerSearch
          organization={viewer?.viewingOrganization}
          trigger={({handleOpen}) => {
            // HACK: we need to store the handleOpen function in a ref so we can call it from the keypress event listener
            if (!keyPressHandlerRef.current) {
              keyPressHandlerRef.current = handleOpen;
            }
            return <HeaderIconButton icon={Icon.Search} onPress={handleOpen} />;
          }}
        />
      )}
    </React.Fragment>
  );
};

const NotificationsButton = ({handleOpenNotifications, hasUnseenNotifications}) => {
  return (
    <HeaderIconButton
      icon={Icon.Bell}
      onPress={handleOpenNotifications}
      hasAlert={hasUnseenNotifications}
    />
  );
};

const HelpButton = () => {
  return (
    <a target={'_blank'} href={'https://help.supermove.com'}>
      <TextTooltip text={'Go to Help Center'}>
        <IconContainer>
          <HeaderIconButton icon={Icon.QuestionCircle} />
        </IconContainer>
      </TextTooltip>
    </a>
  );
};

const SettingsButton = () => (
  <Link to={'/settings'} style={{padding: 8}}>
    <Icon source={Icon.Gear} size={16} color={colors.white} />
  </Link>
);

const SuperAdminOrganizationSwitcher = () => {
  return (
    <React.Fragment>
      <UpdateSuperAdminOrganizationModal
        trigger={({handleOpen}) => <HeaderIconButton icon={Icon.Ghost} onPress={handleOpen} />}
      />
      <Space width={16} />
    </React.Fragment>
  );
};

const GlobalHeaderContent = ({globalSearchModal, notificationPopover, hasUnseenNotifications}) => {
  const {viewer} = useAppContext();

  return (
    <React.Fragment>
      <Space width={16} />
      <ToggleSidebarButton />
      <Space width={16} />
      <LogoButton />
      <Space width={4} />
      <OrganizationName />
      <EnvironmentBadge />
      <Space style={{flex: 1}} />
      <SearchButton
        handleOpenSearch={
          viewer?.viewingOrganization.features.isEnabledGlobalSearchV2 &&
          globalSearchModal.handleOpen
        }
      />
      <Space width={16} />
      <Popover.Content innerRef={notificationPopover.ref}>
        <NotificationsButton
          handleOpenNotifications={notificationPopover.handleToggle}
          hasUnseenNotifications={hasUnseenNotifications}
        />
      </Popover.Content>
      <Space width={16} />
      <HelpButton />
      <Space width={16} />
      <SettingsButton />
      <Space width={16} />
      {viewer?.role === UserRole.SUPER_ADMIN && <SuperAdminOrganizationSwitcher />}
      <GlobalCurrentUserActions />
      <Space width={24} />
    </React.Fragment>
  );
};

const GlobalHeader = () => {
  const globalSearchModal = useModal({name: 'Global Search Modal'});
  const notificationPopover = usePopover({
    name: 'Office Header Notifications Popover',
    enableTracking: true,
  });
  const {unseenNotificationsCount, refetch: refetchCount} = useUserNotificationsCount();

  return (
    <HeaderContainer>
      <GlobalHeaderContent
        globalSearchModal={globalSearchModal}
        notificationPopover={notificationPopover}
        hasUnseenNotifications={!!unseenNotificationsCount}
      />
      <GlobalSearchModal
        key={globalSearchModal.key}
        isOpen={globalSearchModal.isOpen}
        handleClose={globalSearchModal.handleClose}
      />
      <NotificationPopover
        isOpen={notificationPopover.isOpen}
        handleOpen={notificationPopover.handleOpen}
        handleClose={notificationPopover.handleClose}
        popoverRef={notificationPopover.ref}
        refetchCount={refetchCount}
      />
    </HeaderContainer>
  );
};

GlobalHeader.OrganizationName = OrganizationName;
GlobalHeader.EnvironmentBadge = EnvironmentBadge;
GlobalHeader.SearchButton = SearchButton;
GlobalHeader.NotificationsButton = NotificationsButton;
GlobalHeader.SuperAdminOrganizationSwitcher = SuperAdminOrganizationSwitcher;
GlobalHeader.IconButton = HeaderIconButton;

export default GlobalHeader;
