// Libraries
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useToast} from '@supermove/hooks';

// App
import SuccessModal from '@shared/design/components/Modal/SmallModal/SuccessModal';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import DashboardLinkedToGlobalDashboardForm from '@shared/modules/Reports/forms/DashboardLinkedToGlobalDashboardForm';
import useUpdateDashboardLinkedToGlobalDashboardMutation from '@shared/modules/Reports/hooks/useUpdateDashboardLinkedToGlobalDashboardMutation';

export type DashboardDataModel = {
  id: string;
  uuid: string;
  organizationId: string;
  name: string;
  description: string;
  dashboardCategoryId: string;
  exploVariables: string;
};

interface DashboardsPageUpdateDashboardVariablesModalProps {
  dashboard: DashboardDataModel;
  newExploVariables: string;
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
}

const DashboardsPageUpdateDashboardVariablesModal = ({
  dashboard,
  newExploVariables,
  isOpen,
  handleClose,
  refetch,
}: DashboardsPageUpdateDashboardVariablesModalProps) => {
  const updateDashboardVariablesSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Dashboard filters have been updated!',
  });

  const dashboardLinkedToGlobalDashboardForm = DashboardLinkedToGlobalDashboardForm.edit({
    ...dashboard,
    exploVariables: newExploVariables,
  });
  const {form, submitting, handleSubmit} = useUpdateDashboardLinkedToGlobalDashboardMutation({
    dashboardLinkedToGlobalDashboardForm,
    onSuccess: () => {
      updateDashboardVariablesSuccessToast.handleToast();
      refetch();
      handleClose();
    },
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <SuccessModal
      isOpen={isOpen}
      icon={Icon.ChartLineUp}
      title={`Update report view?`}
      subtitle={'This will save the applied filters as the default view for the entire company.'}
      primaryActionText={'Confirm'}
      secondaryActionText={'Cancel'}
      handlePrimaryAction={handleSubmit}
      handleSecondaryAction={handleClose}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DashboardsPageUpdateDashboardVariablesModal.fragment = gql`
  fragment DashboardsPageUpdateDashboardVariablesModalFragment on Dashboard {
    id
    uuid
    organizationId
    name
    description
    dashboardCategoryId
    exploVariables
  }
`;

export default DashboardsPageUpdateDashboardVariablesModal;
