// Libraries
import _ from 'lodash';

const PENDING = 'PENDING';
const IN_PROGRESS = 'IN_PROGRESS';
const COMPLETE = 'COMPLETE';
const SUCCEEDED = 'SUCCEEDED';
const SYNCING = 'SYNCING';
const FAILED = 'FAILED';
const SKIPPED = 'SKIPPED';
const CANCELLED = 'CANCELLED'; // replaces KILLED
const KILLED = 'KILLED'; // being deprecated

const isResyncable = (status) => _.includes([COMPLETE, SKIPPED, CANCELLED, KILLED, FAILED], status);
const isIncomplete = (status) =>
  _.includes([PENDING, IN_PROGRESS, SYNCING, CANCELLED, SKIPPED, KILLED], status);
const isInProgressOrAboutToStart = (status) => _.includes([PENDING, IN_PROGRESS], status);

const BatchStatus = {
  PENDING,
  IN_PROGRESS,
  COMPLETE,
  SUCCEEDED,
  SYNCING,
  SKIPPED,
  CANCELLED, // replaces KILLED
  KILLED, // being deprecated

  isResyncable,
  isIncomplete,
  isInProgressOrAboutToStart,
};

export default BatchStatus;
