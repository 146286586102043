// Supermove
import {gql} from '@supermove/graphql';
import {Json, withFragment} from '@supermove/utils';

export interface UserFormProps {
  userId?: number;
  organizationId?: number;
  role: string;
  salesAppRole?: string;
  identifier: string;
  names: string;
  phoneNumber: string;
  forwardingPhoneNumber: string;
  position: string;
  email: string;
  isActive: boolean;
}

const _new = ({userId, organizationId}: {userId?: number; organizationId?: number}) => ({
  userId,
  organizationId,
  role: '',
  identifier: '',
  names: '["", ""]',
  phoneNumber: '',
  forwardingPhoneNumber: '',
  position: '',
  email: '',
  isActive: false,
});

const edit = withFragment(
  (user: any) => ({
    userId: user.id,
    organizationId: user.organization.id,
    role: user.role,
    salesAppRole: user.salesAppRole,
    identifer: user.identifer,
    names: user.names,
    phoneNumber: user.phoneNumber,
    forwardingPhoneNumber: user.forwardingPhoneNumber,
    position: user.position,
    email: user.email,
    isActive: user.isActive,
  }),
  gql`
    fragment UserForm_edit on User {
      id
      role
      salesAppRole
      identifier
      names
      phoneNumber
      forwardingPhoneNumber
      email
      position
      isActive
      organization {
        id
      }
    }
  `,
);

const toForm = ({
  userId,
  organizationId,
  role,
  salesAppRole,
  identifier,
  names,
  phoneNumber,
  forwardingPhoneNumber,
  position,
  email,
  isActive,
}: {
  userId?: number;
  organizationId?: number;
  salesAppRole?: string;
  role: string;
  identifier: string;
  names: string;
  phoneNumber: string;
  forwardingPhoneNumber: string;
  position: string;
  email: string;
  isActive: boolean;
}) => ({
  userId,
  organizationId,
  role,
  salesAppRole,
  identifier,
  names: Json.toForm(names),
  phoneNumber,
  forwardingPhoneNumber,
  position,
  email,
  isActive,
});

const toMutation = ({
  userId,
  organizationId,
  role,
  salesAppRole,
  identifier,
  names,
  phoneNumber,
  forwardingPhoneNumber,
  position,
  email,
  isActive,
}: {
  userId?: number;
  organizationId?: number;
  role: string;
  salesAppRole?: string;
  identifier: string;
  names: string;
  phoneNumber: string;
  forwardingPhoneNumber: string;
  position: string;
  email: string;
  isActive: boolean;
}) => ({
  userId,
  organizationId,
  role,
  salesAppRole,
  identifier,
  // @ts-expect-error This seems to be defined in new as a string, but used as a string[].
  // Possibly can just be changed to string[]
  names: Json.toMutation(names),
  phoneNumber,
  forwardingPhoneNumber,
  position,
  email,
  isActive,
});

const UserForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default UserForm;
