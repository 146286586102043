// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Styled, Icon} from '@supermove/components';
import {Typography, colors} from '@supermove/styles';

// App
import TextTooltip from '@shared/design/components/TextTooltip';

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Button = Styled.ButtonV2`
`;

const EmojiContainer = Styled.View`
  background-color: ${colors.gray.background};
  height: 18px;
  width: 18px;
  border-radius: 9px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  align-items: center;
  justify-content: center;
  margin-right: -8px;
`;

const IconContainer = Styled.View`
  height: 18px;
  width: 18px;
  align-items: center;
  justify-content: center;
  margin-right: -8px;
`;

const BodyText = Styled.Text`
  ${Typography.Micro}
`;

const TruncateText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.tertiary};
  margin-left: 10px;
`;

const getHoverText = ({tags}) => {
  if (_.isEmpty(tags)) {
    return '';
  }

  const hoverText = tags.map((tag) => `${tag.emoji} ${tag.name}`).join('\n');

  return hoverText;
};

// TODO(Kevin): create a new Tag model and use that instead
// Fetch tags from tagAssignmentBy* and passed into component
// With a combined list -> see ProjectTags
const TagEmojiButton = ({tags, numberOfVisibleSelections, upsertTagModal}) => {
  return (
    <Button onPress={upsertTagModal.handleOpen}>
      {!_.isEmpty(tags) ? (
        <TextTooltip text={getHoverText({tags})}>
          <Container>
            {tags.map((tag, index) => {
              if (index >= numberOfVisibleSelections) {
                return null;
              }
              return (
                <EmojiContainer index={index} key={index}>
                  <BodyText>{tag.emoji}</BodyText>
                </EmojiContainer>
              );
            })}
            {tags.length > numberOfVisibleSelections && (
              <TruncateText>{`+${tags.length - numberOfVisibleSelections}`}</TruncateText>
            )}
          </Container>
        </TextTooltip>
      ) : (
        <IconContainer>
          <Icon source={Icon.Plus} color={colors.blue.interactive} size={16} />
        </IconContainer>
      )}
    </Button>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
TagEmojiButton.propTypes = {
  tags: PropTypes.array.isRequired,
  numberOfVisibleSelections: PropTypes.number,
  upsertTagModal: PropTypes.object.isRequired,
};

TagEmojiButton.defaultProps = {
  numberOfVisibleSelections: 2,
};

export default TagEmojiButton;
