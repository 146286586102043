// Libraries
import React from 'react';

// Supermove
import {Icon, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover, useModal, useNavigationDOM, usePopover, useResponsive} from '@supermove/hooks';
import {Bill, Job} from '@supermove/models';
import {fontWeight, colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import ResponsiveBadge from '@shared/design/components/Badge/ResponsiveBadge';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import InvoiceStatus from '@shared/modules/Billing/enums/InvoiceStatus';
import UpdateValuesForm from '@shared/modules/Billing/forms/UpdateValuesForm';
import useUpdateValuesMutation from '@shared/modules/Billing/hooks/useUpdateValuesMutation';
import BillingProjectDeleteBillModal from 'modules/Project/Billing/components/BillingProjectDeleteBillModal';
import BillingProjectEditBillModal from 'modules/Project/Billing/components/BillingProjectEditBillModal';
import EditBillBillItemsModal from 'modules/Project/Billing/components/EditBillBillItemsModal';
import EditBillBillRulesModal from 'modules/Project/Billing/components/EditBillBillRulesModal';
import EditBillingValuesModal from 'modules/Project/Billing/components/EditBillingValuesModal';
import UpdateProjectValuesModal from 'modules/Project/Billing/components/UpdateProjectValuesModal';
import ConfirmInvoiceEditModal from 'modules/Storage/components/ConfirmInvoiceEditModal';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const Column = Styled.View`
  flex: 1;
  justify-content: center;
`;

const BillHeaderRow = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const SubheadingText = Styled.Text`
  ${Typography.Responsive.Subheading}
`;

const MicroText = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const BillActionsPopoverContainer = Styled.View`
  width: 180px;
  border-radius: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  background-color: ${colors.white};
`;

const BillActionsPopoverItem = Styled.Touchable`
  padding: 12px;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) => (props.isHovered ? colors.hover : 'transparent')};
`;

const BillActionsPopoverItemText = Styled.H7`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const IconButton = Styled.ButtonV2`
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
`;

const ColumnHeader = Styled.Text`
  ${Typography.Responsive.MicroLabel}
`;

const BillActionsPopoverAction = ({
  bill,
  showConfirmEditModal,
  children,
  handlePress,
  billActionsPopover,
}) => {
  const confirmInvoiceEditModal = useModal({name: 'Confirm Invoice Edit Modal'});
  const {ref, isHovered} = useHover();

  return (
    <BillActionsPopoverItem
      ref={ref}
      isHovered={isHovered}
      activeOpacity={0.8}
      onPress={() => {
        if (showConfirmEditModal) {
          confirmInvoiceEditModal.handleOpen();
        } else {
          handlePress();
        }
        billActionsPopover.handleClose();
      }}
    >
      <BillActionsPopoverItemText>{children}</BillActionsPopoverItemText>
      {bill.invoice && (
        <ConfirmInvoiceEditModal
          key={confirmInvoiceEditModal.key}
          isOpen={confirmInvoiceEditModal.isOpen}
          invoice={bill.invoice}
          handleAction={handlePress}
          handleClose={confirmInvoiceEditModal.handleClose}
        />
      )}
    </BillActionsPopoverItem>
  );
};

const BillActionsPopover = ({
  bill,
  billActionsPopover,
  billItemsModal,
  billRulesModal,
  updateProjectValuesModal,
  editBillModal,
  deleteBillModal,
  showConfirmEditModal,
}) => {
  return (
    <Popover
      placement={Popover.Positions.BottomEnd}
      isOpen={billActionsPopover.isOpen}
      handleOpen={billActionsPopover.handleOpen}
      handleClose={billActionsPopover.handleClose}
      reference={billActionsPopover.ref}
      offset={[0, 4]}
    >
      <BillActionsPopoverContainer>
        <Space height={10} />
        <BillActionsPopoverAction
          bill={bill}
          showConfirmEditModal={showConfirmEditModal}
          handlePress={billItemsModal.handleOpen}
          billActionsPopover={billActionsPopover}
        >
          Edit billing items
        </BillActionsPopoverAction>
        <Space height={2} />
        <BillActionsPopoverAction
          bill={bill}
          showConfirmEditModal={showConfirmEditModal}
          handlePress={billRulesModal.handleOpen}
          billActionsPopover={billActionsPopover}
        >
          Edit billing rules
        </BillActionsPopoverAction>
        <Space height={2} />
        <BillActionsPopoverAction
          bill={bill}
          showConfirmEditModal={showConfirmEditModal}
          handlePress={updateProjectValuesModal.handleOpen}
          billActionsPopover={billActionsPopover}
        >
          Edit billing values
        </BillActionsPopoverAction>
        <Space height={2} />
        <BillActionsPopoverAction
          bill={bill}
          showConfirmEditModal={showConfirmEditModal}
          handlePress={editBillModal.handleOpen}
          billActionsPopover={billActionsPopover}
        >
          Edit title & description
        </BillActionsPopoverAction>
        {Bill.getIsDeletable(bill) && (
          <React.Fragment>
            <Space height={2} />
            <BillActionsPopoverAction
              bill={bill}
              handlePress={deleteBillModal.handleOpen}
              billActionsPopover={billActionsPopover}
            >
              Delete bill
            </BillActionsPopoverAction>
          </React.Fragment>
        )}
        <Space height={10} />
      </BillActionsPopoverContainer>
    </Popover>
  );
};

const HeaderActions = ({bill, refetch, pageRefetch}) => {
  const billActionsPopover = usePopover();
  const billItemsModal = useModal({name: 'Bill Items Modal'});
  const billRulesModal = useModal({name: 'Bill Rules Modal'});
  const updateProjectValuesModal = useModal({name: 'Update Project Values Modal'});
  const editBillingValuesModal = useModal({name: 'Edit Billing Values Modal'});
  const editBillModal = useModal({name: 'Edit Bill Modal'});
  const deleteBillModal = useModal({name: 'Delete Bill Modal'});
  const updateValuesForm = UpdateValuesForm.edit(bill.project);
  const {form, handleSubmit} = useUpdateValuesMutation({
    updateValuesForm,
    onSuccess: () => {
      editBillingValuesModal.handleClose();
      refetch();
      pageRefetch();
    },
    onError: (error) => {
      console.log(error);
    },
  });
  return (
    <Container>
      <Popover.Content innerRef={billActionsPopover.ref}>
        <SecondaryButton
          onPress={billActionsPopover.handleToggle}
          isSelected={billActionsPopover.isOpen}
          iconLeft={Icon.Pen}
          isDisabled={bill.invoice && bill.invoice.status === InvoiceStatus.FINALIZED}
          text={'Edit'}
          iconRight={billActionsPopover.isOpen ? Icon.ChevronUp : Icon.ChevronDown}
          isSmall
        />
      </Popover.Content>
      <EditBillBillItemsModal
        key={billItemsModal.key}
        isOpen={billItemsModal.isOpen}
        billUuid={bill.uuid}
        handleClose={billItemsModal.handleClose}
        refetch={refetch}
      />
      <EditBillBillRulesModal
        key={billRulesModal.key}
        isOpen={billRulesModal.isOpen}
        billUuid={bill.uuid}
        refetch={refetch}
        handleClose={billRulesModal.handleClose}
      />
      <UpdateProjectValuesModal
        key={updateProjectValuesModal.key}
        isOpen={updateProjectValuesModal.isOpen}
        projectUuid={bill.project.uuid}
        refetch={refetch}
        handleClose={updateProjectValuesModal.handleClose}
      />
      <EditBillingValuesModal
        key={editBillingValuesModal.key}
        isOpen={editBillingValuesModal.isOpen}
        handleClose={editBillingValuesModal.handleClose}
        projectUuid={bill.project.uuid}
        updateValuesForm={form}
        handleSubmit={handleSubmit}
      />
      <BillingProjectEditBillModal
        key={editBillModal.key}
        isOpen={editBillModal.isOpen}
        billUuid={bill.uuid}
        refetch={refetch}
        handleClose={editBillModal.handleClose}
      />
      <BillingProjectDeleteBillModal
        key={deleteBillModal.key}
        isOpen={deleteBillModal.isOpen}
        billUuid={bill.uuid}
        refetch={refetch}
        handleClose={deleteBillModal.handleClose}
      />
      <BillActionsPopover
        billActionsPopover={billActionsPopover}
        billItemsModal={billItemsModal}
        billRulesModal={billRulesModal}
        updateProjectValuesModal={editBillingValuesModal}
        showConfirmEditModal={bill.invoice && bill.invoice.status === InvoiceStatus.PAID}
        editBillModal={editBillModal}
        deleteBillModal={deleteBillModal}
        bill={bill}
      />
    </Container>
  );
};

const MobileHeaderButtons = ({bill, refetch}) => {
  const {navigator} = useNavigationDOM();
  const deleteBillModal = useModal({name: 'Delete Bill Modal'});

  return (
    <React.Fragment>
      <Row>
        {Bill.getIsDeletable(bill) && (
          <React.Fragment>
            <Space width={8} />
            <IconButton onPress={() => deleteBillModal.handleOpen()}>
              <Icon source={Icon.Trash} color={colors.red.warning} size={16} />
            </IconButton>
          </React.Fragment>
        )}
        <Space width={8} />
        <IconButton onPress={() => navigator.push(`/projects/billing/bills/${bill.uuid}/edit`)}>
          <Icon source={Icon.Pen} color={colors.blue.interactive} size={16} />
        </IconButton>
      </Row>
      <BillingProjectDeleteBillModal
        key={deleteBillModal.key}
        isOpen={deleteBillModal.isOpen}
        billUuid={bill.uuid}
        refetch={refetch}
        handleClose={deleteBillModal.handleClose}
      />
    </React.Fragment>
  );
};

const JobBillBadges = ({bill}) => {
  const responsive = useResponsive();
  return bill.job ? (
    <React.Fragment>
      {responsive.desktop && <Space width={8} />}
      <Row>
        {!!bill.job.date && (
          <React.Fragment>
            <ResponsiveBadge label={Job.getDisplayDate(bill.job, Datetime.DISPLAY_MONTH_DAY)} />
            <Space width={8} />
          </React.Fragment>
        )}
        <ResponsiveBadge label={Bill.getJobName(bill)} />
      </Row>
      {!responsive.desktop && <Space height={8} />}
    </React.Fragment>
  ) : null;
};

const FlexSpaceHolder = ({isQuantityVisible, isTotalVisible}) => {
  // This fills any additional space created from fields being disabled.
  // For every field we take away we add to the flex value to evenly compensate.
  let totalFlexSpace = 0;
  if (!isQuantityVisible) {
    totalFlexSpace += 2;
  }
  if (!isTotalVisible) {
    totalFlexSpace += 2;
  }
  return <Row style={{flex: totalFlexSpace}} />;
};

const ColumnHeaders = ({responsive, isQuantityVisible, isTotalVisible}) => {
  return (
    <React.Fragment>
      <Space height={16} />
      <Row>
        <ColumnHeader style={{flex: 4}} responsive={responsive}>
          Item
        </ColumnHeader>
        <FlexSpaceHolder isQuantityVisible={isQuantityVisible} isTotalVisible={isTotalVisible} />
        {isQuantityVisible && (
          <ColumnHeader style={{flex: 2, textAlign: 'right'}} responsive={responsive}>
            Qty
          </ColumnHeader>
        )}
        <ColumnHeader
          style={{flex: isTotalVisible ? 2 : 3, textAlign: 'right'}}
          responsive={responsive}
        >
          Price
        </ColumnHeader>
        {isTotalVisible && (
          <ColumnHeader style={{flex: 3, textAlign: 'right'}} responsive={responsive}>
            Subtotal
          </ColumnHeader>
        )}
      </Row>
    </React.Fragment>
  );
};

const BillsListItemHeader = ({bill, refetch, pageRefetch, isEditable, showTotal, showQuantity}) => {
  const responsive = useResponsive();
  const isQuantityVisible = isEditable || showQuantity;
  const isTotalVisible = isEditable || showTotal;
  return (
    <React.Fragment>
      <BillHeaderRow>
        <Column>
          {!responsive.desktop && <JobBillBadges bill={bill} />}
          <Row style={{justifyContent: 'space-between'}}>
            <Row style={{alignItems: 'center'}}>
              <SubheadingText numberOfLines={2} responsive={responsive}>
                {bill.title}
              </SubheadingText>
              {responsive.desktop && <JobBillBadges bill={bill} />}
            </Row>
            {!responsive.desktop && isEditable && (
              <MobileHeaderButtons bill={bill} refetch={refetch} />
            )}
          </Row>
          {!!bill.description && (
            <React.Fragment>
              <Space height={4} />
              <MicroText responsive={responsive}>{bill.description}</MicroText>
            </React.Fragment>
          )}
        </Column>
        {isEditable && responsive.desktop && (
          <HeaderActions bill={bill} refetch={refetch} pageRefetch={pageRefetch} />
        )}
      </BillHeaderRow>
      {responsive.desktop && (
        <ColumnHeaders
          responsive={responsive}
          isQuantityVisible={isQuantityVisible}
          isTotalVisible={isTotalVisible}
        />
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

BillsListItemHeader.fragment = gql`
  ${Bill.getJobName.fragment}
  ${Bill.getIsDeletable.fragment}
  ${Job.getDisplayDate.fragment}
  ${UpdateValuesForm.edit.fragment}
  ${ConfirmInvoiceEditModal.fragment}

  fragment BillsListItemHeader on Bill {
    id
    description
    uuid
    invoice {
      id
      status
      ...ConfirmInvoiceEditModal
    }
    job {
      id
      date
      ...Job_getDisplayDate
    }
    project {
      id
      uuid
      ...UpdateValuesForm_edit
    }
    ...Bill_getIsDeletable
    ...Bill_getJobName
  }
`;

export default BillsListItemHeader;
