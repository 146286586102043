// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// App
import {Form} from '@supermove/hooks';

import FieldInput from '@shared/design/components/Field/FieldInput';
import Switch from '@shared/design/components/Switch/index';

export interface SwitchFieldProps<T> {
  form: Form<T>;
  field: string;
  labelLeft?: string;
  labelRight?: string;
  hint?: string;
  disabled?: boolean;
  required?: boolean;
  index?: number;
  size?: string;
  style?: object;
  onChangeValue: (value: boolean) => void;
  isResponsive?: boolean;
}

const SwitchField = <T extends unknown>({
  form,
  field,
  labelLeft,
  labelRight,
  hint,
  disabled,
  required,
  index,
  size,
  style,
  onChangeValue,
  isResponsive = false,
}: SwitchFieldProps<T>) => {
  return (
    <FieldInput
      {...form}
      index={index}
      component={Switch}
      name={field}
      input={{
        disabled,
        required,
        labelLeft,
        labelRight,
        onChange: (switchValue: boolean) => {
          form.setFieldValue(field, switchValue);
          onChangeValue(switchValue);
        },
        isOn: _.get(form.values, field),
        hasErrors: _.get(form.errors, field),
        size,
        isResponsive,
        hint,
        ...style,
      }}
    />
  );
};

SwitchField.SIZE = Switch.SIZE;

// --------------------------------------------------
// Props
// --------------------------------------------------
SwitchField.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  index: PropTypes.number,
  size: PropTypes.string,
  style: PropTypes.object,
  onChangeValue: PropTypes.func,
};

SwitchField.defaultProps = {
  label: null,
  disabled: false,
  required: false,
  index: 0,
  size: Switch.SIZE.MEDIUM,
  style: {},
  onChangeValue: () => {},
};

export default SwitchField;
