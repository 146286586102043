// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {IconSource, Space} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import SmallModal from '@shared/design/components/Modal/SmallModal';

export interface ActionModalProps {
  children?: React.ReactNode;
  color?: string;
  iconColor?: string;
  icon?: IconSource;
  isDisabled?: boolean;
  isSecondaryDisabled?: boolean;
  isLoading?: boolean;
  isMobileSheet?: boolean;
  isOpen: boolean;
  isSubmitting?: boolean;
  handlePressOutside?: () => void;
  handlePrimaryAction?: () => void;
  handleSecondaryAction?: () => void;
  message?: string;
  primaryActionText?: string;
  secondaryActionText?: string;
  title?: string;
  titleColor?: string;
}

const ActionModal = ({
  color = colors.blue.interactive,
  iconColor,
  titleColor,
  icon,
  title,
  message,
  isOpen,
  handlePressOutside,
  handlePrimaryAction,
  handleSecondaryAction,
  primaryActionText,
  secondaryActionText,
  isSubmitting,
  isDisabled,
  isSecondaryDisabled,
  isLoading,
  children,
  isMobileSheet,
}: ActionModalProps) => {
  const responsive = useResponsive();

  return (
    <SmallModal
      isOpen={isOpen}
      handleClose={handlePressOutside}
      handlePressOutside={handlePressOutside}
      style={{alignItems: 'center'}}
      isLoading={isLoading}
      isResponsive
      isMobileSheet={isMobileSheet}
    >
      {!!icon && (
        <React.Fragment>
          <SmallModal.HeaderIcon
            source={icon}
            color={iconColor || color}
            style={{alignItems: 'center'}}
          />
          <Space height={16} />
        </React.Fragment>
      )}
      {!!title && (
        <React.Fragment>
          <SmallModal.HeaderText style={{color: titleColor || color, textAlign: 'center'}}>
            {title}
          </SmallModal.HeaderText>
          <Space height={12} />
        </React.Fragment>
      )}
      <SmallModal.Text responsive={responsive} style={{textAlign: 'center'}}>
        {message}
      </SmallModal.Text>
      {children}
      <Space height={16} />
      <SmallModal.Footer isResponsive>
        {/* HACK(dan) We add a setTimeout to the button onPress handlers to avoid a bug
        where the click action is received by the content behind the modal. This does
        not get resolved by preventing propagation and may have to do with the behavior
        of onMouseUp vs onClick. */}
        {!!handleSecondaryAction && (
          <React.Fragment>
            <QuaternaryButton
              text={secondaryActionText}
              textColor={color}
              onPress={() => setTimeout(handleSecondaryAction, 0)}
              isWidthOfContainer
              style={responsive.mobile ? null : {flex: 1}}
              isResponsive
              isDisabled={isSecondaryDisabled}
            />
            <Space width={8} height={12} />
          </React.Fragment>
        )}
        {!!handlePrimaryAction && (
          <Button
            text={primaryActionText}
            color={color}
            onPress={() => setTimeout(handlePrimaryAction, 0)}
            isWidthOfContainer
            // @ts-expect-error TS(2322): Type '{ flex: number; } | null' is not assignable ... Remove this comment to see the full error message
            style={responsive.mobile ? null : {flex: 1}}
            isSubmitting={isSubmitting}
            isDisabled={isDisabled}
            isResponsive
          />
        )}
      </SmallModal.Footer>
    </SmallModal>
  );
};

export default ActionModal;
