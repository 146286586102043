// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useState, useToast} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import ErrorCallout from '@shared/design/components/Callout/ErrorCallout';
import ContextSwitcher from '@shared/design/components/ContextSwitcher';
import FieldInput from '@shared/design/components/Field/FieldInput';
import FileDragInput from '@shared/design/components/FileDragInput';
import TextTooltip from '@shared/design/components/TextTooltip';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import DocumentV2Image from '@shared/modules/Document/components/DocumentV2Image';
import UploadFileForm from '@shared/modules/File/forms/UploadFileForm';
import useUploadFileForm from '@shared/modules/File/hooks/useUploadFileForm';
import useAppContext from 'modules/App/context/useAppContext';

const MAX_FILE_BYTES = 10000000; // 10mb
const POSITION_TO_ICON = {
  [DocumentV2Image.POSITION.LEFT]: Icon.ObjectsAlignLeft,
  [DocumentV2Image.POSITION.CENTER]: Icon.ObjectsAlignCenter,
  [DocumentV2Image.POSITION.RIGHT]: Icon.ObjectsAlignRight,
};

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Column = Styled.View`
`;

const SectionLabel = Styled.Text`
  ${Typography.Label}
`;

const getHandleImageOnLoad = ({img, resolve, setDraftDocumentContentJson, documentItemIndex}) => {
  return () => {
    setDraftDocumentContentJson((contentJson) => {
      contentJson.documentItems[documentItemIndex].input.metadata.width = img.width;
      contentJson.documentItems[documentItemIndex].input.metadata.height = img.height;
      return {...contentJson};
    });
    resolve();
  };
};

const getImageSizePromise = ({reader, setDraftDocumentContentJson, documentItemIndex}) => {
  return new Promise((resolve) => {
    reader.onload = () => {
      const img = new Image();
      img.onload = getHandleImageOnLoad({
        img,
        resolve,
        setDraftDocumentContentJson,
        documentItemIndex,
      });
      img.src = reader.result;
    };
  });
};

const handleResetImage = ({setDraftDocumentContentJson, documentItemIndex}) => {
  setDraftDocumentContentJson((contentJson) => {
    contentJson.documentItems[documentItemIndex].input.metadata = {
      fileId: null,
      size: 0,
      width: 0,
      height: 0,
    };
    return {...contentJson};
  });
};

const handleSizeValue = (value) => {
  const numeric = Math.abs(parseInt(value, 10));
  return numeric ? _.toString(numeric) : '';
};

const ImageInput = ({
  setErrorMessage,
  setDraftDocumentContentJson,
  documentItem,
  documentItemIndex,
  viewer,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const uploadSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Image uploaded successfully!',
  });

  const {data, loading} = useQuery(ImageInput.query, {
    fetchPolicy: 'cache-and-network',
    variables: {fileId: _.toNumber(documentItem.input.metadata.fileId)},
  });

  const {form, handleSubmit} = useUploadFileForm({
    uploadFileForm: UploadFileForm.new({
      organizationId: viewer.viewingOrganization.id,
      creatorId: viewer.id,
    }),
    onSuccess: ({file}) => {
      uploadSuccessToast.handleToast();
      setDraftDocumentContentJson((contentJson) => {
        contentJson.documentItems[documentItemIndex].input.metadata.fileId = file.id;
        return {...contentJson};
      });
      setIsUploading(false);
    },
    onError: (errors) => {
      console.log({errors});
      handleResetImage({setDraftDocumentContentJson, documentItemIndex});
      setIsUploading(false);
    },
  });

  return (
    <FileDragInput
      isImagesOnly
      isUploading={isUploading || loading}
      handleRemove={() => handleResetImage({setDraftDocumentContentJson, documentItemIndex})}
      handleRemoveText={data?.file?.name}
      handleUploadFile={async (file) => {
        if (!file.type.startsWith('image/')) {
          return setErrorMessage(`This is not a valid image file type.`);
        }

        // Check size of file
        if (file.size > MAX_FILE_BYTES) {
          return setErrorMessage('This exceeds the maximum file size of 10mb.');
        }

        // Check dimensions of image
        const reader = new FileReader();
        const cacheImageSize = getImageSizePromise({
          reader,
          setDraftDocumentContentJson,
          documentItemIndex,
        });
        reader.readAsDataURL(file);
        await cacheImageSize;

        setErrorMessage('');
        setIsUploading(true);
        form.setFieldValue(`uploadFileForm.requestUploadFileForm.mimetype`, file.type);
        form.setFieldValue(
          `uploadFileForm.requestUploadFileForm.filename`,
          UploadFileForm.formatName(file.name),
        );
        form.setFieldValue(`uploadFileForm.file`, file);
        setDraftDocumentContentJson((contentJson) => {
          contentJson.documentItems[documentItemIndex].input.metadata.size = file.size;
          return {...contentJson};
        });
        handleSubmit();
      }}
    />
  );
};

const CustomSizeInputs = ({documentItem, documentItemIndex, setDraftDocumentContentJson}) => {
  const {width, height} = documentItem.input;

  return (
    <Row>
      <Column style={{flex: 1}}>
        <FieldInput.LabelText>Width</FieldInput.LabelText>
        <Space height={4} />
        <FieldInput.TextInput
          placeholder={'Auto'}
          value={width}
          onBlur={() => {
            setDraftDocumentContentJson((contentJson) => {
              contentJson.documentItems[documentItemIndex].input.width = handleSizeValue(width);
              return {...contentJson};
            });
          }}
          onChangeText={(text) => {
            setDraftDocumentContentJson((contentJson) => {
              contentJson.documentItems[documentItemIndex].input.width = text;
              contentJson.documentItems[documentItemIndex].input.height = '';
              return {...contentJson};
            });
          }}
        />
      </Column>
      <Space width={16} />
      <Column style={{flex: 1}}>
        <FieldInput.LabelText>Height</FieldInput.LabelText>
        <Space height={4} />
        <FieldInput.TextInput
          placeholder={'Auto'}
          value={height}
          onBlur={() => {
            setDraftDocumentContentJson((contentJson) => {
              contentJson.documentItems[documentItemIndex].input.height = handleSizeValue(height);
              return {...contentJson};
            });
          }}
          onChangeText={(text) => {
            setDraftDocumentContentJson((contentJson) => {
              contentJson.documentItems[documentItemIndex].input.width = '';
              contentJson.documentItems[documentItemIndex].input.height = text;
              return {...contentJson};
            });
          }}
        />
      </Column>
    </Row>
  );
};

const ImageAlignment = ({documentItem, documentItemIndex, setDraftDocumentContentJson}) => {
  return (
    <ContextSwitcher
      contextDefinitions={[
        DocumentV2Image.POSITION.LEFT,
        DocumentV2Image.POSITION.CENTER,
        DocumentV2Image.POSITION.RIGHT,
      ].map((position) => ({
        iconSource: POSITION_TO_ICON[position],
        isSelected: documentItem.input.position === position,
        onPress: () => {
          setDraftDocumentContentJson((contentJson) => {
            contentJson.documentItems[documentItemIndex].input.position = position;
            return {...contentJson};
          });
        },
      }))}
    />
  );
};

const ImageEditor = ({documentItem, documentItemIndex, setDraftDocumentContentJson}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const {viewer} = useAppContext();

  return (
    <React.Fragment>
      {errorMessage && (
        <React.Fragment>
          <ErrorCallout text={errorMessage} />
          <Space height={12} />
        </React.Fragment>
      )}
      {viewer && (
        <ImageInput
          setErrorMessage={setErrorMessage}
          setDraftDocumentContentJson={setDraftDocumentContentJson}
          documentItem={documentItem}
          documentItemIndex={documentItemIndex}
          viewer={viewer}
        />
      )}
      <Space height={16} />
      <Row>
        <SectionLabel>Size (px)</SectionLabel>
        <Space width={8} />
        <TextTooltip
          text={
            'Setting a height or width will automatically resize the other to maintain aspect ratio.'
          }
          placement={'top'}
        >
          <Column>
            <Icon source={Icon.CircleInfo} color={colors.gray.secondary} size={14} />
          </Column>
        </TextTooltip>
      </Row>
      <Space height={8} />
      <CustomSizeInputs
        documentItem={documentItem}
        documentItemIndex={documentItemIndex}
        setDraftDocumentContentJson={setDraftDocumentContentJson}
      />
      <Space height={16} />
      <SectionLabel>Position</SectionLabel>
      <Space height={8} />
      <ImageAlignment
        documentItem={documentItem}
        documentItemIndex={documentItemIndex}
        setDraftDocumentContentJson={setDraftDocumentContentJson}
      />
    </React.Fragment>
  );
};

ImageInput.query = gql`
  query ImageInput($fileId: Int) {
    ${gql.query}
    file (fileId: $fileId) {
      id
      name
    }
  }
`;

export default ImageEditor;
