// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {useNavigationDOM, useToast} from '@supermove/hooks';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import DashboardCategoryForm, {
  DashboardCategoryType,
} from '@shared/modules/DashboardCategory/forms/DashboardCategoryForm';
import DashboardTagForm, {
  DashboardTagType,
} from '@shared/modules/DashboardTag/forms/DashboardTagForm';
import DashboardForm from '@shared/modules/Reports/forms/DashboardForm';
import useCreateDashboardMutation from '@shared/modules/Reports/hooks/useCreateDashboardMutation';
import GlobalDashboardsLibraryPageCreateDashboardForm from 'modules/Report/Library/components/GlobalDashboardsLibraryPageCreateDashboardForm';

type ResponseType = {
  dashboardCategories: DashboardCategoryType[];
  dashboardTags: DashboardTagType[];
};

const GlobalDashboardsLibraryPageCreateDashboardDrawer = ({
  isOpen,
  organizationId,
  handleClose,
}: {
  isOpen: boolean;
  organizationId: string;
  handleClose: () => void;
}) => {
  const {navigator} = useNavigationDOM();
  const {loading, data} = useQuery<ResponseType>(
    GlobalDashboardsLibraryPageCreateDashboardDrawer.query,
    {
      fetchPolicy: 'cache-and-network',
      skip: !isOpen,
    },
  );
  const createDashboardSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Dashboard created!',
  });
  const dashboardForm = DashboardForm.new({
    organizationId,
    name: '',
    exploDashboardEmbedId: '',
    exploVariables: '',
  });
  const {form, submitting, handleSubmit} = useCreateDashboardMutation({
    dashboardForm,
    onSuccess: ({dashboard}) => {
      createDashboardSuccessToast.handleToast({
        message: `'${dashboard.name}' created!`,
      });
      handleClose();
      navigator.replace(`/reports/${dashboard.uuid}/view`);
    },
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      shouldCloseOnClickOutside={!form.dirty}
      headerText={'Add Custom Report'}
      primaryActionText={'Save'}
      handleSubmit={handleSubmit}
      isSubmitting={submitting}
      isDisabled={loading}
    >
      <Loading loading={!data || loading}>
        {() => (
          <GlobalDashboardsLibraryPageCreateDashboardForm
            form={form}
            dashboardCategories={data?.dashboardCategories || []}
            dashboardTags={data?.dashboardTags || []}
          />
        )}
      </Loading>
    </DrawerWithAction>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
GlobalDashboardsLibraryPageCreateDashboardDrawer.query = gql`
  ${DashboardCategoryForm.edit.fragment}
  ${DashboardTagForm.edit.fragment}
  query GlobalDashboardsLibraryPageCreateDashboardDrawer {
    ${gql.query}
    dashboardCategories {
      id
      ...DashboardCategoryForm_edit
    }
    dashboardTags {
      id
      ...DashboardTagForm_edit
    }
  }
`;

export default GlobalDashboardsLibraryPageCreateDashboardDrawer;
