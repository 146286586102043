// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';
import {PayrollReport} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Currency, pluralize} from '@supermove/utils';

// App
import Table from '@shared/design/components/TableV2Deprecated';
import PayrollReportStatus from '@shared/modules/Accounting/enums/PayrollReportStatus';

const Button = Styled.ButtonV2`
`;

const ButtonText = Styled.Text`
  ${Typography.Label3}
  color: ${colors.blue.interactive};
`;

const TableHeaderText = Styled.Text`
  ${Typography.Label3}
  color: ${colors.gray.secondary};
`;

const CellText = Styled.Text`
  ${Typography.Body4}
`;

const StatusIndicator = Styled.ButtonV2`
  flex-direction: row;
  padding-vertical: 5px;
  padding-horizontal: 8px;
  width: ${({mobile}) => (mobile ? '64px' : '104px')};
  background-color: ${({isPaidOut}) => (isPaidOut ? colors.green.accent : colors.gray.background)};
  border-radius: 4px;
`;

const StatusIndicatorText = Styled.Text`
  ${Typography.Label4};
  color: ${({color}) => color};
`;

const getPayrollReportColumns = ({navigator}) => [
  {
    flex: 2,
    headerContent: ({mobile}) => {
      return <TableHeaderText>{mobile ? 'Period' : 'Report Period'}</TableHeaderText>;
    },
    cellContent: ({item: payrollReport}) => {
      return <CellText>{PayrollReport.getDateRange(payrollReport, {isShortened: true})}</CellText>;
    },
  },
  {
    flex: 2,
    headerContent: ({mobile}) => {
      return <TableHeaderText>{mobile ? 'Employees' : 'Number of Employees'}</TableHeaderText>;
    },
    cellContent: ({item: payrollReport, mobile}) => {
      return (
        <CellText numberOfLines={1}>
          {mobile
            ? payrollReport.compensationReportsCount
            : pluralize(
                PayrollReport.getDisplayKind(payrollReport),
                payrollReport.compensationReportsCount,
                true,
              )}
        </CellText>
      );
    },
  },
  {
    flex: 2,
    headerContent: ({mobile}) => {
      return (
        <TableHeaderText numberOfLines={1}>
          {mobile ? 'Comp.' : 'Total Compensation'}
        </TableHeaderText>
      );
    },
    cellContent: ({item: payrollReport}) => {
      return <CellText>{Currency.display(payrollReport.totalCompensation)}</CellText>;
    },
  },
  {
    flex: 2,
    headerContent: () => {
      return <TableHeaderText>Status</TableHeaderText>;
    },
    cellContent: ({item: payrollReport, mobile}) => {
      const isPaidOut = payrollReport.status === PayrollReportStatus.PAID_OUT;

      return (
        <React.Fragment>
          <StatusIndicator disabled isPaidOut={isPaidOut} mobile={mobile}>
            <StatusIndicatorText color={isPaidOut ? colors.green.status : colors.gray.tertiary}>
              {PayrollReportStatus.getDisplayStatus(payrollReport.status)}
            </StatusIndicatorText>
          </StatusIndicator>
        </React.Fragment>
      );
    },
  },
  {
    flex: 2,
    headerContent: () => {
      return <TableHeaderText>Action</TableHeaderText>;
    },
    cellContent: ({item: payrollReport}) => {
      return (
        <Button onPress={() => navigator.push(`/accounting/payroll-reports/${payrollReport.uuid}`)}>
          <ButtonText>View All</ButtonText>
        </Button>
      );
    },
  },
];

const PayrollReportsList = ({payrollReports}) => {
  const {navigator} = useNavigationDOM();
  return (
    <Table
      columnDefinitions={getPayrollReportColumns({navigator})}
      items={payrollReports}
      emptyStateText={'There are no payroll reports of this type'}
      isDense
      headerStyle={{backgroundColor: colors.gray.background}}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
PayrollReportsList.fragment = gql`
  ${PayrollReport.getDateRange.fragment}
  ${PayrollReport.getDisplayKind.fragment}

  fragment PayrollReportsList on PayrollReport {
    id
    uuid
    kind
    compensationReportsCount
    totalCompensation
    status
    ...PayrollReport_getDateRange
    ...PayrollReport_getDisplayKind
  }
`;

export default PayrollReportsList;
