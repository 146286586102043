// Supermove
import {Icon} from '@supermove/components';
import {colors} from '@supermove/styles';
import {titleize} from '@supermove/utils';

const LEAD = 'LEAD';
const HOLD = 'HOLD';
const BOOKED = 'BOOKED';
const IN_PROGRESS = 'IN_PROGRESS';
const COMPLETED = 'COMPLETED';
const CANCELLED = 'CANCELLED';

const PROJECT_STATUS_OPTIONS = [LEAD, HOLD, BOOKED, IN_PROGRESS, COMPLETED, CANCELLED] as const;

export type ProjectStatusType = (typeof PROJECT_STATUS_OPTIONS)[number];

const OPTIONS = [
  {
    value: LEAD,
    label: 'Lead',
  },
  {
    value: HOLD,
    label: 'Hold',
  },
  {
    value: BOOKED,
    label: 'Booked',
  },
  {
    value: IN_PROGRESS,
    label: 'In Progress',
  },
  {
    value: COMPLETED,
    label: 'Completed',
  },
  {
    value: CANCELLED,
    label: 'Cancelled',
  },
];

const getStatusLabel = (status: ProjectStatusType) => titleize(status.replace('_', ' '));

const getStatusColor = (status: ProjectStatusType) => {
  switch (status) {
    case LEAD:
    case HOLD:
      return colors.orange.status;
    case BOOKED:
      return colors.blue.interactive;
    case COMPLETED:
      return colors.green.status;
    case CANCELLED:
    default:
      return colors.gray.secondary;
  }
};

const getStatusIcon = (status: ProjectStatusType) => {
  switch (status) {
    case LEAD:
      return Icon.Trophy;
    case HOLD:
      return Icon.CalendarClock;
    case BOOKED:
      return Icon.CalendarCheck;
    case COMPLETED:
      return Icon.Check;
    case CANCELLED:
    default:
      return Icon.CalendarXmark;
  }
};

export default {
  // Enum Options
  LEAD,
  HOLD,
  BOOKED,
  IN_PROGRESS,
  COMPLETED,
  CANCELLED,

  OPTIONS,

  // Helpers
  getStatusLabel,
  getStatusColor,
  getStatusIcon,
};
