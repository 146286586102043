// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import SearchBar from '@shared/design/components/SearchBar';
import Table from '@shared/design/components/TableV2Deprecated';

const Container = Styled.View`
`;

const TableContainer = Styled.View`
  width: ${({width}) => width || '800px'};
`;

const Title = Styled.Text`
  ${Typography.Heading4}
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
  z-index: ${({index = 0}) => 100 - index};
`;

const SelectedText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.secondary};
`;

const getVirtualizedHeights = ({itemCount, itemHeight, window}) => {
  const heightOfItems = itemCount * itemHeight;
  const maxHeight = window.innerHeight - 400;
  return {
    virtualizedItemHeight: itemHeight,
    virtualizedTableHeight: heightOfItems < maxHeight ? heightOfItems : maxHeight,
  };
};

const ViewHeader = ({title, handleEdit, csvActions, setSearchTerm}) => {
  return (
    <React.Fragment>
      <Title>{title}</Title>
      <Space height={16} />
      <Row style={{justifyContent: 'space-between'}}>
        <SearchBar
          placeholder={'Search'}
          onChangeText={(text) => {
            setSearchTerm(text);
          }}
          iconColor={colors.gray.tertiary}
          style={{width: '296px', borderColor: colors.gray.tertiary}}
        />
        <Row>
          <DropdownButton
            text={'CSV'}
            actions={csvActions}
            ButtonComponent={SecondaryButton}
            iconLeft={Icon.Upload}
            menuWidth={200}
          />
          <Space width={12} />
          <Button onPress={handleEdit} text={'Edit'} iconLeft={Icon.Pen} />
        </Row>
      </Row>
    </React.Fragment>
  );
};

const ViewTable = ({
  columnDefinitions,
  tableItems,
  emptyStateText,
  tableWidth,
  virtualizedListRef,
}) => {
  const {virtualizedItemHeight, virtualizedTableHeight} = getVirtualizedHeights({
    itemCount: tableItems.length,
    itemHeight: 64,
    window,
  });
  return (
    <TableContainer width={tableWidth}>
      <Table.Virtualized
        columnDefinitions={columnDefinitions}
        emptyStateText={emptyStateText}
        items={tableItems}
        itemKey={'id'}
        isDense
        virtualizedListRef={virtualizedListRef}
        virtualizedItemHeight={virtualizedItemHeight}
        virtualizedTableHeight={virtualizedTableHeight}
      />
    </TableContainer>
  );
};

const EditHeader = ({
  title,
  form,
  handleClose,
  handleSubmit,
  submitting,
  field,
  bulkSelectedFormIndices,
  setBulkSelectedFormIndices,
}) => {
  return (
    <React.Fragment>
      <Title>{title}</Title>
      <Space height={16} />
      <Row style={{justifyContent: 'space-between'}}>
        <Row>
          <SelectedText>{`${_.size(bulkSelectedFormIndices)} selected`}</SelectedText>
          <Space width={16} />
          <SecondaryButton
            onPress={() => {
              _.map(bulkSelectedFormIndices, (bulkSelectedFormIndex) => {
                form.setFieldValue(`${field}.${bulkSelectedFormIndex}.isDeleted`, false);
                form.setFieldValue(`${field}.${bulkSelectedFormIndex}.isDirty`, true);
              });
            }}
            text={'Restore'}
            iconLeft={Icon.ArrowRotateRight}
            iconSize={12}
          />
          <Space width={16} />
          <SecondaryButton
            onPress={() => {
              _.map(bulkSelectedFormIndices, (bulkSelectedFormIndex) => {
                form.setFieldValue(`${field}.${bulkSelectedFormIndex}.isDeleted`, true);
                form.setFieldValue(`${field}.${bulkSelectedFormIndex}.isDirty`, true);
              });
            }}
            text={'Remove'}
            iconLeft={Icon.Trash}
            iconSize={12}
          />
        </Row>
        <Row>
          <QuaternaryButton
            isDisabled={submitting}
            onPress={() => {
              setBulkSelectedFormIndices([]);
              form.handleReset();
              handleClose();
            }}
            text={'Cancel'}
            textColor={colors.blue.interactive}
          />
          <Space width={12} />
          <Button
            isSubmitting={submitting}
            onPress={handleSubmit}
            text={'Save Changes'}
            iconLeft={Icon.Check}
          />
        </Row>
      </Row>
    </React.Fragment>
  );
};

const EditTable = ({form, field, itemKey, columnDefinitions, tableWidth, virtualizedListRef}) => {
  const items = _.get(form.values, field);
  const {virtualizedItemHeight, virtualizedTableHeight} = getVirtualizedHeights({
    itemCount: items.length,
    itemHeight: 64,
    window,
  });
  return (
    <TableContainer width={tableWidth}>
      <Table.Virtualized
        columnDefinitions={columnDefinitions}
        items={items}
        itemKey={itemKey}
        isDense
        hasFooter
        virtualizedListRef={virtualizedListRef}
        virtualizedItemHeight={virtualizedItemHeight}
        virtualizedTableHeight={virtualizedTableHeight}
      />
    </TableContainer>
  );
};

const InventoryLibraryBulkUpdateContents = ({
  ViewComponent,
  viewComponentProps,
  EditComponent,
  editHeaderProps,
  editComponentProps,
  isEdit,
  setIsEdit,
  title,
  csvActions,
  setSearchTerm,
  form,
  handleSubmit,
  submitting,
}) => {
  return (
    <ScrollView horizontal>
      <Container>
        {isEdit ? (
          <React.Fragment>
            <EditHeader
              title={title}
              form={form}
              handleClose={() => {
                form.resetForm();
                setIsEdit(false);
              }}
              handleSubmit={handleSubmit}
              submitting={submitting}
              {...editHeaderProps}
            />
            <Space height={24} />
            <EditComponent {...editComponentProps} />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <ViewHeader
              title={title}
              handleEdit={() => {
                setIsEdit(true);
              }}
              csvActions={csvActions}
              setSearchTerm={setSearchTerm}
            />
            <Space height={24} />
            <ViewComponent {...viewComponentProps} />
            <Space height={100} />
          </React.Fragment>
        )}
      </Container>
    </ScrollView>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
InventoryLibraryBulkUpdateContents.propTypes = {
  ViewComponent: PropTypes.func.isRequired,
  viewComponentProps: PropTypes.object,
  EditComponent: PropTypes.func.isRequired,
  editHeaderProps: PropTypes.object,
  editComponentProps: PropTypes.object,
  isEdit: PropTypes.bool.isRequired,
  setIsEdit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  csvActions: PropTypes.array.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};

InventoryLibraryBulkUpdateContents.defaultProps = {
  viewComponentProps: {},
  editHeaderProps: {},
  editComponentProps: {},
  submitting: false,
};

InventoryLibraryBulkUpdateContents.EditTable = EditTable;
InventoryLibraryBulkUpdateContents.ViewTable = ViewTable;
export default InventoryLibraryBulkUpdateContents;
