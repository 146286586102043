// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {
  useDebouncedCallback,
  useDrawer,
  useModal,
  useMountEffect,
  useResponsive,
  useState,
} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import SearchBar from '@shared/design/components/SearchBar';
import {GlobalDashboardSortKind} from '@shared/modules/GlobalDashboard/enums/GlobalDashboardSortKind';
import UserRole from '@shared/modules/User/enums/UserRole';
import useAppContext from 'modules/App/context/useAppContext';
import GlobalDashboardsLibraryPageCreateDashboardDrawer from 'modules/Report/Library/components/GlobalDashboardsLibraryPageCreateDashboardDrawer';
import GlobalDashboardsLibraryPageGlobalDashboardsFilterModal from 'modules/Report/Library/components/GlobalDashboardsLibraryPageGlobalDashboardsFilterModal';
import GlobalDashboardsLibraryPageGlobalDashboardsListItem, {
  GlobalDashboardDataType,
} from 'modules/Report/Library/components/GlobalDashboardsLibraryPageGlobalDashboardsListItem';
import {GlobalDashboardsLibraryPageUrlFilters} from 'modules/Report/Library/components/types';

const Container = Styled.View`
  flex: 1;
  width: 100%;
`;

const Column = Styled.View`
`;

const ButtonsRow = Styled.View`
  flex-direction: row;
  align-items: center;
  z-index: 100;
`;

const ButtonContainer = Styled.View`
  flex: 1;
`;

const ListContainer = Styled.View`
  flex: 1;
  width: 100%;
  flex-direction: column;
  border-radius: 4px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  overflow: scroll;
`;

const MicroLabel = Styled.Text`
  ${Typography.Responsive.MicroLabel}
`;

const Label = Styled.Text`
  ${Typography.Responsive.Label}
`;

type GlobalDashboardsLibraryPageGlobalDashboardsListProps = {
  filteredGlobalDashboards: GlobalDashboardDataType[];
  globalDashboardUuidToDashboardUuid: Record<string, string>;
  organizationId: string;
  urlFilters: GlobalDashboardsLibraryPageUrlFilters;
  handleSelect: (globalDashboard: GlobalDashboardDataType) => void;
};

const sortKindToButtonText = (sortKind: GlobalDashboardSortKind) => {
  switch (sortKind) {
    case GlobalDashboardSortKind.ALPHABETICAL:
      return 'Sort: A-Z';
    case GlobalDashboardSortKind.NEWEST:
      return 'Sort: Newest';
    case GlobalDashboardSortKind.MOST_POPULAR:
      return 'Sort: Popularity';
  }
};

const GlobalDashboardsLibraryPageGlobalDashboardsList = ({
  filteredGlobalDashboards,
  globalDashboardUuidToDashboardUuid,
  organizationId,
  urlFilters,
  handleSelect,
}: GlobalDashboardsLibraryPageGlobalDashboardsListProps) => {
  const responsive = useResponsive();
  const {viewer} = useAppContext();
  const isSuperAdmin = UserRole.SUPER_ADMIN === viewer?.role;

  const createDashboardDrawer = useDrawer({
    name: 'GlobalDashboardsLibraryPageCreateDashboardDrawer',
  });

  const filterModal = useModal({
    name: 'GlobalDashboardsLibraryFilterModal',
    enableTracking: true,
  });
  const filtersCount = urlFilters.getFilterCount({
    filterKeys: ['categoryIds', 'tagIds'],
  });

  const [query, setQuery] = useState(urlFilters.get('searchQuery'));

  useMountEffect(() => {
    setQuery(urlFilters.get('searchQuery') || '');
  });

  const debouncedFilterUpdate = useDebouncedCallback(urlFilters.handleUpdate, 300);
  const handleSearch = (searchQuery: string) => {
    setQuery(searchQuery);
    debouncedFilterUpdate({searchQuery});
  };

  return (
    <React.Fragment>
      <Container>
        <SearchBar
          style={{width: '100%'}}
          defaultValue={query}
          onChangeText={handleSearch}
          isClearable
        />
        <Space height={16} />
        <ButtonsRow>
          <ButtonContainer responsive={responsive}>
            <SecondaryButton
              text={`Filters ${filtersCount ? `(${filtersCount})` : ''}`}
              isSmall
              iconRight={Icon.AngleDown}
              iconSize={12}
              onPress={filterModal.handleOpen}
              isWidthOfContainer
              isResponsive
            />
          </ButtonContainer>
          <Space width={16} />
          <ButtonContainer responsive={responsive}>
            <DropdownButton
              text={sortKindToButtonText(urlFilters.get('sort') || GlobalDashboardSortKind.NEWEST)}
              ButtonComponent={SecondaryButton}
              isSmall
              menuWidth={160}
              actions={[
                {
                  text: 'Sort A-Z',
                  onPress: () =>
                    urlFilters.handleUpdate({sort: GlobalDashboardSortKind.ALPHABETICAL}, false),
                },
                {
                  text: 'Sort by newest',
                  onPress: () =>
                    urlFilters.handleUpdate({sort: GlobalDashboardSortKind.NEWEST}, false),
                },
                {
                  text: 'Sort by popularity',
                  onPress: () =>
                    urlFilters.handleUpdate({sort: GlobalDashboardSortKind.MOST_POPULAR}, false),
                },
              ]}
              isWidthOfContainer
              isResponsive
            />
          </ButtonContainer>
        </ButtonsRow>
        <Space height={16} />
        <ListContainer>
          {filteredGlobalDashboards.map((globalDashboard) => (
            <GlobalDashboardsLibraryPageGlobalDashboardsListItem
              key={globalDashboard.id}
              globalDashboard={globalDashboard}
              isSelected={globalDashboard.uuid === urlFilters.get('uuid')}
              isAdded={!!globalDashboardUuidToDashboardUuid[globalDashboard.uuid]}
              handleSelect={() => handleSelect(globalDashboard)}
            />
          ))}
        </ListContainer>
        <Space height={16} />
        {isSuperAdmin ? (
          <Column>
            <MicroLabel responsive={responsive}>Only Visible to Supermove Employees</MicroLabel>
            <Space height={8} />
            <TertiaryButton
              iconLeft={Icon.Plus}
              text='Add Custom Report'
              onPress={createDashboardDrawer.handleOpen}
            />
          </Column>
        ) : (
          <Column>
            <MicroLabel responsive={responsive}>Can’t find what you need?</MicroLabel>
            <Space height={4} />
            <Label responsive={responsive}>Contact your account manager for assistance.</Label>
          </Column>
        )}
      </Container>
      <GlobalDashboardsLibraryPageGlobalDashboardsFilterModal
        isOpen={filterModal.isOpen}
        selectedCategoryIds={urlFilters.get('categoryIds')}
        selectedTagIds={urlFilters.get('tagIds')}
        handleClose={filterModal.handleClose}
        handleApply={(filters) => {
          filterModal.handleClose();

          urlFilters.handleUpdate(
            {
              categoryIds: filters.selectedCategoryIds,
              tagIds: filters.selectedTagIds,
            },
            false,
          );
        }}
      />
      <GlobalDashboardsLibraryPageCreateDashboardDrawer
        isOpen={createDashboardDrawer.isOpen}
        organizationId={organizationId}
        handleClose={createDashboardDrawer.handleClose}
      />
    </React.Fragment>
  );
};

export default GlobalDashboardsLibraryPageGlobalDashboardsList;
