// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useEffect, useModal, useState, useTextInput} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import Checkbox from '@shared/design/components/Checkbox';
import BillItemTypeCategory from '@shared/modules/Billing/enums/BillItemTypeCategory';
import BillForm from '@shared/modules/Billing/forms/BillForm';
import BillItemForm from '@shared/modules/Billing/forms/BillItemForm';
import DeleteConditionalBillItemModal from 'modules/Project/Billing/components/DeleteConditionalBillItemModal';
import EditBillLineItemCells from 'modules/Project/Billing/components/EditBillLineItemCells';

const Row = Styled.View`
  flex-direction: row;
`;

const Container = Styled.View`
  flex: 1;
`;

const KindLabelContainer = Styled.View`
  padding-horizontal: 8px;
  padding-top: ${(props) => (props.index > 0 ? 7 : 6)}px;
`;

const DescriptionButton = Styled.Touchable`
  position: absolute;
  top: 7px;
  right: 8px;
  flex-direction: row;
  align-items: center;
`;

const MinMaxContainer = Styled.View`
  flex: 1;
  width: 100%;
  flex-direction: row;
`;

const Column = Styled.View`
  flex: 1;
  align-items: center;
`;

const VerticalLine = Styled.View`
  border-right-width: 1px;
  border-color: ${colors.gray.border};
`;

const TaxColumn = Styled.View`
  flex: 1;
  justify-content: space-between;
  align-items: flex-end;
`;

const MicroText = Styled.Text`
  ${Typography.Micro}
  ${(props) => (props.color ? `color: ${props.color};` : '')}
`;

const NameColumn = ({
  form,
  field,
  billItemForm,
  index,
  isShowingDescription,
  handleShowDescription,
}) => {
  const nameField = `${field}.${index}.name`;
  const hasError = _.get(form.errors, nameField);
  const disabled = !!_.get(form.values, `${field}.${index}.nameFormulaId`);
  const handleChangeText = (text) => {
    form.setFieldValue(nameField, text);
  };

  return (
    <EditBillLineItemCells.LeftDataCell
      disabled={disabled}
      hasBorder
      hasError={hasError}
      isFirstItem={index === 0}
      style={{width: null, flex: 1}}
    >
      <KindLabelContainer index={index}>
        <EditBillLineItemCells.CellCaption>
          {BillItemTypeCategory.getDisplayCategory(billItemForm.category, billItemForm)}
        </EditBillLineItemCells.CellCaption>
      </KindLabelContainer>
      <EditBillLineItemCells.NameInput
        handleChangeText={handleChangeText}
        value={billItemForm.name}
        disabled={disabled}
      />
      <DescriptionButton activeOpacity={0.8} onPress={handleShowDescription}>
        <EditBillLineItemCells.CellButtonText>Description</EditBillLineItemCells.CellButtonText>
        <Space width={4} />
        <Icon
          source={isShowingDescription ? Icon.ChevronUp : Icon.ChevronDown}
          color={colors.blue.interactive}
          size={11}
        />
      </DescriptionButton>
    </EditBillLineItemCells.LeftDataCell>
  );
};

const QuantityColumn = ({form, field, billItemForm, index}) => {
  const [isFocused, setIsFocused] = useState(false);
  const billItemFormField = `${field}.${index}`;
  const minQuantityField = `${billItemFormField}.minQuantity`;
  const maxQuantityField = `${billItemFormField}.maxQuantity`;
  const hasFieldError = _.get(form.errors, minQuantityField);
  const hasFieldTwoError = _.get(form.errors, maxQuantityField);
  const disabled = BillItemForm.getHasQuantityFormula(billItemForm);
  const handleChangeText = (text) => {
    form.setFieldValue(minQuantityField, text);
  };
  const handleChangeTextTwo = (text) => {
    form.setFieldValue(maxQuantityField, text);
  };
  const handleOnBlur = () => {
    setIsFocused(false);
    BillItemForm.handleQuantityOnBlur({
      billItemForm,
      form,
      field: billItemFormField,
    });
  };
  const getShowTbd = (quantity) => quantity === 'TBD';
  const quantity = BillItemForm.getEstimateQuantity(billItemForm, {
    isEnabledTbdBillItems: billItemForm.isEnabledTbdBillItems,
  });

  return (
    <EditBillLineItemCells.DataCell hasBorder isFirstItem={index === 0} disabled={disabled}>
      {isFocused ? (
        <MinMaxContainer>
          <Column style={{paddingTop: 4}}>
            <EditBillLineItemCells.CellCaption>Min</EditBillLineItemCells.CellCaption>
          </Column>
          <VerticalLine />
          <Column style={{paddingTop: 4}}>
            <EditBillLineItemCells.CellCaption>Max</EditBillLineItemCells.CellCaption>
          </Column>
        </MinMaxContainer>
      ) : (
        <EditBillLineItemCells.CellText
          vars={{hasPad: true}}
          color={getShowTbd(quantity) ? colors.gray.tertiary : colors.black}
        >
          {quantity}
        </EditBillLineItemCells.CellText>
      )}
      <EditBillLineItemCells.CellInput
        vars={{isFocused, alignLeft: true, hasError: hasFieldError}}
        width={'50%'}
        onFocus={() => setIsFocused(true)}
        onBlur={handleOnBlur}
        onChangeText={handleChangeText}
        value={billItemForm.minQuantity}
        disabled={disabled}
      />
      <EditBillLineItemCells.CellInput
        vars={{isFocused, hasError: hasFieldTwoError}}
        width={'50%'}
        onFocus={() => setIsFocused(true)}
        onBlur={handleOnBlur}
        onChangeText={handleChangeTextTwo}
        value={billItemForm.maxQuantity}
        disabled={disabled}
      />
    </EditBillLineItemCells.DataCell>
  );
};

const PriceColumn = ({form, field, billItemForm, index, disableEditPrices, style}) => {
  const [showTbd, setShowTbd] = useState(billItemForm.amount === '');
  const [value, setValue] = useState(billItemForm.amount);
  const [isFocused, setIsFocused] = useState(false);
  const amountField = `${field}.${index}.amount`;
  const disabledByPriceFormula = !!_.get(form.values, `${field}.${index}.amountFormulaId`);
  const disabled = disableEditPrices || disabledByPriceFormula;
  const hasError = _.get(form.errors, amountField);
  const handleChangeText = (text) => {
    setValue(text);
    form.setFieldValue(
      amountField,
      billItemForm.isEnabledTbdBillItems && text === ''
        ? ''
        : Currency.toForm(Currency.convertToCents(text)),
    );
  };
  useEffect(() => {
    setShowTbd(value === '');
  }, [value]);
  return (
    <EditBillLineItemCells.RightDataCell
      disabled={disabled}
      hasBorder
      isFirstItem={index === 0}
      style={style}
    >
      {!isFocused && (
        <EditBillLineItemCells.CellText
          vars={{hasPad: true}}
          color={showTbd ? colors.gray.tertiary : colors.black}
        >
          {showTbd ? 'TBD' : billItemForm.amount}
        </EditBillLineItemCells.CellText>
      )}
      <EditBillLineItemCells.CellInput
        disabled={disabled}
        vars={{isFocused, hasError}}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setValue(
            billItemForm.isEnabledTbdBillItems && value === ''
              ? ''
              : Currency.toForm(Currency.convertToCents(value)),
          );
          setIsFocused(false);
        }}
        onChangeText={handleChangeText}
        value={value}
      />
    </EditBillLineItemCells.RightDataCell>
  );
};

const SubtotalColumn = ({billItemForm, index}) => {
  const {minSubtotal, maxSubtotal, showTbd} = BillItemForm.getSubtotals(billItemForm);
  return (
    <EditBillLineItemCells.RightDataCell disabled hasBorder hasPad isFirstItem={index === 0}>
      <EditBillLineItemCells.CellText color={showTbd ? colors.gray.tertiary : colors.black}>
        {showTbd ? 'TBD' : Currency.formatRange({min: minSubtotal, max: maxSubtotal})}
      </EditBillLineItemCells.CellText>
    </EditBillLineItemCells.RightDataCell>
  );
};

const TaxableColumn = ({form, field, billItemForm, index, salesTaxRate}) => {
  const {minTaxTotal, maxTaxTotal, showTbd} = BillItemForm.getSubtotals(billItemForm, {
    salesTaxRate,
  });
  const isTaxable = _.get(billItemForm, 'isTaxable');
  return (
    <EditBillLineItemCells.DataCell
      hasBorder
      isFirstItem={index === 0}
      style={{paddingHorizontal: 8, paddingVertical: 4}}
    >
      <TaxColumn>
        <Checkbox
          isChecked={isTaxable}
          handleToggle={() => form.setFieldValue(`${field}.${index}.isTaxable`, !isTaxable)}
        />
        {isTaxable ? (
          <MicroText color={showTbd ? colors.gray.tertiary : null}>
            {showTbd ? 'TBD' : Currency.formatRange({min: minTaxTotal, max: maxTaxTotal})}
          </MicroText>
        ) : (
          <Space style={{flex: 1}} />
        )}
      </TaxColumn>
    </EditBillLineItemCells.DataCell>
  );
};

const DeleteItemColumn = ({form, billItemForm, conditionalBillRuleForm}) => {
  const deleteConditionalBillItemModal = useModal({name: 'Delete Conditional Bill Item Modal'});
  const handleDelete = () =>
    BillForm.handleRemoveBillItemForm({
      form,
      billFormField: 'billForm',
      billItemFormsField: 'billItemFormsPreSubtotal',
      billItemForm,
    });
  return (
    <React.Fragment>
      <EditBillLineItemCells.IconCell>
        <Space height={3} />
        <EditBillLineItemCells.IconCircle
          activeOpacity={0.8}
          color={colors.gray.tertiary}
          onPress={
            conditionalBillRuleForm ? deleteConditionalBillItemModal.handleOpen : handleDelete
          }
        >
          <Icon source={Icon.Trash} size={12} color={colors.gray.tertiary} />
        </EditBillLineItemCells.IconCircle>
      </EditBillLineItemCells.IconCell>
      <DeleteConditionalBillItemModal
        key={deleteConditionalBillItemModal.key}
        isOpen={deleteConditionalBillItemModal.isOpen}
        handleClose={deleteConditionalBillItemModal.handleClose}
        handleConfirm={handleDelete}
        billRuleName={billItemForm.name}
        billItemName={conditionalBillRuleForm?.name || ''}
      />
    </React.Fragment>
  );
};

const EditBillBillItemsPreSubtotalListItem = ({
  form,
  field,
  billItemForm,
  index,
  disableEditPrices,
  salesTaxRate,
}) => {
  const [isShowingDescription, setIsShowingDescription] = useState(!!billItemForm.description);
  const [inputHeight, setInputHeight] = useState();
  const [isUpdatingDescription, setIsUpdatingDescription] = useState(false);
  const descriptionInput = useTextInput();
  const handleUpdateForm = (text) => {
    form.setFieldValue(`${field}.${index}.description`, text);
  };
  const handleOnFocus = () => {
    setIsUpdatingDescription(true);
  };
  const handleOnBlur = () => {
    setIsUpdatingDescription(false);
  };

  return (
    <Container>
      <Row style={{width: '100%'}}>
        <NameColumn
          form={form}
          field={field}
          billItemForm={billItemForm}
          index={index}
          handleShowDescription={() => {
            if (isShowingDescription) {
              setIsShowingDescription(false);
            } else {
              setIsShowingDescription(true);
              descriptionInput.handleFocus();
            }
          }}
          isShowingDescription={isShowingDescription}
        />
        <QuantityColumn form={form} field={field} billItemForm={billItemForm} index={index} />
        <PriceColumn
          form={form}
          field={field}
          billItemForm={billItemForm}
          index={index}
          disableEditPrices={disableEditPrices}
          style={{width: 118}}
        />
        <SubtotalColumn billItemForm={billItemForm} index={index} />
        <TaxableColumn
          form={form}
          field={field}
          billItemForm={billItemForm}
          index={index}
          salesTaxRate={salesTaxRate}
        />
        <Space width={2} />
        <DeleteItemColumn
          form={form}
          billItemForm={billItemForm}
          conditionalBillRuleForm={_.find(
            form.values.billForm.billRuleForms,
            (billRuleForm) =>
              _.toString(billItemForm.billItemId) === _.toString(billRuleForm.billItemId),
          )}
        />
      </Row>
      {isShowingDescription && (
        <Row style={{width: '100%'}}>
          <EditBillLineItemCells.FullWidthCell style={{width: null, flex: 1, marginRight: 22}}>
            <EditBillLineItemCells.DescriptionInputLabel>
              Description
            </EditBillLineItemCells.DescriptionInputLabel>
            <EditBillLineItemCells.DescriptionInput
              ref={descriptionInput.ref}
              value={billItemForm.description}
              onChangeText={handleUpdateForm}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
              onContentSizeChange={(event) => setInputHeight(event.nativeEvent.contentSize.height)}
              height={inputHeight}
              vars={{isUpdating: isUpdatingDescription}}
              multiline
            />
          </EditBillLineItemCells.FullWidthCell>
        </Row>
      )}
    </Container>
  );
};

export default EditBillBillItemsPreSubtotalListItem;
