// Supermove
import {gql} from '@supermove/graphql';
import {Datetime, Float, withFragment} from '@supermove/utils';

const edit = withFragment(
  (job) => {
    const {numberOfMoversValue, numberOfTrucksValue, minEstimateHoursValue, maxEstimateHoursValue} =
      job;
    return {
      jobId: job.id,
      startTime1: job.startTime1,
      startTime2: job.startTime2,
      estimateHours1: minEstimateHoursValue ? minEstimateHoursValue.amount : job.estimateHours1,
      estimateHours2: maxEstimateHoursValue ? maxEstimateHoursValue.amount : job.estimateHours2,
      crewSize: numberOfMoversValue ? numberOfMoversValue.amount : job.crewSize,
      numberOfTrucks: numberOfTrucksValue ? numberOfTrucksValue.amount : job.numberOfTrucks,
    };
  },
  gql`
    fragment JobDispatchInfoForm_edit on Job {
      id
      startTime1
      startTime2
      estimateHours1
      estimateHours2
      crewSize
      numberOfTrucks
      numberOfMoversValue {
        id
        amount
      }
      numberOfTrucksValue {
        id
        amount
      }
      minEstimateHoursValue {
        id
        amount
      }
      maxEstimateHoursValue {
        id
        amount
      }
    }
  `,
);

const toForm = ({
  jobId,
  startTime1,
  startTime2,
  estimateHours1,
  estimateHours2,
  crewSize,
  numberOfTrucks,
}) => ({
  jobId,
  startTime1: Datetime.toFormTime(startTime1),
  startTime2: Datetime.toFormTime(startTime2),
  estimateHours1: Float.toString(estimateHours1),
  estimateHours2: Float.toString(estimateHours2),
  crewSize: Float.toString(crewSize),
  numberOfTrucks: Float.toString(numberOfTrucks),
});

const toMutation = ({
  jobId,
  startTime1,
  startTime2,
  estimateHours1,
  estimateHours2,
  crewSize,
  numberOfTrucks,
}) => ({
  jobId,
  startTime1: Datetime.toMutationTime(startTime1),
  startTime2: Datetime.toMutationTime(startTime2),
  estimateHours1: Float.toFloat(estimateHours1, null),
  estimateHours2: Float.toFloat(estimateHours2, null),
  crewSize: Float.toFloat(crewSize),
  numberOfTrucks: Float.toFloat(numberOfTrucks),
});

const JobDispatchInfoForm = {
  edit,
  toForm,
  toMutation,
};

export default JobDispatchInfoForm;
