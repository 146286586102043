// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {TimeRange} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import TimesheetBillableEntry from '@shared/modules/Timesheet/components/TimesheetBillableEntry';
import TimesheetBillableEntryKind from '@shared/modules/Timesheet/enums/TimesheetBillableEntryKind';
import TimesheetBlockKind from '@shared/modules/Timesheet/enums/TimesheetBlockKind';

const Wrapper = Styled.View`
  margin-bottom: 10px;
  z-index: ${(props) => 100 - props.index};
`;

const Container = Styled.View`
  flex-direction: row;
`;

const Item = Styled.View`
  flex: ${(props) => props.length || 0};
`;

const Content = Styled.View`
  justify-content: center;
  height: 40px;
  background-color: ${(props) => props.color};
`;

const Name = Styled.Text`
  margin-horizontal: 10px;
  ${Typography.Body3};
  color: ${colors.white};
`;

const Times = Styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

const StartTime = Styled.Text`
  flex: 1;
  margin-top: 5px;
  margin-horizontal: 10px;
  ${Typography.Body4};
`;

const EndTime = Styled.Text`
  margin-top: 5px;
  margin-horizontal: 10px;
  ${Typography.Body4};
`;

const EmptyView = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 40px;
  background-color: ${colors.gray.border};
`;

const EmptyText = Styled.H6`
  color: ${colors.gray.secondary};
`;

const TimeRangeItem = ({isLast, length, timeRange}) => (
  <Item length={length}>
    <Content color={TimeRange.getColor(timeRange)}>
      <Name numberOfLines={1}>{timeRange.name}</Name>
    </Content>
    <Times>
      <StartTime numberOfLines={1}>
        {Datetime.convertToDisplayTime(timeRange.start, Datetime.FORM_TIME)}
      </StartTime>
      {isLast && !!timeRange.end && (
        <EndTime numberOfLines={1}>
          {Datetime.convertToDisplayTime(timeRange.end, Datetime.FORM_TIME)}
        </EndTime>
      )}
    </Times>
  </Item>
);

const RemainingTime = ({length}) => (
  <Item length={length}>
    <Content color={colors.gray.border} />
  </Item>
);

const DocumentV2TimesheetInformationContent = ({job, index}) => {
  const {hasConfirmedTimes, timeRanges} = job.move;

  return (
    <Wrapper index={index}>
      <Container>
        {timeRanges.map((timeRange, index) => (
          <TimeRangeItem
            key={index}
            isFirst={index === 0}
            isLast={index === timeRanges.length - 1}
            length={TimeRange.getMinutes(timeRange)}
            timeRange={timeRange}
          />
        ))}
        {timeRanges.length === 0 ? (
          <EmptyView>
            <EmptyText>No times entered.</EmptyText>
          </EmptyView>
        ) : (
          <RemainingTime
            length={TimeRange.getEstimatedRemainingMinutes(timeRanges, {hasConfirmedTimes})}
          />
        )}
      </Container>
    </Wrapper>
  );
};

const DocumentV2TimesheetInformationQueried = ({index, job, isShowNames}) => {
  if (job.project.projectType.features.timesheetsV2) {
    if (
      job.timesheetBillableEntryKind !== TimesheetBillableEntryKind.JOB ||
      _.isEmpty(job.timesheetBillableEntries)
    ) {
      return null;
    }
    return (
      <TimesheetBillableEntry
        job={job}
        timesheetBillableEntry={job.timesheetBillableEntries[0]}
        isShowNames={isShowNames}
        isViewOnly
      />
    );
  }
  // This covers an edge case where a document gets created as a DURING_MOVE
  // document on the crew app on the first job screen. This may mean that a 'Move'
  // has not yet been created for the job. If a 'Move' has not yet been created,
  // crew app will trigger creating a 'Move' when confirming movers.
  if (!job.move) {
    return null;
  }
  return <DocumentV2TimesheetInformationContent index={index} job={job} />;
};

const DocumentV2TimesheetInformation = ({index, isPreview, job, isShowNames}) => {
  return (
    <Wrapper index={index}>
      {isPreview ? (
        <TimesheetBillableEntry
          job={MOCK_TIMESHEET_BILLABLE_ENTRY_JOB}
          timesheetBillableEntry={MOCK_TIMESHEET_BILLABLE_ENTRY}
          isShowNames={isShowNames}
          isViewOnly
        />
      ) : (
        <DocumentV2TimesheetInformationQueried job={job} index={index} isShowNames={isShowNames} />
      )}
    </Wrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentV2TimesheetInformation.fragment = gql`
  ${TimesheetBillableEntry.fragment}

  fragment DocumentV2TimesheetInformation on Job {
    id
    timesheetBillableEntryKind
    project {
      id
      projectType {
        id
        features {
          timesheetsV2
        }
      }
    }
    move {
      id
      hasConfirmedTimes
      timeRanges {
        name
        kind
        start
        end
      }
    }
    ...TimesheetBillableEntry
  }
`;

// --------------------------------------------------
// Mock-Data for isPreview=true
// --------------------------------------------------
const MOCK_TIMESHEET_BILLABLE_ENTRY = {
  id: 1,
  kind: TimesheetBillableEntryKind.JOB,
  job: {
    id: 1,
    organization: {
      id: 1,
    },
  },
  moverPosition: {
    id: 1,
    name: 'Position 1',
  },
  timesheetPayrollEntries: [
    {
      id: 1,
      user: {
        id: 1,
        fullName: 'Mover 1',
      },
    },
    {
      id: 1,
      user: {
        id: 1,
        fullName: 'Mover 2',
      },
    },
  ],
  timesheetBlocks: [
    {
      id: 1,
      kind: TimesheetBlockKind.WORK,
      rangeFrom: '2021-08-01T15:00:00Z',
      rangeTo: '2021-08-01T16:30:00Z',
    },
    {
      id: 2,
      kind: TimesheetBlockKind.BREAK,
      rangeFrom: '2021-08-01T16:30:00Z',
      rangeTo: '2021-08-01T17:00:00Z',
    },
    {
      id: 3,
      kind: TimesheetBlockKind.DRIVE,
      rangeFrom: '2021-08-01T17:00:00Z',
      rangeTo: '2021-08-01T18:00:00Z',
    },
    {
      id: 4,
      kind: TimesheetBlockKind.WAIT,
      rangeFrom: '2021-08-01T18:00:00Z',
      rangeTo: '2021-08-01T18:30:00Z',
    },
    {
      id: 5,
      kind: TimesheetBlockKind.WORK,
      rangeFrom: '2021-08-01T18:30:00Z',
      rangeTo: '2021-08-01T19:30:00Z',
    },
  ],
};

const MOCK_TIMESHEET_BILLABLE_ENTRY_JOB = {
  id: 1,
  isComplete: true,
  timesheetBillableEntries: [MOCK_TIMESHEET_BILLABLE_ENTRY],
};

export default DocumentV2TimesheetInformation;
