// Library
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Organization} from '@supermove/models';
import {Datetime, withFragment} from '@supermove/utils';

// App
import ValueForm from '@shared/modules/Billing/forms/ValueForm';
import ClientForm from '@shared/modules/Client/forms/ClientForm';

const newForOrganization = withFragment(
  (organization) => ({
    projectId: null,
    sourceProjectId: null,
    projectTypeId: null,
    organizationId:
      organization.isPrimaryMultibranch && organization.features.isEnabledMultibranchStorage
        ? null
        : organization.id,
    bookedById: null,
    coordinatedById: null,
    additionalSalespersonIds: [],
    collectionIds: [],
    name: '',
    identifier: '',
    startDate: '',
    endDate: '',
    officeNotes: '',
    warehouseNotes: '',
    referralSource: '',
    referralDetails: '',
    isTest: false,
    clientForm: ClientForm.new({organizationId: organization.id}),
    billingClientForm: null,
    valueForms: [],

    // Private Fields
    isProjectNameVisible: false,
    isClientSearchEnabled: true,
    isSameBillingClient: true,
  }),
  gql`
    ${Organization.makeProjectValueFormsFromOrganizationVariables.fragment}
    fragment StorageProjectForm_newForOrganization on Organization {
      id
      isPrimaryMultibranch
      features {
        isEnabledMultibranchStorage: isEnabled(feature: "MULTIBRANCH_STORAGE")
      }
      ...Organization_makeProjectValueFormsFromOrganizationVariables
    }
  `,
);

const newFromMoveProject = withFragment(
  (project, {collectionIds} = {}) => ({
    projectId: null,
    sourceProjectId: project.id,
    projectTypeId: null,
    organizationId: project.organization.features.isEnabledMultibranchStorage
      ? project.owningOrganization.id
      : project.organization.id,
    bookedById: project.bookedById,
    coordinatedById: project.coordinatedById,
    additionalSalespersonIds: project.additionalSalespersonIds,
    collectionIds,
    name: '',
    identifier: '',
    startDate: project.endDate,
    endDate: '',
    officeNotes: '',
    warehouseNotes: '',
    referralSource: project.referralSource,
    referralDetails: project.referralDetails,
    isTest: project.isTest,
    clientForm: ClientForm.edit(project.client),
    billingClientForm: project.billingClient
      ? ClientForm.edit(project.billingClient)
      : ClientForm.edit(project.client),
    valueForms: [],

    // Private Fields
    isProjectNameVisible: false,
    isClientSearchEnabled: false,
    isSameBillingClient:
      project.billingClient && project.client
        ? project.billingClient.id === project.client.id
        : !project.billingClient && !project.client,
  }),
  gql`
    ${ClientForm.edit.fragment}
    ${Organization.makeProjectValueFormsFromOrganizationVariables.fragment}
    fragment StorageProjectForm_newFromMoveProject on Project {
      id
      bookedById
      coordinatedById
      additionalSalespersonIds
      endDate
      referralSource
      referralDetails
      isTest
      owningOrganization {
        id
      }
      client {
        id
        ...ClientForm_edit
      }
      billingClient {
        id
        ...ClientForm_edit
      }
      organization {
        id
        features {
          isEnabledMultibranchStorage: isEnabled(feature: "MULTIBRANCH_STORAGE")
        }
        ...Organization_makeProjectValueFormsFromOrganizationVariables
      }
    }
  `,
);

const cancel = withFragment(
  (project) => ({
    projectId: project.id,
  }),
  gql`
    fragment StorageProjectForm_cancel on Project {
      id
    }
  `,
);

const edit = withFragment(
  (project, {isClientSearchEnabled} = {}) => ({
    projectId: project.id,
    sourceProjectId: project.sourceProjectId,
    projectTypeId: project.projectTypeId,
    organizationId: project.organizationId,
    bookedById: project.bookedById,
    coordinatedById: project.coordinatedById,
    additionalSalespersonIds: project.additionalSalespersonIds,
    collectionIds: project.collections.map((collection) => collection.id),
    name: project.name,
    identifier: project.identifier,
    startDate: project.startDate,
    endDate: project.endDate,
    officeNotes: project.officeNotes,
    warehouseNotes: project.warehouseNotes,
    referralSource: project.referralSource,
    referralDetails: project.referralDetails,
    isTest: project.isTest,
    clientForm: ClientForm.edit(project.client),
    billingClientForm: project.billingClient
      ? ClientForm.edit(project.billingClient)
      : ClientForm.edit(project.client),
    valueForms: Organization.makeProjectValueFormsFromProjectValues({project}),

    // Private Fields
    isProjectNameVisible: !!project.name,
    isCompanyNameVisible: !!project.name,
    isClientSearchEnabled,
    isSameBillingClient:
      project.billingClient && project.client
        ? project.billingClient.id === project.client.id
        : !project.billingClient && !project.client,
  }),
  gql`
    ${ClientForm.edit.fragment}
    ${Organization.makeProjectValueFormsFromProjectValues.fragment}
    ${ValueForm.edit.fragment}

    fragment StorageProjectForm_edit on Project {
      id
      sourceProjectId
      projectTypeId
      organizationId
      bookedById
      coordinatedById
      additionalSalespersonIds
      name
      identifier
      startDate
      endDate
      officeNotes
      warehouseNotes
      referralSource
      referralDetails
      isTest
      client {
        id
        ...ClientForm_edit
      }
      billingClient {
        id
        ...ClientForm_edit
      }
      collections {
        id
      }
      values {
        id
        ...ValueForm_edit
      }
      ...Organization_makeProjectValueFormsFromProjectValues
    }
  `,
);

const toForm = ({
  projectId,
  sourceProjectId,
  projectTypeId,
  organizationId,
  bookedById,
  coordinatedById,
  additionalSalespersonIds,
  collectionIds,
  name,
  identifier,
  startDate,
  endDate,
  officeNotes,
  warehouseNotes,
  referralSource,
  referralDetails,
  isTest,
  clientForm,
  billingClientForm,
  valueForms,

  // Private Fields
  isProjectNameVisible,
  isCompanyNameVisible,
  isClientSearchEnabled,
  isSameBillingClient,
}) => ({
  projectId,
  sourceProjectId,
  projectTypeId: _.toNumber(projectTypeId),
  organizationId: _.toNumber(organizationId),
  bookedById,
  coordinatedById,
  additionalSalespersonIds,
  collectionIds,
  name,
  identifier,
  startDate: Datetime.toFormDate(startDate),
  endDate: Datetime.toFormDate(endDate),
  officeNotes,
  warehouseNotes,
  referralSource,
  referralDetails,
  isTest,
  clientForm: clientForm && ClientForm.toForm(clientForm),
  billingClientForm: billingClientForm
    ? ClientForm.toForm(billingClientForm)
    : clientForm && ClientForm.toForm(clientForm),
  valueForms: _.map(valueForms, (valueForm) => ValueForm.toForm(valueForm)),

  // Private Fields
  isProjectNameVisible,
  isCompanyNameVisible,
  isClientSearchEnabled,
  isSameBillingClient,
});

const toMutation = ({
  projectId,
  sourceProjectId,
  organizationId,
  projectTypeId,
  bookedById,
  coordinatedById,
  additionalSalespersonIds,
  collectionIds,
  name,
  identifier,
  startDate,
  endDate,
  officeNotes,
  warehouseNotes,
  referralSource,
  referralDetails,
  isTest,
  clientForm,
  billingClientForm,
  valueForms,
  isSameBillingClient,
}) => ({
  projectId,
  sourceProjectId,
  organizationId,
  projectTypeId,
  bookedById: bookedById === '' ? null : bookedById,
  coordinatedById: coordinatedById === '' ? null : coordinatedById,
  additionalSalespersonIds,
  collectionIds,
  name,
  identifier,
  startDate: startDate ? Datetime.toMutationDate(startDate) : null,
  endDate: endDate ? Datetime.toMutationDate(endDate) : null,
  officeNotes,
  warehouseNotes,
  referralSource,
  referralDetails,
  isTest,
  clientForm: clientForm && ClientForm.toMutation(clientForm),
  billingClientForm: billingClientForm
    ? ClientForm.toMutation(billingClientForm)
    : clientForm
      ? ClientForm.toMutation(clientForm)
      : null,
  valueForms: valueForms.map((valueForm) => ValueForm.toMutation(valueForm)),
  isSameBillingClient,
});

const StorageProjectForm = {
  newForOrganization,
  newFromMoveProject,
  edit,
  cancel,
  toForm,
  toMutation,
};

export default StorageProjectForm;
