// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Job, Organization} from '@supermove/models';
import {Currency, Datetime, Float, Json, uuid, withFragment} from '@supermove/utils';

// App
import ValueForm from '@shared/modules/Billing/forms/ValueForm';
import ClientForm from '@shared/modules/Client/forms/ClientForm';
import LocationKind from '@shared/modules/Location/enums/LocationKind';
import LocationForm from '@shared/modules/Location/forms/LocationForm';
import CustomerForm from '@shared/modules/User/forms/CustomerForm';

const EMPTY_JOB_FORM = {
  jobId: null,
  organizationId: null,
  primaryLaborSourceOrganizationId: null,
  jobTypeId: null,
  creatorId: null,
  bookedById: null,
  jobRequestId: null,
  kind: null,
  identifier: '',
  isTest: false,
  name: '',
  description: '',
  customerForm: null,
  valueForms: [],
  warehouseLocationForm: null,
  locationForms: [],
  locationDistances: '[]',
  moveSize: '',
  crewSize: '',
  numberOfTrucks: 1,
  hourlyRate: '',
  travelFee: '',
  fuelFee: '',
  date: '',
  startDate: '',
  endDate: '',
  isEstimatedRange: false,
  startTime1: '',
  startTime2: '',
  additionalNotes: '',
  dispatchNotes: '',
  officeNotes: '',
  noteToCustomer: '',
  noteFromCustomer: '',
  additionalItems: '[]',
  hasPacking: false,
  referralSource: '',
  referralDetails: '',
  estimateHours1: '',
  estimateHours2: '',
  hourMinimum: '',
  confirmationBillingStart: '',
  confirmationBillingEnd: '',
  isDateFlexible: false,
  packingKind: '',
  isUpdated: false,
  uuid: '',

  // Private Fields
  isDescriptionVisible: false,
  shouldUseCustomerForJobName: true,
  scheduleStatus: null,
  jobTypeName: '',
};

const handleEmptyLocationForms = (jobForm) => {
  const locationFormsWithAddress = jobForm.locationForms.filter((form) => !!form.address);

  // At least one location is required.
  if (_.some(locationFormsWithAddress)) {
    return {...jobForm, locationForms: locationFormsWithAddress};
  }
  return {...jobForm, locationForms: jobForm.locationForms.slice(0, 1)};
};

const getJobName = withFragment(
  (jobForm, {project}) => {
    const jobType = _.find(
      project.projectType.allJobTypes,
      ({id}) => _.toString(id) === _.toString(jobForm.jobTypeId),
    );

    const jobTypeName = jobType ? `${jobType.name}: ` : '';

    const {isEstimatedRange, date, startDate, endDate} = jobForm;
    if (isEstimatedRange) {
      return (
        `${jobTypeName}${startDate ? Datetime.toDisplayDate(startDate, 'MM/DD') : 'TBD'} - ` +
        `${endDate ? Datetime.toDisplayDate(endDate, 'MM/DD') : 'TBD'}`
      );
    }

    return `${jobTypeName}${date ? Datetime.toDisplayDate(date, 'MM/DD') : 'TBD'}`;
  },
  gql`
    fragment JobForm_getJobName on Project {
      id
      projectType {
        id
        allJobTypes {
          id
          name
        }
      }
    }
  `,
);

const getSectionedValueFormsForJobType = withFragment(
  (jobForm, {jobType}) => {
    const {valueForms} = jobForm;
    const valueFormsBySectionId = _.groupBy(valueForms, (valueForm) => valueForm.variableSectionId);

    const groups = jobType.jobTypeVariableSections.map((jobTypeVariableSection) => {
      const sectionValueForms = valueFormsBySectionId[jobTypeVariableSection.id] || [];
      return {
        id: jobTypeVariableSection.id,
        name: jobTypeVariableSection.name,
        valueForms: sectionValueForms,
        valueFormsOnCreate: _.filter(
          sectionValueForms,
          (sectionValueForm) => !!sectionValueForm.isVisibleForCreateProject,
        ),
      };
    });

    return groups.filter((group) => group.valueForms.length > 0);
  },
  gql`
    fragment JobForm_getSectionedValueFormsForJobType on JobType {
      id
      jobTypeVariableSections {
        id
        name
      }
    }
  `,
);

/**
 * Used to copy a job and create a separate job (not linked via a project).
 */
const copy = withFragment(
  (job, {uuid, isDateReset} = {}) => ({
    // This jobId is passed so that we are able to copy the assigned users.
    jobId: job.id,
    organizationId: job.organizationId,
    primaryLaborSourceOrganizationId: job.project.owningOrganization.id,
    jobTypeId: job.jobTypeId,
    creatorId: job.creatorId,
    bookedById: job.bookedById,
    jobRequestId: undefined,
    customerForm: CustomerForm.copy(job.customer),
    locationForms: job.locations.map((location) => LocationForm.copy(location)),
    valueForms: [],
    locationDistances: job.locationDistances,
    kind: job.kind,

    // Reset the identifier so a new one is assigned.
    identifier: '',
    isTest: job.isTest,
    name: job.name,
    description: job.description,
    moveSize: job.moveSize,
    crewSize: job.crewSize,
    numberOfTrucks: job.numberOfTrucks,
    hourlyRate: job.hourlyRate,
    travelFee: job.travelFee,
    fuelFee: job.fuelFee,

    // Reset the date and require the user enters one.
    date: isDateReset ? '' : _.get(job, 'day.value'),
    startDate: isDateReset ? '' : job.startDate,
    endDate: isDateReset ? '' : job.endDate,
    isEstimatedRange: isDateReset ? false : Job.getIsEstimatedRange(job),
    startTime1: job.startTime1,
    startTime2: job.startTime2,
    additionalNotes: job.additionalNotes,
    dispatchNotes: job.dispatchNotes,
    officeNotes: job.officeNotes,
    noteToCustomer: job.noteToCustomer,
    noteFromCustomer: job.noteFromCustomer,
    additionalItems: job.additionalItems,
    hasPacking: job.hasPacking,
    referralSource: job.referralSource,
    referralDetails: job.referralDetails,
    estimateHours1: job.estimateHours1,
    estimateHours2: job.estimateHours2,
    hourMinimum: job.hourMinimum,
    confirmationBillingStart: job.confirmationBillingStart,
    confirmationBillingEnd: job.confirmationBillingEnd,
    isDateFlexible: job.isDateFlexible,
    packingKind: job.packingKind,
    isUpdated: false,
    uuid,

    // Private Fields
    isDescriptionVisible: !!job.description,
    scheduleStatus: null,
    jobTypeName: job.jobType.name,
  }),
  gql`
    ${CustomerForm.copy.fragment}
    ${Job.getIsEstimatedRange.fragment}
    ${LocationForm.copy.fragment}

    fragment JobForm_copy on Job {
      id
      project {
        id
        owningOrganization {
          id
        }
      }
      day {
        id
        value
      }
      startDate
      endDate
      organizationId
      jobTypeId
      creatorId
      bookedById
      locationDistances
      kind
      identifier
      isTest
      name
      description
      moveSize
      crewSize
      numberOfTrucks
      hourlyRate
      travelFee
      fuelFee
      startTime1
      startTime2
      additionalNotes
      dispatchNotes
      officeNotes
      noteToCustomer
      noteFromCustomer
      additionalItems
      hasPacking
      referralSource
      referralDetails
      estimateHours1
      estimateHours2
      hourMinimum
      confirmationBillingStart
      confirmationBillingEnd
      isDateFlexible
      packingKind
      jobType {
        id
        name
      }
      customer {
        id
        ...CustomerForm_copy
      }
      jobRequest {
        id
      }
      locations {
        id
        ...LocationForm_copy
      }
      ...Job_getIsEstimatedRange
    }
  `,
);

const editV2 = withFragment(
  (job, {label} = {}) => {
    const {
      numberOfMoversValue,
      numberOfTrucksValue,
      minEstimateHoursValue,
      maxEstimateHoursValue,
      hourMinimumValue,
      organization,
    } = job;
    const {isEnabledManualJobHourMinimum} = organization.features;
    return {
      jobId: job.id,
      organizationId: job.organizationId,
      primaryLaborSourceOrganizationId: job.project.owningOrganization.id,
      jobTypeId: job.jobTypeId,
      creatorId: job.creatorId,
      bookedById: job.bookedById,
      jobRequestId: _.get(job, 'jobRequest.id'),
      customerForm: CustomerForm.edit(job.customer),
      valueForms: Organization.makeJobValueFormsFromJobValues({job}),
      locationForms: job.locations.map((location) => LocationForm.edit(location)),
      warehouseLocationForm: job.warehouseLocation
        ? LocationForm.edit(job.warehouseLocation)
        : null,
      locationDistances: job.locationDistances,
      kind: job.kind,
      identifier: job.identifier,
      isTest: job.isTest,
      name: job.name,
      description: job.description,
      moveSize: job.moveSize,
      crewSize: numberOfMoversValue ? numberOfMoversValue.amount : job.crewSize,
      numberOfTrucks: numberOfTrucksValue ? numberOfTrucksValue.amount : job.numberOfTrucks,
      hourlyRate: job.hourlyRate,
      travelFee: job.travelFee,
      fuelFee: job.fuelFee,
      date: _.get(job, 'day.value'),
      startDate: job.startDate,
      endDate: job.endDate,
      isEstimatedRange: Job.getIsEstimatedRange(job),
      startTime1: job.startTime1,
      startTime2: job.startTime2,
      additionalNotes: job.additionalNotes,
      dispatchNotes: job.dispatchNotes,
      officeNotes: job.officeNotes,
      noteToCustomer: job.noteToCustomer,
      noteFromCustomer: job.noteFromCustomer,
      additionalItems: job.additionalItems,
      hasPacking: job.hasPacking,
      referralSource: job.referralSource,
      referralDetails: job.referralDetails,
      estimateHours1: minEstimateHoursValue ? minEstimateHoursValue.amount : job.estimateHours1,
      estimateHours2: maxEstimateHoursValue ? maxEstimateHoursValue.amount : job.estimateHours2,
      hourMinimum:
        isEnabledManualJobHourMinimum && hourMinimumValue
          ? hourMinimumValue.amount
          : job.hourMinimum,
      confirmationBillingStart: job.confirmationBillingStart,
      confirmationBillingEnd: job.confirmationBillingEnd,
      isDateFlexible: job.isDateFlexible,
      packingKind: job.packingKind,
      isUpdated: false,
      uuid: job.uuid,

      // Private Fields
      isDescriptionVisible: !!job.description,
      shouldUseCustomerForJobName: true,
      scheduleStatus: job.scheduleStatus,
      jobTypeName: job.jobType.name,
      label,
      isFinal: job.isFinal,
    };
  },
  gql`
    ${CustomerForm.edit.fragment}
    ${Job.getIsEstimatedRange.fragment}
    ${LocationForm.edit.fragment}
    ${Organization.makeJobValueFormsFromJobValues.fragment}
    ${ValueForm.edit.fragment}

    fragment JobForm_editV2 on Job {
      id
      uuid
      organizationId
      jobTypeId
      creatorId
      bookedById
      kind
      identifier
      isTest
      name
      description
      locationDistances
      moveSize
      crewSize
      numberOfTrucks
      hourlyRate
      travelFee
      fuelFee
      startDate
      endDate
      scheduleStatus
      startTime1
      startTime2
      additionalNotes
      dispatchNotes
      officeNotes
      noteToCustomer
      noteFromCustomer
      additionalItems
      hasPacking
      referralSource
      referralDetails
      estimateHours1
      estimateHours2
      hourMinimum
      confirmationBillingStart
      confirmationBillingEnd
      isDateFlexible
      packingKind
      isFinal
      project {
        id
        owningOrganization {
          id
        }
      }
      jobType {
        id
        name
      }
      customer {
        id
        ...CustomerForm_edit
      }
      day {
        id
        value
      }
      jobRequest {
        id
      }
      locations {
        id
        ...LocationForm_edit
      }
      warehouseLocation {
        id
        ...LocationForm_edit
      }
      values {
        id
        ...ValueForm_edit
      }
      numberOfMoversValue {
        id
        amount
      }
      numberOfTrucksValue {
        id
        amount
      }
      minEstimateHoursValue {
        id
        amount
      }
      maxEstimateHoursValue {
        id
        amount
      }
      hourMinimumValue {
        id
        amount
      }
      organization {
        id
        features {
          isEnabledManualJobHourMinimum: isEnabled(feature: "MANUAL_JOB_HOUR_MINIMUM")
        }
      }
      ...Job_getIsEstimatedRange
      ...Organization_makeJobValueFormsFromJobValues
    }
  `,
);

const edit = withFragment(
  (job) => {
    const {
      numberOfMoversValue,
      numberOfTrucksValue,
      minEstimateHoursValue,
      maxEstimateHoursValue,
      hourMinimumValue,
      organization,
    } = job;
    const {isEnabledManualJobHourMinimum} = organization.features;
    return {
      jobId: job.id,
      organizationId: job.organizationId,
      primaryLaborSourceOrganizationId: job.project.owningOrganization.id,
      jobTypeId: job.jobTypeId,
      creatorId: job.creatorId,
      bookedById: job.bookedById,
      jobRequestId: _.get(job, 'jobRequest.id'),
      customerForm: CustomerForm.edit(job.customer),
      valueForms: job.values.map((value) => ValueForm.edit(value)),
      locationForms: job.locations.map((location) => LocationForm.edit(location)),
      warehouseLocationForm: job.warehouseLocation
        ? LocationForm.edit(job.warehouseLocation)
        : null,
      locationDistances: job.locationDistances,
      kind: job.kind,
      identifier: job.identifier,
      isTest: job.isTest,
      name: job.name,
      description: job.description,
      moveSize: job.moveSize,
      crewSize: numberOfMoversValue ? numberOfMoversValue.amount : job.crewSize,
      numberOfTrucks: numberOfTrucksValue ? numberOfTrucksValue.amount : job.numberOfTrucks,
      hourlyRate: job.hourlyRate,
      travelFee: job.travelFee,
      fuelFee: job.fuelFee,
      date: _.get(job, 'day.value'),
      startDate: job.startDate,
      endDate: job.endDate,
      isEstimatedRange: Job.getIsEstimatedRange(job),
      startTime1: job.startTime1,
      startTime2: job.startTime2,
      additionalNotes: job.additionalNotes,
      dispatchNotes: job.dispatchNotes,
      officeNotes: job.officeNotes,
      noteToCustomer: job.noteToCustomer,
      noteFromCustomer: job.noteFromCustomer,
      additionalItems: job.additionalItems,
      hasPacking: job.hasPacking,
      referralSource: job.referralSource,
      referralDetails: job.referralDetails,
      estimateHours1: minEstimateHoursValue ? minEstimateHoursValue.amount : job.estimateHours1,
      estimateHours2: maxEstimateHoursValue ? maxEstimateHoursValue.amount : job.estimateHours2,
      hourMinimum:
        isEnabledManualJobHourMinimum && hourMinimumValue
          ? hourMinimumValue.amount
          : job.hourMinimum,
      confirmationBillingStart: job.confirmationBillingStart,
      confirmationBillingEnd: job.confirmationBillingEnd,
      isDateFlexible: job.isDateFlexible,
      packingKind: job.packingKind,
      isUpdated: false,
      uuid: job.uuid,

      // Private Fields
      isDescriptionVisible: !!job.description,
      shouldUseCustomerForJobName: true,
      scheduleStatus: job.scheduleStatus,
      jobTypeName: job.jobType.name,
    };
  },
  gql`
    ${CustomerForm.edit.fragment}
    ${Job.getIsEstimatedRange.fragment}
    ${LocationForm.edit.fragment}
    ${ValueForm.edit.fragment}

    fragment JobForm_edit on Job {
      id
      uuid
      organizationId
      jobTypeId
      creatorId
      bookedById
      kind
      identifier
      isTest
      name
      description
      locationDistances
      moveSize
      crewSize
      numberOfTrucks
      hourlyRate
      travelFee
      fuelFee
      startDate
      endDate
      scheduleStatus
      startTime1
      startTime2
      additionalNotes
      dispatchNotes
      officeNotes
      noteToCustomer
      noteFromCustomer
      additionalItems
      hasPacking
      referralSource
      referralDetails
      estimateHours1
      estimateHours2
      hourMinimum
      confirmationBillingStart
      confirmationBillingEnd
      isDateFlexible
      packingKind
      project {
        id
        owningOrganization {
          id
        }
      }
      jobType {
        id
        name
      }
      customer {
        id
        ...CustomerForm_edit
      }
      day {
        id
        value
      }
      jobRequest {
        id
      }
      locations {
        id
        ...LocationForm_edit
      }
      warehouseLocation {
        id
        ...LocationForm_edit
      }
      values {
        id
        ...ValueForm_edit
      }
      numberOfMoversValue {
        id
        amount
      }
      numberOfTrucksValue {
        id
        amount
      }
      minEstimateHoursValue {
        id
        amount
      }
      maxEstimateHoursValue {
        id
        amount
      }
      hourMinimumValue {
        id
        amount
      }
      organization {
        id
        features {
          isEnabledManualJobHourMinimum: isEnabled(feature: "MANUAL_JOB_HOUR_MINIMUM")
        }
      }
      ...Job_getIsEstimatedRange
    }
  `,
);

const _new = ({
  creatorId,
  bookedById,
  organizationId,
  startTime1,
  startTime2,
  additionalItems,
  referralSource,
  referralDetails,
  warehouseLocationForm,
  isTest = false,
  uuid,
}) => ({
  jobId: undefined,
  organizationId,
  primaryLaborSourceOrganizationId: null,
  jobTypeId: undefined,
  creatorId,
  bookedById,
  jobRequestId: undefined,
  kind: null,
  identifier: '',
  isTest,
  name: '',
  description: '',
  customerForm: CustomerForm.new({organizationId}),
  valueForms: [],
  warehouseLocationForm: warehouseLocationForm || null,
  locationForms: [
    // We start with one pick up and one drop off location.
    LocationForm.new({kind: LocationKind.PICK_UP}),
    LocationForm.new({kind: LocationKind.DROP_OFF}),
  ],
  locationDistances: '[]',
  moveSize: '',
  crewSize: '',
  numberOfTrucks: 1,
  hourlyRate: '',
  travelFee: '',
  fuelFee: '',
  date: '',
  startDate: '',
  endDate: '',
  isEstimatedRange: false,
  startTime1,
  startTime2,
  additionalNotes: '',
  dispatchNotes: '',
  officeNotes: '',
  noteToCustomer: '',
  noteFromCustomer: '',
  additionalItems,
  hasPacking: false,
  referralSource,
  referralDetails,
  estimateHours1: '',
  estimateHours2: '',
  hourMinimum: '',
  confirmationBillingStart: '',
  confirmationBillingEnd: '',
  isDateFlexible: false,
  packingKind: '',
  isUpdated: false,
  uuid,

  // Private Fields
  isDescriptionVisible: false,
  shouldUseCustomerForJobName: true,
  scheduleStatus: null,
  jobTypeName: '',
  isFinal: false,
});

const newForProject = withFragment(
  (project, {uuid}) => ({
    ...EMPTY_JOB_FORM,
    organizationId: project.organization.id,
    primaryLaborSourceOrganizationId: project.owningOrganization.id,
    bookedById: project.bookedById,
    startTime1: project.organization.jobStartTime1Default,
    startTime2: project.organization.jobStartTime2Default,
    referralSource: project.referralSource,
    referralDetails: project.referralDetails,
    isTest: project.isTest,
    customerForm: CustomerForm.edit(project.customer),
    additionalItems: Json.toString(
      Organization.getJobFormAdditionalItemsDefaultValues(project.organization),
    ),
    warehouseLocationForm: null,
    locationForms: [
      // We start with one pick up and one drop off location.
      LocationForm.new({kind: LocationKind.PICK_UP}),
      LocationForm.new({kind: LocationKind.DROP_OFF}),
    ],

    // Private Fields
    uuid,
  }),
  gql`
    ${CustomerForm.edit.fragment}
    ${Organization.getJobFormAdditionalItemsDefaultValues.fragment}

    fragment JobForm_newForProject on Project {
      id
      bookedById
      referralSource
      referralDetails
      isTest
      customer {
        id
        ...CustomerForm_edit
      }
      organization {
        id
        jobStartTime1Default
        jobStartTime2Default
        ...Organization_getJobFormAdditionalItemsDefaultValues
      }
      owningOrganization {
        id
      }
    }
  `,
);

/**
 * A new JobFrom derived from a Job contains all the edit fields overwritten
 * by a select specific new Job fields. Later on, we can have optimized logic
 * that copies specific locationForms, etc from the previous job.
 */
const newFromJob = withFragment(
  (job, {kind, creatorId, uuid}) => {
    const getOverrideFields = (job) => {
      return [
        // Only the original job is related to the JobRequest. This sets the new
        // Job's jobRequestId to undefined.
        'jobRequestId',
        'jobId',
        'jobTypeId',
        'identifier',
        'date',
        'additionalNotes',
        'noteToCustomer',
        'noteFromCustomer',
        'valueForms',

        // Private
        'scheduleStatus',
        'jobTypeName',
      ];
    };

    const getNewParams = (job) => {
      return _.pick(JobForm.new({}), getOverrideFields(job));
    };

    return {
      ...JobForm.edit(job),
      ...getNewParams(job),

      // Use the supplied `kind` and `creatorId` for the new job.
      kind,
      creatorId,
      uuid,
    };
  },
  gql`
    ${edit.fragment}

    fragment JobForm_newFromJob on Job {
      id
      ...JobForm_edit
    }
  `,
);

const getHourlyRate = ({crewSize, jobFormCustomValues}) => {
  const option = _.find(jobFormCustomValues.crewSize, (option) => option.value === crewSize);
  return String(_.get(option, 'hourlyRate', ''));
};

const getTravelFee = ({crewSize, jobFormCustomValues}) => {
  const option = _.find(jobFormCustomValues.crewSize, (option) => option.value === crewSize);
  return String(_.get(option, 'travelFee', ''));
};

/**
 * Returns the location forms that have longitude and latitude that are numbers.
 */
const getLocations = ({locationForms}) => {
  return locationForms.filter((location) => !!location.latitude && !!location.longitude);
};

const newFromJobRequest = withFragment(
  (jobRequest, {viewerId}) => {
    const jobFormCustomValues = Json.toForm(jobRequest.organization.settings.jobFormCustomValues);

    return {
      jobId: undefined,
      organizationId: jobRequest.organization.id,
      primaryLaborSourceOrganizationId: null,
      jobTypeId: undefined,
      creatorId: viewerId,
      bookedById:
        _.get(jobRequest, 'bookedBy.id') ||
        (jobRequest.organization.features.isEnabledNoAutoAssignBookedByToViewer
          ? undefined
          : viewerId),
      jobRequestId: jobRequest.id,
      valueForms: [],
      locationForms: jobRequest.locations.map((location) => LocationForm.edit(location)),
      locationDistances: jobRequest.locationDistances,
      kind: null,
      identifier: '',
      isTest: false,
      name: '',
      description: '',
      moveSize: jobRequest.moveSize,
      crewSize: jobRequest.preferredCrewSize,
      numberOfTrucks: 1,
      hourlyRate: getHourlyRate({crewSize: jobRequest.preferredCrewSize, jobFormCustomValues}),
      travelFee: getTravelFee({crewSize: jobRequest.preferredCrewSize, jobFormCustomValues}),
      fuelFee: '',

      date: jobRequest.preferredDate,
      startTime1: jobRequest.preferredStartTime,
      startTime2: '',
      additionalNotes: '',
      dispatchNotes: '',
      officeNotes: jobRequest.notes ? `Customer notes: ${jobRequest.notes}` : '',
      noteToCustomer: '',
      noteFromCustomer: jobRequest.notes,
      additionalItems: jobRequest.additionalItems,
      hasPacking: false,
      referralSource: jobRequest.referralSource,
      referralDetails: jobRequest.referralDetails,
      estimateHours1: '',
      estimateHours2: '',
      hourMinimum: '',
      confirmationBillingStart: '',
      confirmationBillingEnd: '',
      isUpdated: false,

      // Private Fields
      isDescriptionVisible: false,
      isDateFlexible: false,
      packingKind: '',
      jobTypeName: '',
      uuid: uuid(),
    };
  },
  gql`
    ${LocationForm.edit.fragment}

    fragment JobForm_newFromJobRequest on JobRequest {
      id
      organizationId
      locationDistances
      moveSize
      preferredCrewSize
      preferredDate
      preferredStartTime
      notes
      additionalItems
      referralSource
      referralDetails
      bookedBy {
        id
      }
      locations {
        id
        ...LocationForm_edit
      }
      organization {
        id
        features {
          isEnabledNoAutoAssignBookedByToViewer: isEnabled(
            feature: "NO_AUTO_ASSIGN_BOOKED_BY_TO_VIEWER"
          )
        }
        settings {
          id
          jobFormCustomValues
        }
      }
    }
  `,
);

const copyJobForm = (jobForm, {uuid} = {}) => {
  return {
    ..._.cloneDeep(jobForm),
    jobId: undefined,
    jobRequestId: undefined,
    identifier: '',
    uuid,
    isFinal: false,
  };
};

const newForProjectForm = withFragment(
  ({viewerId, organization, projectForm, uuid}) => {
    // Copy over the location information from the last jobForm in a projectForm.
    const lastJobForm = _.last(projectForm.jobForms);
    const locationDistances = _.get(lastJobForm, 'locationDistances', []);
    const locationForms = _.cloneDeep(
      _.get(lastJobForm, 'locationForms', [
        LocationForm.toForm(LocationForm.new({kind: LocationKind.PICK_UP})),
        LocationForm.toForm(LocationForm.new({kind: LocationKind.DROP_OFF})),
      ]),
    );
    const referralSource = _.get(lastJobForm, 'referralSource', '');
    const referralDetails = _.get(lastJobForm, 'referralDetails', '');
    const isTest = _.get(lastJobForm, 'isTest', false);

    const newJobForm = {
      ...JobForm.toForm(
        JobForm.new({
          organizationId: projectForm.organizationId,
          creatorId: viewerId,
          bookedById: organization.features.isEnabledNoAutoAssignBookedByToViewer
            ? undefined
            : viewerId,
          additionalItems: Json.toString(
            Organization.getJobFormAdditionalItemsDefaultValues(organization),
          ),
          referralSource,
          referralDetails,
          isTest,
          uuid,
        }),
      ),
      name: '',
      shouldUseCustomerForJobName: true,
      customerForm: _.cloneDeep(projectForm.customerForm),
      locationForms,
      locationDistances,
    };

    return newJobForm;
  },
  gql`
    ${Organization.getJobFormAdditionalItemsDefaultValues.fragment}

    fragment JobForm_newForProjectForm on Organization {
      id
      features {
        isEnabledNoAutoAssignBookedByToViewer: isEnabled(
          feature: "NO_AUTO_ASSIGN_BOOKED_BY_TO_VIEWER"
        )
      }
      ...Organization_getJobFormAdditionalItemsDefaultValues
    }
  `,
);

const toForm = ({
  jobId,
  organizationId,
  primaryLaborSourceOrganizationId,
  jobTypeId,
  creatorId,
  bookedById,
  jobRequestId,
  kind,
  identifier,
  isTest,
  name,
  description,
  customerForm,
  clientForm,
  valueForms,
  warehouseLocationForm,
  locationForms,
  locationDistances,
  moveSize,
  crewSize,
  numberOfTrucks,
  hourlyRate,
  travelFee,
  fuelFee,
  date,
  startDate,
  endDate,
  isEstimatedRange,
  startTime1,
  startTime2,
  additionalNotes,
  dispatchNotes,
  officeNotes,
  noteToCustomer,
  noteFromCustomer,
  additionalItems,
  hasPacking,
  referralSource,
  referralDetails,
  estimateHours1,
  estimateHours2,
  hourMinimum,
  confirmationBillingStart,
  confirmationBillingEnd,
  isDateFlexible,
  packingKind,
  isUpdated,
  uuid,

  // Private Fields
  isDescriptionVisible,
  shouldUseCustomerForJobName,
  scheduleStatus,
  jobTypeName,
  label,
  isFinal,
}) => ({
  jobId,
  organizationId,
  primaryLaborSourceOrganizationId,
  jobTypeId,
  creatorId,
  bookedById,
  jobRequestId,
  kind,
  identifier,
  isTest,
  name,
  description,
  customerForm: customerForm ? CustomerForm.toForm(customerForm) : null,
  clientForm: clientForm ? ClientForm.toForm(clientForm) : null,
  valueForms: valueForms.map((valueForm) => ValueForm.toForm(valueForm)),
  warehouseLocationForm: warehouseLocationForm ? LocationForm.toForm(warehouseLocationForm) : null,
  locationForms: locationForms.map((locationForm) => LocationForm.toForm(locationForm)),
  locationDistances: Json.toForm(locationDistances),
  moveSize,
  crewSize: _.toString(crewSize),
  numberOfTrucks: Float.toString(numberOfTrucks),
  hourlyRate: Float.toString(hourlyRate),
  travelFee: Float.toString(travelFee),
  fuelFee: fuelFee ? Currency.toForm(fuelFee) : '',
  date: Datetime.toFormDate(date),
  startDate: Datetime.toFormDate(startDate),
  endDate: Datetime.toFormDate(endDate),
  isEstimatedRange,
  startTime1: Datetime.toFormTime(startTime1),
  startTime2: Datetime.toFormTime(startTime2),
  additionalNotes,
  dispatchNotes,
  officeNotes,
  noteToCustomer,
  noteFromCustomer,
  additionalItems: Json.toForm(additionalItems),
  hasPacking,
  referralSource,
  referralDetails,
  estimateHours1: Float.toString(estimateHours1),
  estimateHours2: Float.toString(estimateHours2),
  hourMinimum: Float.toString(hourMinimum),
  confirmationBillingStart,
  confirmationBillingEnd,
  isDateFlexible,
  packingKind,
  isUpdated,
  uuid,

  // Private Fields
  isDescriptionVisible,
  shouldUseCustomerForJobName,
  scheduleStatus,
  jobTypeName,
  label,
  isFinal,
});

const _getJobName = ({shouldUseCustomerForJobName, name, customerForm, clientForm}) => {
  // The variable shouldUseCustomerForJobName should overwrite the old behavior if it is explicitly
  // set to false. We check for false because the value will typically be undefined
  // in other cases.
  if (name || shouldUseCustomerForJobName === false) {
    return name.trim();
  }
  if (customerForm) {
    return customerForm.names
      .filter(Boolean)
      .map((name) => name.trim())
      .join(' ');
  }
  if (clientForm.name) {
    return clientForm.name.trim();
  }
  return clientForm.primaryContactForm.names
    .filter(Boolean)
    .map((name) => name.trim())
    .join(' ');
};

const toMutation = ({
  jobId,
  organizationId,
  primaryLaborSourceOrganizationId,
  jobTypeId,
  creatorId,
  bookedById,
  jobRequestId,
  kind,
  identifier,
  isTest,
  name,
  description,
  customerForm,
  valueForms,
  warehouseLocationForm,
  locationForms,
  locationDistances,
  moveSize,
  crewSize,
  numberOfTrucks,
  hourlyRate,
  travelFee,
  fuelFee,
  date,
  startDate,
  endDate,
  isEstimatedRange,
  startTime1,
  startTime2,
  additionalNotes,
  dispatchNotes,
  officeNotes,
  noteToCustomer,
  noteFromCustomer,
  additionalItems,
  hasPacking,
  referralSource,
  referralDetails,
  estimateHours1,
  estimateHours2,
  hourMinimum,
  confirmationBillingStart,
  confirmationBillingEnd,
  isDateFlexible,
  packingKind,
  isUpdated,
  uuid,

  // Private
  clientForm,
  shouldUseCustomerForJobName,
}) => {
  const jobName = _getJobName({
    shouldUseCustomerForJobName,
    name,
    customerForm,
    clientForm,
  });

  return {
    jobId,
    organizationId,
    primaryLaborSourceOrganizationId,
    jobTypeId,
    creatorId,
    bookedById,
    jobRequestId,
    kind,
    identifier,
    isTest,
    name: jobName,
    description,
    customerForm: customerForm ? CustomerForm.toMutation(customerForm) : null,
    valueForms: valueForms.map((valueForm) => ValueForm.toMutation(valueForm)),
    warehouseLocationForm: warehouseLocationForm
      ? LocationForm.toMutation(warehouseLocationForm)
      : null,
    locationForms: locationForms.map((locationForm) => LocationForm.toMutation(locationForm)),
    locationDistances,
    moveSize,
    crewSize,
    numberOfTrucks: Float.toFloat(numberOfTrucks),
    hourlyRate: Float.toFloat(hourlyRate),
    travelFee: Float.toFloat(travelFee),
    fuelFee: Currency.toMutation(fuelFee || 0),
    date: Datetime.toMutationDate(date),
    startDate: startDate ? Datetime.toMutationDate(startDate) : null,
    endDate: endDate ? Datetime.toMutationDate(endDate) : null,
    // We refactored the name of this value everywhere to isEstimatedRange in order to
    // be consistent with the enum value 'ESTIMATED_RANGE'. However in order to maintain
    // backwards compatibility we are keeping the mutation value as isDateRange.
    isDateRange: isEstimatedRange,
    startTime1: Datetime.toMutationTime(startTime1),
    startTime2: Datetime.toMutationTime(startTime2),
    additionalNotes,
    dispatchNotes,
    officeNotes,
    noteToCustomer,
    noteFromCustomer,
    additionalItems: Json.toMutation(additionalItems),
    hasPacking,
    referralSource,
    referralDetails,
    estimateHours1: Float.toFloat(estimateHours1, null),
    estimateHours2: Float.toFloat(estimateHours2, null),
    hourMinimum: Float.toFloat(hourMinimum, null),
    confirmationBillingStart,
    confirmationBillingEnd,
    isDateFlexible,
    packingKind,
    isUpdated,
    uuid,
  };
};

const getDisplayDate = (jobForm) => {
  if (jobForm.isEstimatedRange) {
    const startDate = jobForm.startDate
      ? Datetime.convertToDisplayDate(jobForm.startDate, Datetime.DISPLAY_MONTH_DAY)
      : 'TBD';
    const endDate = jobForm.endDate
      ? Datetime.convertToDisplayDate(jobForm.endDate, Datetime.DISPLAY_MONTH_DAY)
      : 'TBD';
    return `${startDate} - ${endDate}`;
  }
  const date = jobForm.date
    ? Datetime.convertToDisplayDate(jobForm.date, Datetime.DISPLAY_MONTH_DAY)
    : 'TBD';
  return date;
};

const getDispatchFieldVisibility = (
  jobForm,
  {isEnabledManualJobHourMinimum} = {isEnabledManualJobHourMinimum: false},
) => {
  const valueForms = _.get(jobForm, 'valueForms', []);
  const moversValueField = _.find(
    valueForms,
    ({variableIdentifier}) => variableIdentifier === 'JOB_NUMBER_OF_MOVERS_OVERRIDE',
  );
  const trucksValueField = _.find(
    valueForms,
    ({variableIdentifier}) => variableIdentifier === 'JOB_NUMBER_OF_TRUCKS_OVERRIDE',
  );
  const minEstimateHoursField = _.find(
    valueForms,
    ({variableIdentifier}) => variableIdentifier === 'JOB_MIN_ESTIMATE_HOURS_OVERRIDE',
  );
  const maxEstimateHoursField = _.find(
    valueForms,
    ({variableIdentifier}) => variableIdentifier === 'JOB_MAX_ESTIMATE_HOURS_OVERRIDE',
  );
  const hourMinimumField = _.find(
    valueForms,
    ({variableIdentifier}) => variableIdentifier === 'JOB_HOUR_MINIMUM',
  );
  const isMoversValueFieldVisible = _.get(moversValueField, 'isVisibleForCreateProject', false);
  const isTrucksValueFieldVisible = _.get(trucksValueField, 'isVisibleForCreateProject', false);
  const isMinEstimateHoursFieldVisible = _.get(
    minEstimateHoursField,
    'isVisibleForCreateProject',
    false,
  );
  const isMaxEstimateHoursFieldVisible = _.get(
    maxEstimateHoursField,
    'isVisibleForCreateProject',
    false,
  );
  const isHourMinimumValueFieldVisible = _.get(
    hourMinimumField,
    'isVisibleForCreateProject',
    false,
  );
  const isMoversInputVisible = !isMoversValueFieldVisible;
  const isTrucksInputVisible = !isTrucksValueFieldVisible;
  const isMinEstimateHoursInputVisible = !isMinEstimateHoursFieldVisible;
  const isMaxEstimateHoursInputVisible = !isMaxEstimateHoursFieldVisible;
  const isHourMinimumVisible = !(isEnabledManualJobHourMinimum && isHourMinimumValueFieldVisible);
  return {
    isMoversInputVisible,
    isTrucksInputVisible,
    isMinEstimateHoursInputVisible,
    isMaxEstimateHoursInputVisible,
    isHourMinimumVisible,
  };
};

const JobForm = {
  copy,
  copyJobForm,
  edit,
  editV2,
  new: _new,
  newForProject,
  newForProjectForm,
  newFromJob,
  newFromJobRequest,
  toForm,
  toMutation,

  // Helpers
  getJobName,
  getLocations,
  getDisplayDate,
  getSectionedValueFormsForJobType,
  getDispatchFieldVisibility,
  handleEmptyLocationForms,
};

export default JobForm;
