// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';
import {List, URL} from '@supermove/utils';

// App
import Tabs from '@shared/design/components/Tabs';
import ProjectTypeCategory from '@shared/modules/Project/enums/ProjectTypeCategory';
import Line from 'modules/App/components/Line';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';

const PageContainer = Styled.View`
`;

const TabsContainer = Styled.View`
  margin-horizontal: 24px;
`;

const getTabIndex = ({navigator, tabs}) => {
  const {location} = navigator;

  const splitLocationPath = location.pathname.split('/');

  return _.findIndex(tabs, {key: _.last(splitLocationPath)});
};

const getTabDefinitions = ({organization}) => {
  const moveInvoicesTab = {
    key: 'move',
    label: 'Move Invoices',
    link: URL.getUrlFromVariables('/accounting/invoices/move', {
      projectTypeCategories: [ProjectTypeCategory.MOVE],
    }),
  };
  const storageInvoicesTab = {
    key: 'storage',
    label: 'Storage Invoices',
    link: URL.getUrlFromVariables('/accounting/invoices/storage', {
      projectTypeCategories: [ProjectTypeCategory.STORAGE],
    }),
  };
  const reportsTab = {
    key: 'reports',
    label: 'Reports',
    link: '/accounting/reports',
  };
  const employeePayrollTab = {
    key: 'employee-payroll',
    label: 'Employee Payroll',
    link: '/accounting/employee-payroll',
  };
  const contractorPayrollTab = {
    key: 'contractor-payroll',
    label: 'Contractor Payroll',
    link: '/accounting/contractor-payroll',
  };
  const salesPayrollTab = {
    key: 'sales-payroll',
    label: 'Sales Payroll',
    link: '/accounting/sales-payroll',
  };
  const crewPayrollTab = {
    key: 'crew-payroll',
    label: 'Crew Hours',
    link: '/accounting/crew-payroll',
  };
  const exportsTab = {
    key: 'exports',
    label: 'Exports',
    link: `/accounting/${organization.activeCodatIntegration?.uuid}/exports`,
  };
  return [
    moveInvoicesTab,
    ...List.insertIf(organization.settings.isStorageEnabled, storageInvoicesTab),
    ...List.insertIf(
      organization.settings.isCostAndCompensationEnabled,
      employeePayrollTab,
      contractorPayrollTab,
    ),
    ...List.insertIf(
      !organization.settings.isCostAndCompensationEnabled &&
        organization.features.isEnabledPayrollForSales,
      salesPayrollTab,
    ),
    ...List.insertIf(
      !organization.features.isEnabledHideCrewPayrollTab &&
        organization.features.isEnabledPayrollForCrew,
      crewPayrollTab,
    ),
    ...List.insertIf(
      organization.features.isEnabledReports && !organization.features.isEnabledOfficeGlobalReports,
      reportsTab,
    ),
    ...List.insertIf(organization.activeCodatIntegration, exportsTab),
  ];
};

const TabHeader = ({navigator, organization}) => {
  const tabDefinitions = getTabDefinitions({organization});
  const activeTabIndex = getTabIndex({navigator, tabs: tabDefinitions});

  return (
    <TabsContainer>
      <Tabs
        tabs={tabDefinitions}
        handlePressTab={({link}) => navigator.replace(link)}
        activeTabIndex={activeTabIndex}
      />
    </TabsContainer>
  );
};

const AccountingPageHeader = ({organization}) => {
  const {navigator} = useNavigationDOM();

  return (
    <PageContainer>
      <StandardOfficeHeader title={'Accounting'} showLine={false} />
      <TabHeader navigator={navigator} organization={organization} />
      <Line />
    </PageContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AccountingPageHeader.fragment = gql`
  fragment AccountingPageHeader on Organization {
    id
    activeCodatIntegration {
      id
      uuid
    }
    features {
      isEnabledHideCrewPayrollTab: isEnabled(feature: "HIDE_CREW_PAYROLL_TAB")
      isEnabledPayrollForCrew: isEnabled(feature: "PAYROLL_FOR_CREW")
      isEnabledPayrollForSales: isEnabled(feature: "PAYROLL_FOR_SALES")
      isEnabledReports: isEnabled(feature: "REPORTS")
      isEnabledOfficeGlobalReports: isEnabled(feature: "OFFICE_GLOBAL_REPORTS")
    }
    settings {
      id
      isStorageEnabled
      isCostAndCompensationEnabled
    }
  }
`;

export default AccountingPageHeader;
