// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, usePagination, useModal, useResponsive, useQuery} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {URL, pluralize} from '@supermove/utils';

// App
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import PayrollReportKind from '@shared/modules/Accounting/enums/PayrollReportKind';
import RecurringTaskKind from '@shared/modules/Accounting/enums/RecurringTaskKind';
import PayrollReportsListV2 from 'modules/Accounting/PayrollReport/components/PayrollReportsListV2';
import UpsertReportCycleModal from 'modules/Accounting/PayrollReport/components/UpsertReportCycleModal';
import Line from 'modules/App/components/Line';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';

const MAX_CONTENT_WIDTH = 1200;

const PageContentContainer = Styled.View`
  flex: 1;
`;

const ContentSectionContainer = Styled.View`
  max-width: ${({mobile}) => (mobile ? MAX_CONTENT_WIDTH + 12 : MAX_CONTENT_WIDTH + 24)}px;
  padding-horizontal:${({mobile}) => (mobile ? 12 : 24)}px;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const RightAlignRow = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
`;

const TitleText = Styled.Text`
  ${Typography.Responsive.Subheading};
`;

const ReportCycleSupportingText = Styled.Text`
  ${Typography.Responsive.Body};
`;

const ReportCycleButton = Styled.ButtonV2`
  flex-direction: row;
  padding-vertical: 8px;
  padding-horizontal: 12px;
  background-color: ${colors.gray.background};
  border-radius: 4px;
`;

const ReportCycleButtonText = Styled.Text`
  ${Typography.Responsive.Body};
`;

const NextExecutionDateText = Styled.Text`
  ${Typography.Responsive.Body};
  color: ${colors.gray.secondary};
`;

const getVariablesFromParams = (params, payrollReportKind) => {
  return {
    kind: payrollReportKind,
    pagination: PaginationBar.DEFAULT_PAGINATION,
    ...params,
  };
};

const ReportCycleDisplay = ({recurringTask, onPress, responsive}) => {
  return (
    <Row>
      <ReportCycleSupportingText responsive={responsive}>Report Cycle</ReportCycleSupportingText>
      <Space width={16} />
      <ReportCycleButton onPress={onPress}>
        <ReportCycleButtonText responsive={responsive}>
          {RecurringTaskKind.getDisplayRecurringTask(recurringTask)}
        </ReportCycleButtonText>
        <Space width={8} />
        <Icon source={Icon.CaretDown} size={13} color={colors.gray.tertiary} />
      </ReportCycleButton>
    </Row>
  );
};

const ContentHeader = ({
  organizationId,
  payrollReportsCount,
  kind,
  recurringTask,
  refetch,
  responsive,
}) => {
  const upsertReportCycleModal = useModal({name: 'Recurring Task Modal'});

  return (
    <ContentSectionContainer {...responsive}>
      <Space height={24} />
      <Row>
        <TitleText responsive={responsive}>
          {pluralize('Payroll Report', payrollReportsCount, true)}
        </TitleText>
        <Space style={{flex: 1}} />
        <ReportCycleDisplay
          recurringTask={recurringTask}
          onPress={upsertReportCycleModal.handleOpen}
          responsive={responsive}
        />
      </Row>
      {!_.isEmpty(recurringTask) && (
        <React.Fragment>
          <Space height={4} />
          <RightAlignRow>
            <NextExecutionDateText
              responsive={responsive}
            >{`Next run date: ${recurringTask.nextExecutionDateForPayroll}`}</NextExecutionDateText>
          </RightAlignRow>
        </React.Fragment>
      )}
      <Space height={24} />
      <UpsertReportCycleModal
        key={upsertReportCycleModal.key}
        organizationId={organizationId}
        recurringTaskKind={PayrollReportKind.getRecurringTaskKind(kind)}
        recurringTask={recurringTask}
        isOpen={upsertReportCycleModal.isOpen}
        handleClose={upsertReportCycleModal.handleClose}
        refetch={refetch}
      />
    </ContentSectionContainer>
  );
};

const ListPayrollReportsPageContentV2 = ({organization, baseRoute, payrollReportKind}) => {
  const responsive = useResponsive();
  const {navigator, params} = useNavigationDOM();
  const variables = getVariablesFromParams(params, payrollReportKind);

  const {loading, data, refetch} = useQuery(ListPayrollReportsPageContentV2.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      kinds: [variables.kind],
      recurringTaskKind: PayrollReportKind.getRecurringTaskKind(variables.kind),
      ...variables,
    },
  });

  const pagination = usePagination({
    currentPage: _.toNumber(variables.pagination.page), // The URL is the single source of truth for currentPage
    paginationMetadata: _.get(
      data,
      'viewer.viewingOrganization.filteredPayrollReportsPaginatedList.paginationMetadata',
    ),
    onChangePage: (page) => {
      navigator.push(
        URL.getUrlFromVariables(baseRoute, {
          ...variables,
          pagination: {
            page,
            resultsPerPage: variables.pagination.resultsPerPage,
          },
        }),
      );
    },
  });

  // Only show page loading indicator on initial render
  if (loading && !data) {
    return <PageLoadingIndicator />;
  }

  const {payrollReports, paginationMetadata} =
    data.viewer.viewingOrganization.filteredPayrollReportsPaginatedList;

  return (
    <PageContentContainer>
      <ContentHeader
        organizationId={organization.id}
        payrollReportsCount={paginationMetadata.totalResults}
        kind={params.kind}
        recurringTask={data.viewer.viewingOrganization.recurringTaskForKind}
        refetch={refetch}
        responsive={responsive}
      />
      <Line style={{maxWidth: MAX_CONTENT_WIDTH}} />
      <ContentSectionContainer style={{flex: 1}} {...responsive}>
        <Space height={32} />
        <PayrollReportsListV2 payrollReports={payrollReports} refetch={refetch} />
        <Space height={32} />
        <PaginationBar pagination={pagination} />
        <Space height={32} />
      </ContentSectionContainer>
    </PageContentContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ListPayrollReportsPageContentV2.query = gql`
  ${PayrollReportsListV2.fragment}
  ${UpsertReportCycleModal.fragment}
  ${usePagination.fragment}

  query ListProjectsPageContentV2(
    $pagination: PaginationInput!,
    $kinds: [String],
    $recurringTaskKind: String!,
  ) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        filteredPayrollReportsPaginatedList(
          pagination: $pagination,
          kinds: $kinds,
        ) {
          payrollReports: results {
            id
            ...PayrollReportsListV2
          }
          paginationMetadata {
            currentPage
            totalResults
            ...usePagination
          }
        }
        recurringTaskForKind(recurringTaskKind: $recurringTaskKind) {
          id
          nextExecutionDateForPayroll
          ...UpsertReportCycleModal
        }
      }
    }
  }
  `;

export default ListPayrollReportsPageContentV2;
