// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Space, Styled} from '@supermove/components';
import {Dashboard} from '@supermove/models';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownInput from '@shared/design/components/MultiDropdownInput';
import {DashboardCategoryType} from '@shared/modules/DashboardCategory/forms/DashboardCategoryForm';
import {DashboardTagType} from '@shared/modules/DashboardTag/forms/DashboardTagForm';

const Container = Styled.View`
`;

const toDashboardCategoryOptions = (dashboardCategories: DashboardCategoryType[]) =>
  dashboardCategories
    .map((category) => ({
      label: category.name,
      value: category.id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

const toDashboardTagOptions = (dashboardTags: DashboardTagType[]) =>
  dashboardTags
    .map((tag) => ({
      label: tag.name,
      value: tag.id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

interface GlobalDashboardsLibraryPageCreateDashboardFormProps {
  form: any;
  dashboardCategories: DashboardCategoryType[];
  dashboardTags: DashboardTagType[];
}

const GlobalDashboardsLibraryPageCreateDashboardForm = ({
  form,
  dashboardCategories,
  dashboardTags,
}: GlobalDashboardsLibraryPageCreateDashboardFormProps) => {
  const ref = React.useRef(null);
  const field = 'dashboardForm';

  const tagIds = _.get(form.values, `${field}.tagIds`) as string[];

  return (
    <Container ref={ref}>
      <FieldInput
        {...form}
        index={0}
        name={`${field}.name`}
        label={'Report Name'}
        isResponsive
        isRequired
        input={{
          placeholder: 'Enter report name',
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={1}
        name={`${field}.dashboardCategoryId`}
        errorName={`${field}.dashboardCategoryId`}
        label={'Category'}
        isResponsive
        isRequired
        component={DropdownInput}
        input={{
          options: toDashboardCategoryOptions(dashboardCategories),
          placeholder: `Type to search`,
          setFieldValue: form.setFieldValue,
          style: {flex: 1},
          noOptionsMessage: '',
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={2}
        name={`${field}.exploDashboardEmbedId`}
        label={'Explo ID'}
        isResponsive
        isRequired
        input={{
          placeholder: 'Enter Explo ID',
          disabled: !!form.values?.globalDashboardForm?.globalDashboardId,
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={3}
        name={`${field}.exploVariables`}
        label={'Explo Variables'}
        tooltip={'JSON of custom Explo variables passed in to this report'}
        isResponsive
        input={{
          placeholder: 'Enter Explo Variables',
          value: Dashboard.formatJson(form.values?.globalDashboardForm?.exploVariables),
          multiline: true,
          style: {height: 136, paddingTop: 8},
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={4}
        name={`${field}.description`}
        label={'Description'}
        isResponsive
        input={{
          placeholder: 'Enter description',
          multiline: true,
          style: {height: 76, paddingTop: 8},
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={1}
        label={'Tags'}
        isResponsive
        tooltip={
          'Descriptive tags to help customers easily find this report based on keywords and topics.'
        }
        component={MultiDropdownInput}
        name={`${field}.tagIds`}
        input={{
          value: tagIds,
          options: toDashboardTagOptions(dashboardTags),
          setFieldValue: form.setFieldValue,
          placeholder: `Type to search`,
          style: {flex: 1},
          noOptionsMessage: '',
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={5}
        name={`${field}.helpArticleUrl`}
        label={'Link to Help Article'}
        tooltip={'This will be shown to the customer when they are viewing this report.'}
        isResponsive
        input={{
          placeholder: 'https://',
        }}
      />
    </Container>
  );
};

export default GlobalDashboardsLibraryPageCreateDashboardForm;
