// Libraries
import React from 'react';

// Supermove
import {Loading, Popover, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover, useNavigationDOM, usePopover, useQuery} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import {getAdminAppUrl} from '@shared/modules/App/utils/admin/getAdminAppUrl';
import UserRole from '@shared/modules/User/enums/UserRole';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import LogOutModal from 'modules/App/components/Sidebar/LogOutModal';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';

const UserButton = Styled.ButtonV2`
  padding-horizontal: 8px;
  padding-vertical: 4px;
`;

const NameText = Styled.Text`
  ${Typography.Body};
  color: ${colors.white};
`;

const MenuItemButton = Styled.ButtonV2`
  padding-horizontal: 12px;
  padding-vertical: 8px;
  background-color: ${(props) => (props.isHovered ? colors.hover : colors.white)};
`;

const MenuItemText = Styled.Text`
  ${Typography.Body};
`;

const MenuItem = ({text, onPress}) => {
  const {isHovered, ref} = useHover();
  return (
    <MenuItemButton ref={ref} isHovered={isHovered} onPress={onPress}>
      <MenuItemText>{text}</MenuItemText>
    </MenuItemButton>
  );
};

const MyPreferences = ({handleClose}) => {
  const {navigator} = useNavigationDOM();

  return (
    <MenuItem
      text={'Update account settings'}
      onPress={() => {
        navigator.push('/account/profile/personal-information');
        handleClose();
      }}
    />
  );
};

const OpenAdminApp = ({handleClose, companySlug}) => {
  const {navigator} = useNavigationDOM();
  const adminAppUrl = getAdminAppUrl({companySlug});

  return (
    <MenuItem
      text={'Open Admin App'}
      onPress={() => {
        navigator.pushNewTab(adminAppUrl);
        handleClose();
      }}
    />
  );
};

const SignOut = ({handleClose}) => {
  // TODO(dan) We should refactor LogOutModal to be updated with current patterns.
  // Once this is done we should also add a key to the parent popover which we
  // currently can't because it will prevent this modal from rendering right now.
  return (
    <LogOutModal
      trigger={(logoutModal) => {
        return (
          <MenuItem
            text={'Sign out'}
            onPress={() => {
              logoutModal.handleOpen();
              handleClose();
            }}
          />
        );
      }}
    />
  );
};

const GlobalCurrentUserActions = () => {
  const userActionsPopover = usePopover({name: 'User Actions Popover'});
  const {loading, data} = useQuery(GlobalCurrentUserActions.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Loading
      loading={loading}
      as={() => <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={100} />}
    >
      {() => {
        const {viewer} = data;
        const {company: {slug: companySlug} = {}} = viewer.viewingOrganization;

        return (
          <React.Fragment>
            <Popover.Content innerRef={userActionsPopover.ref}>
              <UserButton onPress={userActionsPopover.handleToggle}>
                <NameText>{viewer.fullName}</NameText>
              </UserButton>
            </Popover.Content>
            <Popover
              // A key cannot be added to this popover because it will prevent the
              // LogOutModal from rendering. Once LogOutModal is updated with current
              // useModal pattern, we can add a key.
              reference={userActionsPopover.ref}
              isOpen={userActionsPopover.isOpen}
              handleOpen={userActionsPopover.handleOpen}
              handleClose={userActionsPopover.handleClose}
              placement={Popover.Positions.BottomStart}
              offset={[0, 4]}
            >
              <ResponsivePopover.StaticContainer width={200} style={{paddingVertical: 8}}>
                <MyPreferences handleClose={userActionsPopover.handleClose} />
                {viewer.role === UserRole.SUPER_ADMIN && (
                  <OpenAdminApp
                    handleClose={userActionsPopover.handleClose}
                    companySlug={companySlug}
                  />
                )}
                <SignOut handleClose={userActionsPopover.handleClose} />
              </ResponsivePopover.StaticContainer>
            </Popover>
          </React.Fragment>
        );
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
GlobalCurrentUserActions.query = gql`
  query GlobalCurrentUserActions {
    ${gql.query}
    viewer {
      id
      fullName
      role
      viewingOrganization {
        id
        company {
          id
          slug
        }
      }
    }
  }
`;

export default GlobalCurrentUserActions;
