// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {usePopover, useResponsive} from '@supermove/hooks';
import {Dashboard} from '@supermove/models';
import {Linking} from '@supermove/sdk';
import {colors, Typography} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import IconButton from '@shared/design/components/IconButton';
import Tag from '@shared/design/components/Tag';
import UserRole from '@shared/modules/User/enums/UserRole';
import useAppContext from 'modules/App/context/useAppContext';

const Container = Styled.View`
`;

const Column = Styled.View`
  flex-direction: column;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TagsContainer = Styled.View`
  flex-direction: row;
  flex-wrap: wrap;
`;

const Title = Styled.Text`
  ${Typography.Responsive.Heading2}
`;

const Description = Styled.Text`
  ${Typography.Responsive.Body}
`;

export type DashboardDataType = {
  id: string;
  name: string;
  description: string;
  dashboardCategoryId: string;
  exploVariables: string;
  helpArticleUrl?: string;
  dashboardTags: {
    id: number;
    tag: {
      id: string;
      name: string;
    };
  }[];
  globalDashboard?: {
    id: number;
    name: string;
    description: string;
    dashboardCategoryId: string;
    helpArticleUrl?: string;
    exploVariables: string;
  };
};

type DashboardsPageViewPanelHeaderProps = {
  dashboard: DashboardDataType;
  isNewExploVariables: boolean;
  isEnabledOfficeReportsUpdateAndResetView: boolean;
  handleEdit: () => void;
  handleRemove: () => void;
  handleResetToDefault: () => void;
  handleUpdateView: () => void;
};

const DashboardsPageViewPanelHeader = ({
  dashboard,
  isNewExploVariables,
  isEnabledOfficeReportsUpdateAndResetView,
  handleEdit,
  handleRemove,
  handleResetToDefault,
  handleUpdateView,
}: DashboardsPageViewPanelHeaderProps) => {
  const {viewer} = useAppContext();
  const responsive = useResponsive();
  const actionsPopover = usePopover();

  const hasStaffAdminPermissions = UserRole.hasStaffAdminPermissions(viewer?.role);

  const resetAndUpdateViewActions =
    !responsive.desktop && isEnabledOfficeReportsUpdateAndResetView
      ? [
          {text: 'Reset to default', onPress: handleResetToDefault},
          {text: 'Update view', onPress: handleUpdateView},
        ]
      : [];

  const actions = [
    ...List.insertIf(hasStaffAdminPermissions, {text: 'Edit report details', onPress: handleEdit}),
    ...resetAndUpdateViewActions,
    ...List.insertIf(hasStaffAdminPermissions, {
      text: 'Remove report',
      onPress: handleRemove,
      color: colors.red.warning,
    }),
  ];

  const isTagsPresent = !!dashboard.dashboardTags.length;
  const isHelpArticleUrlPresent =
    !!dashboard.globalDashboard?.helpArticleUrl &&
    responsive.desktop &&
    isEnabledOfficeReportsUpdateAndResetView;

  return (
    <Container>
      <Column>
        <Row>
          <Row style={{flex: 1, justifyContent: 'flex-start'}}>
            <Title responsive={responsive}>{dashboard.name}</Title>
            <Space width={4} />
            {!!actions.length && (
              <ActionMenuPopover popover={actionsPopover} width={200} actions={actions}>
                <IconButton
                  onPress={actionsPopover.handleToggle}
                  isSecondary
                  source={Icon.EllipsisV}
                />
              </ActionMenuPopover>
            )}
          </Row>
          {responsive.desktop && isEnabledOfficeReportsUpdateAndResetView ? (
            <Row>
              {dashboard.globalDashboard && (
                <SecondaryButton
                  iconLeft={Icon.ArrowRotateLeft}
                  text='Reset to Default'
                  isSmall
                  isDisabled={!Dashboard.isDashboardDifferentFromGlobalDashboard(dashboard)}
                  onPress={handleResetToDefault}
                />
              )}
              <Space width={16} />
              <SecondaryButton
                iconLeft={Icon.Check}
                text='Update View'
                isSmall
                isDisabled={!isNewExploVariables}
                onPress={handleUpdateView}
              />
            </Row>
          ) : (
            <Row>
              {!!dashboard.globalDashboard?.helpArticleUrl && (
                <TertiaryButton
                  iconLeft={Icon.QuestionCircle}
                  iconSize={16}
                  text='Help'
                  onPress={() => {
                    if (dashboard.globalDashboard?.helpArticleUrl) {
                      Linking.openURL(dashboard.globalDashboard?.helpArticleUrl);
                    }
                  }}
                />
              )}
            </Row>
          )}
        </Row>
        {!!dashboard.description && (
          <Column>
            <Space height={12} />
            <Description>{dashboard.description}</Description>
          </Column>
        )}
        {(isTagsPresent || isHelpArticleUrlPresent) && (
          <Column>
            <Space height={12} />
            <Row>
              <TagsContainer style={{flex: 1}}>
                {isTagsPresent &&
                  dashboard.dashboardTags.map((dashboardTag) => (
                    <React.Fragment key={dashboardTag.tag.id}>
                      <Tag label={dashboardTag.tag.name} />
                      <Space width={8} />
                    </React.Fragment>
                  ))}
              </TagsContainer>
              {isHelpArticleUrlPresent && (
                <TertiaryButton
                  iconLeft={Icon.QuestionCircle}
                  iconSize={16}
                  isDisabled={!dashboard.globalDashboard?.helpArticleUrl}
                  text='Help'
                  onPress={() => {
                    if (dashboard.globalDashboard?.helpArticleUrl) {
                      Linking.openURL(dashboard.globalDashboard?.helpArticleUrl);
                    }
                  }}
                />
              )}
            </Row>
          </Column>
        )}
      </Column>
    </Container>
  );
};

DashboardsPageViewPanelHeader.fragment = gql`
  ${Dashboard.isDashboardDifferentFromGlobalDashboard.fragment}
  fragment DashboardsPageViewPanelHeaderFragment on Dashboard {
    id
    name
    description
    dashboardCategoryId
    exploVariables
    helpArticleUrl
    dashboardTags {
      id
      tag {
        id
        name
      }
    }
    globalDashboard {
      id
      name
      description
      dashboardCategoryId
      helpArticleUrl
      exploVariables
    }
    ...Dashboard_isDashboardDifferentFromGlobalDashboard
  }
`;

export default DashboardsPageViewPanelHeader;
