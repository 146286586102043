// Libraries
import React from 'react';

// App
import {Space, Styled, Icon, Loading, Spreadsheet} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useSpreadsheet, useToast} from '@supermove/hooks';
import {Config} from '@supermove/sdk';
import {colors} from '@supermove/styles';
import {Document} from '@supermove/utils';

import Button from '@shared/design/components/Button';
import ModalComponent from '@shared/design/components/Modal';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';

const ButtonClose = Styled.ButtonV2`
`;

const ViewDataTableModalContent = ({data, handleClose, dataTableUuid}) => {
  const parsedJson = JSON.parse(JSON.parse(data.dataTable.jsonTable));

  const {spreadsheetColumns, spreadsheetData} = useSpreadsheet({
    jsonData: parsedJson,
    columnWidth: 150,
  });

  const handleCSVDownloadSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'File downloaded!',
  });

  const handleCSVDownload = async ({dataTableUuid, apiToken}) => {
    const url = `${Config.getAPIHost()}/v1/files/data_tables/${dataTableUuid}`;
    const response = await fetch(url, {
      headers: {
        'Cache-Control': 'no-cache',
        'Authorization': `Bearer ${apiToken}`,
      },
    });

    // setting up the filename
    let fileName = 'data.csv';
    const fileDisposition = response.headers.get('Content-Disposition');
    if (fileDisposition && fileDisposition.indexOf('filename=') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(fileDisposition);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }
    }

    const blob = await response.blob(fileName);
    Document.downloadFile({file: blob, filename: fileName});
  };

  return (
    <React.Fragment>
      <ModalComponent.Header style={{justifyContent: 'space-between'}}>
        <ModalComponent.HeaderText>{data.dataTable.name}</ModalComponent.HeaderText>
        <Space style={{flex: 1}} />
        {data.dataTable.organization.features
          .isEnabledOfficeDownloadDataTableDataButtonAvailable ? (
          <React.Fragment>
            <Button
              onPress={async () => {
                await handleCSVDownload({
                  dataTableUuid,
                  apiToken: data.dataTable.organization?.apiToken,
                });
                handleCSVDownloadSuccessToast.handleToast();
              }}
              iconLeft={Icon.FileDownload}
              text={'Download'}
            />
            <Space width={16} />
          </React.Fragment>
        ) : null}
        <ButtonClose onPress={handleClose}>
          <Icon source={Icon.Times} color={colors.gray.secondary} size={20} />
        </ButtonClose>
      </ModalComponent.Header>
      <ModalComponent.Body>
        <Spreadsheet columns={spreadsheetColumns} data={spreadsheetData} />
      </ModalComponent.Body>
    </React.Fragment>
  );
};

const ViewDataTableModal = ({isOpen, handleClose, dataTableUuid}) => {
  const {data, loading} = useQuery(ViewDataTableModal.query, {
    fetchPolicy: 'cache-and-network',
    skip: !isOpen,
    variables: {
      uuid: dataTableUuid,
    },
  });

  return (
    <ModalComponent.Responsive isOpen={isOpen} width={ModalComponent.WIDTH.LARGE}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          return (
            data && (
              <ViewDataTableModalContent
                data={data}
                handleClose={handleClose}
                dataTableUuid={dataTableUuid}
              />
            )
          );
        }}
      </Loading>
    </ModalComponent.Responsive>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ViewDataTableModal.query = gql`

  query ViewDataTableModal($uuid: String!) {
    ${gql.query}
    dataTable(uuid: $uuid){
      id
      jsonTable
      name
      organization {
        id
        apiToken
        features {
          isEnabledOfficeDownloadDataTableDataButtonAvailable: isEnabled(feature: "OFFICE_DOWNLOAD_DATA_TABLE_DATA_BUTTON_AVAILABLE")
        }
      }
    }
  }
`;

export default ViewDataTableModal;
