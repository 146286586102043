// Libraries
import _ from 'lodash';
import qs from 'qs';
import React from 'react';

// Supermove
import {Styled, Icon, Space, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useNavigationDOM, useEffect, useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import Tabs from '@shared/design/components/Tabs';
import CalendarKind from '@shared/modules/Settings/enums/CalendarKind';
import {PageLoadingIndicator} from 'modules/App/components';
import {OfficeHeaderBuilder} from 'modules/App/components/OfficeHeader';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';
import BookingCalendar from 'modules/Calendar/Booking/components/BookingCalendar';
import CapacityCalendarPage from 'modules/Calendar/Capacity/CapacityCalendarPage';
import JobsCalendar from 'modules/Calendar/components/JobsCalendar';
import ListProjectsPage from 'modules/Project/List/ListProjectsPage';

const TabsContainer = Styled.View`
  border-bottom-width: 1px;
  margin-horizontal: 24px;
  border-bottom-style: solid;
  border-bottom-color: ${colors.gray.border};
`;

const Container = Styled.View`
  flex: 1;
`;

const getSearchParams = ({location}) => {
  const search = _.replace(location.search, '?', '');
  return qs.parse(search);
};

const BookingCalendarPage = ({navigator, viewer}) => (
  <Container style={{zIndex: 100}}>
    <BookingCalendar
      searchParams={getSearchParams({location: navigator.location})}
      viewer={viewer}
      isHeaderHidden
    />
  </Container>
);

const getTabDefinitions = ({organization, viewer, navigator, responsive}) => {
  const capacityCalendarTab = [
    {
      label: 'Capacity',
      url: '/moves/capacity/calendar',
      component: <CapacityCalendarPage organization={organization} />,
    },
  ];

  const movesCalendarTab = List.insertIf(organization.settings.isMovesCalendarEnabled, {
    label: 'Calendar',
    url: '/calendar',
    component: responsive.mobile ? (
      <ScrollView>
        <JobsCalendar
          searchParams={getSearchParams({location: navigator.location})}
          viewer={viewer}
          isHeaderHidden
        />
      </ScrollView>
    ) : (
      <JobsCalendar
        searchParams={getSearchParams({location: navigator.location})}
        viewer={viewer}
        isHeaderHidden
      />
    ),
  });

  const primaryTab =
    organization.settings.primaryCalendar === CalendarKind.CAPACITY
      ? capacityCalendarTab
      : movesCalendarTab;
  const secondaryTab =
    organization.settings.primaryCalendar === CalendarKind.CAPACITY
      ? movesCalendarTab
      : capacityCalendarTab;

  return [
    ...primaryTab,
    ...secondaryTab,
    ...List.insertIf(organization.settings.isBookingCalendarEnabled, {
      label: 'Booking',
      url: '/booking/calendar',
      component: <BookingCalendarPage navigator={navigator} viewer={viewer} />,
    }),
    {
      label: 'Projects',
      url: '/moves/list',
      component: <ListProjectsPage />,
    },
  ];
};

const ListProjectsPageNavigationTabs = ({tabs, currentTabIndex}) => {
  const {navigator} = useNavigationDOM();
  return (
    <TabsContainer>
      <Tabs
        tabs={tabs}
        handlePressTab={(tab) => navigator.push(tab.url)}
        activeTabIndex={currentTabIndex}
      />
    </TabsContainer>
  );
};

const MovesPageTabContent = ({tabs, currentTabIndex}) => {
  return tabs[currentTabIndex].component;
};

const MovesPage = () => {
  const responsive = useResponsive();
  const {navigator, params} = useNavigationDOM();
  const {location} = navigator;
  const {data, refetch} = useQuery(MovesPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  // This is used to fix when we hit the back button on tabs for calendars
  // That causes pages to break since theres no slugs, we bypass this by force
  // Refetching the query which then the components below will mount the correct slugs param
  useEffect(() => {
    // slugs is not supported on move list
    if (!params.slugs && location.pathname.includes('capacity')) {
      refetch();
    }
  }, [params, refetch, location]);

  if (!data) {
    return (
      <SidebarPageV2 selected={'moves'}>
        <PageLoadingIndicator />
      </SidebarPageV2>
    );
  }

  const {viewer} = data;
  const organization = viewer.viewingOrganization;
  const tabs = getTabDefinitions({organization, viewer, navigator, responsive});
  const currentTabIndex = _.findIndex(tabs, (tab) =>
    navigator.location.pathname.startsWith(tab.url),
  );

  return (
    <SidebarPageV2 selected={'moves'}>
      <Container>
        <StandardOfficeHeader title={'Moves'} showLine={false}>
          <OfficeHeaderBuilder.Content style={{flex: 1}}>
            <Space width={24} />
            <SecondaryButton
              text={'New Project'}
              iconLeft={Icon.Plus}
              onPress={() => navigator.push('/projects/new')}
            />
          </OfficeHeaderBuilder.Content>
        </StandardOfficeHeader>
        {/* TODO(shopkins): Remove this check once the FF is on for all orgs. This is so the page doesn't error when the tab doesn't exist */}
        {currentTabIndex !== -1 && (
          <React.Fragment>
            <ListProjectsPageNavigationTabs tabs={tabs} currentTabIndex={currentTabIndex} />
            <MovesPageTabContent tabs={tabs} currentTabIndex={currentTabIndex} />
          </React.Fragment>
        )}
      </Container>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
MovesPage.query = gql`
  ${BookingCalendar.fragment}
  ${CapacityCalendarPage.fragment}
  ${JobsCalendar.fragment}
  query MovesPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        slug
        settings {
          id
          isBookingCalendarEnabled
          isMovesCalendarEnabled
          isMovesTabPrimary
          primaryCalendar
        }
        ...CapacityCalendarPage
      }
      ...BookingCalendar
      ...JobsCalendar
    }
  }
`;

export default MovesPage;
