// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// App
import {ScrollView, Space, Styled} from '@supermove/components';
import {useNavigationDOM, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// Components
import Breadcrumbs from '@shared/design/components/Breadcrumbs';
import Tabs from '@shared/design/components/Tabs';
import Line from 'modules/App/components/Line';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';

const PageContainer = Styled.View`
  flex: 1;
`;

const PageContentContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const PageContentHeaderContainer = Styled.View`
  padding-horizontal: ${({isResponsive, responsive}) => (isResponsive && !responsive.desktop ? 20 : 0)}px;
`;

const StandardOfficeHeaderContainer = Styled.View`
  padding-horizontal: 24px;
`;

const TabContainer = Styled.View`
  padding-left: 24px;
`;

const Title = Styled.Text`
  ${Typography.PageHeading}
`;

const Description = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.secondary};
  max-width: 700px;
`;

const PageContent = ({
  children,
  title,
  description,
  scrollViewStyle,
  scrollViewContentContainerStyle,
  hasSubTabs,
  hasNoChildrenStyle,
  isResponsive,
  HeaderComponent,
  headerComponentProps,
}) => {
  const responsive = useResponsive();

  return (
    <PageContentContainer>
      {hasNoChildrenStyle ? (
        children
      ) : (
        <ScrollView
          style={
            scrollViewStyle || {
              flex: 1,
              paddingHorizontal:
                hasSubTabs || (isResponsive && !responsive.desktop) ? '0px' : '24px',
            }
          }
          contentContainerStyle={scrollViewContentContainerStyle}
        >
          {HeaderComponent ? (
            <HeaderComponent {...headerComponentProps} />
          ) : (
            <PageContentHeaderContainer isResponsive={isResponsive} responsive={responsive}>
              {!!title && (
                <React.Fragment>
                  <Space height={32} />
                  <Title>{title}</Title>
                </React.Fragment>
              )}
              {!!description && (
                <React.Fragment>
                  <Space height={12} />
                  <Description>{description}</Description>
                </React.Fragment>
              )}
              {!hasSubTabs && <Space height={24} />}
            </PageContentHeaderContainer>
          )}
          {children}
          <Space height={32} />
        </ScrollView>
      )}
    </PageContentContainer>
  );
};

const SettingsPage = ({
  children,
  breadcrumbs,
  tabs,
  title,
  description,
  scrollViewStyle,
  scrollViewContentContainerStyle,
  hasSubTabs,
  hasNoChildrenStyle,
  isResponsive,
  HeaderComponent,
  headerComponentProps,
}) => {
  const {navigator} = useNavigationDOM();
  const currentTabIndex = _.findIndex(tabs, (tab) => navigator.location.pathname.includes(tab.url));

  return (
    <SidebarPageV2 selected={'settings'}>
      <PageContainer>
        <StandardOfficeHeaderContainer>
          <StandardOfficeHeader title={''} showLine={false} officeHeaderStyle={{}}>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </StandardOfficeHeader>
        </StandardOfficeHeaderContainer>
        {tabs && (
          <TabContainer>
            <Tabs
              tabs={tabs}
              handlePressTab={(tab) => navigator.push(tab.url)}
              activeTabIndex={currentTabIndex}
            />
          </TabContainer>
        )}
        <Line />
        <PageContent
          title={title}
          description={description}
          scrollViewStyle={scrollViewStyle}
          scrollViewContentContainerStyle={scrollViewContentContainerStyle}
          hasSubTabs={hasSubTabs}
          hasNoChildrenStyle={hasNoChildrenStyle}
          isResponsive={isResponsive}
          HeaderComponent={HeaderComponent}
          headerComponentProps={headerComponentProps}
        >
          {children}
        </PageContent>
      </PageContainer>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
SettingsPage.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      onPress: PropTypes.func,
    }),
  ),
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ),
  title: PropTypes.string,
  description: PropTypes.string,
  hasSubTabs: PropTypes.bool,
  hasNoChildrenStyle: PropTypes.bool,
  isResponsive: PropTypes.bool,
  HeaderComponent: PropTypes.func,
  headerComponentProps: PropTypes.object,
};

SettingsPage.defaultProps = {
  breadcrumbs: [],
  tabs: [],
  title: '',
  description: '',
  hasSubTabs: false,
  hasNoChildrenStyle: false,
  isResponsive: false,
  HeaderComponent: null,
  headerComponentProps: {},
};

SettingsPage.TitleText = Title;
SettingsPage.DescriptionText = Description;
SettingsPage.PageContent = PageContent;

export default SettingsPage;
