// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {MultiDropdownInput, Space, Styled, Popover} from '@supermove/components';
import {FilteredClientsForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useResponsive} from '@supermove/hooks';
import {Organization} from '@supermove/models';
import {Typography} from '@supermove/styles';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import PopoverFilter from '@shared/modules/App/components/PopoverFilter';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import MultiBranchOrganizationField from 'modules/Organization/components/MultibranchOrganizationField';

const ContentContainer = Styled.View`
  padding-horizontal: 16px;
`;

const Header = Styled.Text`
  ${Typography.Label1}
`;

const getSalespersonDropdownOptions = ({options, organization, description}) => {
  if (organization.features.isEnabledAllowEmptyBookedBy) {
    options.unshift({label: 'No salesperson', value: null, description});
  }
  return options;
};

const MultiBranchDropdownFilter = ({index, field, form, organization}) => {
  const organizations = _.get(organization, 'company.organizations', []);
  const {params} = useNavigationDOM();

  return (
    <MultiBranchOrganizationField
      label={'Branches'}
      placeholder={'Select branches'}
      width={'100%'}
      index={index}
      isPortaled={false}
      isResponsive
      value={params.slugs || []}
      organizations={organizations}
      disableAllOption
      onChangeValue={(newSlugs) => {
        form.setFieldValue(`${field}.slugs`, newSlugs);
      }}
    />
  );
};

const SalespeopleMultiselect = ({field, form, organization, index}) => {
  const options = getSalespersonDropdownOptions({
    organization,
    description: organization.features.isEnabledClientMultibranchMigration ? 'All branches' : '',
    options: Organization.getSalespersonOptions(organization).map((user) => ({
      label: user.label,
      value: _.toString(user.value), // Keeps filter option selected when page is refreshed
      description: user.description,
    })),
  });

  return (
    <FieldInput
      {...form}
      component={MultiDropdownInput}
      name={`${field}.salespersonIds`}
      label={'Salespeople'}
      style={{zIndex: index}}
      isResponsive
      input={{
        options,
        placeholder: 'Select salespeople',
        setFieldValue: form.setFieldValue,
        style: {
          width: '100%',
        },
        showDescriptionInOption: true,
      }}
    />
  );
};

const ClientFilters = ({organization, form, isRestricted}) => {
  const field = 'filteredClientsForm';

  const shouldSeeBranchFilter =
    organization.isPrimary && organization.features.isEnabledClientMultibranchMigration;

  return (
    <React.Fragment>
      {shouldSeeBranchFilter && (
        <React.Fragment>
          <MultiBranchDropdownFilter
            organization={organization}
            form={form}
            field={field}
            index={0}
          />
          <Space height={16} />
        </React.Fragment>
      )}
      {!isRestricted && (
        <React.Fragment>
          <SalespeopleMultiselect organization={organization} form={form} field={field} index={1} />
          <Space height={16} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const ClientFilterPopoverContentV1 = ({organization, form, isRestricted, viewerId}) => {
  return (
    <ResponsivePopover.StaticContainer width={320} maxHeight={480}>
      <ContentContainer>
        <Space height={16} />
        <Header>Filter</Header>
        <Space height={8} />
        <ClientFilters organization={organization} form={form} isRestricted={isRestricted} />
        <SecondaryButton
          text={'Clear Filters'}
          onPress={() => {
            form.setFieldValue(
              'filteredClientsForm.salespersonIds',
              isRestricted ? [viewerId] : null,
            );
            form.setFieldValue(
              'filteredClientsForm.slugs',
              organization.isPrimary ? ['ALL_ORGANIZATIONS'] : [organization.slug],
            );
          }}
        />
        <Space height={16} />
      </ContentContainer>
    </ResponsivePopover.StaticContainer>
  );
};

const ClientFilterPopoverContent = ({organization, form, isRestricted, responsive}) => {
  return (
    <ContentContainer style={{paddingHorizontal: responsive.desktop ? null : 16}}>
      <ClientFilters organization={organization} form={form} isRestricted={isRestricted} />
      {/* Without this, the mobile dropdown doesn't have enough space to display */}
      {responsive.desktop ? null : <Space height={72} />}
    </ContentContainer>
  );
};

const ClientsFilterPopover = ({popover, organization, form, isRestricted, viewerId}) => {
  const responsive = useResponsive();

  if (organization.features.isEnabledFilterPopoverV2) {
    return (
      <PopoverFilter
        activeFiltersCount={FilteredClientsForm.getNumberOfFiltersApplied({
          form,
          organization,
          isRestricted,
        })}
        hideActionButtons
        popover={popover}
        responsive={responsive}
        handleApply={popover.handleClose}
        handleClear={() => {
          form.setFieldValue(
            'filteredClientsForm.salespersonIds',
            isRestricted ? [viewerId] : null,
          );
          form.setFieldValue(
            'filteredClientsForm.slugs',
            organization.isPrimary ? ['ALL_ORGANIZATIONS'] : [organization.slug],
          );
        }}
      >
        <ClientFilterPopoverContent
          organization={organization}
          form={form}
          isRestricted={isRestricted}
          responsive={responsive}
        />
      </PopoverFilter>
    );
  }

  return (
    <React.Fragment>
      <Popover
        placement={Popover.Positions.BottomStart}
        isOpen={popover.isOpen}
        handleOpen={popover.handleOpen}
        handleClose={popover.handleClose}
        reference={popover.ref}
        offset={[0, 4]}
      >
        <ClientFilterPopoverContentV1
          organization={organization}
          form={form}
          isRestricted={isRestricted}
          viewerId={viewerId}
        />
      </Popover>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ClientsFilterPopover.fragment = gql`
  ${Organization.getSalespersonOptions.fragment}
  ${MultiBranchOrganizationField.fragment}

  fragment ClientsFilterPopover on Organization {
    id
    isPrimary
    features {
      isEnabledClientMultibranchMigration: isEnabled(feature: "CLIENT_MULTIBRANCH_MIGRATION")
      isEnabledFilterPopoverV2: isEnabled(feature: "FILTER_POPOVER_V_2")
      isEnabledAllowEmptyBookedBy: isEnabled(feature: "ALLOW_EMPTY_BOOKED_BY")
    }
    ...Organization_getSalespersonOptions
    ...MultiBranchOrganizationField
  }
`;

export default ClientsFilterPopover;
