// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const isVariablesChanged = withFragment(
  (dashboard: {exploVariables: string}, newVariables: Record<string, any>) => {
    return !_.isEqual(JSON.parse(dashboard.exploVariables), newVariables);
  },
  gql`
    fragment Dashboard_isVariablesChanged on Dashboard {
      id
      exploVariables
    }
  `,
);

const isDashboardDifferentFromGlobalDashboard = withFragment(
  (dashboard: {
    dashboardCategoryId: string;
    name: string;
    description: string;
    exploVariables: string;
    globalDashboard?: {
      dashboardCategoryId: string;
      name: string;
      description: string;
      exploVariables: string;
    };
  }) => {
    if (!dashboard.globalDashboard) {
      return false;
    }
    return (
      dashboard.dashboardCategoryId !== dashboard.globalDashboard.dashboardCategoryId ||
      dashboard.name !== dashboard.globalDashboard.name ||
      dashboard.description !== dashboard.globalDashboard.description ||
      isVariablesChanged(dashboard, JSON.parse(dashboard.globalDashboard.exploVariables))
    );
  },
  gql`
    ${isVariablesChanged.fragment}
    fragment Dashboard_isDashboardDifferentFromGlobalDashboard on Dashboard {
      id
      dashboardCategoryId
      name
      description
      exploVariables
      globalDashboard {
        dashboardCategoryId
        name
        description
        exploVariables
      }
      ...Dashboard_isVariablesChanged
    }
  `,
);

const formatJson = (value: string) => {
  try {
    const parsed = JSON.parse(value);
    if (typeof parsed !== 'object' || Object.keys(parsed).length === 0) {
      return value;
    }
    return JSON.stringify(parsed, null, 2);
  } catch (error) {
    return value;
  }
};

const Dashboard = {
  isVariablesChanged,
  isDashboardDifferentFromGlobalDashboard,
  formatJson,
};

export default Dashboard;
