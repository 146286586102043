// Supremove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import {DashboardLinkedToGlobalDashboardModel} from '@shared/modules/Reports/types/DashboardLinkedToGlobalDashboardModel';

export interface DashboardLinkedToGlobalDashboardFormProps {
  dashboardId: string;
  organizationId: string;
  dashboardCategoryId: string;
  name: string;
  description: string;
  exploVariables: string;
}

const _new = () => ({
  dashboardId: null,
  organizationId: null,
  dashboardCategoryId: null,
  name: '',
  description: '',
  exploVariables: '',
});

const edit = withFragment(
  (dashboard: DashboardLinkedToGlobalDashboardModel) => {
    return {
      dashboardId: dashboard.id,
      organizationId: dashboard.organizationId,
      dashboardCategoryId: dashboard.dashboardCategoryId,
      name: dashboard.name,
      description: dashboard.description,
      exploVariables: dashboard.exploVariables,
    };
  },
  gql`
    fragment DashboardLinkedToGlobalDashboardForm_edit on DashboardLinkedToGlobalDashboard {
      id
      name
      description
      organizationId
      dashboardCategoryId
      exploVariables
    }
  `,
);

const toForm = (dashboardForm: DashboardLinkedToGlobalDashboardFormProps) => ({
  dashboardId: dashboardForm.dashboardId,
  organizationId: dashboardForm.organizationId,
  dashboardCategoryId: dashboardForm.dashboardCategoryId,
  name: dashboardForm.name,
  description: dashboardForm.description,
  exploVariables: dashboardForm.exploVariables,
});

const toMutation = (dashboardForm: DashboardLinkedToGlobalDashboardFormProps) => {
  return {
    dashboardId: dashboardForm.dashboardId,
    name: dashboardForm.name,
    description: dashboardForm.description,
    organizationId: dashboardForm.organizationId,
    dashboardCategoryId: dashboardForm.dashboardCategoryId,
    exploVariables: dashboardForm.exploVariables,
  };
};

const DashboardLinkedToGlobalDashboardForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default DashboardLinkedToGlobalDashboardForm;
