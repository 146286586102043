// Libraries
import React from 'react';

// Supermove
import {Loading, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {
  useInlineFormMutation,
  useMemo,
  useNavigationDOM,
  useResponsive,
  useToast,
  useUrlFilters,
} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import DashboardStatus from '@shared/modules/GlobalDashboard/enums/DashboardStatus';
import {GlobalDashboardSortKind} from '@shared/modules/GlobalDashboard/enums/GlobalDashboardSortKind';
import DashboardFromGlobalDashboardForm from '@shared/modules/Reports/forms/DashboardFromGlobalDashboardForm';
import UserRole from '@shared/modules/User/enums/UserRole';
import useAppContext from 'modules/App/context/useAppContext';
import GlobalDashboardsLibraryPageGlobalDashboardViewPanel from 'modules/Report/Library/components/GlobalDashboardsLibraryPageGlobalDashboardViewPanel';
import GlobalDashboardsLibraryPageGlobalDashboardsList from 'modules/Report/Library/components/GlobalDashboardsLibraryPageGlobalDashboardsList';
import GlobalDashboardsLibraryPageGlobalDashboardsListItem, {
  GlobalDashboardDataType,
} from 'modules/Report/Library/components/GlobalDashboardsLibraryPageGlobalDashboardsListItem';
import GlobalDashboardsLibraryPageGlobalDashboardsListModal from 'modules/Report/Library/components/GlobalDashboardsLibraryPageGlobalDashboardsListModal';
import GlobalDashboardsPageHeader from 'modules/Report/Library/components/GlobalDashboardsPageHeader';
import {GlobalDashboardsLibraryPageFiltersType} from 'modules/Report/Library/components/types';

const PreviewEmptyState = Styled.Text`
  ${Typography.Responsive.Body};
  color: ${colors.gray.tertiary};
`;

const PageContainer = Styled.View`
  flex: 1;
`;

const ListContainer = Styled.View`
  flex-direction: column;
  align-items: center;
  border-right-width: 1;
  border-color: ${colors.gray.border};
  background-color: ${colors.gray.background};
  width: 376px;
  min-height: 100%;
  padding: 24px;
`;

const PreviewPanelContainer = Styled.View`
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right-width: 1;
  borderColor: ${colors.gray.border};
`;

const PreviewPanelSideContainer = Styled.View`
  flex: 1;
  min-height: 100%;
`;

const Row = Styled.View`
  flex: 1;
  align-items: flex-start;
  flex-direction: row;
`;

const getGlobalDashboardUuidToDashboardUuid = (
  dashboards: QueryResponse['organization']['dashboards'],
) => {
  return dashboards.reduce(
    (acc, dashboard) => {
      if (dashboard.globalDashboard) {
        acc[dashboard.globalDashboard.uuid] = dashboard.uuid;
      }
      return acc;
    },
    {} as Record<string, string>,
  );
};

type QueryResponse = {
  filteredGlobalDashboards: ({
    dashboardCategory: {
      id: string;
      name: string;
    };
  } & GlobalDashboardDataType)[];
  viewer: {
    id: string;
    viewingOrganization: {
      id: string;
      features: {
        isEnabledOfficeReportsLibrary: boolean;
      };
      settings: {
        id: string;
        isStorageEnabled: boolean;
      };
    };
  };
  organization: {
    id: string;
    dashboards: {
      id: string;
      uuid: string;
      globalDashboard?: {
        id: string;
        uuid: string;
      };
    }[];
  };
};

const GlobalDashboardsLibraryPage = () => {
  const {viewer} = useAppContext();
  const isStaffAdmin = UserRole.getIsStaffAdmin(viewer?.role);
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();

  const urlFilters = useUrlFilters<GlobalDashboardsLibraryPageFiltersType>({
    getRoute: () => '/reports/library',
    filterKeys: ['uuid', 'searchQuery', 'categoryIds', 'tagIds', 'sort', 'dashboardUuid'],
  });

  const {data, loading} = useQuery<QueryResponse>(GlobalDashboardsLibraryPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      status: DashboardStatus.PUBLISHED,
      onlyDefault: false,
      ...urlFilters.getFilters(),
      sort: urlFilters.get('sort') || GlobalDashboardSortKind.NEWEST,
    },
  });

  const globalDashboardUuidToDashboardUuid = useMemo(
    () => getGlobalDashboardUuidToDashboardUuid(data?.organization?.dashboards || []),
    [data],
  );

  const isDashboardAlreadyAdded = !!globalDashboardUuidToDashboardUuid[urlFilters.get('uuid')];
  const isDashboardSelected = urlFilters.get('uuid');

  const addDashboardSuccessToast = useToast({ToastComponent: SuccessToast});

  const {form, handleSubmit, submitting} = useInlineFormMutation({
    name: 'dashboardFromGlobalDashboardForm',
    form: {
      organizationId: data?.organization.id,
      globalDashboardId: (data?.filteredGlobalDashboards || []).find(
        (globalDashboard) => globalDashboard.uuid === urlFilters.get('uuid'),
      )?.id,
    },
    enableReinitialize: true,
    toForm: DashboardFromGlobalDashboardForm.toForm,
    toMutation: DashboardFromGlobalDashboardForm.toMutation,
    mutation: gql`
      mutation CreateDashboardFromGlobalDashboardMutation($dashboardFromGlobalDashboardForm: DashboardFromGlobalDashboardForm!) {
        response: createDashboardFromGlobalDashboard(dashboardFromGlobalDashboardForm: $dashboardFromGlobalDashboardForm) {
          ${gql.errors}
          dashboard {
            id
            uuid
            name
          }
        }
      }
    `,
    onSuccess: (result) => {
      addDashboardSuccessToast.handleToast({
        message: `'${result.dashboard.name}' added!`,
      });
      navigator.replace(`/reports/${result.dashboard.uuid}/view`);
    },
  });

  const handleClose = () => {
    if (urlFilters.get('dashboardUuid')) {
      navigator.replace(`/reports/${urlFilters.get('dashboardUuid')}/view`);
    } else {
      navigator.replace('/reports');
    }
  };

  const handleSelect = (globalDashboard: GlobalDashboardDataType) => {
    urlFilters.handleUpdate({uuid: globalDashboard.uuid});
  };

  const isStorageEnabled = !!data?.viewer.viewingOrganization.settings.isStorageEnabled;
  const filteredGlobalDashboards = (data?.filteredGlobalDashboards || []).filter(
    (globalDashboard) => {
      // Disable storage category if storage is not enabled in settings
      if (globalDashboard.dashboardCategory.name === 'Storage' && !isStorageEnabled) {
        return false;
      }
      // Disable accounting category if not a staff admin
      if (globalDashboard.dashboardCategory.name === 'Accounting' && !isStaffAdmin) {
        return false;
      }
      return true;
    },
  );

  return (
    <React.Fragment>
      {responsive.desktop ? (
        <PageContainer>
          <GlobalDashboardsPageHeader
            isDashboardAlreadyAdded={isDashboardAlreadyAdded}
            isDashboardSelected={isDashboardSelected}
            handleGoBack={handleClose}
            handleAddReport={handleSubmit}
          />
          <Row>
            <ListContainer>
              <Loading loading={loading || !data}>
                {() =>
                  data && (
                    <GlobalDashboardsLibraryPageGlobalDashboardsList
                      filteredGlobalDashboards={filteredGlobalDashboards}
                      globalDashboardUuidToDashboardUuid={globalDashboardUuidToDashboardUuid}
                      organizationId={data.organization.id}
                      urlFilters={urlFilters}
                      handleSelect={handleSelect}
                    />
                  )
                }
              </Loading>
            </ListContainer>
            <PreviewPanelSideContainer>
              <PreviewPanelContainer>
                {urlFilters.get('uuid') ? (
                  <GlobalDashboardsLibraryPageGlobalDashboardViewPanel
                    selectedGlobalDashboardUuid={urlFilters.get('uuid')}
                    isAdded={isDashboardAlreadyAdded}
                  />
                ) : (
                  <PreviewEmptyState>Select a report to preview.</PreviewEmptyState>
                )}
              </PreviewPanelContainer>
            </PreviewPanelSideContainer>
          </Row>
        </PageContainer>
      ) : (
        <GlobalDashboardsLibraryPageGlobalDashboardsListModal
          isOpen
          filteredGlobalDashboards={filteredGlobalDashboards}
          globalDashboardUuidToDashboardUuid={globalDashboardUuidToDashboardUuid}
          organizationId={data?.organization.id || ''}
          handleClose={handleClose}
          urlFilters={urlFilters}
          handleSelect={handleSelect}
          handleAdd={handleSubmit}
        />
      )}
    </React.Fragment>
  );
};

GlobalDashboardsLibraryPage.query = gql`
  ${GlobalDashboardsLibraryPageGlobalDashboardsListItem.fragment}
  query GlobalDashboardsLibraryPageQuery(
    $sort: String!
    $status: String
    $categoryIds: [String!]
    $tagIds: [String!]
    $searchQuery: String
    $onlyDefault: Boolean
  ) {
    ${gql.query}
    filteredGlobalDashboards(
      sort: $sort
      status: $status
      categoryIds: $categoryIds
      tagIds: $tagIds
      searchQuery: $searchQuery
      onlyDefault: $onlyDefault
    ) {
      id
      dashboardCategory {
        id
        name
      }
      ...GlobalDashboardsLibraryPageGlobalDashboardsListItem
    }
    viewer {
      id
      viewingOrganization {
        id
        features {
          isEnabledOfficeReportsLibrary: isEnabled(feature: "OFFICE_REPORTS_LIBRARY")
        }
        settings {
          id
          isStorageEnabled
        }
      }
    }
    organization {
      id
      dashboards {
        id
        uuid
        globalDashboard {
          id
          uuid
        }
      }
    }
  }
`;

export default GlobalDashboardsLibraryPage;
