// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// Shared
import DashboardForm from '@shared/modules/Reports/forms/DashboardForm';

const edit = withFragment(
  (reportDashboards) => ({
    reportDashboardForms: reportDashboards.map((reportDashboard) => {
      return DashboardForm.edit(reportDashboard);
    }),
  }),
  gql`
    ${DashboardForm.edit.fragment}

    fragment ReorderReportDashboardsForm_edit on Dashboard {
      id
      ...DashboardForm_edit
    }
  `,
);

const toForm = ({reportDashboardForms}) => ({
  reportDashboardForms: reportDashboardForms.map((reportDashboardForm) => {
    return DashboardForm.toForm(reportDashboardForm);
  }),
});

const toMutation = ({reportDashboardForms}) => ({
  reportDashboardForms: reportDashboardForms.map((reportDashboardForm) => {
    return DashboardForm.toMutation(reportDashboardForm);
  }),
});

const ReorderReportDashboardsForm = {
  edit,
  toForm,
  toMutation,
};

export default ReorderReportDashboardsForm;
