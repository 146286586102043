// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useNavigationDOM} from '@supermove/hooks';
import {Job, Project, Tag} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import EmptyState from '@shared/design/components/EmptyState';
import MobileProjectBlockHeader from 'modules/Project/V2/Show/Blocks/components/MobileProjectBlockHeader';
import ProjectBlockWrapper from 'modules/Project/V2/Show/Blocks/components/ProjectBlockWrapper';
import TagPill from 'modules/Tag/components/TagPill';

const CARD_WIDTH_MIN = 240;
const CARD_WIDTH_MAX = 496;
const CARD_GAP_WIDTH = 16;

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const JobCardContainer = Styled.ButtonV2`
  width: ${({width}) => width}px;
  padding: 12px;
  background-color: ${colors.gray.background};
  border-width: 1px;
  border-radius: 4px;
  border-color: ${colors.gray.border};
`;

const IconContainer = Styled.View`
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
`;

const LabelText = Styled.Text`
  ${Typography.Responsive.Label}
`;

const MicroText = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const getCardWidth = () => {
  // Design intent is for the second card to show a little bit of itself,
  // specifically to take up 20% of the screen width. This while staying
  // in the bounds of the min and max card widths.
  return Math.min(CARD_WIDTH_MAX, Math.max(CARD_WIDTH_MIN, window.innerWidth * 0.75));
};

const JobDetail = ({icon, text}) => {
  const responsive = useResponsive();
  return (
    <Row>
      <IconContainer>
        <Icon source={icon} size={16} color={colors.gray.secondary} />
      </IconContainer>
      <Space width={8} />
      <MicroText responsive={responsive}>{text}</MicroText>
    </Row>
  );
};

const JobCard = ({job, width, urlFilters}) => {
  const responsive = useResponsive();
  const firstAndLastDisplayCityState = Job.getDisplayFirstAndLastCityState(job);
  const tags = Tag.getJobTagAndTagAssignment(job);

  return (
    <JobCardContainer width={width} onPress={() => urlFilters.handleUpdate({jobUuid: job.uuid})}>
      <LabelText responsive={responsive}>{job.fullName}</LabelText>
      <Space height={8} />
      <JobDetail icon={Icon.Calendar} text={Job.getDisplayDate(job, 'ddd, MM/DD/YY', 'TBD')} />
      <Space height={8} />
      <JobDetail icon={Icon.Clock} text={Job.getOriginArrivalWindow(job)} />
      <Space height={8} />
      <JobDetail icon={Icon.CalendarClock} text={Job.getDisplayEstimateHours(job)} />
      <Space height={8} />
      <Row>
        <JobDetail icon={Icon.Truck} text={`${job.totalAssignedTrucks} / ${job.numberOfTrucks}`} />
        <Space width={12} />
        <JobDetail icon={Icon.User} text={`${job.totalAssignedMovers} / ${job.numberOfMovers}`} />
      </Row>
      <Space height={8} />
      <JobDetail icon={Icon.MapPin} text={firstAndLastDisplayCityState} />
      {job.description !== '' && (
        <React.Fragment>
          <Space height={8} />
          <JobDetail icon={Icon.InfoCircle} text={job.description} />
        </React.Fragment>
      )}
      {job.officeNotes !== '' && (
        <React.Fragment>
          <Space height={8} />
          <JobDetail icon={Icon.StickyNote} text={job.officeNotes} />
        </React.Fragment>
      )}
      <Space height={8} />
      <Row style={{flexWrap: 'wrap'}}>
        {tags.map((tag) => {
          return (
            <Row key={tag.id}>
              <TagPill tag={tag} hasDeleteButton={false} />
              <Space width={8} />
            </Row>
          );
        })}
      </Row>
    </JobCardContainer>
  );
};

const EditJobsButton = () => {
  const {navigator, params} = useNavigationDOM();

  return (
    <TertiaryButton
      text={`Edit`}
      onPress={() => navigator.push(`/projects/${params.projectUuid}/edit/jobs`)}
      isResponsive
    />
  );
};

const ProjectJobsBlockContent = ({project, urlFilters}) => {
  const displayJobs = Project.getDisplayAllJobsExcludingChildJobs(project, {
    showCancelledJobs: false,
  });
  const cardWidth = getCardWidth();

  return (
    <React.Fragment>
      <MobileProjectBlockHeader
        title={`Jobs (${displayJobs.length})`}
        ActionsComponent={EditJobsButton}
        actionsComponentProps={{project}}
        style={{paddingHorizontal: 16}}
      />
      <Container>
        {_.some(displayJobs) ? (
          <ScrollView horizontal showsHorizontalScrollIndicator={false}>
            <Space width={CARD_GAP_WIDTH} />
            {displayJobs.map((job, index) => (
              <React.Fragment key={job.id}>
                {index !== 0 && <Space width={CARD_GAP_WIDTH} />}
                <JobCard job={job} width={cardWidth} urlFilters={urlFilters} />
              </React.Fragment>
            ))}
            <Space width={CARD_GAP_WIDTH} />
          </ScrollView>
        ) : (
          <EmptyState
            isSmallTitle
            title={'No jobs.'}
            message={'Once you add a job, it will appear here.'}
            primaryActionIcon={Icon.Plus}
            primaryActionText={'Add New Job'}
            handlePrimaryAction={() => {}}
          />
        )}
      </Container>
    </React.Fragment>
  );
};

const ProjectJobsBlock = ({
  project,
  handleSetPositionY,
  layoutKey,
  index,
  urlFilters,
  projectBlockKind,
}) => {
  return (
    <ProjectBlockWrapper
      index={index}
      query={ProjectJobsBlock.query}
      queryVariables={{projectUuid: project.uuid}}
      layoutKey={layoutKey}
      handleSetPositionY={handleSetPositionY}
      style={{paddingVertical: 24}}
      projectBlockKind={projectBlockKind}
    >
      {({data}) => {
        return <ProjectJobsBlockContent project={data.project} urlFilters={urlFilters} />;
      }}
    </ProjectBlockWrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectJobsBlock.listener = gql`
  fragment ProjectJobsBlock_listener on Project {
    id
  }
`;

ProjectJobsBlock.fragment = gql`
  ${ProjectJobsBlock.listener}
  fragment ProjectJobsBlock on Project {
    id
    uuid
    ...ProjectJobsBlock_listener
  }
`;

ProjectJobsBlock.query = gql`
  ${ProjectJobsBlock.listener}
  ${Project.getDisplayAllJobsExcludingChildJobs.fragment}
  ${Job.getDisplayDate.fragment}
  ${Job.getOriginArrivalWindow.fragment}
  ${Job.getDisplayEstimateHours.fragment}
  ${Job.getDisplayFirstAndLastCityState.fragment}
  ${Tag.getJobTagAndTagAssignment.fragment}

  query ProjectJobsBlock($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      allJobsExcludingChildJobs {
        id
        uuid
        fullName
        totalAssignedTrucks
        totalAssignedMovers
        numberOfTrucks
        numberOfMovers
        description
        officeNotes
        ...Job_getDisplayDate
        ...Job_getOriginArrivalWindow
        ...Job_getEstimatedHours
        ...Job_getFirstAndLastDisplayCityState
        ...Tag_getJobTagAndTagAssignment
      }
      ...Project_getDisplayAllJobsExcludingChildJobs
      ...ProjectJobsBlock_listener
    }
  }
`;

export default ProjectJobsBlock;
