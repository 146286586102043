const RINGCENTRAL = 'RINGCENTRAL';
const GRASSHOPPER = 'GRASSHOPPER';
const VIRTUAL_PBX = 'VIRTUAL_PBX';
const SPECTRUM = 'SPECTRUM';
const DIALPAD = 'DIALPAD';
const VONAGE = 'VONAGE';
const OPENPHONE = 'OPENPHONE';
const GO_TO_CONNECT = 'GO_TO_CONNECT';
const NEXTIVA = 'NEXTIVA';
const CALLRAIL = 'CALLRAIL';
const GOOGLE_VOICE = 'GOOGLE_VOICE';
const CELL_PHONE = 'CELL_PHONE';
const LANDLINE = 'LANDLINE';
const OTHER = 'OTHER';

const RINGCENTRAL_DISPLAY = 'RingCentral';
const GRASSHOPPER_DISPLAY = 'Grasshopper';
const VIRTUAL_PBX_DISPLAY = 'Virtual PBX';
const SPECTRUM_DISPLAY = 'Spectrum';
const DIALPAD_DISPLAY = 'Dialpad';
const VONAGE_DISPLAY = 'Vonage';
const OPENPHONE_DISPLAY = 'OpenPhone';
const GO_TO_CONNECT_DISPLAY = 'Go To Connect';
const NEXTIVA_DISPLAY = 'Nextiva';
const CALLRAIL_DISPLAY = 'CallRail';
const GOOGLE_VOICE_DISPLAY = 'Google Voice';
const CELL_PHONE_DISPLAY = 'Cell Phone';
const LANDLINE_DISPLAY = 'Landline';
const OTHER_DISPLAY = 'Other';

const PROVIDERS = [
  RINGCENTRAL,
  GRASSHOPPER,
  VIRTUAL_PBX,
  SPECTRUM,
  DIALPAD,
  VONAGE,
  OPENPHONE,
  GO_TO_CONNECT,
  NEXTIVA,
  CALLRAIL,
  GOOGLE_VOICE,
  CELL_PHONE,
  LANDLINE,
  OTHER,
] as const;

type Provider = (typeof PROVIDERS)[number];
type DropdownOption = {label: string; value: Provider};

const getDropdownOptions = (): DropdownOption[] => {
  return [
    {
      label: CALLRAIL_DISPLAY,
      value: CALLRAIL,
    },
    {
      label: CELL_PHONE_DISPLAY,
      value: CELL_PHONE,
    },
    {
      label: DIALPAD_DISPLAY,
      value: DIALPAD,
    },
    {
      label: GO_TO_CONNECT_DISPLAY,
      value: GO_TO_CONNECT,
    },
    {
      label: GOOGLE_VOICE_DISPLAY,
      value: GOOGLE_VOICE,
    },
    {
      label: GRASSHOPPER_DISPLAY,
      value: GRASSHOPPER,
    },
    {
      label: LANDLINE_DISPLAY,
      value: LANDLINE,
    },
    {
      label: NEXTIVA_DISPLAY,
      value: NEXTIVA,
    },
    {
      label: OPENPHONE_DISPLAY,
      value: OPENPHONE,
    },
    {
      label: RINGCENTRAL_DISPLAY,
      value: RINGCENTRAL,
    },
    {
      label: SPECTRUM_DISPLAY,
      value: SPECTRUM,
    },
    {
      label: VIRTUAL_PBX_DISPLAY,
      value: VIRTUAL_PBX,
    },
    {
      label: VONAGE_DISPLAY,
      value: VONAGE,
    },
    {
      label: OTHER_DISPLAY,
      value: OTHER,
    },
  ];
};

const PhoneNumberProvider = {
  RINGCENTRAL,
  GRASSHOPPER,
  VIRTUAL_PBX,
  SPECTRUM,
  DIALPAD,
  VONAGE,
  OPENPHONE,
  GO_TO_CONNECT,
  NEXTIVA,
  CALLRAIL,
  GOOGLE_VOICE,
  CELL_PHONE,
  LANDLINE,
  OTHER,
  RINGCENTRAL_DISPLAY,
  GRASSHOPPER_DISPLAY,
  VIRTUAL_PBX_DISPLAY,
  SPECTRUM_DISPLAY,
  DIALPAD_DISPLAY,
  VONAGE_DISPLAY,
  OPENPHONE_DISPLAY,
  GO_TO_CONNECT_DISPLAY,
  NEXTIVA_DISPLAY,
  CALLRAIL_DISPLAY,
  GOOGLE_VOICE_DISPLAY,
  CELL_PHONE_DISPLAY,
  LANDLINE_DISPLAY,
  OTHER_DISPLAY,

  getDropdownOptions,
} as const;

export default PhoneNumberProvider;
