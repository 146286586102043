// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useResponsive} from '@supermove/hooks';

// App
import Button from '@shared/design/components/Button';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import CreateProjectClaimDrawer from 'modules/Project/Claim/components/CreateProjectClaimDrawer';
import ProjectClaims from 'modules/Project/Claim/components/ProjectClaims';
import MobileProjectBlockHeader from 'modules/Project/V2/Show/Blocks/components/MobileProjectBlockHeader';
import ProjectBlockWrapper from 'modules/Project/V2/Show/Blocks/components/ProjectBlockWrapper';

const BLOCK_TITLE = 'Claims';

const Row = Styled.View`
  flex-direction: row;
`;

const Column = Styled.View`
`;

const SkeletonRow = () => {
  return (
    <Row>
      <Column style={{flex: 2}}>
        <SkeletonLoader width={50} height={SkeletonLoader.HEIGHT.Text} />
      </Column>
      <Column style={{flex: 2}}>
        <SkeletonLoader width={40} height={SkeletonLoader.HEIGHT.Text} />
      </Column>
      <Column style={{flex: 2}}>
        <SkeletonLoader width={50} height={SkeletonLoader.HEIGHT.Text} />
      </Column>
      <Column style={{flex: 2}}>
        <SkeletonLoader width={75} height={SkeletonLoader.HEIGHT.Text} />
      </Column>
      <Column style={{flex: 3}}>
        <SkeletonLoader width={75} height={SkeletonLoader.HEIGHT.Text} />
      </Column>
    </Row>
  );
};

const SkeletonBody = () => {
  return (
    <Column>
      <SkeletonRow />
      <Space height={16} />
      <SkeletonRow />
      <Space height={16} />
      <SkeletonRow />
    </Column>
  );
};

const SkeletonComponent = () => {
  return (
    <ActionPanel
      BodyComponent={SkeletonBody}
      ActionButtonComponent={() => (
        <SkeletonLoader width={128} height={SkeletonLoader.HEIGHT.ButtonSmall} />
      )}
      title={BLOCK_TITLE}
      style={{width: '100%'}}
    />
  );
};

const ClaimActions = ({createClaimDrawer}) => {
  return (
    <Button
      iconLeft={Icon.Plus}
      text={'Create Claim'}
      isSmall
      onPress={createClaimDrawer.handleOpen}
    />
  );
};

const BodyComponent = ({project, refetch}) => {
  return <ProjectClaims project={project} refetch={refetch} />;
};

const ProjectClaimsBlockContent = ({project, refetch, createClaimDrawer}) => {
  return (
    <ActionPanel
      BodyComponent={BodyComponent}
      bodyComponentProps={{project, refetch}}
      ActionButtonComponent={ClaimActions}
      actionButtonComponentProps={{createClaimDrawer}}
      title={BLOCK_TITLE}
      style={{width: '100%'}}
      bodyStyle={{padding: 0}}
    />
  );
};

const CreateButton = ({createClaimDrawer}) => {
  return <TertiaryButton text={`Create`} onPress={createClaimDrawer.handleOpen} isResponsive />;
};

const ProjectClaimsBlockContentMobile = ({project, createClaimDrawer, refetch, urlFilters}) => {
  return (
    <React.Fragment>
      <MobileProjectBlockHeader
        title={BLOCK_TITLE}
        ActionsComponent={CreateButton}
        actionsComponentProps={{createClaimDrawer}}
      />
      <ProjectClaims.Mobile project={project} refetch={refetch} urlFilters={urlFilters} />
    </React.Fragment>
  );
};

const ProjectClaimsBlock = ({
  project,
  handleSetPositionY,
  layoutKey,
  index,
  urlFilters,
  projectBlockKind,
}) => {
  const responsive = useResponsive();
  const createClaimDrawer = useDrawer({name: 'Create Claim Drawer'});

  return (
    <ProjectBlockWrapper
      index={index}
      query={ProjectClaimsBlock.query}
      queryVariables={{
        projectUuid: project.uuid,
        isDesktop: responsive.desktop,
      }}
      layoutKey={layoutKey}
      handleSetPositionY={handleSetPositionY}
      SkeletonComponent={SkeletonComponent}
      projectBlockKind={projectBlockKind}
    >
      {({data, refetch}) => {
        const {project} = data;
        return (
          <React.Fragment>
            {responsive.desktop ? (
              <ProjectClaimsBlockContent
                key={project.claimAttachmentsHash}
                project={project}
                refetch={refetch}
                createClaimDrawer={createClaimDrawer}
              />
            ) : (
              <ProjectClaimsBlockContentMobile
                key={project.claimAttachmentsHash}
                project={project}
                createClaimDrawer={createClaimDrawer}
                refetch={refetch}
                urlFilters={urlFilters}
              />
            )}
            <CreateProjectClaimDrawer
              key={createClaimDrawer.key}
              isOpen={createClaimDrawer.isOpen}
              handleClose={createClaimDrawer.handleClose}
              project={project}
              onSuccess={() => {
                createClaimDrawer.handleClose();
                refetch();
              }}
            />
          </React.Fragment>
        );
      }}
    </ProjectBlockWrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectClaimsBlock.listener = gql`
  fragment ProjectClaimsBlock_listener on Project {
    id
    claimAttachmentsHash
  }
`;

ProjectClaimsBlock.fragment = gql`
  ${ProjectClaimsBlock.listener}
  fragment ProjectClaimsBlock on Project {
    id
    uuid
    ...ProjectClaimsBlock_listener
  }
`;

ProjectClaimsBlock.query = gql`
  ${CreateProjectClaimDrawer.fragment}
  ${ProjectClaimsBlock.listener}
  ${ProjectClaims.fragment}
  ${ProjectClaims.Mobile.fragment}

  query ProjectClaimsBlock($projectUuid: String!, $isDesktop: Boolean!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      ...CreateProjectClaimDrawer
      ...ProjectClaimsBlock_listener
      ...ProjectClaims @include(if: $isDesktop)
      ...ProjectClaims_Mobile @skip(if: $isDesktop)
    }
  }
`;

export default ProjectClaimsBlock;
