// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {BillingLibraryModel} from '@supermove/models';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import BillRuleTypeForm from '@shared/modules/Billing/forms/BillRuleTypeForm';
import useCreateBillRuleTypeMutation from '@shared/modules/Billing/hooks/useCreateBillRuleTypeMutation';
import BillRuleTypeFields from 'modules/Organization/Settings/BillingLibraries/components/BillRuleTypeFields';

const AddBillRuleTypeDrawerContent = ({
  billingLibrary,
  userId,
  refetch,
  isOpen,
  handleClose,
}: {
  billingLibrary: BillingLibraryModel;
  userId: string;
  refetch: () => void;
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const billRuleTypeForm = BillRuleTypeForm.new({
    billingLibraryId: billingLibrary.id,
    organizationId: billingLibrary.organizationId,
    userId,
  });
  const {form, submitting, handleSubmit} = useCreateBillRuleTypeMutation({
    billRuleTypeForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors: unknown) => console.log({errors}),
  });
  return (
    <DrawerWithAction
      isOpen={isOpen}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      headerText={'Add Bill Rule'}
      shouldCloseOnClickOutside={false}
      isUsingLoadingComponent
    >
      <BillRuleTypeFields form={form} field={'billRuleTypeForm'} billingLibrary={billingLibrary} />
    </DrawerWithAction>
  );
};

const AddBillRuleTypeDrawer = ({
  billingLibraryUuid,
  refetch,
  userId,
  isOpen,
  handleClose,
}: {
  billingLibraryUuid: string;
  refetch: () => void;
  userId: string;
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const {data, loading} = useQuery(AddBillRuleTypeDrawer.query, {
    fetchPolicy: 'cache-and-network',
    variables: {billingLibraryUuid},
    skip: !isOpen,
  });

  return (
    <Loading
      loading={!data || loading}
      as={() => {
        return (
          <DrawerWithAction.LoadingComponent
            isOpen={isOpen}
            headerText={'Add Bill Rule'}
            handleClose={handleClose}
          >
            <PageLoadingIndicator />
          </DrawerWithAction.LoadingComponent>
        );
      }}
    >
      {() => (
        <AddBillRuleTypeDrawerContent
          billingLibrary={data.billingLibrary}
          userId={userId}
          refetch={refetch}
          isOpen={isOpen}
          handleClose={handleClose}
        />
      )}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AddBillRuleTypeDrawer.query = gql`
  ${BillRuleTypeFields.fragment}
  query AddBillRuleTypeDrawer($billingLibraryUuid: String!) {
    ${gql.query}
    billingLibrary(billingLibraryUuid: $billingLibraryUuid) {
      id
      organizationId
      ...BillRuleTypeFields
    }
  }
`;

export default AddBillRuleTypeDrawer;
