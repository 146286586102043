// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled, Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, usePopover} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Table from '@shared/design/components/TableV2Deprecated';
import ActionMenuPopover from 'modules/App/components/ActionMenuPopover';
import DeleteClaimTypeModal from 'modules/Organization/Settings/Company/components/DeleteClaimTypeModal';
import UpdateClaimTypeModal from 'modules/Organization/Settings/Company/components/UpdateClaimTypeModal';

const ClaimTypesTableContainer = Styled.View`
  max-width: 780px;
`;

const ColorDot = Styled.View`
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background-color: ${({color}) => color};
`;

const EditClaimTypeButton = Styled.ButtonV2`
`;

const MoreActionsButton = Styled.ButtonV2`
  padding-horizontal: 6px;
`;

const sortClaimTypeByName = (claimTypes) => {
  return _.orderBy(
    claimTypes,
    [(claimType) => claimType.name.toLowerCase(), (claimType) => claimType.name],
    ['asc', 'desc'],
  );
};

const getClaimTypesColumnDefinitions = ({
  organization,
  refetch,
  isEnabledClaimsProjectOrganizationMigration,
}) => [
  {
    width: 50,
    headerContent: () => {
      return null;
    },
    cellContent: ({item: claimType}) => {
      return <ColorDot color={claimType.color} />;
    },
  },
  {
    flex: 2,
    headerContent: () => {
      return <Table.HeaderText>Name</Table.HeaderText>;
    },
    cellContent: ({item: claimType}) => {
      return <Table.CellText numberOfLines={1}>{claimType.name}</Table.CellText>;
    },
  },
  {
    flex: 4,
    headerContent: () => {
      return <Table.HeaderText>Description</Table.HeaderText>;
    },
    cellContent: ({item: claimType}) => {
      return (
        <Table.CellText numberOfLines={2}>
          {claimType.description ? claimType.description : ''}
        </Table.CellText>
      );
    },
  },
  {
    flex: 1,
    isHidden: !organization.isPrimary || !isEnabledClaimsProjectOrganizationMigration,
    headerContent: () => {
      return <Table.HeaderText>Actions</Table.HeaderText>;
    },
    cellContent: ({item: claimType}) => {
      return <ClaimTypeActions claimType={claimType} refetch={refetch} />;
    },
  },
];

const ClaimTypeActionsMenu = ({popover, removeClaimTypeModal}) => {
  return (
    <ActionMenuPopover popover={popover} placement={Popover.Positions.BottomStart} width={208}>
      <ActionMenuPopover.MenuItem
        onPress={() => {
          removeClaimTypeModal.handleOpen();
          popover.handleClose();
        }}
      >
        Remove
      </ActionMenuPopover.MenuItem>
    </ActionMenuPopover>
  );
};

const ClaimTypeActions = ({claimType, refetch}) => {
  const editClaimTypeModal = useModal({name: 'Edit Claim Type Modal'});
  const removeClaimTypeModal = useModal({name: 'Remove Claim Type Modal'});
  const claimTypeActionsPopover = usePopover();

  return (
    <React.Fragment>
      <EditClaimTypeButton onPress={editClaimTypeModal.handleOpen}>
        <Icon source={Icon.Pen} color={colors.blue.interactive} size={16} />
      </EditClaimTypeButton>
      <Space width={10} />
      <Popover.Content innerRef={claimTypeActionsPopover.ref}>
        <MoreActionsButton onPress={claimTypeActionsPopover.handleOpen}>
          <Icon source={Icon.EllipsisV} color={colors.gray.secondary} size={16} />
        </MoreActionsButton>
      </Popover.Content>
      <UpdateClaimTypeModal
        key={editClaimTypeModal.key}
        isOpen={editClaimTypeModal.isOpen}
        handleClose={editClaimTypeModal.handleClose}
        claimType={claimType}
        refetch={refetch}
      />
      <ClaimTypeActionsMenu
        popover={claimTypeActionsPopover}
        removeClaimTypeModal={removeClaimTypeModal}
      />
      <DeleteClaimTypeModal
        key={removeClaimTypeModal.key}
        claimType={claimType}
        refetch={refetch}
        removeClaimTypeModal={removeClaimTypeModal}
      />
    </React.Fragment>
  );
};

const ClaimTypesTable = ({organization, refetch, isEnabledClaimsProjectOrganizationMigration}) => {
  return (
    <ClaimTypesTableContainer>
      <Table
        columnDefinitions={getClaimTypesColumnDefinitions({
          organization,
          refetch,
          isEnabledClaimsProjectOrganizationMigration,
        })}
        emptyStateText={`Create a claim type and it'll appear here`}
        items={sortClaimTypeByName(organization.companySettings.claimTypes)}
      />
    </ClaimTypesTableContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ClaimTypesTable.fragment = gql`
  ${UpdateClaimTypeModal.fragment}
  ${DeleteClaimTypeModal.fragment}
  fragment ClaimTypesTable on Organization {
    id
    companySettings {
      claimTypes {
        id
        name
        color
        description
        ...UpdateClaimTypeModal
        ...DeleteClaimTypeModal
      }
    }
  }
`;

export default ClaimTypesTable;
