// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useCallback, useNavigationDOM, useState} from '@supermove/hooks';
import {Typography} from '@supermove/styles';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import ExploDashboard, {
  DashboardEvent,
  encodeBase64Url,
  EventType,
} from '@shared/modules/Reports/components/ExploDashboard';
import {OfficeHeaderBuilder} from 'modules/App/components/OfficeHeader';
import SidebarPage from 'modules/App/components/SidebarPage';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';
import ReportsNavigation from 'modules/Report/components/ReportsNavigation';

const Container = Styled.View`
  flex: 1;
`;

const EmptyStateContainer = Styled.View`
  flex: 1;
  align-items: center;
`;

const EmptyStateTitle = Styled.Text`
  ${Typography.Responsive.Subheading}
`;

interface DashboardContentProps {
  responsive: any;
  data: {
    viewer: {
      id: number;
      viewingOrganization: {
        id: number;
        features: {
          isEnabledReportsSaveCopy: boolean;
        };
      };
    };
    dashboard: {
      id: number;
      name: string;
      exploDashboardEmbedId: string;
      exploVariables: string;
      organization: {
        id: number;
        exploUserGroupToken: string;
      };
    };
  };
}

const ShowDashboardPage = () => {
  const {
    params: {uuid},
  } = useNavigationDOM();
  return (
    <>
      {/* @ts-expect-error */}
      <SidebarPage selected={'reports'} query={ShowDashboardPage.query} variables={{uuid}}>
        {({responsive, data}: DashboardContentProps) => (
          <DashboardContent responsive={responsive} data={data} />
        )}
      </SidebarPage>
    </>
  );
};

const DashboardContent = ({responsive, data}: DashboardContentProps) => {
  const {viewer, dashboard} = data;

  const [dashboardVariables, setDashboardVariables] = useState<Record<string, any>>(
    JSON.parse(dashboard.exploVariables || '{}'),
  );
  const onMessage = useCallback((event: DashboardEvent): void => {
    switch (event.event) {
      case EventType.SendVariableUpdated: {
        const {varName, newValue} = event.detail;

        if (typeof newValue === 'object' && 'startDate' in newValue && 'endDate' in newValue) {
          // Ignore changes to any date picker controls. Date pickers should always use the defaults
          // set on the Explo dashboard, because Explo only supports passing in absolute dates.
          //   https://docs.explo.co/creating-dashboards/ui-elements/controls/date
          return;
        }
        if (newValue === undefined || newValue === null) {
          setDashboardVariables((variables) => {
            delete variables[varName];
            return {...variables};
          });
          return;
        }

        setDashboardVariables((variables) => {
          return {
            ...variables,
            [varName]: newValue,
          };
        });
      }
    }
  }, []);

  // TODO(atsu): Implement saving using actual mutations, instead of this jank navigation into the
  //  settings page
  const searchParams = new URLSearchParams({
    name: `${dashboard.name} (Copy)`,
    exploDashboardEmbedId: dashboard.exploDashboardEmbedId,
    exploVariables: encodeDashboardVariables(dashboardVariables),
  });
  return (
    <Container>
      <StandardOfficeHeader title={'Reports'}>
        {viewer.viewingOrganization.features.isEnabledReportsSaveCopy ? (
          <OfficeHeaderBuilder.Content style={{alignItems: 'right'}}>
            <Space width={24} />
            <a href={`/settings/reports?${searchParams}#create`}>
              <SecondaryButton text={'Save a Copy'} iconLeft={Icon.Copy} />
            </a>
          </OfficeHeaderBuilder.Content>
        ) : (
          <></>
        )}
      </StandardOfficeHeader>
      <ReportsNavigation />
      {dashboard ? (
        <Container {...responsive}>
          <ExploDashboard
            viewerId={viewer.id}
            name={dashboard.name}
            exploDashboardEmbedId={dashboard.exploDashboardEmbedId}
            exploUserGroupToken={dashboard.organization.exploUserGroupToken}
            dashboardId={dashboard.id}
            exploVariables={dashboard.exploVariables}
            onMessage={onMessage}
          />
        </Container>
      ) : (
        <EmptyStateContainer>
          <Space height={100} />
          <EmptyStateTitle>Unable to find this dashboard</EmptyStateTitle>
        </EmptyStateContainer>
      )}
    </Container>
  );
};

const encodeDashboardVariables = (dashboardVariables: Record<string, any>): string => {
  if (Object.keys(dashboardVariables).length === 0) {
    return '';
  }
  return encodeBase64Url(JSON.stringify(dashboardVariables, null, 2));
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ShowDashboardPage.query = gql`
  query ShowDashboardPage($uuid: String!) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        features {
          isEnabledReportsSaveCopy: isEnabled(feature: "REPORTS_SAVE_COPY")
        }
      }
    }
    dashboard(dashboardUuid: $uuid) { 
      id
      name
      exploDashboardEmbedId
      exploVariables
      organization {
        id
        exploUserGroupToken
      }
    }
  }
`;

export default ShowDashboardPage;
