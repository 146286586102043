// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {BillModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

export interface ReorderBillFormType {
  billId: string;
  index: number;
}

const edit = withFragment(
  (bill: BillModel) => ({
    billId: bill.id,
    index: bill.index,
  }),
  gql`
    fragment ReorderBillForm_edit on Bill {
      id
      index
    }
  `,
);

const toForm = ({billId, index}: ReorderBillFormType) => ({
  billId,
  index,
});

const toMutation = ({billId, index}: ReorderBillFormType) => ({
  billId,
  index,
});

const ReorderBillForm = {
  edit,
  toForm,
  toMutation,
};

export default ReorderBillForm;
