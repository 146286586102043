// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {
  DateInput,
  MultiDropdownInput,
  Space,
  Styled,
  Popover,
  ScrollView,
} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Organization, ReferralSource} from '@supermove/models';
import {Typography} from '@supermove/styles';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import PopoverFilter from '@shared/modules/App/components/PopoverFilter';
import TagCategory from '@shared/modules/Tag/enums/TagCategory';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import OrganizationStaffDropdown from 'modules/Organization/components/OrganizationStaffDropdown';
import TagDropdownInputField from 'modules/Tag/components/TagDropdownInputField';

const ContentContainer = Styled.View`
  padding-horizontal: 16px;
`;

const PopoverContainer = Styled.Touchable`
`;

const Header = Styled.Text`
  ${Typography.Label1}
`;

const BodyText = Styled.Text`
  ${Typography.Body3}
  padding-top: 32px;
`;

const FilterContainer = Styled.View`
  flex-direction: row;
  zIndex: ${(props) => 1000 - props.index}
`;

const ProjectTagFilter = ({organization, form, index}) => {
  const filterByArchivedTag = organization.companySettings.tags.filter((tag) => !tag.isArchived);
  const projectTags = filterByArchivedTag.filter((tag) => tag.category === TagCategory.PROJECT);

  const tagOptions = projectTags
    ? projectTags.map((tag) => ({
        value: tag.id,
        label: `${tag.emoji} ${tag.name}`,
      }))
    : [];

  return (
    <TagDropdownInputField
      options={tagOptions}
      placeholder={'Select project tags'}
      label={'Project Tags'}
      index={index}
      value={form.values?.filteredProjectsForm?.projectTagIds}
      onChangeValue={(projectTagIds) => {
        form.setFieldValue('filteredProjectsForm.projectTagIds', projectTagIds);
      }}
    />
  );
};

const FollowUpStartDateFilter = ({name, form, index}) => {
  return (
    <FieldInput
      {...form}
      index={index}
      isResponsive
      component={DateInput}
      name={`${name}.followUpStartDate`}
      label={'Follow Up Start'}
      style={{
        zIndex: 100 - index,
        flex: 1,
      }}
      input={{
        placeholder: 'MM/DD/YYYY',
        setFieldValue: form.setFieldValue,
        style: {width: '100%'},
      }}
    />
  );
};

const FollowUpEndDateFilter = ({name, form, index}) => {
  return (
    <FieldInput
      {...form}
      index={index}
      isResponsive
      component={DateInput}
      name={`${name}.followUpEndDate`}
      label={'Follow Up End'}
      style={{
        zIndex: 100 - index,
        flex: 1,
      }}
      input={{
        position: 'bottom-end',
        placeholder: 'MM/DD/YYYY',
        setFieldValue: form.setFieldValue,
        style: {width: '100%'},
      }}
    />
  );
};

const FromDateFilter = ({name, form, index}) => {
  return (
    <FieldInput
      {...form}
      index={index}
      isResponsive
      component={DateInput}
      name={`${name}.fromDate`}
      label={'Start Date'}
      style={{
        zIndex: 100 - index,
        flex: 1,
      }}
      input={{
        placeholder: 'MM/DD/YYYY',
        setFieldValue: form.setFieldValue,
        style: {width: '100%'},
      }}
    />
  );
};

const ToDateFilter = ({name, form, index}) => {
  return (
    <FieldInput
      {...form}
      index={index}
      isResponsive
      component={DateInput}
      name={`${name}.toDate`}
      label={'End Date'}
      style={{
        zIndex: 100 - index,
        flex: 1,
      }}
      input={{
        position: 'bottom-end',
        placeholder: 'MM/DD/YYYY',
        setFieldValue: form.setFieldValue,
        style: {width: '100%'},
      }}
    />
  );
};

const ProjectTypeFilter = ({index, name, form, projectTypes}) => {
  return (
    <FieldInput
      {...form}
      component={MultiDropdownInput}
      name={`${name}.projectTypeIds`}
      isResponsive
      label={'Project Types'}
      style={{
        zIndex: 100 - index,
      }}
      input={{
        options: projectTypes.map((projectType) => ({
          label: projectType.name,
          value: projectType.id,
        })),
        placeholder: 'Select project types',
        setFieldValue: form.setFieldValue,
        style: {flex: 1},
      }}
    />
  );
};

const SalespeopleMultiselect = ({index, name, form, organization}) => {
  return (
    <OrganizationStaffDropdown
      organization={organization}
      form={form}
      field={`${name}.salespersonIds`}
      label={'Salesperson'}
      noneOptionLabel={'No salesperson'}
      placeholder={'Select salespeople'}
      isMultiSelect
      index={index}
      inputStyle={{flex: 1}}
      isSalesperson
      isResponsive
    />
  );
};

const CoordinatorMultiselect = ({index, name, form, organization}) => {
  return (
    <OrganizationStaffDropdown
      organization={organization}
      form={form}
      field={`${name}.coordinatorIds`}
      label={'Coordinators'}
      noneOptionLabel={'No coordinator'}
      placeholder={'Select coordinators'}
      isMultiSelect
      index={index}
      inputStyle={{flex: 1}}
      isCoordinator
      isResponsive
    />
  );
};

const ReferralSourceMultiselect = ({index, name, form, organization}) => {
  const {referralSources} = organization.settings;
  return (
    <FieldInput
      {...form}
      component={MultiDropdownInput}
      name={`${name}.referralSources`}
      isResponsive
      label={'Referral Sources'}
      style={{
        zIndex: 100 - index,
      }}
      input={{
        options: ReferralSource.getDropdownOptions({referralSources}),
        placeholder: 'Select referral sources',
        setFieldValue: form.setFieldValue,
        style: {flex: 1},
      }}
    />
  );
};

const LaborSourceMultiselect = ({index, name, form, organizations}) => {
  const getLaborSourceOptions = ({organizations}) => {
    const sortedOrganizationsByName = _.sortBy(organizations, ['name']);
    const laborSourceOptions = sortedOrganizationsByName.map((organization) => ({
      label: organization.name,
      value: organization.id,
    }));
    return [
      {
        label: 'No Labor Source',
        value: 'NO_CREW',
      },
      ...laborSourceOptions,
    ];
  };
  return (
    <FieldInput
      {...form}
      component={MultiDropdownInput}
      name={`${name}.crewOrganizationIds`}
      label={'Labor Sources'}
      isResponsive
      style={{
        zIndex: 100 - index,
      }}
      input={{
        options: getLaborSourceOptions({organizations}),
        placeholder: 'Select labor sources',
        setFieldValue: form.setFieldValue,
        style: {flex: 1},
      }}
    />
  );
};

const ProjectFilters = ({organization, form, isRestricted, field}) => {
  const {
    features: {isEnabledProjectDetailsFollowUp, isEnabledMovesListMultiBranchSupport},
    isPrimary,
    projectTypesForFilters,
  } = organization;

  const isLaborSourceFilterVisible = isEnabledMovesListMultiBranchSupport && isPrimary;

  return (
    <React.Fragment>
      {isEnabledProjectDetailsFollowUp && (
        <FilterContainer index={0}>
          <FollowUpStartDateFilter name={field} form={form} index={0} />
          <Space width={8} />
          <BodyText>-</BodyText>
          <Space width={8} />
          <FollowUpEndDateFilter name={field} form={form} index={1} />
        </FilterContainer>
      )}
      <Space height={8} />
      <FilterContainer index={1}>
        <FromDateFilter name={field} form={form} index={2} />
        <Space width={8} />
        <BodyText>-</BodyText>
        <Space width={8} />
        <ToDateFilter name={field} form={form} index={3} />
      </FilterContainer>
      <Space height={8} />
      <ProjectTypeFilter projectTypes={projectTypesForFilters} form={form} name={field} index={4} />
      <Space height={8} />
      {organization.features.isEnabledProjectTag && (
        <React.Fragment>
          <ProjectTagFilter organization={organization} form={form} name={field} index={5} />
          <Space height={8} />
        </React.Fragment>
      )}
      {!isRestricted && (
        <React.Fragment>
          <SalespeopleMultiselect organization={organization} form={form} name={field} index={6} />
          <Space height={8} />
        </React.Fragment>
      )}
      <CoordinatorMultiselect organization={organization} form={form} name={field} index={7} />
      {isLaborSourceFilterVisible && (
        <React.Fragment>
          <Space height={8} />
          <LaborSourceMultiselect
            organizations={organization.company.organizations}
            form={form}
            name={field}
            index={8}
          />
        </React.Fragment>
      )}
      <Space height={8} />
      <ReferralSourceMultiselect organization={organization} form={form} name={field} index={9} />
      <Space height={16} />
    </React.Fragment>
  );
};

const ProjectFilterPopoverContentV1 = ({organization, form, isRestricted, viewerId}) => {
  const field = 'filteredProjectsForm';

  return (
    <ResponsivePopover.StaticContainer width={320} maxHeight={580}>
      <ScrollView style={{maxHeight: 580}}>
        <ContentContainer>
          <Space height={16} />
          <Header>Filter</Header>
          <Space height={8} />
          <ProjectFilters
            organization={organization}
            form={form}
            field={field}
            isRestricted={isRestricted}
            viewerId={viewerId}
          />
          <SecondaryButton
            text={'Clear Filters'}
            onPress={() => {
              form.setFieldValue(`${field}.salespersonIds`, isRestricted ? [viewerId] : null);
              form.setFieldValue(`${field}.followUpStartDate`, null);
              form.setFieldValue(`${field}.followUpEndDate`, null);
              form.setFieldValue(`${field}.fromDate`, null);
              form.setFieldValue(`${field}.toDate`, null);
              form.setFieldValue(`${field}.coordinatorIds`, null);
              form.setFieldValue(`${field}.projectTypeIds`, null);
              form.setFieldValue(`${field}.crewOrganizationIds`, null);
              form.setFieldValue(`${field}.projectTagIds`, null);
              form.setFieldValue(`${field}.referralSources`, null);
            }}
          />
          <Space height={16} />
        </ContentContainer>
      </ScrollView>
    </ResponsivePopover.StaticContainer>
  );
};

const ProjectFilterPopoverContent = ({organization, form, isRestricted, viewerId, responsive}) => {
  const field = 'filteredProjectsForm';

  return (
    <ContentContainer style={{paddingHorizontal: responsive.desktop ? null : 16}}>
      <ProjectFilters
        organization={organization}
        form={form}
        field={field}
        isRestricted={isRestricted}
        viewerId={viewerId}
      />
    </ContentContainer>
  );
};

const ListProjectsPageFiltersPopover = ({
  activeFilterCount,
  popover,
  organization,
  form,
  isRestricted,
  viewerId,
}) => {
  const responsive = useResponsive();
  if (organization.features.isEnabledFilterPopoverV2) {
    const field = 'filteredProjectsForm';
    return (
      <PopoverFilter
        activeFiltersCount={activeFilterCount}
        hideActionButtons
        popover={popover}
        isScrolling
        responsive={responsive}
        handleApply={popover.handleClose}
        handleClear={() => {
          form.setFieldValue('filteredProjectsForm.salespersonIds', isRestricted ? [viewerId] : []);
          form.setFieldValue(`${field}.followUpStartDate`, null);
          form.setFieldValue(`${field}.followUpEndDate`, null);
          form.setFieldValue(`${field}.fromDate`, null);
          form.setFieldValue(`${field}.toDate`, null);
          form.setFieldValue(`${field}.coordinatorIds`, []);
          form.setFieldValue(`${field}.projectTypeIds`, null);
          form.setFieldValue(`${field}.crewOrganizationIds`, null);
          form.setFieldValue(`${field}.projectTagIds`, null);
          form.setFieldValue(`${field}.referralSources`, null);
        }}
      >
        <ProjectFilterPopoverContent
          organization={organization}
          form={form}
          isRestricted={isRestricted}
          viewerId={viewerId}
          responsive={responsive}
        />
      </PopoverFilter>
    );
  }
  return (
    <PopoverContainer>
      <Popover
        placement={Popover.Positions.BottomStart}
        isOpen={popover.isOpen}
        handleOpen={popover.handleOpen}
        handleClose={popover.handleClose}
        reference={popover.ref}
        offset={[0, 4]}
      >
        <ProjectFilterPopoverContentV1
          organization={organization}
          form={form}
          isRestricted={isRestricted}
          viewerId={viewerId}
        />
      </Popover>
    </PopoverContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ListProjectsPageFiltersPopover.fragment = gql`
  ${Organization.getCoordinatorOptions.fragment}
  ${OrganizationStaffDropdown.fragment}
  ${ReferralSource.getDropdownOptions.fragment}
  ${TagDropdownInputField.fragment}

  fragment ListProjectsPageFiltersPopover on Organization {
    id
    isPrimary
    features {
      isEnabledProjectDetailsFollowUp: isEnabled(feature: "PROJECT_DETAILS_FOLLOW_UP")
      isEnabledShowSalespersonsFromAllOrganizations: isEnabled(
        feature: "SHOW_SALESPERSONS_FROM_ALL_ORGANIZATIONS"
      )
      isEnabledMovesListMultiBranchSupport: isEnabled(feature: "MOVES_LIST_MULTI_BRANCH_SUPPORT")
      isEnabledProjectTag: isEnabled(feature: "PROJECT_TAG")
      isEnabledFilterPopoverV2: isEnabled(feature: "FILTER_POPOVER_V_2")
    }
    company {
      id
      organizations {
        id
        name
      }
    }
    projectTypesForFilters(category: "MOVE") {
      id
      name
    }
    companySettings {
      tags {
        id
        name
        emoji
        isArchived
        category
        ...TagDropdownInputField
      }
    }
    settings {
      id
      referralSources {
        ...ReferralSource_getDropdownOptions
      }
    }
    ...Organization_getCoordinatorOptions
    ...OrganizationStaffDropdown
  }
`;

export default ListProjectsPageFiltersPopover;
