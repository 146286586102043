// Libraries
import _ from 'lodash';
import React, {Key} from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {ResponsiveType, useDrawer, useModal, useResponsive} from '@supermove/hooks';
import {
  BillingLibraryModel,
  BillRuleType,
  BillRuleTypeModel,
  OrganizationModel,
} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import EmptyState from '@shared/design/components/EmptyState';
import Table from '@shared/design/components/Table';
import {
  ColumnDefinitionType,
  ItemType,
} from '@shared/design/components/Table/components/TableBuilder';
import TextTooltip from '@shared/design/components/TextTooltip';
import BillRuleKind from '@shared/modules/Billing/enums/BillRuleKind';
import DeleteBillRuleTypeModal from 'modules/Organization/Settings/BillingLibraries/components/DeleteBillRuleTypeModal';
import EditBillItemTypeDrawer from 'modules/Organization/Settings/BillingLibraries/components/EditBillItemTypeDrawer';
import EditBillRuleTypeDrawer from 'modules/Organization/Settings/BillingLibraries/components/EditBillRuleTypeDrawer';

interface FormulaTextTagProps {
  vars: {
    hasFormula: boolean;
  };
  color?: string;
}

const FormulaTextTag = Styled.Text<FormulaTextTagProps>`
  ${({vars = {}}) => (vars.hasFormula ? Typography.Responsive.Label : Typography.Responsive.Body)}
  color: ${({vars = {}, color}) => (vars.hasFormula ? colors.orange.status : color || colors.gray.primary)};
`;

const CenterCell = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Button = Styled.ButtonV2`
  cursor: pointer;
`;

const LinkText = Styled.Text`
  ${Typography.Responsive.Link}
`;

interface FormulaTextProps extends FormulaTextTagProps {
  children: React.ReactNode;
  tooltipContent?: string;
}

const FormulaText = ({children, color, vars, tooltipContent}: FormulaTextProps) => {
  const responsive = useResponsive();
  return (
    <TextTooltip text={tooltipContent}>
      <FormulaTextTag responsive={responsive} vars={vars} color={color}>
        {children}
      </FormulaTextTag>
    </TextTooltip>
  );
};

const ConditionalBillItemValue = ({
  billRuleType,
  refetch,
  userId,
  organization,
}: {
  billRuleType: BillRuleTypeModel;
  refetch: () => void;
  userId: string;
  organization: OrganizationModel;
}) => {
  const responsive = useResponsive();
  const editBillItemTypeDrawer = useDrawer({name: 'Edit Bill Item Type Drawer'});
  return (
    <React.Fragment>
      <Button onPress={editBillItemTypeDrawer.handleOpen}>
        <LinkText responsive={responsive}>{billRuleType.billItemType?.name}</LinkText>
      </Button>
      <EditBillItemTypeDrawer
        key={editBillItemTypeDrawer.key}
        isOpen={editBillItemTypeDrawer.isOpen}
        handleClose={editBillItemTypeDrawer.handleClose}
        billItemType={billRuleType.billItemType}
        refetch={refetch}
        userId={userId}
        organization={organization}
        showGlobalEditWarning
      />
    </React.Fragment>
  );
};

const getColumnDefinitions = ({
  userId,
  responsive,
  refetch,
  organization,
}: {
  userId: string;
  responsive: ResponsiveType;
  refetch: () => void;
  organization: OrganizationModel;
}): ColumnDefinitionType[] => [
  {
    flex: 1,
    headerLabel: 'Kind',
    cellText: (billRuleType) => BillRuleType.getDisplayKind(billRuleType),
  },
  {
    flex: 3,
    headerLabel: 'Name',
    cellComponent: (item) => {
      const billRuleType = item as BillRuleTypeModel;
      return (
        <FormulaText
          vars={{
            hasFormula: BillRuleType.hasNameFormula(billRuleType),
          }}
          tooltipContent={BillRuleType.renderNameFormulaString(billRuleType)}
        >
          {billRuleType.name}
        </FormulaText>
      );
    },
    mobileOptions: {
      isInHeader: true,
    },
    secondary: {
      headerLabel: 'Description',
      cellComponent: (item) => {
        const billRuleType = item as BillRuleTypeModel;
        const {description} = billRuleType;
        return description ? (
          <FormulaText
            vars={{
              hasFormula: BillRuleType.hasDescriptionFormula(billRuleType),
            }}
            color={colors.gray.secondary}
            tooltipContent={BillRuleType.renderDescriptionFormulaString(billRuleType)}
          >
            {description}
          </FormulaText>
        ) : null;
      },
    },
  },
  {
    flex: 2,
    headerLabel: 'Value',
    cellComponent: (item) => {
      const billRuleType = item as BillRuleTypeModel;
      const isConditionalBillItem = billRuleType.kind === BillRuleKind.CONDITIONAL_BILL_ITEM;
      const value = BillRuleType.getDisplayValue({...billRuleType, showValueFormulaName: true});
      if (isConditionalBillItem) {
        return (
          <ConditionalBillItemValue
            billRuleType={billRuleType}
            refetch={refetch}
            userId={userId}
            organization={organization}
          />
        );
      }

      return value ? (
        <FormulaText
          vars={{
            hasFormula: BillRuleType.hasValueFormula(billRuleType),
          }}
          tooltipContent={BillRuleType.renderValueFormulaString(billRuleType)}
        >
          {value}
        </FormulaText>
      ) : null;
    },
  },
  {
    flex: 1,
    headerLabel: 'Show Customers?',
    cellComponent: (item) => {
      const billRuleType = item as BillRuleTypeModel;
      return billRuleType.isVisibleToCustomer ? (
        <CenterCell style={{alignItems: responsive.desktop ? 'center' : 'flex-start'}}>
          <Icon source={Icon.Check} size={16} color={colors.green.status} />
        </CenterCell>
      ) : null;
    },
  },
  {
    flex: 1,
    headerLabel: 'Last Updated',
    cellText: (billRuleType) => BillRuleType.getDisplayUpdatedAt(billRuleType),
    secondary: {
      cellText: (item) => {
        const billRuleType = item as unknown as BillRuleTypeModel;
        return billRuleType.updatedBy ? `By ${billRuleType.updatedBy.fullName}` : '';
      },
    },
  },
  {
    minWidth: 60,
    headerLabel: 'Actions',
    actions: (item) => {
      const billRuleType = item as unknown as BillRuleTypeModel;
      return [
        {
          text: 'Edit',
          desktopIcon: Icon.Pen,
          onPress: ({handleOpen}: {handleOpen?: () => void}) => {
            handleOpen && handleOpen();
          },
          actionHook: {
            hook: useDrawer as (arg: Record<string, any>) => Record<string, any>,
            hookArgument: {name: 'Edit Bill Rule Type Drawer'},
            renderComponent: ({isOpen, handleClose}) => {
              return (
                <EditBillRuleTypeDrawer
                  key={isOpen as unknown as Key}
                  isOpen={isOpen}
                  handleClose={handleClose}
                  billRuleTypeId={_.toNumber(billRuleType.id)}
                  userId={userId}
                  refetch={refetch}
                />
              );
            },
          },
        },
        {
          text: 'Remove',
          onPress: ({handleOpen}: {handleOpen?: () => void}) => {
            handleOpen && handleOpen();
          },
          actionHook: {
            hook: useModal,
            hookArgument: {name: 'Delete Bill Rule Type Modal'},
            renderComponent: ({isOpen, handleClose}) => {
              return (
                <DeleteBillRuleTypeModal
                  key={isOpen as unknown as Key}
                  isOpen={isOpen}
                  handleClose={handleClose}
                  billRuleTypeId={billRuleType.id}
                  refetch={refetch}
                />
              );
            },
          },
        },
      ];
    },
  },
];

const EmptyTable = () => {
  return (
    <EmptyState title={'No bill rules yes.'} message={'To get started, create a bill rule.'} />
  );
};

const BillRuleTypesTable = ({
  billingLibrary,
  refetch,
  userId,
}: {
  billingLibrary: BillingLibraryModel;
  refetch: () => void;
  userId: string;
}) => {
  const responsive = useResponsive();
  return (
    <Table
      columnDefinitions={getColumnDefinitions({
        userId,
        responsive,
        refetch,
        organization: billingLibrary.organization,
      })}
      EmptyStateComponent={EmptyTable}
      // HACK(jholston): items expects an ItemType[]
      items={
        _.orderBy(billingLibrary.billRuleTypes, ['updatedAt'], ['desc']) as unknown as ItemType[]
      }
      itemKey={'id'}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillRuleTypesTable.fragment = gql`
  ${BillRuleType.getDisplayKind.fragment}
  ${BillRuleType.getDisplayValue.fragment}
  ${BillRuleType.getDisplayUpdatedAt.fragment}
  ${BillRuleType.hasDescriptionFormula.fragment}
  ${BillRuleType.hasNameFormula.fragment}
  ${BillRuleType.hasValueFormula.fragment}
  ${BillRuleType.renderDescriptionFormulaString.fragment}
  ${BillRuleType.renderNameFormulaString.fragment}
  ${BillRuleType.renderValueFormulaString.fragment}
  ${EditBillItemTypeDrawer.fragment}
  fragment BillRuleTypesTable on BillingLibrary {
    id
    name
    organization {
      id
      ...EditBillItemTypeDrawer_Organization
    }
    billRuleTypes {
      id
      name
      description
      isVisibleToCustomer
      updatedAt
      updatedBy {
        id
        fullName
      }
      billItemType {
        id
        name
        ...EditBillItemTypeDrawer
      }
      ...BillRuleType_getDisplayKind
      ...BillRuleType_getDisplayValue
      ...BillRuleType_getDisplayUpdatedAt
      ...BillRuleType_hasDescriptionFormula
      ...BillRuleType_hasNameFormula
      ...BillRuleType_hasValueFormula
      ...BillRuleType_renderDescriptionFormulaString
      ...BillRuleType_renderNameFormulaString
      ...BillRuleType_renderValueFormulaString
    }
  }
`;

export default BillRuleTypesTable;
