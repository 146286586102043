// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Link, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';
import {Project} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';
import {Datetime, pluralize} from '@supermove/utils';

// App
import PhoneNumber from '@shared/design/components/PhoneNumber';
import Table from '@shared/design/components/TableV2Deprecated';
import TextTooltip from '@shared/design/components/TextTooltip';
import EditStorageProjectStatusDropdown from 'modules/Storage/components/EditStorageProjectStatusDropdown';

const Container = Styled.View`
  background-color: ${colors.white};
  flex: 1;
`;

const CellContainer = Styled.View`
  flex: 1;
`;

const HeaderTitle = Styled.Text`
  ${Typography.Label3}
`;

const CellPrimaryText = Styled.Text`
  ${Typography.Body3}
`;

const CellSecondaryText = Styled.Text`
  ${Typography.Body3};
  color: ${colors.gray.tertiary}
`;

const LinkText = Styled.Text`
  ${Typography.Link2};
  color: ${colors.blue.interactive};
  text-decoration: underline;
  text-decoration-color: ${colors.blue.interactive};
`;

const ProjectTypeColorIndicator = Styled.Text`
  color: ${({color}) => color};
`;

const LinkWrapper = Styled.ButtonV2`
`;

/**
 * Define columns, including:
 * title, key, flex ratio, and functions
 * for extracting primary and secondary data for each cell
 */
const getStorageProjectsListColumns = ({status, refetch, canShowBranch}) => {
  return [
    {
      flex: 3,
      headerContent: () => {
        return <HeaderTitle>Status</HeaderTitle>;
      },
      cellContent: ({item: project}) => {
        return (
          <CellPrimaryText data-test-id='storage-projects-list-status'>
            <EditStorageProjectStatusDropdown
              project={project}
              onSuccess={() => {
                refetch();
              }}
            />
          </CellPrimaryText>
        );
      },
    },
    {
      flex: 2,
      minWidth: 110,
      headerContent: () => {
        return <HeaderTitle>Project Name</HeaderTitle>;
      },
      cellContent: ({item: project}) => {
        return (
          <CellContainer>
            <CellPrimaryText>{Project.getName(project)}</CellPrimaryText>
          </CellContainer>
        );
      },
    },
    {
      flex: 3,
      headerContent: () => {
        return <HeaderTitle>Customer Name</HeaderTitle>;
      },
      cellContent: ({item: project}) => {
        const hasSeparateContactName =
          project.client.name !== project.client.primaryContact.fullName;

        return (
          <CellContainer>
            <LinkWrapper>
              <Link to={`/clients/${project.client.uuid}`}>
                <LinkText numberOfLines={1}>{project.client.name}</LinkText>
              </Link>
            </LinkWrapper>
            <CellPrimaryText numberOfLines={1}>
              {hasSeparateContactName ? project.client.primaryContact.fullName : ''}
            </CellPrimaryText>
          </CellContainer>
        );
      },
    },
    {
      flex: 3,
      headerContent: () => {
        return <HeaderTitle>Contact</HeaderTitle>;
      },
      cellContent: ({item: project}) => {
        return (
          <CellContainer>
            {project.client.primaryContact.phoneNumber ? (
              <PhoneNumber isClickable phoneNumber={project.client.primaryContact.phoneNumber} />
            ) : (
              <CellPrimaryText numberOfLines={1}>--</CellPrimaryText>
            )}
            <CellSecondaryText numberOfLines={1}>
              {project.client.primaryContact.email ? project.client.primaryContact.email : ''}
            </CellSecondaryText>
          </CellContainer>
        );
      },
    },
    {
      flex: 3,
      headerContent: () => {
        return (
          <CellContainer>
            <HeaderTitle>Project Type</HeaderTitle>
            {canShowBranch && <Table.HeaderSubtext>Branch</Table.HeaderSubtext>}
          </CellContainer>
        );
      },
      cellContent: ({item: project}) => {
        return (
          <CellContainer>
            <CellPrimaryText numberOfLines={1}>
              <ProjectTypeColorIndicator color={project.projectType.color}>
                •
              </ProjectTypeColorIndicator>
              <Space width={7} />
              {project.projectType.name}
            </CellPrimaryText>
            {canShowBranch && (
              <CellSecondaryText numberOfLines={1}>{project.organization.name}</CellSecondaryText>
            )}
          </CellContainer>
        );
      },
    },
    {
      flex: 3,
      headerContent: () => {
        return (
          <CellContainer>
            <HeaderTitle>Weight</HeaderTitle>
          </CellContainer>
        );
      },
      cellContent: ({item: project}) => {
        return (
          <CellContainer>
            <CellPrimaryText numberOfLines={1}>
              {project.weight ? pluralize('lb', project.weight, true) : '--'}
            </CellPrimaryText>
          </CellContainer>
        );
      },
    },
    {
      flex: 3,
      headerContent: () => {
        return (
          <CellContainer>
            <HeaderTitle>Dates</HeaderTitle>
            <Table.HeaderSubtext>(Date In - Date Out)</Table.HeaderSubtext>
          </CellContainer>
        );
      },
      cellContent: ({item: project}) => {
        const {startDate, endDate} = project;
        if (!startDate && !endDate) {
          return <CellPrimaryText>--</CellPrimaryText>;
        }

        const displayStartDate = Datetime.convertToDisplayDate(
          startDate,
          Datetime.DISPLAY_SHORT_DATE,
        );
        const displayEndDate = Datetime.convertToDisplayDate(endDate, Datetime.DISPLAY_SHORT_DATE);

        if (startDate === endDate) {
          return <CellPrimaryText>{displayStartDate}</CellPrimaryText>;
        } else if (!endDate) {
          return <CellPrimaryText>{`${displayStartDate} - None`}</CellPrimaryText>;
        } else if (!startDate) {
          return <CellPrimaryText>{`None - ${displayEndDate}`}</CellPrimaryText>;
        } else {
          return <CellPrimaryText>{`${displayStartDate} - ${displayEndDate}`}</CellPrimaryText>;
        }
      },
    },
    {
      flex: 3,
      headerContent: () => {
        return (
          <CellContainer>
            <HeaderTitle numberOfLines={2}>Salesperson Coordinator</HeaderTitle>
          </CellContainer>
        );
      },
      cellContent: ({item: project}) => {
        return (
          <CellContainer>
            <CellPrimaryText numberOfLines={1}>
              {project.bookedBy ? project.bookedBy.fullName : '--'}
            </CellPrimaryText>
            <CellSecondaryText numberOfLines={1}>
              {project.coordinatedBy ? project.coordinatedBy.fullName : '--'}
            </CellSecondaryText>
          </CellContainer>
        );
      },
    },
    {
      flex: 3,
      headerContent: () => {
        return (
          <CellContainer>
            <Table.HeaderText>Referral Source</Table.HeaderText>
            <Table.HeaderSubtext>Referral Details</Table.HeaderSubtext>
          </CellContainer>
        );
      },
      cellContent: ({item: project}) => {
        const {referralSource, referralDetails} = project;
        const hasReferralSource = !!referralSource;
        const hasReferralDetails = !!referralDetails;
        const isTooltipVisible = hasReferralSource || hasReferralDetails;
        const tooltipText = `${referralSource || ''}${
          hasReferralSource && hasReferralDetails ? '\n\n' : ''
        }${referralDetails || ''}`;
        return (
          <TextTooltip text={isTooltipVisible ? tooltipText : null}>
            <CellContainer>
              <CellPrimaryText numberOfLines={1}>{referralSource || '--'}</CellPrimaryText>
              <CellSecondaryText numberOfLines={1}>{referralDetails || '--'}</CellSecondaryText>
            </CellContainer>
          </TextTooltip>
        );
      },
    },
  ];
};

const StorageProjectsList = ({status, loading, refetch, projects, hasError, canShowBranch}) => {
  const {navigator} = useNavigationDOM();

  return (
    <Container>
      <Table.FixedHeaderScroll
        columnDefinitions={getStorageProjectsListColumns({
          status,
          refetch,
          canShowBranch,
        })}
        items={projects}
        emptyStateText={'There are no storage projects that match these filters'}
        loading={loading}
        isClickable
        onRowPress={(project) => navigator.push(`/storage/projects/${project.uuid}`)}
        hasError={hasError}
        rowStyle={{overflowY: 'visible', overflowX: 'visible'}}
        containerStyle={{borderBottomWidth: 1}}
        headerStyle={{
          borderTopWidth: 0,
          borderLeftWidth: 0,
          borderRightWidth: 0,
          borderBottomWidth: 1,
          borderBottomColor: colors.gray.border,
        }}
      />
    </Container>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
StorageProjectsList.propTypes = {
  loading: PropTypes.bool,
  projects: PropTypes.array.isRequired,
};

StorageProjectsList.defaultProps = {
  loading: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
StorageProjectsList.fragment = gql`
  ${Project.getName.fragment}
  fragment StorageProjectsList on Project {
    id
    uuid
    status
    startDate
    endDate
    weight
    referralSource
    referralDetails
    client {
      id
      uuid
      name
      primaryContact {
        id
        fullName
        phoneNumber
        email
      }
    }
    organization {
      id
      name
    }
    bookedBy {
      id
      fullName
    }
    coordinatedBy {
      id
      fullName
    }
    projectType {
      id
      name
      color
    }
    ...Project_getName
  }
`;

export default StorageProjectsList;
