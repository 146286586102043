// Supermove
import {colors} from '@supermove/styles';

const OFFICE = 'OFFICE';
const CREW = 'CREW';
const SALES = 'SALES';

const LABELS: Record<string, string> = {
  [OFFICE]: 'Office App',
  [CREW]: 'Crew App',
  [SALES]: 'Sales Copilot',
};

const SORT_ORDER = [OFFICE, CREW, SALES];

const SupermoveProductKind = {
  OFFICE,
  CREW,
  SALES,
  SORT_ORDER,

  getLabel: (supermoveProductKind: string): string => {
    return LABELS[supermoveProductKind] || supermoveProductKind;
  },
  getColor: (supermoveProductKind: string): string => {
    switch (supermoveProductKind) {
      case OFFICE:
        return colors.orange.status;
      case SALES:
        return colors.green.status;
      default:
        return colors.blue.interactive;
    }
  },
};

export default SupermoveProductKind;
