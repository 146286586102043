// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigationDOM, useResponsive} from '@supermove/hooks';
import {Bill} from '@supermove/models';
import {fontWeight, colors, Typography} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import BillingProjectDeleteBillModal from 'modules/Project/Billing/components/BillingProjectDeleteBillModal';
import BillsListItemBillRulesList from 'modules/Project/Billing/components/BillsListItemBillRulesList';
import BillsListItemHeaderV1 from 'modules/Project/Billing/components/BillsListItemHeaderV1';
import BillsListItemPreSubtotalBillItemsListV1 from 'modules/Project/Billing/components/BillsListItemPreSubtotalBillItemsListV1';
import BillsListItemSubtotalSectionV1 from 'modules/Project/Billing/components/BillsListItemSubtotalSectionV1';
import EditBillLineItemCells from 'modules/Project/Billing/components/EditBillLineItemCells';

const Row = Styled.View`
  flex-direction: row;
`;

const SplitRow = Styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

const Line = Styled.View`
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
  border-style: ${(props) => (props.dashed ? 'dashed' : 'solid')};
`;

const BillsListItemContainer = Styled.View`
  flex-direction: row;
  border-color: ${colors.blue.interactive};
`;

const BillInformationContainer = Styled.View`
  padding: ${(props) => (props.isEditable ? '12px' : '0px')};
  background-color: ${(props) =>
    props.isHighlighted ? 'rgba(116, 185, 255, 0.02)' : 'transparent'};
  border-color: ${(props) => (props.isHighlighted ? colors.blue.interactive : colors.gray.border)};
  border-width: ${(props) => (props.isEditable ? '1px' : '0px')};
  border-radius: 8px;
  flex: 1;
`;

const BillDataContainer = Styled.View`
  align-items: flex-end;
  flex: ${(props) => props.flex};
`;

const AlignRightColumn = Styled.View`
  flex: 8;
  align-items: flex-end;
`;

const BillTotalText = Styled.Text`
  ${Typography.Label2}
  color: ${(props) => props.color};
`;

const BillTotalTextMobile = Styled.Text`
  ${Typography.Label2}
  color: ${colors.blue.accentDark};
  ${fontWeight(900)}
`;

const ActionButton = Styled.ButtonV2`
  height: 36px;
  padding-horizontal: 12px;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 4px;
  flex-direction: row;
  align-self: flex-end;
  background-color: ${colors.white};
`;

const ActionButtonText = Styled.Text`
  ${Typography.Label1}
  color: ${colors.blue.accentDark};
`;

const handleChangeOrder = ({form, field, fromIndex, toIndex, handleSubmit}) => {
  const billForms = _.get(form.values, field);
  const reorderedBillForms = List.move({list: billForms, fromIndex, toIndex});
  reorderedBillForms.forEach((billForm, index) => {
    billForm.index = index;
  });
  form.setFieldValue(field, reorderedBillForms);

  // The setTimeout ensures that form.setFieldValue completes before running handleSubmit
  setTimeout(() => handleSubmit(), 0);
};

const ReorderButtonsColumn = ({form, field, index, handleSubmit}) => {
  const isFirstItem = index === 0;
  const isLastItem = index === _.get(form.values, field).length - 1;
  const handlePress = ({fromIndex, toIndex}) => {
    handleChangeOrder({form, field, fromIndex, toIndex, handleSubmit});
  };

  return (
    <EditBillLineItemCells.IconCell justify={'center'}>
      <EditBillLineItemCells.IconCircle
        activeOpacity={0.8}
        disabled={isFirstItem}
        onPress={() => handlePress({fromIndex: index, toIndex: index - 1})}
      >
        <Icon
          source={Icon.ArrowUp}
          size={12}
          color={isFirstItem ? colors.gray.border : colors.blue.interactive}
        />
      </EditBillLineItemCells.IconCircle>
      <Space height={2} />
      <EditBillLineItemCells.IconCircle
        activeOpacity={0.8}
        disabled={isLastItem}
        onPress={() => handlePress({fromIndex: index, toIndex: index + 1})}
      >
        <Icon
          source={Icon.ArrowDown}
          size={12}
          color={isLastItem ? colors.gray.border : colors.blue.interactive}
        />
      </EditBillLineItemCells.IconCircle>
    </EditBillLineItemCells.IconCell>
  );
};

const BillTotal = ({bill}) => {
  const responsive = useResponsive();
  const billTotal = bill.isEstimateAvailable ? Bill.getEstimateTotal(bill) : 'TBD';
  if (responsive.mobile) {
    return (
      <SplitRow>
        <BillTotalTextMobile>Total</BillTotalTextMobile>
        <BillTotalTextMobile>{billTotal}</BillTotalTextMobile>
      </SplitRow>
    );
  }
  return (
    <Row>
      <AlignRightColumn>
        <BillTotalText color={colors.gray.primary}>{`Total${
          bill.title ? ` ${bill.title}` : ''
        }`}</BillTotalText>
      </AlignRightColumn>
      <BillDataContainer flex={3}>
        <BillTotalText color={colors.gray.primary}>{billTotal}</BillTotalText>
      </BillDataContainer>
    </Row>
  );
};

const BillsListItemV1 = ({
  index,
  bill,
  isDocument,
  isEditable,
  isHighlighted,
  isReordering,
  showQuantity,
  showTotal,
  showBillRules,
  showEmptyTotals,
  refetch,
  reorderBillsForm,
  handleSubmit,
  descriptionField,
  getTruncatedKey,
  truncated,
  setTruncated,
  enabledToggle,
  setEnabledToggle,
  isEnabledBillItemDescriptionOnMobile,
}) => {
  const {navigator} = useNavigationDOM();
  const hasTip = bill.tip > 0;
  const hasSalesTax = bill.estimateSalesTaxAmountMin > 0 || bill.estimateSalesTaxAmountMax > 0;
  const showSubtotal = bill.hasBillModifiers || hasTip || hasSalesTax;
  const responsive = useResponsive();
  const deleteBillModal = useModal({name: 'Delete Bill Modal'});

  return (
    <React.Fragment>
      <BillsListItemContainer>
        {isReordering && (
          <React.Fragment>
            <ReorderButtonsColumn
              form={reorderBillsForm}
              field={'reorderBillsForm.reorderBillForms'}
              index={index}
              handleSubmit={handleSubmit}
            />
            <Space width={8} />
          </React.Fragment>
        )}
        <BillInformationContainer isEditable={isEditable} isHighlighted={isHighlighted}>
          <BillsListItemHeaderV1
            bill={bill}
            refetch={refetch}
            isEditable={isEditable}
            isHighlighted={isHighlighted}
          />
          <Space height={8} />
          <Line />
          <Space height={8} />
          <BillsListItemPreSubtotalBillItemsListV1
            bill={bill}
            isDocument={isDocument}
            isEditable={isEditable}
            isHighlighted={isHighlighted}
            showQuantity={showQuantity}
            showTotal={showTotal}
            descriptionField={descriptionField}
            getTruncatedKey={getTruncatedKey}
            truncated={truncated}
            setTruncated={setTruncated}
            enabledToggle={enabledToggle}
            setEnabledToggle={setEnabledToggle}
            isEnabledBillItemDescriptionOnMobile={isEnabledBillItemDescriptionOnMobile}
          />
          <Space height={8} />
          <Line dashed={isEditable} />
          {showSubtotal && <BillsListItemSubtotalSectionV1 bill={bill} isEditable={isEditable} />}
          {showTotal && (showEmptyTotals || bill.isEstimateAvailable) && (
            <React.Fragment>
              <Space height={isEditable ? 12 : 8} />
              <BillTotal bill={bill} />
            </React.Fragment>
          )}
          {(showBillRules || isEditable) && bill.hasBillRules && (
            <BillsListItemBillRulesList bill={bill} isEditable={isEditable} />
          )}
        </BillInformationContainer>
      </BillsListItemContainer>
      {responsive.mobile &&
        isEditable &&
        bill.project.organization.features.isEnabledProjectBillingEditMobileView && (
          <React.Fragment>
            <Space height={4} />
            <Row>
              <Space style={{flex: 1}} />
              {Bill.getIsDeletable(bill) && (
                <ActionButton onPress={deleteBillModal.handleOpen}>
                  <Icon source={Icon.Trash} color={colors.blue.accentDark} size={13} />
                </ActionButton>
              )}
              <Space width={4} />
              <ActionButton
                onPress={() => navigator.push(`/projects/billing/bills/${bill.uuid}/edit`)}
              >
                <Icon source={Icon.Edit} color={colors.blue.accentDark} size={13} />
                <Space width={8} />
                <ActionButtonText>Edit Bill</ActionButtonText>
              </ActionButton>
            </Row>
            <Space height={4} />
            <BillingProjectDeleteBillModal
              key={deleteBillModal.key}
              isOpen={deleteBillModal.isOpen}
              billUuid={bill.uuid}
              refetch={refetch}
              handleClose={deleteBillModal.handleClose}
            />
          </React.Fragment>
        )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

BillsListItemV1.fragment = gql`
  ${Bill.getEstimateTotal.fragment}
  ${Bill.getIsDeletable.fragment}
  ${BillsListItemBillRulesList.fragment}
  ${BillsListItemHeaderV1.fragment}
  ${BillsListItemPreSubtotalBillItemsListV1.fragment}
  ${BillsListItemSubtotalSectionV1.fragment}

  fragment BillsListItemV1 on Bill {
    id
    uuid
    title
    isEstimateAvailable
    tip
    estimateSalesTaxAmountMin
    estimateSalesTaxAmountMax
    hasBillModifiers
    hasBillRules
    index
    project {
      id
      organization {
        id
        features {
          isEnabledProjectBillingEditMobileView: isEnabled(
            feature: "PROJECT_BILLING_EDIT_MOBILE_VIEW"
          )
        }
      }
    }
    ...Bill_getEstimateTotal
    ...Bill_getIsDeletable
    ...BillsListItemBillRulesList
    ...BillsListItemHeaderV1
    ...BillsListItemPreSubtotalBillItemsListV1
    ...BillsListItemSubtotalSectionV1
  }
`;

export default BillsListItemV1;
