// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FlatList, Icon, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Line from '@shared/design/components/Line';
import TableBuilder, {
  ColumnDefinitionType,
  ItemType,
  RowHookType,
  ItemFunctionReturnVoidType,
} from '@shared/design/components/Table/components/TableBuilder';
import TableCard from '@shared/design/components/Table/components/TableCard';
import TableRow, {
  RowVerticalPaddingType,
} from '@shared/design/components/Table/components/TableRow';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';

const TableContainer = Styled.View`
  background-color: ${colors.white}
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 4px;
  overflow: hidden;
`;

const TableLoadingContainer = Styled.View`
  min-height: 70px;
`;

const HeaderRow = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 8px;
  padding-horizontal: 8px;
  min-height: 16px;
`;

const HeaderLabel = Styled.Text`
  ${Typography.Responsive.Label}
`;

const HeaderSecondaryLabel = Styled.Text`
  ${Typography.Responsive.MicroLabel}
`;

const EmptyStateContainer = Styled.View`
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 120px;
`;

const EmptyStateText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.secondary};
`;

const TableHeader = ({columnDefinitions}: {columnDefinitions: ColumnDefinitionType[]}) => {
  const responsive = useResponsive();

  return (
    <HeaderRow>
      {columnDefinitions.map((columnDefinition: ColumnDefinitionType, index: number) => {
        const {flex, minWidth, maxWidth, width, headerLabel, headerComponent} = columnDefinition;
        return (
          <TableBuilder.Cell key={index} style={{flex, minWidth, maxWidth, width}}>
            {headerComponent && headerComponent({})}
            {headerLabel && <HeaderLabel responsive={responsive}>{headerLabel}</HeaderLabel>}
            {TableBuilder.getColumnHasSecondary(columnDefinition) &&
              columnDefinition.secondary?.headerLabel && (
                <React.Fragment>
                  <HeaderSecondaryLabel responsive={responsive}>
                    {columnDefinition.secondary.headerLabel}
                  </HeaderSecondaryLabel>
                </React.Fragment>
              )}
          </TableBuilder.Cell>
        );
      })}
    </HeaderRow>
  );
};

interface TableBodyProps {
  columnDefinitions: ColumnDefinitionType[];
  items: ItemType[];
  itemKey?: string;
  onRowPress?: ItemFunctionReturnVoidType;
  emptyStateHeight?: number;
  emptyStateText?: string;
  EmptyStateComponent?: React.FC;
  isLoading?: boolean;
  isScrollable?: boolean;
  rowVerticalPadding?: RowVerticalPaddingType;
  hasError?: boolean;
  rowHook?: RowHookType;
  activeRowIndex?: number;
}
const TableBody = ({
  columnDefinitions,
  items,
  itemKey,
  onRowPress,
  emptyStateHeight,
  emptyStateText,
  EmptyStateComponent,
  isLoading,
  isScrollable,
  rowVerticalPadding,
  hasError,
  rowHook,
  activeRowIndex,
}: TableBodyProps) => {
  if (isLoading) {
    return (
      <TableLoadingContainer>
        <PageLoadingIndicator />
      </TableLoadingContainer>
    );
  } else if (hasError) {
    return (
      <EmptyStateContainer>
        <Icon source={Icon.ExclamationTriangle} size={18} color={colors.gray.tertiary} />
        <EmptyStateText>
          An error has occurred, please refresh the page. If the problem persists, please contact
          us.
        </EmptyStateText>
      </EmptyStateContainer>
    );
  } else if (items.length === 0) {
    const containerHeight = EmptyStateComponent ? emptyStateHeight || 300 : emptyStateHeight;
    return (
      <EmptyStateContainer height={containerHeight}>
        {EmptyStateComponent ? (
          <EmptyStateComponent />
        ) : (
          <EmptyStateText>{emptyStateText}</EmptyStateText>
        )}
      </EmptyStateContainer>
    );
  }

  return (
    <React.Fragment>
      {isScrollable ? (
        <FlatList
          keyExtractor={(item: ItemType, index: number) =>
            itemKey ? (_.get(item, itemKey) as React.Key) : index
          }
          data={items}
          renderItem={({item, index}: {item: ItemType; index: number}) => (
            <React.Fragment>
              <TableRow
                key={itemKey ? (_.get(item, itemKey) as React.Key) : index}
                columnDefinitions={columnDefinitions}
                item={item}
                rowIndex={index}
                rowHook={rowHook}
                onRowPress={onRowPress}
                isRowSelected={index === activeRowIndex}
                rowVerticalPadding={rowVerticalPadding}
              />
              <Line />
            </React.Fragment>
          )}
          contentContainerStyle={{flex: 1}}
        />
      ) : (
        <React.Fragment>
          {items.map((item: ItemType, index: number) => (
            <React.Fragment key={itemKey ? (_.get(item, itemKey) as React.Key) : index}>
              {index > 0 && <Line />}
              <TableRow
                columnDefinitions={columnDefinitions}
                item={item}
                rowIndex={index}
                rowHook={rowHook}
                onRowPress={onRowPress}
                isRowSelected={index === activeRowIndex}
                rowVerticalPadding={rowVerticalPadding}
              />
            </React.Fragment>
          ))}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

interface TableCardsListProps extends TableBodyProps {
  mobileRowPressLabel?: string;
}
const TableCardsList = ({
  columnDefinitions,
  items,
  itemKey,
  onRowPress,
  rowHook,
  activeRowIndex,
  mobileRowPressLabel,
  isScrollable,
}: TableCardsListProps) => {
  return (
    <React.Fragment>
      {isScrollable ? (
        <FlatList
          keyExtractor={(item: ItemType, index: number) =>
            itemKey ? (_.get(item, itemKey) as React.Key) : index
          }
          data={items}
          renderItem={({item, index}: {item: ItemType; index: number}) => (
            <TableCard
              item={item}
              rowIndex={index}
              columnDefinitions={columnDefinitions}
              rowHook={rowHook}
              onRowPress={onRowPress}
              isRowSelected={index === activeRowIndex}
              mobileRowPressLabel={mobileRowPressLabel}
            />
          )}
          ItemSeparatorComponent={Line}
          style={{borderRadius: 4}}
        />
      ) : (
        <React.Fragment>
          {items.map((item: ItemType, index: number) => {
            return (
              <React.Fragment key={itemKey ? (_.get(item, itemKey) as React.Key) : index}>
                {index > 0 && <Line />}
                <TableCard
                  item={item}
                  rowIndex={index}
                  columnDefinitions={columnDefinitions}
                  rowHook={rowHook}
                  onRowPress={onRowPress}
                  isRowSelected={index === activeRowIndex}
                  mobileRowPressLabel={mobileRowPressLabel}
                />
              </React.Fragment>
            );
          })}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export interface TableProps extends TableCardsListProps {
  rowVerticalPadding?: RowVerticalPaddingType;
  containerStyle?: React.CSSProperties;
}
const Table = ({
  columnDefinitions,
  items,
  itemKey,
  onRowPress,
  emptyStateHeight,
  emptyStateText = 'No results',
  EmptyStateComponent,
  isLoading = false,
  isScrollable = false,
  rowVerticalPadding,
  hasError = false,
  rowHook,
  activeRowIndex,
  containerStyle = {},
}: TableProps) => {
  const responsive = useResponsive();
  const filteredColumns = columnDefinitions.filter((column) => !column.isHidden);
  return (
    <TableContainer
      responsive={responsive}
      style={{
        flex: isScrollable && responsive.desktop ? 1 : undefined,
        flexShrink: isScrollable && !responsive.desktop ? 1 : undefined,
        ...containerStyle,
      }}
    >
      {responsive.desktop ? (
        <React.Fragment>
          <TableHeader columnDefinitions={filteredColumns} />
          <Line />
          <TableBody
            columnDefinitions={filteredColumns}
            items={items}
            itemKey={itemKey}
            onRowPress={onRowPress}
            emptyStateHeight={emptyStateHeight}
            emptyStateText={emptyStateText}
            EmptyStateComponent={EmptyStateComponent}
            isLoading={isLoading}
            isScrollable={isScrollable}
            rowVerticalPadding={rowVerticalPadding}
            hasError={hasError}
            rowHook={rowHook}
            activeRowIndex={activeRowIndex}
          />
        </React.Fragment>
      ) : (
        <TableCardsList
          columnDefinitions={filteredColumns}
          items={items}
          itemKey={itemKey}
          onRowPress={onRowPress}
          rowHook={rowHook}
          activeRowIndex={activeRowIndex}
          isScrollable={isScrollable}
        />
      )}
    </TableContainer>
  );
};

export const TableComponents = {
  PrimaryText: TableBuilder.PrimaryText,
  PrimaryLink: TableBuilder.PrimaryLink,
  SecondaryText: TableBuilder.SecondaryText,
  SecondaryLink: TableBuilder.SecondaryLink,
  HeaderLabel,
  HeaderSecondaryLabel,
};

export default Table;
